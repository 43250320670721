import { API } from '@config';
import { RateCalculatorTable } from '@src/types/RateCalculator';

export const getRateCalculator = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/rate-calculators?populate=*`, options);
  const data: RateCalculatorTable = await response.json();

  return data;
};
