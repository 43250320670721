import React, {useEffect, useState} from 'react';
import { Content as ContentContainer, H1, H2, Details } from '@core';
import { HeaderGradient } from '@src/components';
import { GPAINITIATIVESLINKS } from '@src/types/GpaInitiativesLinks';
import { getGpaInitiativesGuamLinks } from '@src/services/GpaInitiativesGuamLinks';
import { getGpaInitiativesOtherLinks } from '@src/services/GpaInitiativesOtherLinks';
import { API } from '@src/config';

export const OtherResources = () => {
  const [guamLinks, setGuamLinks] = useState<GPAINITIATIVESLINKS[]>([]);
  const [otherLinks, setOtherLinks] = useState<GPAINITIATIVESLINKS[]>([]);

  const getData = async () => {
    const guamLinksData = await getGpaInitiativesGuamLinks();
    const otherLinksData = await getGpaInitiativesOtherLinks();

    setGuamLinks(guamLinksData.data);
    setOtherLinks(otherLinksData.data);
  }

  console.log(guamLinks,otherLinks)

  useEffect(()=>{
    getData()
  })

  const clickItem = (link?: string, file?: any) => {
    if(file != null){
      window.open(`${API}${file}`);
    }else{
      window.open(`${link}`);
    }
  }

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Other Resources"
      />
      <ContentContainer>
        <H1 className="my-5 uppercase">ENERGY RESOURCE LINKS</H1>
        <Details>Links to external organizations that provide information about energy efficiency.</Details>
        <H2 className="mb-5">GUAM LINKS</H2>
        {guamLinks.map((content)=>{
          return(
            <>
            <Details className="text-orange mb-0.5">
              <button className='text-orange text-left' onClick={()=>clickItem(content.attributes.Link, content.attributes.File?.data != null ? content.attributes.File.data.attributes.url : content.attributes.File.data)} type="button">{content.attributes.Anchor}</button>
            </Details>
            <Details>{content.attributes.Description}</Details>
            </>
          )
        })}
        <H2 className="mb-5">OTHER LINKS</H2>
        {otherLinks.map((content)=>{
          return(
            <>
            <Details className="text-orange mb-0.5">
            <button className='text-orange' onClick={()=>clickItem(content.attributes.Link, content.attributes.File?.data != null ? content.attributes.File.data.attributes.url : content.attributes.File.data)} type="button">{content.attributes.Anchor}</button>
              </Details>
            <Details>{content.attributes.Description}</Details>
            </>
          )
        })}
        {/* {Content.contents.map((content) => {
          return (
            <>
              <H2 className="mb-5">{content.title}</H2>
              <Details className="text-orange mb-0.5">
                <a href={content.link} rel="noreferrer" target="_blank">
                  {content.text}
                </a>
              </Details>
              <Details>{content.description}</Details>
            </>
          );
        })} */}
      </ContentContainer>
    </>
  );
};
