import React, { FC, PropsWithChildren, HTMLAttributes } from 'react';

interface H2Prop extends HTMLAttributes<HTMLHeadElement> {
  className?: string;
}

type H2Props = PropsWithChildren<H2Prop>;

export const H2: FC<H2Props> = ({ children, className }, props) => {
  return (
    <h2
      className={`font-rubik font-bold phone:text-sm tablet:text-base laptop:text-lg 
  desktop:text-lg mb-2 ${className}`}
      {...props}
    >
      {children}
    </h2>
  );
};
