import { H3 } from '@src/core';
import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

interface AccordionSmoothProps {
  title: string;
  content: string;
}

export const AccordionBlue = ({ title, content }: AccordionSmoothProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div className="power-alerts__shadow">
      <button
        className="flex items-center w-full justify-between px-4 pt-4 pb-3 mt-2 bg-blue"
        onClick={toggleAccordion}
        type="button"
      >
        <H3 className="text-white w-5/6 text-left">{title}</H3>
        <FaChevronDown
          className={`w-8 h-8 ease-linear ${isAccordionOpen ? 'rotate-180' : ''}`}
          color="white"
        />
      </button>
      <div
        className={`shadow-3xl shadow-cyan-500/50 mb-6 border-blue border-2 rounded-none px-4 overflow-hidden  ${
          isAccordionOpen ? 'max-h-96 py-4' : 'max-h-0 py-0'
        } ease-linear`}
      >
        {content}
      </div>
    </div>
  );
};
