import React, { useState, useEffect } from "react";
import {
  H2,
  H3,
  Details,
  Content as ContentContainer,
  Images,
  H4,
  H1,
  List,
  UnorderedList,
} from "@core";
import { HeaderGradient } from "@components";
import { Dialog, Tab } from "@headlessui/react";
import { getCommunitySafetyIndoor } from "@src/services/CommunitySafetyIndoor";
import { COMMUNITYSAFETYINDOOR } from "@src/types/CommunitySafetyIndoor";
import { API } from "@config";
import { COMMUNITYSAFETYOUTDOOR } from "@src/types/CommunitySafetyOutdoor";
import { COMMUNITYSAFETYSTORM } from "@src/types/CommunitySafetyStorm";
import { getCommunitySafetyOutdoor } from "@src/services/CommunitySafetyOutdoor";
import { getCommunitySafetyStorm } from "@src/services/CommunitySafetyStorm";

export const Safety = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [modalList, setModalList] = useState<string[]>([]);

  const [indoor, setIndoor] = useState<COMMUNITYSAFETYINDOOR[]>([]);
  const [outdoor, setOutdoor] = useState<COMMUNITYSAFETYOUTDOOR[]>([]);
  const [storm, setStorm] = useState<COMMUNITYSAFETYSTORM[]>([]);

  const getData = async () => {
    const indoorData = await getCommunitySafetyIndoor();
    setIndoor(indoorData.data);

    const outdoorData = await getCommunitySafetyOutdoor();
    setOutdoor(outdoorData.data);

    const stormData = await getCommunitySafetyStorm();
    setStorm(stormData.data);
  };

  useEffect(() => {
    getData();
  }, []);

  function myDialog() {
    return (
      <Dialog
        className="relative z-50"
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <div aria-hidden="true" className="fixed inset-0 bg-black opacity-20" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-white flex flex-col items-center px-6 py-10">
            <div className="w-32 h-32">
              <img
                alt="Modal Thumbnail"
                className="w-full h-full  bg-blue rounded-full"
                src={modalImage}
              />
            </div>
            <Dialog.Title className="text-center">
              <H1>{modalTitle}</H1>
            </Dialog.Title>
            <Dialog.Description className="text-center font-rubik text-lg">
              {modalDescription}
            </Dialog.Description>
            <UnorderedList className="text-sm">
              {modalList.map((list) => (
                <List key={list}>{list}</List>
              ))}
            </UnorderedList>
            <button
              className="bg-orange text-white font-rubik w-20 h-10 rounded-full mt-4"
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(false);
              }}
              type="button"
            >
              Ok
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    );
  }

  function prepareAndOpenMyDialog(
    imageUrl: string,
    title: string,
    description: string,
    list: string[]
  ) {
    setModalImage(`${API}${imageUrl}`);
    setModalTitle(title);
    setModalDescription(description);
    setModalList(list);
    setIsOpen(true);
  }

  return (
    <>
      {myDialog()}
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Safety"
      />
      <section className=" relative">
        <div className="absolute h-full bg-black/50" />
        <div className="absolute inset-0  h-full bg-black bg-opacity-10 z-10" />
        <div className="h-full flex items-center laptop:px-28 tablet:px-20 phone:px-10 z-20 absolute">
          <div className=" tablet:w-500px phone:w-full flex flex-col tablet:items-start phone:items-center py-20">
            <h1
              className="font-rubik font-bold text-white tabletWide:text-5xl phoneWide:text-3xl phone:text-lg
              tablet:text-left phone:text-center"
            >
              GPA wants to keep you safe while we keep you energized.
            </h1>
          </div>
        </div>
        <img
          alt="News And Media Banner"
          className="w-full aspect-ratio__16-5"
          src={Images.CommunityBanner}
        />
      </section>
      <ContentContainer>
        <section>
          <div className="laptop:h-1/2 w-full flex tabletWide:flex-row phone:flex-col-reverse bg-white">
            <div className="h-96 w-full overflow-hidden object-cover object-center tabletWide:w-3/6 tabletWide:flex tabletWide:items-center laptopSm:w-2/5">
              <div className="youtube-video__container">
                <iframe
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  className="youtube-video__iframe"
                  frameBorder="0"
                  height="720"
                  src="https://www.youtube.com/embed/dSd-5fOvGjQ"
                  title="GPA PSA: Avoid Power Lines When Fruit Picking"
                  width="1280"
                />
              </div>
            </div>
            <div className="w-auto mr-auto flex flex-col justify-center p-2 laptopSm:w-3/5 tabletWide:w-3/6 laptop:p-14">
              <h2 className="font-jubilat text-blue laptop:text-5xl phone:text-2xl">
                Safety Contacts.
              </h2>
              <H4 className="text-blue">
                Tree Trimming Services (671) 647-5787
              </H4>
              <p className="font-rubik font-normal text-black mt-5 laptop:text-xl phone:text-lg">
                Trimming trees next to power lines can be dangerous to you and
                others. Our line workers are trained to do it for you. Just take
                a photo of your tree and imposing power line and email us the
                photo with your name, contact number and address at{" "}
                <a
                  className="text-blue underline"
                  href="mailto:customersfirst@gpagwa.com"
                >
                  customersfirst@gpagwa.com
                </a>
                .
              </p>
            </div>
          </div>
        </section>
      </ContentContainer>
      <ContentContainer className="bg-grayC410">
        <section className="w-full">
          <h1
            className="font-jubilat text-center text-blue laptop:mb-14 phone:mb-10 laptop:text-5xl 
        tablet:text-5xl phone:text-4xl"
          >
            PROGRAMS AND SERVICES
          </h1>
          <Tab.Group>
            <Tab.List className="flex items-center justify-center space-x-8">
              <Tab>
                <H2 className="text-blue">Indoor</H2>
              </Tab>
              <Tab>
                <H2 className="text-blue">Outdoor</H2>
              </Tab>
              <Tab>
                <H2 className="text-blue">Storm</H2>
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="flex flex-wrap justify-center">
                {indoor.map((content) => {
                  return (
                    <>
                      <div className="w-full phoneWide:w-1/2 laptopSm:w-1/3 my-0 tablet:my-5 flex items-start justify-center">
                        <button
                          className="w-3/4 text-center flex flex-col justify-center items-center hover:shadow-lg cursor-pointer px-4 py-4 tablet:px-16 tablet:py-8 hover:bg-white ease-in duration-300"
                          onClick={(e) => {
                            e.preventDefault();
                            prepareAndOpenMyDialog(
                              content.attributes.File.data != null
                                ? content.attributes.File.data.attributes.url
                                : content.attributes.File.data,
                              content.attributes.Title,
                              content.attributes.Description,
                              []
                            );
                          }}
                          type="button"
                        >
                          <div className="w-32 h-32 mb-4">
                            <img
                              alt={content.attributes.Title}
                              className="mb-3 w-full h-full bg-blue rounded-full"
                              src={`${API}${
                                content.attributes.File.data != null
                                  ? content.attributes.File.data.attributes.url
                                  : content.attributes.File.data
                              }`}
                            />
                          </div>
                          <H3 className="mb-2 text-blue line-clamp-2">
                            {content.attributes.Title}
                          </H3>
                          <Details className="line-clamp-2">
                            <a href={content.attributes.Title}>
                              {content.attributes.Description}
                            </a>
                          </Details>
                        </button>
                      </div>
                    </>
                  );
                })}
              </Tab.Panel>
              <Tab.Panel className="flex flex-wrap justify-center">
                {outdoor.map((content) => {
                  return (
                    <>
                      <div className="w-full phoneWide:w-1/2 laptopSm:w-1/3 my-0 tablet:my-5 flex items-start justify-center">
                        <button
                          className="w-3/4 text-center flex flex-col justify-center items-center hover:shadow-lg cursor-pointer px-4 py-4 tablet:px-16 tablet:py-8 hover:bg-white ease-in duration-300"
                          onClick={(e) => {
                            e.preventDefault();
                            prepareAndOpenMyDialog(
                              content.attributes.File.data != null
                                ? content.attributes.File.data.attributes.url
                                : content.attributes.File.data,
                              content.attributes.Title,
                              content.attributes.Description,
                              []
                            );
                          }}
                          type="button"
                        >
                          <div className="w-32 h-32 mb-4">
                            <img
                              alt={content.attributes.Title}
                              className="mb-3 w-full h-full rounded-full bg-blue"
                              src={`${API}${
                                content.attributes.File.data != null
                                  ? content.attributes.File.data.attributes.url
                                  : content.attributes.File.data
                              }`}
                            />
                          </div>
                          <H3 className="mb-2 text-blue line-clamp-2">
                            {content.attributes.Title}
                          </H3>
                          <Details className="line-clamp-2">
                            <a href={content.attributes.Title}>
                              {content.attributes.Description}
                            </a>
                          </Details>
                        </button>
                      </div>
                    </>
                  );
                })}
              </Tab.Panel>
              <Tab.Panel className="flex flex-wrap justify-center">
                {storm.map((content) => {
                  return (
                    <>
                      <div className="w-full phoneWide:w-1/2 laptopSm:w-1/3 my-0 tablet:my-5 flex items-start justify-center">
                        {content.attributes.Document.data !== null ? (
                          <a
                            className="text-center flex flex-col justify-center items-center"
                            href={`${API}/${content.attributes.Document.data.attributes.url}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <button
                              className="w-3/4 text-center flex flex-col justify-center items-center hover:shadow-lg cursor-pointer px-4 py-4 tablet:px-16 tablet:py-8 hover:bg-white ease-in duration-300"
                              type="button"
                            >
                              <div className="w-32 h-32 mb-4">
                                <img
                                  alt={content.attributes.Title}
                                  className="mb-3 w-full h-full bg-blue rounded-full"
                                  src={`${API}${
                                    content.attributes.File.data != null
                                      ? content.attributes.File.data.attributes
                                          .url
                                      : content.attributes.File.data
                                  }`}
                                />
                              </div>
                              {/* <H3 className="mb-2 text-blue line-clamp-2">
                              {content.attributes.Title}
                            </H3> */}
                              <Details className="line-clamp-2">
                                <a href={content.attributes.Title}>
                                  {content.attributes.Title}
                                </a>
                              </Details>
                            </button>
                          </a>
                        ) : (
                          <button
                            className="w-3/4 text-center flex flex-col justify-center items-center hover:shadow-lg cursor-pointer px-4 py-4 tablet:px-16 tablet:py-8 hover:bg-white ease-in duration-300"
                            onClick={(e) => {
                              e.preventDefault();
                              prepareAndOpenMyDialog(
                                content.attributes.File.data != null
                                  ? content.attributes.File.data.attributes.url
                                  : content.attributes.File.data,
                                content.attributes.Title,
                                content.attributes.Description,
                                []
                              );
                            }}
                            type="button"
                          >
                            <div className="w-32 h-32 mb-4">
                              <img
                                alt={content.attributes.Title}
                                className="mb-3 w-full h-full bg-blue rounded-full"
                                src={`${API}${
                                  content.attributes.File.data != null
                                    ? content.attributes.File.data.attributes
                                        .url
                                    : content.attributes.File.data
                                }`}
                              />
                            </div>
                            <H3 className="mb-2 text-blue line-clamp-2">
                              {content.attributes.Title}
                            </H3>
                            <Details className="line-clamp-2">
                              <a href={content.attributes.Title}>
                                {content.attributes.Description}
                              </a>
                            </Details>
                          </button>
                        )}
                      </div>
                    </>
                  );
                })}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </section>
      </ContentContainer>
    </>
  );
};
