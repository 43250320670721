import React from 'react';
import { Images } from '@core';
import { ManageMyAccountBusiness as Content } from '@content';
import { LearnMoreItem } from '@components';

export const ManageMyAccountBusiness = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <section
        className="h-2/3 tablet:h-1/2 phone:bg-left tabletWide:bg-center bg-cover bg-hero bg-no-repeat flex flex-col"
        style={{ backgroundImage: `url(${Images.BusinessBanner})` }}
      >
        <div
          className="h-full bg-black bg-opacity-30 flex flex-col items-center laptop:px-20 phone:px-10 
        justify-center"
        >
          <h1
            className="font-jubilat font-bold text-white mb-5 laptop:text-7xl tablet:text-5xl phone:text-5xl
            tablet:text-left phone:text-center"
          >
            {Content.title}
          </h1>
          <p
            className="font-rubik font-light text-white mb-5 laptop:text-3xl tablet:text-2xl phone:text-lg
            tablet:text-left phone:text-center"
          >
            {Content.description}
          </p>
          <div className="flex mt-10 tablet:flex-row phone:flex-col">
            <a href="http://paygpa.com/" rel="noreferrer" target="_blank">
              <button
                className="bg-orange h-16 py-4 ring-white ring-opacity-60 rounded-full text-white text-bold laptop:px-6 
                phone:px-3 phone:text-sm tablet:text-base mx-2 flex items-center justify-center"
                type="button"
              >
                <img alt="GPA Logo" className="h-8 mr-2 tablet:mr-5" src={Images.GPALogo} /> Login
                to Your PayGPA Account
              </button>
            </a>
            <a href="http://myenergyguam.com/" rel="noreferrer" target="_blank">
              <button
                className="bg-orange h-16 py-4 ring-white ring-opacity-60 rounded-full text-white text-bold laptop:px-6
              phone:px-3 phone:text-sm tablet:text-base mx-2 phone:mt-4 tablet:mt-0"
                type="button"
              >
                Login to Your MyEnergyGuam Account
              </button>
            </a>
          </div>
        </div>
      </section>
      <section
        className="w-full flex items-center justify-center bg-white px-10 tabletWide:px-48 laptop:px-10 py-20 tabletWide:py-32 flex-wrap
      gap-x-20"
      >
        {Content.learnMore.map((value) => (
          <LearnMoreItem
            icon={value.icon}
            key={value.title}
            link={value.link}
            title={value.title}
          />
        ))}
      </section>
    </>
  );
};
