import { Images } from "@src/core";
import React, { MouseEventHandler, useState } from "react";
import { FaPlus } from "react-icons/fa";

const openAnimation = {
  animation: "buttonOpening 250ms ease-in",
};

const closeAnimation = {
  animation: "buttonClosing 270ms ease-out",
  animationFillMode: "forwards",
};

export const BeatThePeak = () => {
  const [showAboutThisProgramContent, setShowAboutThisProgramContent] =
    useState<boolean>(false);
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <div
        className="pb-20"
        style={{
          backgroundColor: "whitesmoke",
        }}
      >
        <div
          className="pt-4 items-center phone:justify-center phone:flex phone:px-8 tablet:px-0 tablet:block related"
          style={{
            minHeight: "350px",
            backgroundImage: `url(${Images.GPABeattheBeakBanner})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <img
            alt="logo"
            className="absolute h-16 w-16 right-12 phone:hidden tabletWide:block"
            src={Images.GPALogo}
          />
          <div className="flex items-center">
            <h1
              className="font-newmake text-white text-center phone:text-3xl phone:mb-6 tablet:mt-6 tablet:text-6xl"
              style={{
                maxWidth: "750px",
              }}
            >
              YOU HAVE THE POWER TO STOP{" "}
              <span className="with-outline outages-underline-arc">
                OUTAGES
              </span>
            </h1>
            <img
              alt="peak-graph"
              className="h-72 phone:hidden tablet:block"
              src={Images.BeatThePeakLogo}
            />
          </div>
        </div>
        <div className="phone:px-12 phoneLg:px-4">
          <img alt="peak-graph" className="mt-12" src={Images.PeakGraph} />
          <h1 className="font-bold text-center text-blue mt-10 phone:text-3xl tablet:text-6xl">
            Beat the Peak
          </h1>
          <p
            className="text-center text-blue mx-auto mt-4 phone:text-xl tablet:text-2xl"
            style={{
              maxWidth: "700px",
            }}
          >
            By working together and making shifts in our energy usage, GPA
            customers can beat the peak and stop outages.
          </p>
          <button
            className="phone:text-lg font-bold tablet:text-2xl border-4 border-red border-solid w-full mx-auto w-max px-4 rounded-full text-red block mt-8 cursor-default"
            style={{
              maxWidth: "300px",
              color: "#DC3545",
              backgroundColor: '"#DC3545"',
            }}
            type="button"
          >
            JOIN THE CHARGE
          </button>
          <p
            className="phone:text-lg phone:text-center tablet:text-xl tablet:text-left mt-8"
            style={{
              maxWidth: "900px",
              marginInline: "auto",
            }}
          >
            We all know power use during peak hours, between 5PM to 11PM is the
            highest. However, being mindful about our energy use at peak hours
            will help stop scheduled outages and save you money. Let’s do this
            together! <br /> <br /> If we are more conscious of what can be done
            to reduce unnecessary power consumption; if we remind each other and
            encourage one another to be wise with how we choose to use our
            power, then together we can Beat the Peak! <br /> <br /> As Guam's
            sizzling September heat rises, so too will the demands on our power
            grid. Extreme weather will elicit increased energy consumption,
            which will also contribute to increased utility expenses.
          </p>
          <div
            className="flex phone:flex-col phoneWide:flex-row items-center justify-left mx-auto mt-12"
            style={{
              maxWidth: "900px",
            }}
          >
            <img
              alt="no-exit-logo"
              className="h-16 phone:mb-4 phoneWide:mb-0"
              src={Images.Sun}
            />
            <p
              className="phone:text-lg phone:text-center tablet:text-xl text-left text-blue tablet:ml-4"
              style={{
                maxWidth: "900px",
              }}
            >
              Using energy in the morning and midday makes the most of our solar
              systems, which produce more power during off-peak hours.
            </p>
          </div>
          <h1 className="font-bold phone:text-3xl tablet:text-5xl text-center text-blue mt-20">
            Together we can
            <span className="underline-arc"> avoid outages.</span>
          </h1>
          {/* width: 500px; 
          height: 100px;  
          border: solid 5px #000;
          border-color: #000 transparent transparent transparent;
          border-radius: 50%/100px 100px 0 0; */}
          <div className="flex items-center justify-center mt-20 phone:flex-col phoneLg:flex-row">
            {/* Left side */}
            <div
              className="bg-white text-center pb-20 px-8"
              style={{
                maxWidth: "450px",
                minHeight: "350px",
              }}
            >
              <img
                alt="no-exit-logo"
                className="mx-auto mt-20"
                src={Images.NoExit}
                style={{ height: "110px" }}
              />
              <h1 className="text-xl font-bold text-blue mt-6">
                Avoid using large appliances.
              </h1>
              <p className="mt-4">
                That means doing laundry or running the water heater overnight
                or earlier in the day if possible.
              </p>
            </div>

            {/* Right side */}
            <div
              className="bg-white text-center pb-20 px-2 phoneLg:ml-6 phoneLg:mt-0 phone:mt-8"
              style={{
                maxWidth: "450px",
                // marginLeft: "20px",
                minHeight: "350px",
              }}
            >
              <img
                alt="temp-logo"
                className="h-36 mx-auto mt-20"
                src={Images.Temp75}
                style={{ height: "124px" }}
              />
              <h1 className="text-xl font-bold text-blue mt-2">
                Only use aircon when necessary.
              </h1>

              <p className="mt-4">
                Setting the temperature to 75&deg;/24&#8451; or as high as your
                family can safely tolerate is the best way to beat the peak.
              </p>
            </div>
          </div>
          <button
            className="phone:text-lg text-2xl bg-green w-max mx-auto px-4 rounded-full text-white font-bold py-2 block mt-16"
            style={{
              maxWidth: "300px",
              backgroundColor: "#3AB549",
            }}
            type="button"
          >
            <a
              href="https://energysense.guampowerauthority.com/"
              rel="noreferrer"
              target="_blank"
            >
              ENERGY-SAVING TIPS
            </a>
          </button>
          <div>
            <div
              className="flex items-center mt-16"
              style={{
                maxWidth: "900px",
                marginInline: "auto",
              }}
            >
              <h1
                className="font-rubik font-normal text-blue phone:text-lg tablet:text-xl laptop:text-2xl 
  desktop:text-2xl phone:mt-0"
              >
                About this program
              </h1>
              <FaPlus
                // eslint-disable-next-line no-unused-vars
                onClick={(_event): MouseEventHandler<SVGElement> | void =>
                  setShowAboutThisProgramContent((prevState) => !prevState)
                }
                style={{
                  ...(showAboutThisProgramContent
                    ? closeAnimation
                    : openAnimation),
                  marginLeft: "15px",
                  cursor: "pointer",
                }}
              />
            </div>
            {showAboutThisProgramContent ? (
              <p
                className="text-lg mt-4"
                style={{
                  maxWidth: "900px",
                  marginInline: "auto",
                }}
              >
                Electricity is a real-time service. As energy use fluctuates
                throughout the day, we must generate the exact amount of power
                our customers need at the exact moment they use it. <br />{" "}
                <br />
                As we repair our infrastructure and continue our work on the
                completion of the Ukudu power plant we ask our customers to help
                in conserving power to reduce service interruptions.
                <br /> <br /> We start by asking large industrial users to
                suspend operations and by shutting off service to customers that
                have agreed to occasional interruptions through "Interruptible
                load program". Next, we appeal to the public for conservation,
                urging customers to turn up their thermostats and take other
                steps to conserve energy during peak hours. If this doesn't
                work, our last resort is to create "scheduled outages" - short
                power outages that are rotated from one village to another to
                limit the impact for any one group of customers.
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
