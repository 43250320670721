import React from 'react';
import { H2, Details, List, UnorderedList, Content as ContentContainer, OrderedList } from '@core';
import {
  ContributionRates,
  CreditableService,
  DefinedBenefitPlan,
  DefinedContributionPlan,
  RetirementEligibility,
} from '@content';
import { HeaderGradient } from '@components';

export const RetirementPlans = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={DefinedBenefitPlan.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <section>
              <H2 className="text-blue">{DefinedBenefitPlan.title}</H2>
              <Details>
                {DefinedBenefitPlan.contents.map((content) => {
                  return (
                    <>
                      {content} <br />
                      <br />
                    </>
                  );
                })}
              </Details>
              <OrderedList className="-mt-5 mb-5">
                {DefinedBenefitPlan.ol.map((value) => {
                  return <List key={value}>{value}</List>;
                })}
              </OrderedList>
              <Details>{DefinedBenefitPlan.outro}</Details>
            </section>
            <section className="my-7">
              <H2 className="text-blue">{ContributionRates.title}</H2>
              <Details>{ContributionRates.description}</Details>
            </section>
            <section className="my-7">
              <H2 className="text-blue">{CreditableService.title}</H2>
              <Details>{CreditableService.description}</Details>
              <OrderedList>
                {CreditableService.ol.map((value) => {
                  return <List key={value}>{value}</List>;
                })}
              </OrderedList>
            </section>
            <section className="my-7">
              <H2 className="text-blue">{RetirementEligibility.title}</H2>
              {RetirementEligibility.contents.map((content) => {
                return (
                  <>
                    <Details>
                      {content.intro} <br /> <br /> {content.description}
                    </Details>
                    <UnorderedList className='mb-5'>
                      {content.ul.map((value) => {
                        return <List key={value}>{value}</List>;
                      })}
                    </UnorderedList>
                  </>
                );
              })}
              <Details>{RetirementEligibility.description}</Details>
              <UnorderedList>
                {RetirementEligibility.ul.map((value) => {
                  return <List key={value}>{value}</List>;
                })}
              </UnorderedList>
            </section>
            <hr className="border-gray mt-7 mb-10" />
            <section className="my-7">
              <H2 className="text-blue">{DefinedContributionPlan.title}</H2>
              <Details>
                {DefinedContributionPlan.intro} <br /> <br /> {DefinedContributionPlan.description}
              </Details>
              <UnorderedList className='mb-10'>
                {DefinedContributionPlan.ul.map((value) => {
                  return <List key={value}>{value}</List>;
                })}
              </UnorderedList>
              {DefinedContributionPlan.contents.map((content) => {
                return (
                  <>
                    <H2>{content.title}</H2>
                    <Details>{content.description}</Details>
                  </>
                );
              })}
              {DefinedContributionPlan.percentages.map((content) => {
                return (
                  <>
                    <H2>{content.title}</H2>
                    <UnorderedList>
                      {content.ul.map((value) => {
                        return <List key={value}>{value}</List>;
                      })}
                    </UnorderedList>
                  </>
                );
              })}
              {DefinedContributionPlan.options.map((content) => {
                return (
                  <>
                    <H2>{content.title}</H2>
                    <Details>
                      {content.description} &nbsp;
                      <a className='text-orange' href={content.link} rel="noreferrer" target="_blank">{content.anchor}</a>
                    </Details>
                  </>
                );
              })}
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
