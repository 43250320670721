export const CustomerService = {
  title: `CUSTOMER SERVICE | <span class="text-black">START, STOP & MOVE SERVICE</span>`,
  contents: [
    {
      anchor: `Start Service: Application and Required Documents`,
      link: `#start-service`,
    },
    {
      anchor: `Credit Establishment / Re-establishment`,
      link: `#credit-establishment`,
    },
    {
      anchor: `Application Fee`,
      link: `#application-fee`,
    },
    {
      anchor: `Security Deposit`,
      link: `#security-deposit`,
    },
    {
      anchor: `Return of Security Deposit`,
      link: `#return-deposit`,
    },
    {
      anchor: `Service Reconnection Fee`,
      link: `#reconnection-fee`,
    },
    {
      anchor: `Stop Service`,
      link: `#stop-service`,
    },
    {
      anchor: `GPA Payment Centers`,
      link: `#payment-centers`,
    },
    {
      anchor: `Net Metering Application`,
      link: `#metering-application`,
    },
  ],
  additionals: [
    {
      id: `payment-centers`,
      header: `Payment Center Locations/Hours/Phone Numbers`,
      text: `Here's where you can apply for service`,
      link: `/contact-us`,
      anchor: ``,
    },
    {
      id: `metering-application`,
      header: `Net Metering Application`,
      text: ``,
      link: ``,
      anchor: `Here's your application for net metering for residential customers.`,
    },
  ],
};

export const ServiceTable = {
  table: [
    {
      th: [`Number of Rooms`, `Deposit Amount`],
    },
  ],
};

export const SSMServiceContent1 = {
  title: `Start, Stop & Move Service`,
  contents: [
    {
      header: `Start Service: Application and Required Documents`,
      description: `To apply for power, visit the Payment Center at the Gloria B. Nelson Public Service Building, 688 Route 15 Fadian, Mangilao; the Hagatña Satellite Office, Suite 103 Julale Shopping Center, 424 West O’Brien Drive; or the Guam Waterworks Authority in upper Tumon. To save time, you may download the application and fill it out prior your visit. You'll also need to bring the documents and payments that are explained below.`,
      link: {
        text: 'Application for Power Download 489 kB pdf',
        link: '',
      },
      ulTitle: `Pursuant to GPA Service Rules & Regulations, Rule 3, Section A.10., the 
      following establishes the required documents needed to apply for power:`,
      ul: [
        `Any building/property owner or landlord applying for electrical service must submit to the Authority 
        a copy of documents substantiating ownership that references the location where electricity is to be 
        provided. The documentation may include, but is not limited to, building permit, building/house number, 
        certificate of title, warranty deed, rental business license, etc.`,
        `Any tenant or agent applying for electric service must provide one of the following: a verified and 
        confirmed house/building number, certificate of title, owner/landlord business license, signed 
        lease/rental agreement and a letter of authorization for electrical service installation including a 
        verified/confirmed service location and meter number. The authorization letter shall remain valid for 
        15 calendar days from the date of the signed letter.`,
        `Any business establishment applying for electrical service, must provide a copy of business license, 
        building number, articles of incorporation/by laws, lease/rental agreement and authorization.`,
      ],
      descriptionEnd: `This requisite is not intended to deny electrical service to anyone, but to 
      protect the Authority's interests from any unauthorized electrical installation at an owner's private property.
      `,
      semiContents: [
        {
          id: `credit-establishment`,
          header: `Credit Establishment / Re-establishment`,
          description: `A customer applying for service will be required to establish or 
          re-establish credit before service will be provided.`,
          ulTitle: `Credit Establishment: Credit can be established when:`,
          ul: [
            `the customer establishes a record of prompt payment for services for twelve (12) consecutive months`,
            `the authority may grant a widowed customer or co-customer credit with the spouse's prior good credit rating, or`,
            `the customer pays a cash security deposit as described below.`,
          ],
          descriptionEnd: `Credit Re-establishment: A customer whose service has been discontinued, or who was 
          disconnected for non-payment of bills, will be required to pay all debts owed to the Authority, including 
          deposits and the application accommodation charges, before new service is provided.`,
        },
        {
          id: `application-fee`,
          header: `Application Fee`,
          description: `An application fee of $25.00 will be assessed upon applying for power.`,
          ulTitle: ``,
          ul: [],
          descriptionEnd: ``,
        },
        {
          id: `security-deposit`,
          header: `Security Deposit`,
          description: `A security deposit which is intended to guaranty payment of bills, will be required for any 
          customer who has not established credit or for re-connection for non-payment.`,
          ulTitle: ``,
          ul: [],
          descriptionEnd: ``,
        },
        {
          id:``,
          header: `Residential Service:`,
          description: `New customers upon application of service will be assessed the following security deposit 
          based on the service class, number of rooms and use of air conditioners:`,
          ulTitle: ``,
          ul: [],
          descriptionEnd: ``,
        },
      ],
    },
  ],
};

export const SSMServiceContent2 = {
  contents: [
    {
      header: `Other Classes of Service: `,
      description: `The amount of deposit shall not exceed 2 1/2 times the estimated monthly bill, but shall 
          not be less than $125.00.`,
    },
    {
      header: `Transfer of Deposits: `,
      description: `Deposits plus interest are transferable from one customer to another, upon presentation 
          of a notarized written consent of the depositor and/or when both parties are present and sign the agreement.`,
    },
    {
      header: `Interest on Security Deposit: `,
      description: `Payment of interest at the rate of 1.24% per annum, will be made annually and automatically 
          credited to the customer's account. The deposit will cease to earn interest on the date it is returned 
          or on the date the deposit and interest is credited to the customer's account.`,
    },
  ],
  contents2: [
    {
      id: `return-deposit`,
      header: `Return of Security Deposit`,
      description: ``,
      ulTitle: ``,
      ul: [],
      descriptionEnd: ``,
      semiContents: [
        {
          header: `Pre-service Cancellation: `,
          description: `When an application for service has been canceled prior to the establishment of service, 
          the deposit will be applied to any charges applicable in accordance with the rate schedules. 
          The excess portion of the deposit shall be returned and the cusotmer shall be so advised.`,
          ulTitle: ``,
          ul: [],
          descriptionEnd: ``,
        },
        {
          header: `Service Termination:`,
          description: `A customer is entitled to receive a refund of the original deposit, any additional deposits made, 
          plus accrued interest upon discontinuance of service, or disconnection for non-payment of bills. The Authority 
          shall have the right to offset any balance due on the customer's account against their security deposit.`,
          ulTitle: ``,
          ul: [],
          descriptionEnd: ``,
        },
        {
          header: `Good Credit:`,
          description: `The Authority shall review a customer's account at least once every year and if such review 
          indicates the customer has established credit as provided under the Service Rules and Regulations Section 
          20210.10, A, then the outstanding deposit shall be refunded to the customer. GPA shall afford its customers 
          the option of having the deposit refund applied to the customer's account in the form of a credit, or 
          having the deposit refunded by separate check in a period not to exceed one (1) billing cycle.`,
          ulTitle: ``,
          ul: [],
          descriptionEnd: ``,
        },
      ],
    },
    {
      id: `reconnection-fee`,
      header: `Service Reconnection Fee`,
      description: `If at anytime your service is terminated for non-payment or non-compliance with any GPA 
      service rules/regulations or rate schedules, a reconnection fee is required before service is restored.`,
      ulTitle: ``,
      ul: [
        `$25 for application fees (re-establish the account).`,
        `Fee for same-day service to be determined by customer's rate schedule (Please call 647-5787 or more information)`,
      ],
      descriptionEnd: ``,
      semiContents: [],
    },
    {
      id: `stop-service`,
      header: `Stop Service: How to Close Your Account`,
      intro: `Requests for termination `,
      description: `of services must be made two (2) working days prior to the 
      actual requested date by the account holder. The request can be made either in person, or with 
      written authorization by the account holder.`,
      ulTitle: ``,
      ul: [
        `The account holder terminating electrical service must provide a copy of valid identification, 
        sketch to premises and update information/forwarding address.`,
        `The meter must be accessible.`,
        `The account holder will be sponsible for all charges until discontinuance of electrical service.`,
        `Upon discontinuance of service, the Authority will refund the customer's deposit by applying it to 
        the customer's account or issuing a check; in each case the customer will be advised.`,
      ],
      descriptionEnd: ``,
      semiContents: [],
    },
  ],
};
