import React from 'react';
import { Images, Content as ContentContainer } from '@core';
import { HeaderGradient } from '@components';

export const UnderstandMyBill = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Understand My Bill"
      />
      <ContentContainer>
        <div className="w-full flex items-center justify-center">
          <img
            alt="We're here to help"
            className="laptop:h-full tablet:h-auto phone:h-auto"
            src={Images.BillSample}
          />
        </div>
      </ContentContainer>
    </>
  );
};
