import React from 'react';

interface HeaderGradientProps {
  title: string;
  rightColor: string;
  leftColor: string;
}

export const HeaderGradient: React.FC<HeaderGradientProps> = ({ title, rightColor, leftColor }) => {
  const color = `${leftColor} ${rightColor}`;

  return (
    // Temporary change, client requested to use plain blue
    // bg-gradient-to-r
    <div
      className={`w-full flex items-center desktop:h-20 phone:h-14 
      laptop:px-20 tablet:px-12 phone:px-7 bg-blue ${color}`}
    >
      <h1 className="font-jubilat font-bold tracking-normal text-white laptop:text-3xl tablet:text-2xl phone:text-xl">
        {title}
      </h1>
    </div>
  );
};
