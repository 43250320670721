import { API } from '@config';
import { FileData } from '@types';

export const getFileData = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/content-files?populate=File`, options);
  const data: FileData = await response.json();

  return data;
};

// export const getFileData2 = async () => {
//   const options = {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   };
//   const response = await fetch(
//     `${API}/api/content-files?populate=File&pagination[page]=2`,
//     options,
//   );
//   const data: FileData = await response.json();

//   return data;
// };

// export const getFileData3 = async () => {
//   const options = {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   };
//   const response = await fetch(
//     `${API}/api/content-files?populate=File&pagination[page]=3`,
//     options,
//   );
//   const data: FileData = await response.json();

//   return data;
// };