import React, { FC, PropsWithChildren, HTMLAttributes } from 'react';

interface H5Prop extends HTMLAttributes<HTMLHeadElement> {
  className?: string;
}

type H5Props = PropsWithChildren<H5Prop>;

export const H5: FC<H5Props> = ({ children, className }, props) => {
  return (
    <h1
      className={`font-rubik font-normal text-black phone:text-xl tablet:text-3xl laptop:text-4xl 
  desktop:text-4xl ${className}`}
      {...props}
    >
      {children}
    </h1>
  );
};
