import { API } from '@config';
import { StandardsAndGuidelinesTable } from '@types';

export const getStandardsAndGuidelines = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/business-fn-p-standards?populate=File&sort=id%3Adesc`, options);
  const data: StandardsAndGuidelinesTable = await response.json();

  return data;
};
