export const GPADemographics = {
  pageTitle: `Demographics`,
  title: `Guam Power Authority demographics by:`,
  contents: [
    {
      title: `Age Demographics - `,
      anchor: `Download 12.9 kB pdf`,
    },
    {
      title: `Eligible Retirees - Administration `,
      anchor: `Download 11.5 kB pdf`,
    },
    {
      title: `Eligible Retirees - Operations `,
      anchor: `Download 11.9 kB pdf`,
    },
  ],
};