import { EducationImages } from '@core';

export const EduPosterBook = {
  title: `Educational Resources`,
  contents: [
    {
      image: EducationImages.edu1,
      title: `POSTER: Getting Power to You`,
      description: `The island-wide power system is one of Guam's most important assets. High school students will enjoy exploring this interactive illustration of how the power gets into our homes.`,
      anchor: `Download pdf`,
      link: ``,
    },
    {
      image: EducationImages.edu2,
      title: `POSTER: How Power is Produced`,
      description: `GPA generates all the electricity that we use in our homes, schools, and communities on Guam. Learn about what goes on inside the power plants.`,
      anchor: `Download pdf`,
      link: ``,
    },
    {
      image: EducationImages.edu3,
      title: `POSTER: GPA Renewable Power`,
      description: `GPA renewable power projects are leading the way to sustainable energy. Using the sun and the wind to generate electricity,contributes to a better Guam today and in the future.`,
      anchor: `Download pdf`,
      link: ``,
    },
    {
      image: EducationImages.edu4,
      title: `POSTER: How Power is Recovered`,
      description: `In this activity, children grades 5-8 use a checklist to find ways to save money on their family's monthly power bill.`,
      anchor: `Download pdf`,
      link: ``,
    },
    {
      image: EducationImages.edu5,
      title: `POSTER: GPA Power The Future`,
      description: `Learn about the changes that GPA is making today to protect our island's future.`,
      anchor: `Download pdf`,
      link: ``,
    },
    {
      image: EducationImages.edu6,
      title: `Kid's Checklist for Saving Power?`,
      description: `In this activity, children grades 5-8 use a checklist to find ways to save money on their family's monthly power bill.`,
      anchor: `Download pdf`,
      link: ``,
    },
    {
      image: EducationImages.edu7,
      title: `Learning How Electricity Changed Our Lives`,
      description: `In this activity, 5-8th graders become journalists by interviewing their elders about the use of electricity in schools and homes when they were kids.`,
      anchor: `Download pdf`,
      link: ``,
    },
    {
      image: EducationImages.edu8,
      title: `How Power Gets to You`,
      description: `In this activity, children grades 5-8 learn how electricity travels across the island from the power plants into their homes.`,
      anchor: `Download pdf`,
      link: ``,
    },
    {
      image: EducationImages.edu9,
      title: `How Safe Is Your Home?`,
      description: `In this activity, children grades 5-8 use a checklist to locate electrical hazards around the house and report them to adults.`,
      anchor: `Download pdf`,
      link: ``,
    },
    {
      image: EducationImages.edu10,
      title: `GPA Kids Coloring Book We Make Electricity from ENERGY`,
      description: `What is energy? Examples of energy we use every day. Some of the energy sources that are used to make electricity.`,
      anchor: `Download pdf`,
      link: ``,
    },
    {
      image: EducationImages.edu11,
      title: `GPA Kids Coloring Book Power Then and Power Now`,
      description: `Electricity has changed how people live on Guam. Compare how people worked and played before electricity with our lives today... from hotnu to microwave!`,
      anchor: `Download pdf`,
      link: ``,
    },
    {
      image: EducationImages.edu12,
      title: `GPA Kids Coloring Book Power Then and Power Now 2`,
      description: `Teachers and parents requested more comparisions of how people worked and played before electricity ... from karabao carts to electric cars!`,
      anchor: `Download pdf`,
      link: ``,
    },
    {
      image: EducationImages.edu13,
      title: `GPA Kids Coloring Book for Electrical Safety`,
      description: `Young artists who like to color will learn electrical safety tips while coloring scenes of Guam neighborhoods`,
      anchor: `Download pdf`,
      link: ``,
    },
    {
      image: EducationImages.edu14,
      title: `GPA Kids Coloring Book for Saving Power`,
      description: `Have fun coloring and learn how you can help save money on your family's monthly power bill.`,
      anchor: `Download pdf`,
      link: ``,
    },
    {
      image: EducationImages.edu15,
      title: `Teachers' Guide Education and Safety`,
      description: `GPA has developed for you and your elementary, middle and high school students. Everything on these pages is free for download or contact GPA's PIO office for available materials.`,
      anchor: `Download pdf`,
      link: ``,
    },
  ],
};

export const EduMoreLinks = {
  title: `More Links and More Fun!`,
  description: `Hi kids! Do you want to have fun learning about power generation, energy efficiency and alternative energy? Below are links to energy-related games and learning sites`,
  ul: [
    {
      anchor: `Energy Puzzles`,
      link: `http://www.eia.doe.gov/kids/energy_fungames/wordgames/words.html`,
    },
    {
      anchor: `Energy Slang`,
      link: `http://www.eia.doe.gov/kids/energy_fungames/energyslang/index.html`,
    },
    {
      anchor: `Energy Hog Busters`,
      link: `http://www.energyhog.org/childrens.htm`,
    },
    {
      anchor: `Energy Efficiency & Renewable Energy`,
      link: `http://www1.eere.energy.gov/kids/`,
    },
    {
      anchor: `Tennessee Valley Authority Kids`,
      link: `http://www.tvakids.com/`,
    },
    {
      anchor: `Seattle City Light’s Kids Exploring Energy Site`,
      link: `http://www2.seattle.gov/exploreenergy/`,
    },
  ],
};
