import React, { useEffect, useState } from 'react';
import {
  H1,
  H2,
  Details,
  OrderedList,
  List,
  UnorderedList,
  Content as ContentContainer,
} from '@core';
import { FormsAndPermits as Content } from '@content';
import { HeaderGradient } from '@components';
import { API } from '@src/config';
import { STANDARDSANDGUIDELINESRESIDENTS } from '@src/types/StandardsAndGuidelinesResidential';
import { WORKORDERSRESIDENTS } from '@src/types/WorkOrdersResidential';
import { WORKSIGNSRESIDENT } from '@src/types/WorkSignsResidents';
import { WORKOTHERSRESIDENTS } from '@src/types/WorkOthersResidential';
import { getStandardsAndGuidelinesResident } from '@src/services/StandardsAndGuidelinesResidential';
import { getWorkOrdersResidential } from '@src/services/WorkOrdersResidential';
import { getWorkSignsResidential } from '@src/services/WorkSignsResidential';
import { getWorkOthersResidential } from '@src/services/WorkOthersResidential';

export const FormsAndPermits = () => {
  // const [standards, setStandards] = useState<STANDARDSANDGUIDELINES[]>([]);
  // const [workOrders, setWorkOrders] = useState<WORKORDERS[]>([]);
  // const [workSigns, setWorkSigns] = useState<WORKSIGNS[]>([]);
  // const [workOthers, setWorkOthers] = useState<WORKOTHERS[]>([]);
  const [standards, setStandards] = useState<STANDARDSANDGUIDELINESRESIDENTS[]>([]);
  const [workOrders, setWorkOrders] = useState<WORKORDERSRESIDENTS[]>([]);
  const [workSigns, setWorkSigns] = useState<WORKSIGNSRESIDENT[]>([]);
  const [workOthers, setWorkOthers] = useState<WORKOTHERSRESIDENTS[]>([]);

  const getData = async () => {
    // const fileDatas = await getFileData();
    // setFile(fileDatas.data);

    const standardsData = await getStandardsAndGuidelinesResident();
    setStandards(standardsData.data);

    const workOrderData = await getWorkOrdersResidential();
    setWorkOrders(workOrderData.data);

    const workSignsData = await getWorkSignsResidential();
    setWorkSigns(workSignsData.data);

    const workOthersData = await getWorkOthersResidential();
    setWorkOthers(workOthersData.data);
  };

  const clickItem = (link?: string, file?: any) => {
    if (file != null) {
      window.open(`${API}${file}`);
    } else {
      window.open(`${link}`);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={Content.title}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-full laptop:w-8/12 mr-20">
            <H1>{Content.subTitle}</H1>
            <Details>{Content.description}</Details>
            <OrderedList>
              {Content.ol.map((value) => {
                return <List key={value}>{value}</List>;
              })}
            </OrderedList>
            {Content.contents.map((content) => {
              return (
                <>
                  <H2 className="mb-5 mt-10">{content.title}</H2>
                  <Details>{content.description}</Details>
                  <OrderedList>
                    {content.ol.map((value) => {
                      return <List key={value}>{value}</List>;
                    })}
                  </OrderedList>
                </>
              );
            })}
          </div>
          <div className="w-full laptop:w-4/12">
            <H1>Downloadable Forms</H1>
            <H2 className="mt-4">STANDARDS & GUIDELINES</H2>
            <Details>Resources for Owners and Contractors</Details>
            <UnorderedList>
              {standards.map((value) => {
                return (
                  <List className="text-orange" key={value.attributes.createdAt}>
                    <button
                      className="text-left"
                      onClick={() =>
                        clickItem(
                          value.attributes.Link,
                          value.attributes.File?.data != null
                            ? value.attributes.File.data.attributes.url
                            : value.attributes.File.data,
                        )
                      }
                      type="button"
                    >
                      {value.attributes.Anchor}
                    </button>
                  </List>
                );
              })}
            </UnorderedList>
            <H2 className="mt-4">WORK ORDER PROCESSING</H2>
            <UnorderedList>
              {workOrders.map((value) => {
                return (
                  <List className="text-orange" key={value.attributes.createdAt}>
                    <button
                      className="text-left"
                      onClick={() =>
                        clickItem(
                          value.attributes.Link,
                          value.attributes.File?.data != null
                            ? value.attributes.File.data.attributes.url
                            : value.attributes.File.data,
                        )
                      }
                      type="button"
                    >
                      {value.attributes.Anchor}
                    </button>
                  </List>
                );
              })}
            </UnorderedList>
            <H2 className="mt-4">SIGNS</H2>
            <UnorderedList>
              {workSigns.map((value) => {
                return (
                  <List className="text-orange" key={value.attributes.createdAt}>
                    <button
                      className="text-left"
                      onClick={() =>
                        clickItem(
                          value.attributes.Link,
                          value.attributes.File?.data != null
                            ? value.attributes.File.data.attributes.url
                            : value.attributes.File.data,
                        )
                      }
                      type="button"
                    >
                      {value.attributes.Anchor}
                    </button>
                  </List>
                );
              })}
            </UnorderedList>
            <H2 className="mt-4">OTHERS</H2>
            <UnorderedList>
              {workOthers.map((value) => {
                return (
                  <List className="text-orange" key={value.attributes.createdAt}>
                    <button
                      className="text-left"
                      onClick={() =>
                        clickItem(
                          value.attributes.Link,
                          value.attributes.File?.data != null
                            ? value.attributes.File.data.attributes.url
                            : value.attributes.File.data,
                        )
                      }
                      type="button"
                    >
                      {value.attributes.Anchor.length > 35
                        ? `${value.attributes.Anchor.substring(0, 35)}...`
                        : value.attributes.Anchor}
                    </button>
                  </List>
                );
              })}
            </UnorderedList>
            {/* {DownloadableFormsBusiness.map((forms) => {
              return (
                <>
                  <H2 className="mt-4">{forms.title}</H2>
                  <Details className="font-normal">{forms.description}</Details>
                  <UnorderedList>
                    {standards.map((value) => {
                      console.log("map testing", value.attributes.Anchor)
                      return(
                        <List className="text-orange" key="something">
                          <a href="https://facebook.com" key="asdf">hellow</a>
                        </List>
                      )
                    })}
                    {forms.ul.map((value) => {
                      return (
                        <>
                          {file
                            .filter((fileData) => fileData.attributes.AnchorText === value.anchor)
                            .map((fileData) => {
                              return (
                                <>
                                  <List className="text-orange">
                                    <a
                                      href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      {fileData.attributes.AnchorText}
                                    </a>
                                  </List>
                                </>
                              );
                            })}
                        </>
                      );
                    })}
                  </UnorderedList>
                </>
              );
            })} */}
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
