import GPALogo from "@assets/images/GPALogo.png";
import Hero from "@assets/images/hero.jpeg";
import Hearthand from "@assets/images/hearthand.jpeg";
import Doa from "@assets/images/doa.png";
import BusinessHero from "@assets/images/BusinessHero.png";
import ResidentialHero from "@assets/images/ResidentialHero.png";
import OrgChart from "@assets/images/OrgChart.jpg";
import JohnBenevente from "@assets/images/JohnBenevente.jpg";
import MelindaMafnas from "@assets/images/MelindaMafnas.jpg";
import ar2021 from "@assets/images/ar_2021_image.jpg";
import ar2020 from "@assets/images/ar_2020_image.png";
import ar2019 from "@assets/images/ar_2019_image.png";
import ar2018 from "@assets/images/ar_2018_image.png";
import ar2017 from "@assets/images/ar_2017_image.png";
import ar2016 from "@assets/images/ar_2016_image.png";
import ar2014 from "@assets/images/ar_2014_image.jpg";
import ar2013 from "@assets/images/ar_2013_image.jpg";
import ar2010 from "@assets/images/ar_2010_image.jpg";
import ar2009 from "@assets/images/ar_2009_image.jpg";
import ar2008 from "@assets/images/ar_2008_image.png";
import ar0607 from "@assets/images/ar_06_07_image.jpg";
import indoorSafety1 from "@assets/images/indoor-1.png";
import indoorSafety2 from "@assets/images/indoor-2.png";
import indoorSafety3 from "@assets/images/indoor-3.png";
import indoorSafety4 from "@assets/images/indoor-4.png";
import indoorSafety5 from "@assets/images/indoor-5.png";
import indoorSafety6 from "@assets/images/indoor-6.png";
import indoorSafety7 from "@assets/images/indoor-7.png";
import indoorSafety8 from "@assets/images/indoor-8.png";
import outdoorSafety1 from "@assets/images/outdoor-1.png";
import outdoorSafety2 from "@assets/images/outdoor-2.png";
import outdoorSafety3 from "@assets/images/outdoor-3.png";
import outdoorSafety4 from "@assets/images/outdoor-4.png";
import stormSafety1 from "@assets/images/storm-1.png";
import stormSafety2 from "@assets/images/storm-2.png";
import stormSafety3 from "@assets/images/storm-3.png";
import stormSafety4 from "@assets/images/storm-4.png";
import stormSafety5 from "@assets/images/storm-5.png";
import edu1 from "@assets/images/education1.jpg";
import edu2 from "@assets/images/education2.jpg";
import edu3 from "@assets/images/education3.jpg";
import edu4 from "@assets/images/education4.jpg";
import edu5 from "@assets/images/how_power_future_poster.jpg";
import edu6 from "@assets/images/teachers-and-kids-energy_audit.jpg";
import edu7 from "@assets/images/learning-how-electricity-changed-image.jpg";
import edu8 from "@assets/images/how-electricity-gets-to-your-house-image.jpg";
import edu9 from "@assets/images/teachers-and-kids-home_safe.jpg";
import edu10 from "@assets/images/kids-coloring-energy-image.jpg";
import edu11 from "@assets/images/kids-coloring-book-now-then-1-image.jpg";
import edu12 from "@assets/images/kids-coloring-book-now-then-2-image.jpg";
import edu13 from "@assets/images/teachers-and-kids-coloring_book.jpg";
import edu14 from "@assets/images/teachers-and-kids-coloring_book_energy.jpg";
import edu15 from "@assets/images/teachers_guide_image.jpg";
import HereToHelp from "@assets/images/here_to_help.jpg";
import BillSample from "@assets/images/new_statement3.png";
import EnergySense from "@assets/images/es_head.jpg";
import PDFIcon from "@assets/images/pdf_icon.png";
import WasherDryer from "@assets/images/WD-main1.jpg";
import JobOpeningsImg from "@assets/images/GPA_Banner_Find_Job_Openings.jpg";
import StartTopService from "@assets/images/GPA_Banner_StartStopService.png";
import ResidentialAccount from "@assets/images/GPA_Banner_Residential_Manage_My_Account.jpg";
import WhatWereDoing from "@assets/images/GPA_Banner_WhatWereDoing.jpg";
import HumanResourcesBanner from "@assets/images/GPA_Banner_HumanResources.png";
import YouCanSaveUse from "@assets/images/you_can_save_use1.png";
import GuideCover from "@assets/images/ApplianceGuideCover_use.png";
import EnergyGuide from "@assets/images/EnergyGuide_use1.png";
import EnergyStar from "@assets/images/energy-star-box1.png";
import Aircon from "@assets/images/aircon_room_use2.png";
import CeilingFan from "@assets/images/ceiling_fan011.png";
import Switch from "@assets/images/switch_wikimedia_use1.png";
import WaterTimer from "@assets/images/hotwatertimer1.png";
import Tankless from "@assets/images/go-tankless-image2.png";
import LightBulb from "@assets/images/LightBulbsCompared1.png";
import Window from "@assets/images/window2.png";
import DripPan from "@assets/images/drip-pan1.png";
import Condenser from "@assets/images/condenser_use1.png";
import Washer from "@assets/images/washerdryer1.png";
import Montage from "@assets/images/irp_montage.jpg";
import Forum from "@assets/images/gpa-lng-forum-button2-small2.jpg";
import PlayBtn from "@assets/images/play_button.png";
import Turbine from "@assets/images/Wind_turbine_web.jpg";
import DownloadBanner from "@assets/images/GPA22064_WebsiteBanners_Secondary.png";
import PowerAlerts from "@assets/images/20220721-093039.jpg";
import WatchGPA from "@assets/images/20220721-093020.jpg";
import BusinessBanner from "@assets/images/GPA_Banner_Business.jpg";
import CorporateNewsBanner from "@assets/images/GPA_Banner_News.jpg";
import CommunityBanner from "@assets/images/GPA_Banner_Safety.jpg";
import PowerToServeBanner from "@assets/images/GPA_Banner_Power_to_Serve.jpg";
import GetFinancialAssistanceBanner from "@assets/images/get_financial_assistance_banner.jpg";
import TractMyEnergyConsumptionBanner from "@assets/images/GPA_Banner_TrackMyEnergy.png";

import PowerAlert from "@assets/images/GPA_Banner_Power_Alerts.jpg";
import PowerInYourHands from "@assets/images/power_in_your_hands.png";
import PowerAlertMyBusiness from "@assets/images/power_alerts_my_business.png";
import PowerAlertMyHome from "@assets/images/power_alerts_my_home.png";
import PowerAlertOurGpa from "@assets/images/power_alerts_our_gpa.png";

import PayGWA from "@assets/images/paygwaapp.png";
import PayGPWALogo from "@assets/images/paygpwa-logo.png";
import AppStore from "@assets/images/appstore.png";
import PlayStore from "@assets/images/playstore.png";

import CleanEnergyMasterPlanSmallPhoto from "@assets/images/CleanEnergyMasterPlan_Thumbnail.jpg";
import PowertoSaveSmallPhoto from "@assets/images/PowertoSave_Thumbnail.jpg";
import PowertoServeSmallPhoto from "@assets/images/PowertoServe_Thumbnail.jpg";
import InOurCommunityBanner from "@assets/images/GPA_Banner_In_Our_Community.jpg";
import CommunityPartnerSmallPhoto from "@assets/images/CommunityPartner_Thumbnail.png";
import EducationalResourcesSmallPhoto from "@assets/images/EducationalResources_Thumbnail.png";
import SafetySmallPhoto from "@assets/images/Safety_Thumbnail.png";
import CommunityPoweredImage from "@assets/images/communityPowered.png";
import SignUpNewsletterImage from "@assets/images/signup-enewsletter.png";
import EducationalResourcesBanner from "@assets/images/GPA_Banner_Educational_Resources.png";
import TelephoneIcon from "@assets/images/telephone-icon.png";
import MessageIcon from "@assets/images/message-icon.png";
import CommunityPartnetsAndProgramsBanner from "@assets/images/GPA_Banner_CommunityPartners.png";
import AboutUsBanner from "@assets/images/GPA_Banner_AboutUs.png";
import CustomerSupportBanner from "@assets/images/GPA_Banner_CustomerSupport.png";
import NewsMediaBanner from "@assets/images/GPA_Banner_NewsMedia.png";

import CleanEnergyGoal13 from "@assets/images/E-WEB-Goal-13.png";
import CleanEnergyGoal07 from "@assets/images/E-WEB-Goal-07.png";
import ExpertElectrician from "@assets/images/Expert.png";
import GpaPowerCreditBackground from "@assets/images/Gpa_Power_Credit.jpeg";
import GpaCommunityPowered from "@assets/images/GPA_CommunityPowered.png";
import MaintenanceImage from "@assets/images/maintenance-box.png";
import CEMPGreenBG from "@assets/images/cemp-green-bg.png";
import CEMPProgress from "@assets/images/cemp-progress-img.png";
import RenewableEnergyProgressBarr from "@assets/images/renewable-energy-progress-progress-bar.png";
import CEMPProgressBackground from "@assets/images/progress-background.png";
import GPACommercialRebates from "@assets/images/GPA-Commercial-Rebates.png";
import FiveFiveYearsGPALogo from "@assets/images/55YearsGPALogo.jpeg";
import FiveFiveYearsMayActiveCarousel from "@assets/images/GPA_1920x600webbanner_GMletter.png";
import GPAMawarBG from "@assets/images/GPA_Mawar_Background.jpg";
import AlertYellow from "@assets/images/Alert_Yellow.png";
import GPAPowerRestorationBg from "@assets/images/GPA_PowerRestorationAdvisory_background.png";
import OrgChart2 from "@assets/images/GPAOrganizationalChart(1).png";
import OrgChart2Enhanced from "@assets/images/GPA_organizational_chart_v3.jpg";
import RenewableEnergyProgressBarIncremented from "@assets/images/renewable-energy-progress-progress-bar-incremented.png";
import HomePageAugust from "@assets/images/home-bg-august.png";
import GPA55thlogo2 from "@assets/images/GPA_55thlogo2.png";
import GPATyphoonRecipeBannerBackground from "@assets/images/GPA-TyphoonRecipeBanner-Background.jpg";
import ERAnewlogo2 from "@assets/images/ERAnewlogo2.png";
import GPAPotentialOutageBanner from "@assets/images/GPA_PotentialOutage_Banner.jpg";
import Alert from "@assets/images/Alert.png";
import GPAAugBanner from "@assets/images/GPA_Aug_Banner.jpg";
import PeakGraph from "@assets/images/Peak_Graph.png";
import NoExit from "@assets/images/No_exit.png";
import Temp from "@assets/images/Temp.png";
import Sun from "@assets/images/Sun.png";
import GPABeattheBeakBanner from "@assets/images/GPA_BeattheBeak_Banner.jpg";
import BeatThePeakLogo from "@assets/images/BeatthePeak_logo.png";
import GPABeattheBeakBannerv2 from "@assets/images/BeatthePeak_Home_Banner_v2.jpg";
import BeatThePeakLogov2 from "@assets/images/BTP_Logo_Horizontal.png";
import Temp2 from "@assets/images/Temp2.png";
import SeventySixTemp from "@assets/images/76temp.png";
import BeatThePeakCarousel from "@assets/images/beat-the-peak-carousel.jpg";
import PotentialOutageSecondary from "@assets/images/potential-outage-secondary-bg.jpg";
import BeatThePeakSVG from "@assets/images/BTP_Logo_1.svg";
import PotentialOutageSecondaryPortrait from "@assets/images/potential-outage-secondary-portrait.jpg";
import BeatThePeakRed from "@assets/images/btp-red.jpg";
import OrgChart3 from "@assets/images/GPAOrganizationalChart-3.png";
import Temp75 from "@assets/images/Temp75.svg";

export const Images = {
  GPALogo,
  Hero,
  Hearthand,
  Doa,
  BusinessHero,
  ResidentialHero,
  OrgChart,
  JohnBenevente,
  MelindaMafnas,
  HereToHelp,
  BillSample,
  EnergySense,
  PDFIcon,
  WasherDryer,
  JobOpeningsImg,
  StartTopService,
  ResidentialAccount,
  WhatWereDoing,
  PlayBtn,
  DownloadBanner,
  PowerAlerts,
  WatchGPA,
  BusinessBanner,
  CorporateNewsBanner,
  CommunityBanner,
  PowerAlert,
  PowerInYourHands,
  PowerAlertMyBusiness,
  PowerAlertMyHome,
  PowerAlertOurGpa,
  PlayStore,
  PayGWA,
  AppStore,
  PayGPWALogo,
  PowerToServeBanner,
  GetFinancialAssistanceBanner,
  TractMyEnergyConsumptionBanner,
  HumanResourcesBanner,
  CleanEnergyMasterPlanSmallPhoto,
  PowertoServeSmallPhoto,
  PowertoSaveSmallPhoto,
  InOurCommunityBanner,
  CommunityPartnerSmallPhoto,
  EducationalResourcesSmallPhoto,
  SafetySmallPhoto,
  CommunityPoweredImage,
  SignUpNewsletterImage,
  EducationalResourcesBanner,
  TelephoneIcon,
  MessageIcon,
  CommunityPartnetsAndProgramsBanner,
  AboutUsBanner,
  CustomerSupportBanner,
  NewsMediaBanner,
  CleanEnergyGoal13,
  CleanEnergyGoal07,
  ExpertElectrician,
  GpaPowerCreditBackground,
  GpaCommunityPowered,
  MaintenanceImage,
  CEMPGreenBG,
  CEMPProgress,
  RenewableEnergyProgressBarr,
  CEMPProgressBackground,
  GPACommercialRebates,
  FiveFiveYearsGPALogo,
  FiveFiveYearsMayActiveCarousel,
  GPAMawarBG,
  AlertYellow,
  GPAPowerRestorationBg,
  OrgChart2,
  OrgChart2Enhanced,
  RenewableEnergyProgressBarIncremented,
  HomePageAugust,
  GPA55thlogo2,
  GPATyphoonRecipeBannerBackground,
  ERAnewlogo2,
  GPAPotentialOutageBanner,
  Alert,
  GPAAugBanner,
  PeakGraph,
  NoExit,
  Temp,
  Sun,
  GPABeattheBeakBanner,
  BeatThePeakLogo,
  GPABeattheBeakBannerv2,
  BeatThePeakLogov2,
  Temp2,
  SeventySixTemp,
  BeatThePeakCarousel,
  PotentialOutageSecondary,
  BeatThePeakSVG,
  PotentialOutageSecondaryPortrait,
  BeatThePeakRed,
  OrgChart3,
  Temp75,
};

export const ARImages = {
  ar2021,
  ar2020,
  ar2019,
  ar2018,
  ar2017,
  ar2016,
  ar2014,
  ar2013,
  ar2010,
  ar2009,
  ar2008,
  ar0607,
};

export const SafetyImages = {
  indoorSafety1,
  indoorSafety2,
  indoorSafety3,
  indoorSafety4,
  indoorSafety5,
  indoorSafety6,
  indoorSafety7,
  indoorSafety8,
  outdoorSafety1,
  outdoorSafety2,
  outdoorSafety3,
  outdoorSafety4,
  stormSafety1,
  stormSafety2,
  stormSafety3,
  stormSafety4,
  stormSafety5,
};

export const EducationImages = {
  edu1,
  edu2,
  edu3,
  edu4,
  edu5,
  edu6,
  edu7,
  edu8,
  edu9,
  edu10,
  edu11,
  edu12,
  edu13,
  edu14,
  edu15,
};

export const WhatYouCanDoImages = {
  YouCanSaveUse,
  GuideCover,
  EnergyGuide,
  EnergyStar,
  Aircon,
  CeilingFan,
  Switch,
  WaterTimer,
  Tankless,
  LightBulb,
  Window,
  DripPan,
  Condenser,
  Washer,
};

export const InfoVidImages = {
  Montage,
  Forum,
  Turbine,
};
