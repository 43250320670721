import React, { Fragment } from 'react';
import { Images, Content as ContentContainer } from '@core';
import { HeaderGradient } from '@components';
import { WhatYouCanDo } from './WhatYouCanDo';
import { Tab } from '@headlessui/react';
import { NewPowerPlantProject } from './NewPowerPlantProject';
import { OtherResources } from './OtherResources';

export const WhatWereDoing = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="GPA Initiatives"
      />
      <div
        className="h-1/4 tabletWide:h-1/3 laptop:h-1/2 bg-top bg-cover bg-hero"
        style={{ backgroundImage: `url(${Images.WhatWereDoing})` }}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <Tab.Group>
              <Tab.List className="flex justify-center border-b tab-list-style">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <div
                      className={`${
                        selected ? 'bg-blue text-white' : 'bg-lightgray text-blue'
                      } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                    >
                      POWER TO SAVE
                    </div>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <div
                      className={`${
                        selected ? 'bg-blue text-white' : 'bg-lightgray text-blue'
                      }  py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                    >
                      CLEAN ENERGY MASTER PLAN
                    </div>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <div
                      className={`${
                        selected ? 'bg-blue text-white' : 'bg-lightgray text-blue'
                      } py-2 px-3 tablet:py-4 tablet:px-8 mr-1 tablet:mr-2 text-xs tablet:text-lg cursor-pointer`}
                    >
                      OTHER RESOURCES
                    </div>
                  )}
                </Tab>
              </Tab.List>
              <Tab.Panels className="">
                <Tab.Panel>
                  <div className="my-10">
                    <WhatYouCanDo />
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <NewPowerPlantProject />
                </Tab.Panel>
                <Tab.Panel>
                  <OtherResources />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};

// export const WhatWereDoing = () => {
//   return (
//     <>
//       <div className="mt-20 desktop:mt-28" />
//       <HeaderGradient
//         leftColor="from-general-darkblue"
//         rightColor="to-general-red"
//         title={Content.title}
//       />
//       <div
//         className="h-1/4 tabletWide:h-1/3 laptop:h-1/2 bg-top bg-cover bg-hero"
//         style={{ backgroundImage: `url(${Images.WhatWereDoing})` }}
//       />
//       <ContentContainer>
//         <div className="flex laptop:flex-row phone:flex-col">
//           <div className="w-12/12">
//             <H1 className="mb-5 uppercase">{Content.title}</H1>
//             <Details>{Content.description1}</Details>
//             <Details>{Content.description2}</Details>
//             {Content.contents.map((content) => {
//               return (
//                 <>
//                   <H2 className="mb-5">{content.title}</H2>
//                   <Details>{content.description1}</Details>
//                   <UnorderedList>
//                     {content.ul?.map((value) => {
//                       return <List key={value}>{value}</List>;
//                     })}
//                   </UnorderedList>
//                   <Details>{content.description2}</Details>
//                   <Details className="text-orange mb-4">
//                     <a href={content.link}>{content.anchor}</a>
//                   </Details>
//                 </>
//               );
//             })}
//           </div>
//         </div>
//       </ContentContainer>
//     </>
//   );
// };
