import React from 'react';

interface LookingForProps {
  icon: string;
  title: string;
  link: string;
}

export const LookingFor: React.FC<LookingForProps> = ({ icon, title, link }) => {
  return (
    <a className="flex flex-col justify-center items-center w-52" href={link}>
      <div className="h-28 w-28 flex items-center justify-center bg-darkblue rounded-full mb-5">
        <img alt="Bold" className="h-16" src={icon} />
      </div>
      <h2 className="font-rubik text-center text-black font-semibold mb-2 laptop:text-3xl tablet:text-2xl phone:text-xl">
        {title}
      </h2>
    </a>
  );
};
