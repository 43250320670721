import { API } from '@config';
import { GpaInitiativesIrpTable } from '@src/types/GpaInitiativesIrp';

export const getGpaInitiativesIrp1 = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${API}/api/gpa-initiatives-irp-1-meetings?populate=*&sort=id%3Aasc`,
    options,
  );
  const data: GpaInitiativesIrpTable = await response.json();

  return data;
};
