import React, { useState, useEffect, Fragment } from "react";
import { H5, H3, H1 } from "@core";
import { SeeWattsNewItem, Pagination } from "@components";
import { getArticles } from "@services";
import { ARTICLEDATA } from "@types";
import { Disclosure } from "@headlessui/react";
import { API } from "@config";

export const WattsNew = () => {
  const [articles, setArticles] = useState<ARTICLEDATA[]>([]);
  const [filterArticles, setFilterArticles] = useState<ARTICLEDATA[]>([]);
  const [allArticles, setAllArticles] = useState<ARTICLEDATA[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(6);
  const [articlesPerLargePage] = useState(9);
  const [pageTitle, setPageTitle] = useState("LATEST");
  const [isDoneFetching, setIsDoneFetching] = useState<boolean>(true);
  const [activeMonthYear, setActiveMonthYear] = useState<{
    month: string;
    year: string;
  }>({
    month: "",
    year: "",
  });

  const filterResult = (item: string, yr: string) => {
    setPageTitle(() => "PRESS RELEASES");
    const result = allArticles
      .filter((article) => {
        const getDate = new Date(article.attributes.Date);
        const artDate = getDate.toLocaleString("en-us", {
          year: "numeric",
          month: "long",
        });
        const selectedDate = `${item} ${yr}`;
        return artDate === selectedDate ? article : null;
      })
      .filter((article) => {
        return (
          article.attributes.article_category.data?.attributes.Name.toString() !==
          "Newsletter"
        );
      });
    setCurrentPage(1);
    return setFilterArticles(result);
  };

  const getData = async () => {
    setIsDoneFetching(false);
    const datas = await getArticles();
    // console.log(datas);

    setArticles(
      datas.data.sort((a, b) => {
        return Date.parse(b.attributes.Date) - Date.parse(a.attributes.Date);
      })
    );
    setAllArticles(
      datas.data.sort((a, b) => {
        return Date.parse(b.attributes.Date) - Date.parse(a.attributes.Date);
      })
    );
    setFilterArticles(
      datas.data.sort((a, b) => {
        return Date.parse(b.attributes.Date) - Date.parse(a.attributes.Date);
      })
    );
    setIsDoneFetching(true);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const monthToRender = params.get("monthToRender");

    if (monthToRender !== null) {
      filterResult("May", "2023");
      setActiveMonthYear({ month: "May", year: "2023" });
    }
  }, [isDoneFetching]);

  const paginate = (pageNumber: React.SetStateAction<number>) =>
    setCurrentPage(pageNumber);

  // const dateData = backupArticles.map((article) => article)
  // setBackupArticles(articles.map((article) => article));
  const dateYear = allArticles.map((value) => {
    const getDate = new Date(value.attributes.Date);
    const year = getDate.toLocaleString("en-us", { year: "numeric" });
    return year;
  });

  function uniqueDate(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  const years = dateYear.filter(uniqueDate);

  function sortByMonth(arr: any) {
    const referenceMonthsOrder = [
      "December",
      "November",
      "October",
      "September",
      "August",
      "July",
      "June",
      "May",
      "April",
      "March",
      "February",
      "January",
    ];

    // console.log(arr);
    arr.sort((a: string, b: string) => {
      // console.log(a, b);
      return referenceMonthsOrder.indexOf(b) - referenceMonthsOrder.indexOf(a);
    });
  }

  const dateMonth = allArticles.map((value) => {
    const getDate = new Date(value.attributes.Date);
    const month = getDate.toLocaleString("en-us", { month: "long" });
    return month;
  });

  const months = dateMonth.filter(uniqueDate);
  sortByMonth(months);
  // console.log(months);

  const defaultResult = () => {
    setPageTitle(() => "LATEST");
    const result = articles.filter((article) => {
      return (
        article.attributes.article_category.data?.attributes.Name.toString() !==
        "Newsletter"
      );
    });
    setCurrentPage(1);
    setActiveMonthYear({
      month: "",
      year: "",
    });
    return setFilterArticles(result);
  };

  const newsletterResult = () => {
    setPageTitle(() => "NEWSLETTERS");
    const result = articles
      .filter((article) => {
        return (
          article.attributes.article_category.data?.attributes.Name.toString() ===
          "Newsletter"
        );
      })
      .map((article) => {
        return article;
      });
    setCurrentPage(1);
    setActiveMonthYear({
      month: "",
      year: "",
    });

    return setFilterArticles(result);
  };

  const gmReportResult = () => {
    setPageTitle(() => "GENERAL MANAGER'S REPORT");
    const result = articles
      .filter((article) => {
        return (
          article.attributes.article_category.data?.attributes.Name.toString() ===
          "general manager report"
        );
      })
      .map((article) => {
        return article;
      });
    setCurrentPage(1);
    setActiveMonthYear({
      month: "",
      year: "",
    });

    return setFilterArticles(result);
  };

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filterArticles.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const indexOfLastArticleLg = currentPage * articlesPerLargePage;
  const indexOfFirstArticleLg = indexOfLastArticleLg - articlesPerLargePage;
  const currentArticlesLg = filterArticles.slice(
    indexOfFirstArticleLg,
    indexOfLastArticleLg
  );

  const hasArticlesLg = () => {
    if (currentArticlesLg.length > 0) {
      return (
        <>
          <div className="w-full grid grid-flow-row tablet:grid-cols-3 phone:grid-cols-1 gap-10">
            {currentArticlesLg.map((article) => {
              const date = new Date(article.attributes.Date);
              return (
                <SeeWattsNewItem
                  date={date.toLocaleString("en-us", {
                    weekday: "long",
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                  description={article.attributes.Description.substring(0, 150)}
                  key={article.id}
                  pdfLink={`${API}${article.attributes.Document.data.attributes.url}`}
                  title={article.attributes.Title}
                />
              );
            })}
          </div>
        </>
      );
    }
    return (
      <>
        <H1 className="text-blue text-4xl text-center">No Articles Found.</H1>
      </>
    );
  };

  const hasArticlesSm = () => {
    if (currentArticles.length > 0) {
      return (
        <>
          <div className="w-full grid grid-flow-row phoneWide:grid-cols-2 tabletWide:grid-cols-3 phone:grid-cols-1 gap-10">
            {currentArticles.map((article) => {
              const date = new Date(article.attributes.Date);
              return (
                <SeeWattsNewItem
                  date={date.toLocaleString("en-us", {
                    weekday: "long",
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                  description={article.attributes.Description.substring(0, 150)}
                  key={article.id}
                  pdfLink={`${API}${article.attributes.Document.data.attributes.url}`}
                  title={article.attributes.Title}
                />
              );
            })}
          </div>
        </>
      );
    }
    return (
      <>
        <H1 className="text-blue text-4xl text-center">No Articles Found.</H1>
      </>
    );
  };

  return (
    <>
      <div className="flex flex-col laptopSm:flex-row w-12/12 gap-10 laptopSm:gap-20 desktop:hidden">
        <section className="w-full laptopSm:w-3/12 h-full border-solid rounded-md border-gray border">
          <div className="bg-blue text-white rounded-t-md flex justify-center py-2">
            <H3>Archives</H3>
          </div>
          <button
            className="flex flex-col w-full border border-gray group"
            onClick={() => defaultResult()}
            type="button"
          >
            <H3 className="py-2 px-4 whitespace-nowrap">
              See All Press Releases
            </H3>
          </button>
          {years.map((year: any) => {
            return (
              <Fragment key={year}>
                <Disclosure key={year}>
                  <Disclosure.Button
                    className="flex flex-col w-full border border-gray group"
                    key={year.id}
                    type="button"
                  >
                    <H3 className="py-2 px-4">{year} Press Releases</H3>
                  </Disclosure.Button>
                  <Disclosure.Panel className="flex flex-col w-full bg-lightgray">
                    {/* remove hidden if testing */}
                    {months.filter(Boolean).map((item) => {
                      return (
                        <Fragment key={item}>
                          <button
                            key={item}
                            onClick={() => {
                              filterResult(item, year);
                              setActiveMonthYear({ month: item, year });
                            }}
                            type="button"
                          >
                            <p
                              className={`w-full py-2 px-4 hover:text-orange ${
                                activeMonthYear.month === item &&
                                activeMonthYear.year === year
                                  ? "text-orange"
                                  : ""
                              } text-left`}
                              key={item}
                            >
                              {item}
                            </p>
                          </button>
                        </Fragment>
                      );
                    })}
                  </Disclosure.Panel>
                </Disclosure>
              </Fragment>
            );
          })}
          <button
            className="flex flex-col w-full border border-gray group"
            onClick={() => newsletterResult()}
            type="button"
          >
            <H3 className="py-2 px-4">Newsletters</H3>
          </button>
          <button
            className="flex flex-col w-full border border-gray group"
            onClick={() => gmReportResult()}
            type="button"
          >
            <H3 className="py-2 px-4">GM Report</H3>
          </button>
        </section>
        <section className="w-full laptopSm:w-9/12">
          <H5>{pageTitle}</H5>
          <hr className="border-gray mt-5 mb-10" />
          {hasArticlesSm()}
          <div className="flex justify-center">
            <Pagination
              currentPage={currentPage}
              itemsPerPage={articlesPerPage}
              paginate={paginate}
              totalItems={currentArticles.length}
            />
          </div>
        </section>
      </div>
      <div className="desktop:flex desktop:flex-row w-12/12 gap-10 tabletWide:gap-20  phone:hidden">
        <section className="w-full tabletWide:w-2/12 h-full border-solid rounded-md border-gray border">
          <div className="bg-blue text-white rounded-t-md flex justify-center py-2">
            <H3>Archives</H3>
          </div>
          <button
            className="flex flex-col w-full border border-gray group"
            onClick={() => defaultResult()}
            type="button"
          >
            <H3 className="py-2 px-4 whitespace-nowrap">
              See All Press Releases
            </H3>
          </button>
          {years.map((year: any) => {
            return (
              <Fragment key={year}>
                <Disclosure>
                  <Disclosure.Button
                    className="flex flex-col w-full border border-gray group"
                    key={year.id}
                    type="button"
                  >
                    <H3 className="py-2 px-4">{year} Press Releases</H3>
                  </Disclosure.Button>
                  <Disclosure.Panel className="flex flex-col w-full bg-lightgray">
                    {/* remove hidden if testing */}
                    {months.map((item) => {
                      return (
                        <Fragment key={item}>
                          <button
                            key={item}
                            onClick={() => {
                              filterResult(item, year);
                              setActiveMonthYear({ month: item, year });
                            }}
                            type="button"
                          >
                            <p
                              className={`w-full py-2 px-4 hover:text-orange ${
                                activeMonthYear.month === item &&
                                activeMonthYear.year === year
                                  ? "text-orange"
                                  : ""
                              } text-left`}
                              key={item}
                            >
                              {item}
                            </p>
                          </button>
                        </Fragment>
                      );
                    })}
                  </Disclosure.Panel>
                </Disclosure>
              </Fragment>
            );
          })}
          <button
            className="flex flex-col w-full border border-gray group"
            onClick={() => newsletterResult()}
            type="button"
          >
            <H3 className="py-2 px-4">Newsletters</H3>
          </button>
          <button
            className="flex flex-col w-full border border-gray group"
            onClick={() => gmReportResult()}
            type="button"
          >
            <H3 className="py-2 px-4 text-left">GM Report</H3>
          </button>
          {/* <>
            <Disclosure>
              <Disclosure.Button
                className="flex flex-col w-full border border-gray group"
                type="button"
              >
                <H3 className="py-2 px-4">Newsletters</H3>
              </Disclosure.Button>
              <Disclosure.Panel className="flex flex-col w-full bg-lightgray">
                remove hidden if testing
                <>
                  <button type="button">
                    <p className="w-full py-2 px-4 hover:text-orange text-left">
                      General Manager’s Reporst
                    </p>
                  </button>
                </>
              </Disclosure.Panel>
            </Disclosure>
          </> */}
        </section>
        <section className="w-full tabletWide:w-10/12">
          <H5>{pageTitle}</H5>
          <hr className="border-gray mt-5 mb-10" />
          {hasArticlesLg()}
          <div className="flex justify-center">
            <Pagination
              currentPage={currentPage}
              itemsPerPage={articlesPerLargePage}
              paginate={paginate}
              totalItems={articles.length}
            />
          </div>
        </section>
      </div>
    </>
  );
};
