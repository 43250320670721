export const CafeteriaPlanContent = {
  pageTitle: `The Cafeteria Plan`,
  description: `The Cafeteria Plan is a tax savings plan where certain benefits are pre-taxed which includes insurance premiums, health care and dependent care expenses.  By pre-taxing insurance premiums and other eligible expenses, you will reduce your taxable income and take home more money.  This is an optional benefit which has an administrative cost of $1.50 per pay period.`,
  info: `The Cafeteria Plan is currently administered by Administrative Services Corp. (ASC).  You may contact them at (671) 477-2724, Facsimile number:  (671) 477-2729`,
};

export const CafeteriaAccounts = {
  question: `What expenses qualify under the cafeteria plan?`,
  title1: `Premium Expense Account:`,
  category1: `1. Insurance Premiums`,
  list1: [
    `a. Health Insurance`,
    `b. Dental Insurance`,
    `c. Group Term Life Insurance (up to $50,000 of coverage)`,
  ],
  title2: `Reimbursement Accounts:`,
  category2: `2. Health Care Expenses (i.e. out of pocket expense) such as:`,
  list2: [
    `Deductibles`,
    `Co-payments`,
    `Prescriptions`,
    `Eyeglasses`,
    `Over-the-counter Medications`,
  ],
  category3: `3. Dependent Care Expenses`,
  list3: [
    `a. Child Care (Day Care [Nursery];  After School Programs (DEED) - Under 13 years of age`,
    `b. Adult Care`,
  ],
};