import React from "react";
import {
  Images,
  Content as ContentContainer,
  Details,
  H1,
  H2,
  List,
  UnorderedList,
} from "@core";

// import { Images, Details, H1, H2, List, UnorderedList } from '@core';
import { HeaderGradient } from "@components";
import { Menu } from "@headlessui/react";
import { FaChevronDown } from "react-icons/fa";

export const GPAInitiatives = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="GPA Initiatives"
      />
      <section className=" relative">
        <div className="absolute h-full bg-black/50" />
        <div className="absolute inset-0  h-full bg-black bg-opacity-10 z-10" />
        <div
          className="h-full flex items-center
          laptop:px-28 tablet:px-20 phone:px-10 z-20 absolute"
        >
          <div className=" tablet:w-500px phone:w-full flex flex-col tablet:items-start phone:items-center py-20">
            <h1
              className="font-rubik font-bold text-white tabletWide:text-5xl phoneWide:text-3xl phone:text-lg
              tablet:text-left phone:text-center"
            >
              If you're a GPA customer, you're a renewable customer.
            </h1>
          </div>
        </div>
        <img
          alt="News And Media Banner"
          className="w-full aspect-ratio__16-5"
          src={Images.WhatWereDoing}
        />
      </section>
      <ContentContainer>
        <section className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <H1 className="mb-5 uppercase">WHAT WE'RE DOING</H1>
            <Details>
              Electrical power is linked to every aspect of our lives. Producing
              enough power to serve the island economically and reliability
              means we can maintain our quality of life, create a stable,
              growing economy, and provide important services for our schools
              and other institutions.
            </Details>
            <Details>
              GPA is leading the way in exploring alternative ways of producing
              power that are both economical and environmentally responsible. In
              2007, we announced the process and timetable for bringing everyone
              together to discuss our island’s needs and the solutions that will
              be right for our whole community. This jump start means that today
              we are well along the way towards developing the new sources of
              energy that will be needed for our changing world.
            </Details>
            <H2 className="mb-5">GPA’s strategic planning criteria</H2>
            <Details>
              Reliable electric service continues to be a key issue for Guam.
              While significant gains have been made, it is important to
              continue to improve. The GPA plants that generate the island’s
              electrical power have a significant environmental footprint.
              Future energy resources must be in keeping with evolving renewable
              resource goals.
            </Details>
            <UnorderedList>
              <List>
                The current generating fleet is dependent on oil-based fuels,
                but GPA has a goal of diversifying its generation resources.
              </List>
              <List>
                The IRP stakeholder process recognizes that GPA’s customers
                desire a more meaningful dialog with the Authority on issues
                that affect them. The Department of Defense expansion will
                impact the electrical power infrastructure. GPA should exchange
                and provide information to DOD NEPA during the IRP process.
              </List>
            </UnorderedList>
            <Details>
              <a
                className="text-orange mb-4"
                href="https://admin.guampowerauthority.com/uploads/GPA_2022_Integrated_Resource_Plan_b16ef41f9e.pdf"
                rel="noreferrer"
                target="_blank"
              >
                - Download the 2022 Integrated Resource Plan
              </a>
            </Details>
            <Details className="text-orange mb-4">
              <a href="/gpa-initiatives/what-were-doing/information-videos">
                Information and videos
              </a>
            </Details>
          </div>
        </section>
      </ContentContainer>
      <section className="flex items-center flex-col bg-grayC410 laptop:py-32 phone:py-28 tablet:px-20 phone:px-10">
        <div className="w-full flex flex-col items-center">
          <h1
            className="font-jubilat text-center text-blue laptop:mb-14 phone:mb-10 laptop:text-5xl 
        tablet:text-5xl phone:text-4xl"
          >
            OUR PATH TO 100% RENEWABLE ENERGY BY 2045
          </h1>
          <div className="flex flex-col items-center tablet:items-stretch tablet:flex-row">
            <a
              className="w-full tabletWide:w-1/3 overflow-hidden bg-darkblue mr-0 tablet:mr-2 tabletWide:mr-8 mb-8 tabletWide:mb-0 relative"
              href="/gpa-initiatives/clean-energy-master-plan"
            >
              <div className="thumbnail__linear-gradient absolute top-0 left-0 w-full h-full" />
              <div className=" bg-transparent absolute bottom-0 left-0 p-4">
                <h6 className="text-white text-lg">Clean Energy Master Plan</h6>
              </div>
              <img
                alt="Clean Energy Master Plan"
                className="w-full h-full object-cover"
                src={Images.CleanEnergyMasterPlanSmallPhoto}
              />
            </a>
            <a
              className="w-full tabletWide:w-1/3 overflow-hidden bg-darkblue mr-0 tablet:mr-2 tabletWide:mr-8 mb-8 tabletWide:mb-0 relative"
              href="/corporate/operations"
            >
              <div className="thumbnail__linear-gradient absolute top-0 left-0 w-full h-full" />
              <div className=" bg-transparent absolute bottom-0 left-0 p-4">
                <h6 className="text-white text-lg">Power to Serve</h6>
              </div>
              <img
                alt="Clean Energy Master Plan"
                className="w-full h-full object-cover"
                src={Images.PowertoServeSmallPhoto}
              />
            </a>
            <a
              className="w-full tabletWide:w-1/3 overflow-hidden bg-darkblue relative"
              href="https://energysense.guampowerauthority.com/savings-tips"
            >
              <div className="thumbnail__linear-gradient absolute top-0 left-0 w-full h-full" />
              <div className=" bg-transparent absolute bottom-0 left-0 p-4">
                <h6 className="text-white text-lg">Power to Save</h6>
              </div>
              <img
                alt="Clean Energy Master Plan"
                className="w-full h-full object-cover"
                src={Images.PowertoSaveSmallPhoto}
              />
            </a>
          </div>
        </div>
      </section>
      <ContentContainer>
        <H1 className="mb-5 uppercase">OTHER RESOURCES</H1>
        <Menu as="div" className="relative max-w-xl">
          <Menu.Button className="border p-4 flex items-center justify-between w-full">
            Options <FaChevronDown />
          </Menu.Button>
          <Menu.Items className="absolute top-100 left-0 w-full flex flex-col bg-lightgray shadow-md">
            <Menu.Item>
              <a
                className="px-4 py-2 hover:bg-blue hover:text-white"
                href="/gpa-initiatives/other-resources"
              >
                Guam Energy Resources
              </a>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </ContentContainer>
    </>
  );
};
