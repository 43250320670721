import { API } from '@src/config';

export const subscribeToNewsLetter = async (
  firstName: string,
  lastName: string,
  phoneNumber: string,
  email: string,
) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: {
        FirstName: firstName,
        LastName: lastName,
        PhoneNumber: phoneNumber,
        Email: email,
      },
    }),
  };

  let data;

  try {
    // const response = await fetch(
    //   `${API}/api/search-results?filters[$or][0][title][$containsi]=${searchString}&filters[$or][1][description][$containsi]=${searchString}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
    //   options,
    // );
    const response = await fetch(`${API}/api/e-newsletters`, options);
    data = await response.json();
    return data;
  } catch (err) {
    // console.error(err);
  }

  return data;
};

export const requestSchoolVisit = async (
  name: string,
  school: string,
  phoneNumber: string,
  email: string,
) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: {
        Name: name,
        School: school,
        Number: phoneNumber,
        Email: email,
      },
    }),
  };

  let data;

  try {
    // const response = await fetch(
    //   `${API}/api/search-results?filters[$or][0][title][$containsi]=${searchString}&filters[$or][1][description][$containsi]=${searchString}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
    //   options,
    // );
    const response = await fetch(`${API}/api/school-visit-requests`, options);
    data = await response.json();
    return data;
  } catch (err) {
    // console.error(err);
  }

  return data;
};
