import React, { FC, PropsWithChildren, HTMLAttributes } from 'react';

interface OrderedListProp extends HTMLAttributes<HTMLOListElement> {
  className?: string;
}

type OrderedListProps = PropsWithChildren<OrderedListProp>;

export const OrderedList: FC<OrderedListProps> = ({ children, className }, props) => {
  return (
    <ol className={`font-rubik list-decimal list-inside pl-4 ${className}`} {...props}>
      {children}
    </ol>
  );
};
