import { API } from '@config';
import { AboutUsDepartmentNumbersTable } from '@src/types/AboutUsDepartmentNumbers';

export const getAboutUsDepartmentNumbers = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/about-us-department-numbers?populate=File&sort=id%3Adesc`, options);
  const data: AboutUsDepartmentNumbersTable = await response.json();

  return data;
};
