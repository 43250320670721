import { API } from '@config';
import { CommunitySafetyOutdoorTable } from '@src/types/CommunitySafetyOutdoor';

export const getCommunitySafetyOutdoor = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${API}/api/community-safety-outdoors?populate=File&sort=id%3Aasc`,
    options,
  );
  const data: CommunitySafetyOutdoorTable = await response.json();

  return data;
};
