import React, { useEffect, useState } from "react";
import {
  H3,
  Details,
  Images,
  Content as ContentContainer,
  H1,
  H2,
} from "@core";
import { EduPosterBook } from "@content";
import { HeaderGradient } from "@components";
import { requestSchoolVisit } from "@services";
// import { FILEDATA } from '@types';
import { API } from "@src/config";
import { Dialog } from "@headlessui/react";
import { COMMUNITYEDUCATIONALRESOURCES } from "@src/types/CommunityEducationalResources";
import { getCommunityEducationalResources } from "@src/services/CommunityEducationalResources";

const CarouselButtons = (numberOfPages: number) => {
  const content = [];
  for (let i = 0; i < numberOfPages; i++) {
    content.push(
      <button
        aria-current={i === 0 ? "true" : "false"}
        aria-label={`Slide ${i + 1}`}
        className="active carousel-buttons"
        data-bs-slide-to={i}
        data-bs-target="#carouselDarkVariant"
        type="button"
      />
    );
  }
  return content;
};

const SliceIntoChunks = (
  arr: COMMUNITYEDUCATIONALRESOURCES[],
  chunkSize: number
) => {
  const res = [];
  const content = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  content.push(
    res.map((page, index) => {
      return (
        <>
          <div
            className={
              index === 0
                ? "carousel-item active float-left w-full h-full"
                : "carousel-item float-left w-full h-full"
            }
          >
            <div className="w-full flex flex-wrap">
              {/* {console.log(page, "check pages")} */}
              {page.map((c) => {
                const onOpenImage = () => {
                  window.open(
                    `https://admin.guampowerauthority.com${c.attributes.File.data.attributes.url}`
                  );
                };
                return (
                  <>
                    <div
                      className="w-full phoneMd:w-1/2 laptopSm:w-1/4 mb-4 bg-grey"
                      onClick={onOpenImage}
                    >
                      <div className="flex flex-col items-center justify-center px-4 w-full">
                        <div className="">
                          <img
                            alt={c.attributes.Title}
                            className="block h-52"
                            src={`${API}${
                              c.attributes.File.data != null
                                ? c.attributes.File.data.attributes.url
                                : c.attributes.File.data
                            }`}
                          />
                        </div>
                        <div className="">
                          <H3 className="mb-2 text-blue text-center">
                            {c.attributes.Title}
                          </H3>
                          <Details className="text-justify">
                            {c.attributes.Description.length > 200
                              ? `${c.attributes.Description.substring(
                                  0,
                                  200
                                )}...`
                              : c.attributes.Description}
                          </Details>
                          <Details className="text-orange text-center phoneMd:text-left">
                            <a
                              href="something"
                              rel="noreferrer"
                              target="_blank"
                            >
                              {c.attributes.Anchor}
                            </a>
                          </Details>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      );
    })
  );

  return content;
};

// const EducationalBook = (
//   image: string,
//   title: string,
//   description: string,
//   anchor: string,
//   // file: FILEDATA[],
// ) => {
//   return (
//     // <div className="flex flex-col items-center tablet:flex-row tablet:items-start tablet:space-x-2 pb-10 phoneMd:pb-16">
//     <div className="flex flex-col items-center justify-center px-4 w-full">
//       <div className="">
//         <img alt={title} className="block h-52" src={`${API}${image}`} />
//       </div>
//       <div className="">
//         <H3 className="mb-2 text-blue text-center">{title}</H3>
//         <Details className="text-justify">{description.length > 200 ? `${description.substring(0,200)}...` : description}</Details>
//           <Details className="text-orange text-center phoneMd:text-left">
//             <a
//               href="something"
//               rel="noreferrer"
//               target="_blank"
//             >
//               {anchor}
//             </a>
//           </Details>
//       </div>
//     </div>
//   );
// };

export const Education = () => {
  const [name, setName] = useState<string>("");
  const [school, setSchool] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setFailedModalOpen] = useState(false);

  // const [file, setFile] = useState<FILEDATA[]>([]);
  const [communityEducationalResources, setCommunityEducationalResources] =
    useState<COMMUNITYEDUCATIONALRESOURCES[]>([]);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  // const chunks = array_chunks(SymbolData, 3);

  const getData = async () => {
    // const fileDatas = await getFileData();
    // setFile(fileDatas.data);
    const educResData = await getCommunityEducationalResources();
    setCommunityEducationalResources(educResData.data);
    setNumberOfPages(Math.ceil(educResData.data.length / 4));

    // const array_chunks = (array, chunk_size) => Array(Math.ceil(array.length / chunk_size)) .fill() .map((_, index) => index * chunk_size) .map((begin) => array.slice(begin, begin + chunk_size));
    // const chunks = array_chunks(SymbolData, 3);
  };

  const onFormSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (name === "" || school === "" || phoneNumber === "" || email === "") {
      setFailedModalOpen(true);
    } else {
      setSuccessModalOpen(true);
      requestSchoolVisit(name, school, phoneNumber, email);
      setName("");
      setSchool("");
      setPhoneNumber("");
      setEmail("");
    }
  };

  function successDialog() {
    return (
      <Dialog
        className="relative z-50 "
        onClose={() => setSuccessModalOpen(false)}
        open={isSuccessModalOpen}
      >
        <div aria-hidden="true" className="fixed inset-0 bg-black opacity-20" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-white p-8">
            <Dialog.Title>
              <H2>School Visit Request</H2>
            </Dialog.Title>
            <Dialog.Description>
              <Details>
                Thank you for filling out the request form. The request has been
                submitted.
              </Details>
            </Dialog.Description>

            <button
              className="bg-orange text-white text-rubik w-28 rounded-full py-2"
              onClick={() => setSuccessModalOpen(false)}
              type="button"
            >
              OK
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    );
  }
  function failedDialog() {
    return (
      <Dialog
        className="relative z-50 "
        onClose={() => setFailedModalOpen(false)}
        open={isFailedModalOpen}
      >
        <div aria-hidden="true" className="fixed inset-0 bg-black opacity-20" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-white p-8">
            <Dialog.Title>
              <H2>Warning</H2>
            </Dialog.Title>
            <Dialog.Description>
              <Details>You need to fill up the required fields</Details>
            </Dialog.Description>

            <button
              className="bg-orange text-white text-rubik w-28 rounded-full py-2"
              onClick={() => setFailedModalOpen(false)}
              type="button"
            >
              OK
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    );
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {successDialog()}
      {failedDialog()}

      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-misc-orange"
        rightColor="to-misc-yellow"
        title={EduPosterBook.title}
      />
      <section className=" relative">
        <div className="absolute h-full bg-black/50" />
        <div className="absolute inset-0  h-full bg-black bg-opacity-10 z-10" />
        <div className="h-full flex items-center laptop:px-28 tablet:px-20 phone:px-10 z-20 absolute">
          <div className=" tablet:w-500px phone:w-full flex flex-col tablet:items-start phone:items-center py-20">
            <h1
              className="font-rubik font-bold text-white tabletWide:text-5xl phoneWide:text-3xl phone:text-lg
              tablet:text-left phone:text-center"
            >
              Committed to energizing teachers and students.
            </h1>
          </div>
        </div>
        <img
          alt="News And Media Banner"
          className="w-full aspect-ratio__16-5"
          src={Images.EducationalResourcesBanner}
        />
      </section>
      <section className="flex items-center flex-col bg-grayC410 laptop:py-32 phone:py-14 tablet:px-20 phone:px-10">
        <div className="w-full flex flex-col items-center">
          <h1
            className="font-jubilat text-center text-blue laptop:mb-14 phone:mb-10 laptop:text-5xl 
        tablet:text-5xl phone:text-4xl"
          >
            Resources for Teachers and Students
          </h1>

          {/* <div className="w-full flex tabletWide:h-80 laptop:h-96 phone:h-auto"> */}
          <div className="w-full flex h-auto">
            <div
              className="carousel slide carousel-dark relative w-full overflow-hidden object-cover"
              data-bs-ride="carousel"
              id="carouselDarkVariant"
            >
              <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-2 opacity-50">
                {CarouselButtons(numberOfPages)}
              </div>
              <div className="carousel-inner relative overflow-hidden h-full w-full">
                {SliceIntoChunks(communityEducationalResources, 4)}
                {/* <div className="carousel-item active float-left w-full h-full">
                  <div className="w-full flex flex-wrap">
                    {communityEducationalResources.map((content) => {
                      return (
                        <div className="w-full phoneMd:w-1/2 laptopSm:w-1/4 mb-4 bg-grey" key={content.id}>
                          {EducationalBook(
                            content.attributes.File.data != null ? content.attributes.File.data.attributes.url : content.attributes.File.data,
                            content.attributes.Title,
                            content.attributes.Description,
                            content.attributes.Anchor,
                            // file,
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <ContentContainer>
          <div className="flex items-center justify-center">
            <div className="flex flex-col tabletWide:flex-row tabletWide:items-center tabletWide:space-x-16 max-w-lg tabletWide:max-w-none">
              <div className="flex flex-col items-center tabletWide:items-start mb-8">
                <h1
                  className="font-jubilat text-center text-blue laptop:text-5xl 
        tablet:text-5xl phone:text-4xl"
                >
                  CONTACT US
                </h1>
                <h2
                  className="font-rubik font-normal text-blue phone:text-lg tablet:text-xl laptop:text-2xl 
  desktop:text-2xl phone:mt-0 tablet:mt-5"
                >
                  Joyce N. Sayama
                </h2>
                <p className="mb-4">Communications Manager</p>
                <div className="flex items-center space-x-2 mb-1">
                  <img alt="Telephone Icon" src={Images.TelephoneIcon} />
                  <span>671 648-3145</span>
                </div>
                <a
                  className="flex items-center space-x-2"
                  href="mailto:jsayama@gpagwa.com"
                >
                  <img alt="Message Icon" src={Images.MessageIcon} />
                  <span>jsayama@gpagwa.com</span>
                </a>
                <a
                  className="flex items-center space-x-2"
                  href="mailto:jaacruz@gpagwa.com"
                >
                  <img alt="Message Icon" src={Images.MessageIcon} />
                  <span>jaacruz@gpagwa.com</span>
                </a>
              </div>
              <div className="p-16 tabletWide:max-w-md shadow-lg ">
                <H1 className="text-center tabletWide:text-left">
                  REQUEST A VISIT TO YOUR SCHOOL
                </H1>
                <Details className="text-center tabletWide:text-left">
                  We love supporting local schools and educators with fun,
                  hands-on energy education. Contact us today!
                </Details>
                <form onSubmit={onFormSubmitHandler}>
                  <div className="flex flex-col mb-4 ">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="name">
                      Name<span className="text-orange">*</span>
                    </label>
                    <input
                      id="name"
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      value={name}
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="school">
                      School<span className="text-orange">*</span>
                    </label>
                    <input
                      id="school"
                      name="lastName"
                      onChange={(e) => setSchool(e.target.value)}
                      type="text"
                      value={school}
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="phone-number">
                      Phone Number<span className="text-orange">*</span>
                    </label>
                    <input
                      id="phone-number"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      type="number"
                      value={phoneNumber}
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="email-address">
                      Email Address<span className="text-orange">*</span>
                    </label>
                    <input
                      id="email-address"
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      value={email}
                    />
                  </div>
                  <div className="w-full flex justify-center tabletWide:justify-start">
                    <button
                      className="bg-blue text-white cursor-pointer h-12 w-32 flex items-center justify-center"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ContentContainer>
      </section>
    </>
  );
};
