import React, { FC, PropsWithChildren, HTMLAttributes } from 'react';

interface TableProp extends HTMLAttributes<HTMLTableElement> {
  className?: string;
}

type TableProps = PropsWithChildren<TableProp>;

export const Table: FC<TableProps> = ({ children, className }, props) => {
  return (
    <div className="overflow-x-scroll scrollbar-hide">
      <table
        className={`font-rubik font-light text-black phone:text-sm tablet:text-base laptop:text-lg 
      desktop:text-lg w-full h-full ${className}`}
        {...props}
      >
        <tbody className="w-full h-full tablet:w-auto">{children}</tbody>
      </table>
    </div>
  );
};
