export const Benefits = {
  pageTitle: `Employee Benefits`,
  ul: [
    {
      anchor: `GPA Retirement Plans`,
      link: `retirement-plans`,
    },
    {
      anchor: `Medical/Dental Insurance`,
      link: `benefits`,
    },
    {
      anchor: `Life Insurance`,
      link: `life-insurance-benefit`,
    },
    {
      anchor: `Cafeteria Plan`,
      link: `the-cafeteria-plan`,
    },
    {
      anchor: `Work Leave / Sick Leave / Maternity Leave`,
      link: `leaves`,
    },
    {
      anchor: `Paid Holidays`,
      link: `paid-holidays`,
    },
    {
      anchor: `Pay Codes`,
      link: `pay-codes`,
    },
    {
      anchor: `Petitions`,
      link: `petitions`,
    },
  ],
  title: `Related Links`,
  description: `Information on all applicable laws governing employees.`,
  anchor: `www.guamcourts.org`,
  link: `http://www.guamcourts.org/CompilerofLaws/GCA/title4.html`,
};
