import React, { useEffect, useState } from 'react';
import { H1, H2, Details, Content as ContentContainer, UnorderedList, List, Images } from '@core';
import { OperationsOverview, EnvironmentalCompliance, StrategicPlanningOperations } from '@content';
import { HeaderGradient } from '@components';
import { CORPORATEOPERATIONS } from '@src/types/CorporateOperations';
import { getOperationsForms2009 } from '@src/services/OperationsForms2009Classes';
import { getOperationsForms2011 } from '@src/services/OperationsForms2011Classes';
import { getOperationsForms2012 } from '@src/services/OperationsForms2012Classes';
import { getOperationsFormsAdditional } from '@src/services/OperationsFormsAdditional';
import { getOperationsLeacBaseloadDispatch } from '@src/services/OperationsLeacBaseloadDispatch';
import { getOperationsLeacBaseloadDispatchChart } from '@src/services/OperationsLeacBaseloadDispatchChart';
import { getOperationsLeacBaseloadSavings } from '@src/services/OperationsLeacBaseloadSavings';
import { getOperationsLeacEafChart } from '@src/services/OperationsLeacEafChart';
import { getOperationsLeacEforChart } from '@src/services/OperationsLeacEforChart';
import { getOperationsLeacFuelUsage } from '@src/services/OperationsLeacFuelUsage';
import { getOperationsLeacGrossheatRate } from '@src/services/OperationsLeacGrossHeatRate';
import { getOperationsLeacIwpsDispatch } from '@src/services/OperationsLeacIwpsDispatch';
import { getOperationsLeacPreviousLeacCharts } from '@src/services/OperationsLeacPreviousLeacCharts';
import { API } from '@src/config';
// import { getFileData } from '@services';
// import { FILEDATA } from '@types';
// import { API } from '@src/config';

export const Operations = () => {
  // const [file, setFile] = useState<FILEDATA[]>([]);
  const [forms2009, setForms2009] = useState<CORPORATEOPERATIONS[]>([]);
  const [forms2011, setForms2011] = useState<CORPORATEOPERATIONS[]>([]);
  const [forms2012, setForms2012] = useState<CORPORATEOPERATIONS[]>([]);
  const [formsAdditional, setFormsAdditional] = useState<CORPORATEOPERATIONS[]>([]);
  const [leacBaseloadDispatch, setLeacBaseloadDispatch] = useState<CORPORATEOPERATIONS[]>([]);
  const [leacBaseloadDispatchChart, setLeacBaseloadDispatchChart] = useState<CORPORATEOPERATIONS[]>(
    [],
  );
  const [leacBaseloadSavings, setLeacBaseloadSavings] = useState<CORPORATEOPERATIONS[]>([]);
  const [leacEafChart, setLeacEafChart] = useState<CORPORATEOPERATIONS[]>([]);
  const [leacEforChart, setLeacEforChart] = useState<CORPORATEOPERATIONS[]>([]);
  const [leacFuelUsage, setLeacFuelUsage] = useState<CORPORATEOPERATIONS[]>([]);
  const [leacGrossHeatRate, setLeacGrossHeatRate] = useState<CORPORATEOPERATIONS[]>([]);
  const [leacIwpsDispatch, setLeacIwpsDispatch] = useState<CORPORATEOPERATIONS[]>([]);
  const [leacPreviousLeacCharts, setLeacPreviousLeacCharts] = useState<CORPORATEOPERATIONS[]>([]);

  const getData = async () => {
    const forms2009Data = await getOperationsForms2009();
    const forms2011Data = await getOperationsForms2011();
    const forms2012Data = await getOperationsForms2012();
    const formsAdditionalData = await getOperationsFormsAdditional();
    const leacBaseloadDispatchData = await getOperationsLeacBaseloadDispatch();
    const leacBaseloadDispatchChartData = await getOperationsLeacBaseloadDispatchChart();
    const leacBaseloadSavingsData = await getOperationsLeacBaseloadSavings();
    const leacEafChartData = await getOperationsLeacEafChart();
    const leacEforChartData = await getOperationsLeacEforChart();
    const leacFuelUsageData = await getOperationsLeacFuelUsage();
    const leacGrossHeatRateData = await getOperationsLeacGrossheatRate();
    const leacIwpsDispatchData = await getOperationsLeacIwpsDispatch();
    const leacPreviousLeacChartsData = await getOperationsLeacPreviousLeacCharts();

    setForms2009(forms2009Data.data);
    setForms2011(forms2011Data.data);
    setForms2012(forms2012Data.data);
    setFormsAdditional(formsAdditionalData.data);
    setLeacBaseloadDispatch(leacBaseloadDispatchData.data);
    setLeacBaseloadDispatchChart(leacBaseloadDispatchChartData.data);
    setLeacBaseloadSavings(leacBaseloadSavingsData.data);
    setLeacEafChart(leacEafChartData.data);
    setLeacEforChart(leacEforChartData.data);
    setLeacFuelUsage(leacFuelUsageData.data);
    setLeacGrossHeatRate(leacGrossHeatRateData.data);
    setLeacIwpsDispatch(leacIwpsDispatchData.data);
    setLeacPreviousLeacCharts(leacPreviousLeacChartsData.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const clickItem = (link?: string, file?: any) => {
    if (file != null) {
      window.open(`${API}${file}`);
    } else {
      window.open(`${link}`);
    }
  };

  // console.log(forms2009,forms2011,forms2012,forms2012,formsAdditional,leacBaseloadDispatch,leacBaseloadDispatchChart,leacBaseloadSavings,leacEafChart,leacEforChart,leacFuelUsage,leacGrossHeatRate,leacIwpsDispatch,leacPreviousLeacCharts,leacPreviousLeacCharts)

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={OperationsOverview.bannerTitle}
      />

      <section className="relative">
        <div className="absolute inset-0  h-full bg-black bg-opacity-10 z-10" />
        <div className="h-full flex items-center laptop:px-28 tablet:px-20 phone:px-10 z-20 absolute">
          <div className="tablet:w-500px  phone:w-full flex flex-col tablet:items-start phone:items-center py-20">
            <h1
              className="font-rubik font-bold text-white tabletWide:text-5xl phoneWide:text-3xl phone:text-lg
            tablet:text-left phone:text-center"
            >
              Day and night, we’re always on. EmPOWERED TO SERVE our community.
            </h1>
          </div>
        </div>
        <img
          alt="News And Media Banner"
          className="w-full aspect-ratio__16-6"
          src={Images.PowerToServeBanner}
        />
      </section>
      <ContentContainer>
        <div className="flex flex-row relative">
          <div className="flex flex-col">
            <section
              className="flex flex-col-reverse tablet:flex-row mb-10 tablet:mb-20"
              id="audit-and-revenue"
            >
              <div className="w-full tablet:w-3/4">
                <div>
                  <H1 className="mb-5">{OperationsOverview.title}</H1>
                  <H2 className="text-blue">{OperationsOverview.subTitle}</H2>
                </div>
                <div>
                  {OperationsOverview.contents1.map((content) => {
                    return (
                      <>
                        <H1 className="mb-5">{content.title}</H1>
                        <H2>{content.subTitle}</H2>
                        <Details>{content.paragraph1}</Details>
                        <UnorderedList className="my-5">
                          {content.ul.map((value) => {
                            return (
                              <>
                                <List>{value.text}</List>
                              </>
                            );
                          })}
                        </UnorderedList>
                        <Details className="mb-10">{content.paragraph2}</Details>
                      </>
                    );
                  })}
                  {OperationsOverview.contents2.map((content) => {
                    return (
                      <>
                        <H1 className="mb-5">{content.title}</H1>
                        <H2>{content.subTitle}</H2>
                        <Details>{content.paragraph1}</Details>
                        <UnorderedList className="my-5">
                          {content.ul.map((value) => {
                            return (
                              <>
                                <List>{value.text}</List>
                              </>
                            );
                          })}
                        </UnorderedList>
                        <Details className="mb-10">{content.paragraph2}</Details>
                      </>
                    );
                  })}
                  {OperationsOverview.contents3.map((content) => {
                    return (
                      <>
                        <H1 className="mb-5">{content.title}</H1>
                        <H2>{content.subTitle}</H2>
                        <Details>{content.paragraph1}</Details>
                        <UnorderedList className="my-5">
                          {content.ul.map((value) => {
                            return (
                              <>
                                <List>
                                  {value.text}&nbsp;
                                  <a className="text-orange" href={value.link}>
                                    {value.anchor}
                                  </a>
                                </List>
                              </>
                            );
                          })}
                        </UnorderedList>
                        <Details className="mb-10">{content.paragraph2}</Details>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="text-orange flex flex-col pt-8 mb-8 tablet:mb-0 tablet:ml-8 w-full tablet:w-1/4">
                <a className="text-orange mb-2" href="#audit-and-revenue">
                  Internal Audit/Revenue Protection
                </a>
                <a className="text-orange mb-2" href="#planning-and-environmental-compliance">
                  Planning and Regulatory/Environmental Compliance
                </a>
                <a className="text-orange mb-2" href="#planning-and-operations">
                  Strategic Planning & Operations Research
                </a>
                <a className="text-orange" href="#leac-performance">
                  LEAC Performance Control
                </a>
              </div>
            </section>
            <section id="leac-performance">
              <H1>Downloadable Forms</H1>
              <div className="mb-16">
                <H2 className="my-5">ADDITIONAL RESOURCES</H2>
                <UnorderedList>
                  {formsAdditional.map((value) => {
                    return (
                      <List className="text-orange" key={value.id}>
                        <button
                          className="text-left"
                          onClick={() =>
                            clickItem(
                              value.attributes.Link,
                              value.attributes.File?.data != null
                                ? value.attributes.File.data.attributes.url
                                : value.attributes.File.data,
                            )
                          }
                          type="button"
                        >
                          {value.attributes.Anchor}
                        </button>
                      </List>
                    );
                  })}
                </UnorderedList>
              </div>
              <div>
                <H2 className="mb-5">TOTAL CLASSES HELD</H2>
                <div className="phone:flex flex-wrap tabletWide:grid tabletWide:grid-cols-3 mb-5">
                  <div className="w-64 tabletWide:w-80 mr-auto tabletWide:mr-0 mb-5">
                    <H2 className="mb-5">2009 - TOTAL CLASSES HELD - 8</H2>
                    <UnorderedList>
                      {forms2009.map((value) => {
                        return (
                          <List className="text-orange" key={value.id}>
                            <button
                              className="text-left"
                              onClick={() =>
                                clickItem(
                                  value.attributes.Link,
                                  value.attributes.File?.data != null
                                    ? value.attributes.File.data.attributes.url
                                    : value.attributes.File.data,
                                )
                              }
                              type="button"
                            >
                              {value.attributes.Anchor.length > 25
                                ? `${value.attributes.Anchor.substring(0, 25)}...`
                                : value.attributes.Anchor}
                            </button>
                          </List>
                        );
                      })}
                    </UnorderedList>
                  </div>
                  <div className="w-64 tabletWide:w-80 mr-auto tabletWide:mr-0 mb-5">
                    <H2 className="mb-5">2011 - TOTAL CLASSES HELD - 6</H2>
                    <UnorderedList>
                      {forms2011.map((value) => {
                        return (
                          <List className="text-orange" key={value.id}>
                            <button
                              className="text-left"
                              onClick={() =>
                                clickItem(
                                  value.attributes.Link,
                                  value.attributes.File?.data != null
                                    ? value.attributes.File.data.attributes.url
                                    : value.attributes.File.data,
                                )
                              }
                              type="button"
                            >
                              {value.attributes.Anchor.length > 25
                                ? `${value.attributes.Anchor.substring(0, 25)}...`
                                : value.attributes.Anchor}
                            </button>
                          </List>
                        );
                      })}
                    </UnorderedList>
                  </div>
                  <div className="w-64 tabletWide:w-80 mr-auto tabletWide:mr-0 mb-5">
                    <H2 className="mb-5">2012 - TOTAL CLASSES HELD - 8</H2>
                    <UnorderedList>
                      {forms2012.map((value) => {
                        return (
                          <List className="text-orange" key={value.id}>
                            <button
                              className="text-left"
                              onClick={() =>
                                clickItem(
                                  value.attributes.Link,
                                  value.attributes.File?.data != null
                                    ? value.attributes.File.data.attributes.url
                                    : value.attributes.File.data,
                                )
                              }
                              type="button"
                            >
                              {value.attributes.Anchor.length > 25
                                ? `${value.attributes.Anchor.substring(0, 25)}...`
                                : value.attributes.Anchor}
                            </button>
                          </List>
                        );
                      })}
                    </UnorderedList>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div>
                <H1 className="mb-5">LEAC PERFORMANCE CONTROL</H1>
                <div className="phone:flex flex-wrap tablet:grid tablet:grid-cols-2 laptopSm:grid-cols-3">
                  <div className="w-64 tabletWide:w-80 mr-auto tabletWide:mr-0 mb-5">
                    <H2 className="mb-5">Baseload Dispatch Performance Chart</H2>
                    <UnorderedList>
                      {leacBaseloadDispatchChart.map((value) => {
                        return (
                          <List className="text-orange" key={value.id}>
                            <button
                              className="text-left"
                              onClick={() =>
                                clickItem(
                                  value.attributes.Link,
                                  value.attributes.File?.data != null
                                    ? value.attributes.File.data.attributes.url
                                    : value.attributes.File.data,
                                )
                              }
                              type="button"
                            >
                              {value.attributes.Anchor}
                            </button>
                          </List>
                        );
                      })}
                    </UnorderedList>
                  </div>
                  <div className="w-64 tabletWide:w-80 mr-auto tabletWide:mr-0 mb-5">
                    <H2 className="mb-5">Gross Heat Rate Performance Chart</H2>
                    <UnorderedList>
                      {leacGrossHeatRate.map((value) => {
                        return (
                          <List className="text-orange" key={value.id}>
                            <button
                              className="text-left"
                              onClick={() =>
                                clickItem(
                                  value.attributes.Link,
                                  value.attributes.File?.data != null
                                    ? value.attributes.File.data.attributes.url
                                    : value.attributes.File.data,
                                )
                              }
                              type="button"
                            >
                              {value.attributes.Anchor}
                            </button>
                          </List>
                        );
                      })}
                    </UnorderedList>
                  </div>
                  <div className="w-64 tabletWide:w-80 mr-auto tabletWide:mr-0 mb-5">
                    <H2 className="mb-5">Fuel Usage Consumption</H2>
                    <UnorderedList>
                      {leacFuelUsage.map((value) => {
                        return (
                          <List className="text-orange" key={value.id}>
                            <button
                              className="text-left"
                              onClick={() =>
                                clickItem(
                                  value.attributes.Link,
                                  value.attributes.File?.data != null
                                    ? value.attributes.File.data.attributes.url
                                    : value.attributes.File.data,
                                )
                              }
                              type="button"
                            >
                              {value.attributes.Anchor}
                            </button>
                          </List>
                        );
                      })}
                    </UnorderedList>
                  </div>
                  <div className="w-64 tabletWide:w-80 mr-auto tabletWide:mr-0 mb-5">
                    <H2 className="mb-5">IWPS Dispatch Performance</H2>
                    <UnorderedList>
                      {leacIwpsDispatch.map((value) => {
                        return (
                          <List className="text-orange" key={value.id}>
                            <button
                              className="text-left"
                              onClick={() =>
                                clickItem(
                                  value.attributes.Link,
                                  value.attributes.File?.data != null
                                    ? value.attributes.File.data.attributes.url
                                    : value.attributes.File.data,
                                )
                              }
                              type="button"
                            >
                              {value.attributes.Anchor}
                            </button>
                          </List>
                        );
                      })}
                    </UnorderedList>
                  </div>
                  <div className="w-64 tabletWide:w-80 mr-auto tabletWide:mr-0 mb-5">
                    <H2 className="mb-5">Baseload Dispatch Performance</H2>
                    <UnorderedList>
                      {leacBaseloadDispatch.map((value) => {
                        return (
                          <List className="text-orange" key={value.id}>
                            <button
                              className="text-left"
                              onClick={() =>
                                clickItem(
                                  value.attributes.Link,
                                  value.attributes.File?.data != null
                                    ? value.attributes.File.data.attributes.url
                                    : value.attributes.File.data,
                                )
                              }
                              type="button"
                            >
                              {value.attributes.Anchor}
                            </button>
                          </List>
                        );
                      })}
                    </UnorderedList>
                  </div>
                  <div className="w-64 tabletWide:w-80 mr-auto tabletWide:mr-0 mb-5">
                    <H2 className="mb-5">Savings from Increased Baseload Use</H2>
                    <UnorderedList>
                      {leacBaseloadSavings.map((value) => {
                        return (
                          <List className="text-orange" key={value.id}>
                            <button
                              className="text-left"
                              onClick={() =>
                                clickItem(
                                  value.attributes.Link,
                                  value.attributes.File?.data != null
                                    ? value.attributes.File.data.attributes.url
                                    : value.attributes.File.data,
                                )
                              }
                              type="button"
                            >
                              {value.attributes.Anchor}
                            </button>
                          </List>
                        );
                      })}
                    </UnorderedList>
                  </div>
                  <div className="w-64 tabletWide:w-80 mr-auto tabletWide:mr-0 mb-5">
                    <H2 className="mb-5">Previous LEAC Period Control Charts:</H2>
                    <UnorderedList>
                      {leacPreviousLeacCharts.map((value) => {
                        return (
                          <List className="text-orange" key={value.id}>
                            <button
                              className="text-left"
                              onClick={() =>
                                clickItem(
                                  value.attributes.Link,
                                  value.attributes.File?.data != null
                                    ? value.attributes.File.data.attributes.url
                                    : value.attributes.File.data,
                                )
                              }
                              type="button"
                            >
                              {value.attributes.Anchor}
                            </button>
                          </List>
                        );
                      })}
                    </UnorderedList>
                  </div>
                  <div className="w-64 tabletWide:w-80 mr-auto tabletWide:mr-0 mb-5">
                    <H2 className="mb-5">Equivalent Forced Outage Rate (EFOR)Charts</H2>
                    <UnorderedList>
                      {leacEforChart.map((value) => {
                        return (
                          <List className="text-orange" key={value.id}>
                            <button
                              className="text-left"
                              onClick={() =>
                                clickItem(
                                  value.attributes.Link,
                                  value.attributes.File?.data != null
                                    ? value.attributes.File.data.attributes.url
                                    : value.attributes.File.data,
                                )
                              }
                              type="button"
                            >
                              {value.attributes.Anchor}
                            </button>
                          </List>
                        );
                      })}
                    </UnorderedList>
                  </div>
                  <div className="w-64 tabletWide:w-80 mr-auto tabletWide:mr-0 mb-5">
                    <H2 className="mb-5">Equivalent Availability Factor (EAF)Performance Charts</H2>
                    <UnorderedList>
                      {leacEafChart.map((value) => {
                        return (
                          <List className="text-orange" key={value.id}>
                            <button
                              className="text-left"
                              onClick={() =>
                                clickItem(
                                  value.attributes.Link,
                                  value.attributes.File?.data != null
                                    ? value.attributes.File.data.attributes.url
                                    : value.attributes.File.data,
                                )
                              }
                              type="button"
                            >
                              {value.attributes.Anchor}
                            </button>
                          </List>
                        );
                      })}
                    </UnorderedList>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="
            bg-shadow laptop:-mx-20 tablet:-mx-12 phone:-mx-7 laptop:px-20 laptop:py-14 tablet:p-12 phone:p-7"
              id="planning-and-environmental-compliance"
            >
              <div>
                <H1>{EnvironmentalCompliance.title}</H1>
                {EnvironmentalCompliance.contents.map((content) => {
                  return (
                    <>
                      <H2 className="text-blue mt-10">{content.title}</H2>
                      <Details>{content.paragraph}</Details>
                      <UnorderedList>
                        {content.ul.map((value) => {
                          return <List key={value}>{value}</List>;
                        })}
                      </UnorderedList>
                    </>
                  );
                })}
              </div>
              <div>
                {EnvironmentalCompliance.endContent.map((content) => {
                  return (
                    <>
                      <H2 className="text-black mt-10">{content.title}</H2>
                      <Details>
                        {content.paragraph[0]}
                        <br />
                        {content.paragraph[1]}&nbsp;
                        <a
                          className="text-orange"
                          href={content.link}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {content.anchor}
                        </a>
                      </Details>
                    </>
                  );
                })}
              </div>
            </section>
            <section className="my-10 tablet:my-20" id="planning-and-operations">
              <H1 className="mb-5">{StrategicPlanningOperations.title}</H1>
              {StrategicPlanningOperations.contents.map((content) => {
                return (
                  <>
                    <H2 className="mb-5 text-blue">{content.title}</H2>
                    <Details>{content.paragraph}</Details>
                    <UnorderedList>
                      {content.ul.map((value) => {
                        return (
                          <>
                            <List className="text-orange" key={value.anchor}>
                              <a href={value.link}>{value.anchor}</a>
                            </List>
                          </>
                        );
                      })}
                    </UnorderedList>
                  </>
                );
              })}
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
