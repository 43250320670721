import React, { useEffect, useState } from "react";
import {
  List,
  Content as ContentContainer,
  Table,
  TableCell as TD,
  OrderedList,
} from "@core";
// import { Rates as Content } from '@content';
import { HeaderGradient } from "@components";
// import { getRateConstants } from '@services';
// import { RATECONSTANTSDATA } from '@src/types/RateConstants';
import { RATECALCULATOR } from "@src/types/RateCalculator";
import { getRateCalculator } from "@src/services/RateCalculator";

// class GPACalculator {
//   kilowattInput: number;

//   monthlyCustomerChargeCalcValue: number;

//   emergencyWaterWellWasteCalcValue: number;

//   fuelRecoveryChargeCalcValue: number;

//   selfInsuranceChargeCalcValue: number;

//   nonFuelEnergyChargeFirst500KWHRCalcValue: number;

//   nonFuelEnergyChargeOver500KWHRCalcValue: number;

//   monthlyCustomerChargeRateValue: number;

//   emergencyWaterWellWasteRateValue: number;

//   fuelRecoveryChargeRateValue: number;

//   selfInsuranceChargeRateValue: number;

//   nonFuelEnergyChargeFirst500KWHRRateValue: number;

//   nonFuelEnergyChargeOver500KWHRRateValue: number;

//   monthlyCustomerChargeTotal: number;

//   emergencyWaterWellWasteTotal: number;

//   fuelRecoveryChargeTotal: number;

//   selfInsuranceChargeTotal: number;

//   nonFuelEnergyChargeFirst500KWHRTotal: number;

//   nonFuelEnergyChargeOver500KWHRTotal: number;

//   total: number;

//   constructor() {
//     this.kilowattInput = 0.0;

//     this.monthlyCustomerChargeCalcValue = 0.0;
//     this.emergencyWaterWellWasteCalcValue = 0.0;
//     this.fuelRecoveryChargeCalcValue = 0.0;
//     this.selfInsuranceChargeCalcValue = 0.0;
//     this.nonFuelEnergyChargeFirst500KWHRCalcValue = 0.0;
//     this.nonFuelEnergyChargeOver500KWHRCalcValue = 0.0;

//     this.monthlyCustomerChargeRateValue = 0.0;
//     this.emergencyWaterWellWasteRateValue = 0.0;
//     this.fuelRecoveryChargeRateValue = 0.0;
//     this.selfInsuranceChargeRateValue = 0.0;
//     this.nonFuelEnergyChargeFirst500KWHRRateValue = 0.0;
//     this.nonFuelEnergyChargeOver500KWHRRateValue = 0.0;

//     this.monthlyCustomerChargeTotal = 0.0;
//     this.emergencyWaterWellWasteTotal = 0.0;
//     this.fuelRecoveryChargeTotal = 0.0;
//     this.selfInsuranceChargeTotal = 0.0;
//     this.nonFuelEnergyChargeFirst500KWHRTotal = 0.0;
//     this.nonFuelEnergyChargeOver500KWHRTotal = 0.0;

//     this.total = 0.0;
//   }

//   calculate = (
//     kwInput: number,
//     minDeduction: number,
//     mCCCV: number,
//     eWWWC: number,
//     fRCC: number,
//     sIC: number,
//     nFECF500: number,
//     nFECO500: number,
//   ): number[] => {
//     this.monthlyCustomerChargeRateValue = 1;
//     this.emergencyWaterWellWasteRateValue = kwInput - minDeduction;
//     this.fuelRecoveryChargeRateValue = kwInput;
//     this.selfInsuranceChargeRateValue = kwInput;
//     this.nonFuelEnergyChargeFirst500KWHRRateValue = minDeduction;
//     this.nonFuelEnergyChargeOver500KWHRRateValue = kwInput - minDeduction;

//     this.monthlyCustomerChargeTotal = 1 * 15;
//     this.emergencyWaterWellWasteTotal = (kwInput - minDeduction) * eWWWC;
//     this.fuelRecoveryChargeTotal = kwInput * fRCC;
//     this.selfInsuranceChargeTotal = kwInput * sIC;
//     this.nonFuelEnergyChargeFirst500KWHRTotal = minDeduction * nFECF500;
//     this.nonFuelEnergyChargeOver500KWHRTotal = (kwInput - minDeduction) * nFECO500;

//     this.total =
//       this.monthlyCustomerChargeTotal +
//       this.emergencyWaterWellWasteTotal +
//       this.fuelRecoveryChargeTotal +
//       this.selfInsuranceChargeTotal +
//       this.nonFuelEnergyChargeFirst500KWHRTotal +
//       this.nonFuelEnergyChargeOver500KWHRTotal;

//     return [
//       this.monthlyCustomerChargeRateValue,
//       mCCCV,
//       this.monthlyCustomerChargeTotal,
//       this.emergencyWaterWellWasteRateValue,
//       eWWWC,
//       this.emergencyWaterWellWasteTotal,
//       this.fuelRecoveryChargeRateValue,
//       fRCC,
//       this.fuelRecoveryChargeTotal,
//       this.selfInsuranceChargeRateValue,
//       sIC,
//       this.selfInsuranceChargeTotal,
//       this.nonFuelEnergyChargeFirst500KWHRRateValue,
//       nFECF500,
//       this.nonFuelEnergyChargeFirst500KWHRTotal,
//       this.nonFuelEnergyChargeOver500KWHRRateValue,
//       nFECO500,
//       this.nonFuelEnergyChargeOver500KWHRTotal,
//       this.total,
//     ];
//   };
// }

export const RateCalculator = () => {
  const [kwhInput, setKwhInput] = useState<string>("");
  // const [rateConstants, setRateConstants] = useState<RATECONSTANTSDATA>();
  // const [computationResult, setComputationResult] = useState<number[]>([]);

  const [rateCalculator, setRateCalculator] = useState<RATECALCULATOR[]>([]);
  const [isCalculate, setIsCalculate] = useState<Boolean>(false);
  const [formError, setFormError] = useState<Boolean>(false);
  const [calculateTotal, setCalculateTotal] = useState<number>();

  // const minDeduction = rateConstants?.attributes.MinimumFixedDeduction ?? '0';
  // const mCCCV = rateConstants?.attributes.MonthlyCustomerChargeCalcValue ?? '0';
  // const eWWWCV = rateConstants?.attributes.EmergencyWaterWellWasteCalcValue ?? '0';
  // const fRCCV = rateConstants?.attributes.FuelRecoveryChargeCalcValue ?? '0';
  // const sICCV = rateConstants?.attributes.SelfInsuranceChargeCalcValue ?? '0';
  // const nFFCV = rateConstants?.attributes.NonFuelFirstCalcValue ?? '0';
  // const nFOCV = rateConstants?.attributes.NonFuelOverCalcValue ?? '0';

  // const gpaCalculator = new GPACalculator();

  function formSubmitHandler(event: any) {
    let total = 0;
    event.preventDefault();

    if (kwhInput !== "" && parseFloat(kwhInput) >= 500) {
      setIsCalculate(true);
      setFormError(false);
      for (let i = 0; i < rateCalculator.length; i++) {
        if (rateCalculator[i].attributes.Type === "Fixed") {
          total +=
            rateCalculator[i].attributes.Rate_Value *
            rateCalculator[i].attributes.Calc_Value;
        }
        if (rateCalculator[i].attributes.Type === "Percentage") {
          total +=
            ((parseFloat(kwhInput) * rateCalculator[i].attributes.Rate_Value) /
              100) *
            rateCalculator[i].attributes.Calc_Value;
        }
        if (rateCalculator[i].attributes.Type === "Fixed Deduction") {
          total +=
            (parseFloat(kwhInput) - rateCalculator[i].attributes.Rate_Value) *
            rateCalculator[i].attributes.Calc_Value;
        }
        console.log("current is", total);
      }
      setCalculateTotal(total);
    } else {
      setFormError(true);
    }
    // setComputationResult(
    //   gpaCalculator.calculate(
    //     +kwhInput,
    //     +minDeduction,
    //     +mCCCV,
    //     +eWWWCV,
    //     +fRCCV,
    //     +sICCV,
    //     +nFFCV,
    //     +nFOCV,
    //   ),
    // );
  }

  const getConstants = async () => {
    // const rateConstantsTemp = await getRateConstants();
    // setRateConstants(rateConstantsTemp.data);

    const rateCalculatorData = await getRateCalculator();
    setRateCalculator(rateCalculatorData.data);
  };

  useEffect(() => {
    getConstants();
  }, []);

  console.log("isCalculate", isCalculate);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Calculator"
      />
      <ContentContainer>
        <Table className="my-10">
          <tr>
            <TD
              className="col-span-2"
              colSpan={4}
              key="kwhInput"
              rowType="heading"
            >
              <div className="flex justify-between items-center flex-col tablet:flex-row">
                <div>Rate Calculator</div>
                <form onSubmit={(e) => formSubmitHandler(e)}>
                  {formError === true ? (
                    <>
                      <span className="mr-4 text-lightRed">
                        Please input Kilowatts 500 and above:
                      </span>
                      <input
                        className="mr-4 text-red border border-red"
                        onChange={(e) => {
                          setIsCalculate(false);
                          setKwhInput(e.target.value);
                        }}
                        type="number"
                      />
                    </>
                  ) : (
                    <>
                      <span className="mr-4">Kilowatt Hours:</span>
                      <input
                        className="mr-4 text-black"
                        onChange={(e) => {
                          setIsCalculate(false);
                          setKwhInput(e.target.value);
                        }}
                        type="number"
                      />
                    </>
                  )}

                  <button className="bg-orange px-4 py-2 " type="submit">
                    Calculate
                  </button>
                </form>
              </div>
            </TD>
          </tr>
          {rateCalculator.map((item) => {
            return (
              <tr key={item.attributes.createdAt}>
                <TD>{item.attributes.Label}</TD>
                {/* <TD>{item.attributes.Type == "Percentage" ? `Input * ${item.attributes.Rate_Value}` : "Rate Value"}</TD> */}
                <TD>
                  {isCalculate === true && formError === false ? (
                    <>
                      {item.attributes.Type === "Fixed"
                        ? `${item.attributes.Rate_Value}`
                        : null}
                      {item.attributes.Type === "Percentage"
                        ? `${
                            (parseFloat(kwhInput) *
                              item.attributes.Rate_Value) /
                            100
                          }`
                        : null}
                      {item.attributes.Type === "Fixed Deduction"
                        ? `${parseFloat(kwhInput) - item.attributes.Rate_Value}`
                        : null}
                    </>
                  ) : (
                    "N/A"
                  )}
                </TD>
                <TD>X {item.attributes.Calc_Value} =</TD>
                <TD>
                  {isCalculate === true && formError === false ? (
                    <>
                      {item.attributes.Type === "Fixed"
                        ? `$${(
                            item.attributes.Rate_Value *
                            item.attributes.Calc_Value
                          ).toFixed(2)}`
                        : null}
                      {item.attributes.Type === "Percentage"
                        ? `$${(
                            ((parseFloat(kwhInput) *
                              item.attributes.Rate_Value) /
                              100) *
                            item.attributes.Calc_Value
                          ).toFixed(2)}`
                        : null}
                      {item.attributes.Type === "Fixed Deduction"
                        ? `$${(
                            (parseFloat(kwhInput) -
                              item.attributes.Rate_Value) *
                            item.attributes.Calc_Value
                          ).toFixed(2)}`
                        : null}
                    </>
                  ) : (
                    "N/A"
                  )}
                </TD>
              </tr>
            );
          })}

          {/* <tr>
            <TD>Monthly Customer Charge</TD>
            <TD>{computationResult[0] ?? 'N/A'}</TD>
            <TD>{`X ${mCCCV ?? 'N/A'} =`}</TD>
            <TD className="flex">$ {computationResult[2] ?? 'N/A'}</TD>
          </tr>
          <tr>
            <TD>EMERGENCY WATER WELL/WASTE</TD>
            <TD>{computationResult[3] ?? 'N/A'}</TD>
            <TD>{`X ${eWWWCV ?? 'N/A'} =`}</TD>
            <TD className="flex">$ {computationResult[5] ?? 'N/A'}</TD>
          </tr>
          <tr>
            <TD>FUEL RECOVERY CHARGE</TD>
            <TD>{computationResult[6] ?? 'N/A'}</TD>
            <TD>{`X ${fRCCV ?? 'N/A'} =`}</TD>
            <TD className="flex">$ {computationResult[8] ?? 'N/A'}</TD>
          </tr>
          <tr>
            <TD>SELF INSURANCE CHARGE</TD>
            <TD>{computationResult[9] ?? 'N/A'}</TD>
            <TD>{`X ${sICCV ?? 'N/A'} =`}</TD>
            <TD className="flex">$ {computationResult[11] ?? 'N/A'}</TD>
          </tr>
          <tr>
            <TD>NON-FUEL ENERGY CHARGE - FIRST 500 KWHR</TD>
            <TD>{computationResult[12] ?? 'N/A'}</TD>
            <TD key="mcc">{`X ${nFFCV ?? 'N/A'} =`}</TD>
            <TD className="flex">$ {computationResult[14] ?? 'N/A'}</TD>
          </tr>
          <tr>
            <TD>NON-FUEL ENERGY CHARGE - OVER 500 KWHR</TD>
            <TD>{computationResult[15] ?? 'N/A'}</TD>
            <TD>{`X ${nFOCV ?? 'N/A'} =`}</TD>
            <TD className="flex">$ {computationResult[17] ?? 'N/A'}</TD>
          </tr> */}
          <tr>
            <TD>**TOTAL</TD>
            <TD>&nbsp;</TD>
            <TD>&nbsp;</TD>
            <TD className="flex">
              {isCalculate === true && formError === false
                ? `$${calculateTotal?.toFixed(2)}`
                : "N/A"}
            </TD>
          </tr>
        </Table>
        <div className="w-11/12 mr-20">
          <OrderedList>
            <div className="grid laptop:grid-flow-row phone:grid-flow-row gap-x-10">
              <List>Base Rates effective on October 1, 2015.</List>
              <List>
                Fuel Recovery Charge or LEAC effective June 1, 2023 through
                January 31, 2024.
              </List>
              <List>
                Self-Insurance Fund resumes on August 1, 2021. GPA maintains a
                self-insurance fund to pay for items and deductibles not covered
                by its insurance policies.
              </List>
            </div>
          </OrderedList>
        </div>
      </ContentContainer>
    </>
  );
};
