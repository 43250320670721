export const ResourceLinks = {
  pageTitle: `Strategic Planning and Operations Research`,
  title: `Renewable Resources Links`,
  contents: [
    {
      title: `National Wind Coordinating Collaborative`,
      anchor: `www.nationalwind.org`,
      link: `https://rewi.org/nwcc-timeline/`,
    },
    {
      title: `Wind Energy Resources Atlas`,
      anchor: `www.nrel.gov/grid/solar-resource/renewable-resource-data`,
      link: `https://www.nrel.gov/grid/solar-resource/renewable-resource-data.html`,
    },
    // {
    //   title: `U.S. Department of Energy—Energy Efficiency and Renewable Energy`,
    //   anchor: `www1.eere.energy.gov/windandhydro`,
    //   link: `https://www1.eere.energy.gov/windandhydro`,
    // },
  ],
};
