import React, { useEffect } from 'react';
import { H1, H2, Details, List, UnorderedList, Content as ContentContainer, Images } from '@core';
import { OverviewMission, HROffice, HRLinks } from '@content';
import { HeaderGradient } from '@components';
// import { FILEDATA } from '@src/types';
// import { getFileData } from '@src/services';
// import { API } from '@src/config';

export const HumanResources = () => {
  // const [file, setFile] = useState<FILEDATA[]>([]);

  const getData = async () => {
    // const fileDatas = await getFileData();
    // setFile(fileDatas.data);
    // console.log(file);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={OverviewMission.title}
      />
      <div
        className="h-1/4 tabletWide:h-1/3 laptop:h-1/2 bg-top bg-cover bg-hero"
        style={{ backgroundImage: `url(${Images.HumanResourcesBanner})` }}
      />
      <ContentContainer>
        <div className="flex tabletWide:flex-row phone:flex-col">
          <div className="w-full tabletWide:w-8/12 tabletWide:mr-20">
            <section>
              {OverviewMission.contents.map((content) => {
                return (
                  <>
                    <H2 className="text-blue">{content.title}</H2>
                    <Details>{content.description}</Details>
                    {/* {content.ul.map((value) => {
                      return (
                        <Details className="-mt-5 text-orange" key={value.anchor}>
                          <a href={value.link}>{value.anchor}</a>
                        </Details>
                      );
                    })} */}
                  </>
                );
              })}
            </section>
            <section className="mt-10">
              <H1>{HROffice.title}</H1>
              <div>
                {HROffice.hr.map((content) => {
                  return (
                    <>
                      <H2 className="mt-5">{content.title}</H2>
                      <Details>{content.phone}</Details>
                      <Details className="-mt-5">{content.fax}</Details>
                      <Details className="-mt-5">
                        {content.email}
                        <a className="text-orange" href={content.mailto}>
                          {content.emailText}
                        </a>
                      </Details>
                    </>
                  );
                })}
              </div>
              <div>
                {HROffice.op.map((content) => {
                  return (
                    <>
                      <H2 className="mt-5">{content.title}</H2>
                      <Details>{content.days}</Details>
                      <Details className="-mt-5">{content.time}</Details>
                      <Details className="-mt-5">{content.closed}</Details>
                    </>
                  );
                })}
              </div>
              <Details>{HROffice.description}</Details>
              <div>
                {HROffice.admin.map((content) => {
                  return (
                    <>
                      <H2>{content.title}</H2>
                      <Details>{content.name}</Details>
                      <Details className="-mt-5">{content.position}</Details>
                      <Details className="-mt-5">{content.phone}</Details>
                      <Details className="-mt-5">
                        {content.email}
                        <a className="text-orange" href={content.mailto}>
                          {content.emailText}
                        </a>
                      </Details>
                    </>
                  );
                })}
              </div>
              <div>
                {HROffice.links.map((content) => {
                  return (
                    <>
                      <H2>{content.title}</H2>
                      <Details>
                        <a className="text-orange" href={content.link}>
                          {content.anchor}
                        </a>
                      </Details>
                    </>
                  );
                })}
              </div>
            </section>
            {/* <section className="mt-16">
              <H1 className="mb-5">{SpecialPrograms.title}</H1>
              {SpecialPrograms.contents.map((content) => {
                return (
                  <>
                    <Details className="text-orange mb-0.5">{content.category}</Details>
                    <H2>{content.title}</H2>
                    <Details className="flex flex-wrap">
                      {content.description}
                      <Details className="text-orange">{content.file}</Details>
                    </Details>
                  </>
                );
              })}
            </section> */}
          </div>
          <section className="w-full tabletWide:w-4/12 laptop:mt-0">
            {HRLinks.links.map((link) => {
              return (
                <>
                  <H1>{link.title}</H1>
                  <UnorderedList>
                    {link.ul.map((value) => {
                      if (value.link !== '') {
                        return (
                          <List className="text-orange mb-4" key={value.anchor}>
                            <a href={value.link}>{value.anchor}</a>
                          </List>
                        );
                      }

                      return (
                        <>
                          {/* {file
                            .filter((fileData) => fileData.attributes.AnchorText === value.anchor)
                            .map((fileData) => {
                              return (
                                <>
                                  <List className="text-orange" key={value.anchor}>
                                    <a
                                      className="text-orange"
                                      href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      {value.anchor}
                                    </a>
                                  </List>
                                </>
                              );
                            })} */}
                        </>
                      );
                    })}
                  </UnorderedList>
                </>
              );
            })}
          </section>
        </div>
      </ContentContainer>
    </>
  );
};
