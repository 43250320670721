import { API } from '@config';
import { ContactUsDepartmentFaxTable } from '@src/types/ContactUsDepartmentFax';

export const getDepartmentFax = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/contact-us-department-faxes?sort=Title&populate=*`, options);
  const data: ContactUsDepartmentFaxTable = await response.json();

  return data;
};
