import { API } from '@config';
import { HrDemographicsTable } from '@src/types/HrDemographics';

export const getHrDemographics = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/hr-demographics?populate=File&sort=id%3Adesc`, options);
  const data: HrDemographicsTable = await response.json();

  return data;
};
