import { API } from "@config";
import { CorporateFinancialsTable } from "@src/types/CorporateFinancials";

export const getFinancialRating = async () => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${API}/api/financial-credit-ratings?populate=*&sort=id%3Adesc`,
    options
  ); // populate=File&sort=id%3Adesc
  const data: CorporateFinancialsTable = await response.json();

  return data;
};
