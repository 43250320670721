import React from 'react';
import { H2, Details, Content as ContentContainer } from '@core';
import { HeaderGradient } from '@components';
import { ApprenticeshipProgramDetails } from '@content';

export const ApprenticeshipeProgramPage = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={ApprenticeshipProgramDetails.title}
      />
      <ContentContainer>
        <H2>{ApprenticeshipProgramDetails.subtitle1}</H2>
        <Details>{ApprenticeshipProgramDetails.description1}</Details>
        <Details>
          {ApprenticeshipProgramDetails.ul.map((list, index) => {
            return <li key={index}>{list}</li>;
          })}
        </Details>
        <Details>{ApprenticeshipProgramDetails.description2}</Details>
      </ContentContainer>
    </>
  );
};
