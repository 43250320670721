import React, { useEffect, useState } from 'react';
import { H1, H2, Details, UnorderedList, List, Content as ContentContainer } from '@core';
import { RateSched, Surcharges } from '@content';
import { HeaderGradient } from '@components';
import { getFileData } from '@services';
import { FILEDATA } from '@types';
import { API } from '@src/config';

export const RateSchedules = () => {
  const [file, setFile] = useState<FILEDATA[]>([]);

  const getData = async () => {
    const fileDatas = await getFileData();
    setFile(fileDatas.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={RateSched.title}
      />
      <ContentContainer className="-mb-20">
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <section>
              <div>
                <H1>{RateSched.subTitle}</H1>
                <Details>{RateSched.description}</Details>
                <UnorderedList>
                  <div className="grid grid-cols-1 tabletWide:grid-cols-2 gap-x-10">
                    {RateSched.ol.map((value) => {
                      return (
                        <>
                          {file
                            .filter((fileData) => fileData.attributes.AnchorText === value.text)
                            .map((fileData) => {
                              return (
                                <>
                                  <List className="text-orange">
                                    <a
                                      href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      {fileData.attributes.AnchorText}
                                    </a>
                                  </List>
                                </>
                              );
                            })}
                        </>
                      );
                    })}
                  </div>
                </UnorderedList>
              </div>
            </section>
            <section
              className="mt-10 tablet:mt-20
            bg-shadow laptop:-mx-20 tablet:-mx-12 phone:-mx-7 laptop:pb-20 laptop:px-20 laptop:pt-14 tablet:p-12 phone:p-7"
            >
              <div>
                <H1 className="mb-5">{Surcharges.title}</H1>
                {Surcharges.contents.map((content) => {
                  return (
                    <>
                      <H2>{content.title}</H2>
                      <Details>{content.description}</Details>
                    </>
                  );
                })}
              </div>
            </section>

            {
              // NOTE: Client asked to hide Base Rate FAQ section for now, might be useful in the future.
              /* <section
              className="mt-10 tablet:mt-20
              bg-shadow w-screen laptop:-mx-20 tablet:-mx-12 phone:-mx-7 -mb-20 laptop:p-20 tablet:p-12 phone:p-7"
            >
              <H1>{BaseRateFAQ.title}</H1>
              {BaseRateFAQ.villageMeetings.map((content) => {
                return (
                  <>
                    <H2 className="text-blue my-5">{content.title}</H2>
                    <Details>{content.background}</Details>
                    <Details>{content.introduction}</Details>
                    <OrderedList>
                      {content.ol.map((value) => {
                        return (
                          <>
                            <List key={value.text}>
                              {value.text}&nbsp;
                              <a href={value.link}>
                                <span className="text-orange">{value.anchor}</span>
                              </a>
                            </List>
                          </>
                        );
                      })}
                    </OrderedList>
                    <Details className="text-orange mt-5">
                      <a href={content.moreLink}>{content.moreAnchor}</a>
                    </Details>
                    <Details>{content.conclusion}</Details>
                  </>
                );
              })}
              {BaseRateFAQ.pucRequirements.map((content) => {
                return (
                  <>
                    <H2 className="text-blue mt-10 mb-5">{content.title}</H2>
                    <H2>{content.subTitle}</H2>
                    <UnorderedList>
                      {content.ul.map((value) => {
                        return (
                          <List className="text-orange" key={value.anchor}>
                            <a href={value.link}>{value.anchor}</a>
                          </List>
                        );
                      })}
                    </UnorderedList>
                  </>
                );
              })}
              {BaseRateFAQ.whatsNext.map((content) => {
                return (
                  <>
                    <H2 className="text-blue mt-10 mb-5">{content.title}</H2>
                    <Details>{content.description}</Details>
                    <Details>
                      <a className="text-orange" href={content.link}>
                        {content.anchor}
                      </a>
                    </Details>
                    <Details>{content.instruction}</Details>
                  </>
                );
              })}
              <UnorderedList>
                {BaseRateFAQ.additionals.map((value) => {
                  return (
                    <List className="text-orange" key={value.anchor}>
                      <a href={value.link}>{value.anchor}</a>
                    </List>
                  );
                })}
              </UnorderedList>
            </section> */
            }
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
