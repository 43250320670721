import { API } from '@config';
import { CorporateFinancialsTable } from '@src/types/CorporateFinancials';

export const getFinancialAuditedStatementsArchive = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/financials-audited-statements-archives?populate=File&sort=id%3Adesc`, options);
  const data: CorporateFinancialsTable = await response.json();

  return data;
};
