export const ProjectsForecastsStudiesContent = {
  pageTitle: `Strategic Planning and Operations Research`,
  title: `Projects,Forecasts and Studies`,
  contents: [
    {
      title: `Energy Storage Feasibility Study`,
      body: `GPA looks towards energy storage to address GPA performance to unit outages.  GPA intends to address increased penetration of renewables, particularly solar PV systems, using energy storage systems along with new high inertia power generating units.`,
    },
    {
      title: `Integrated Resource Plan (IRP)`,
      body: `GPA is working on implementing its 2012 IRP recommendations.  With a 198 MW new power plant build, operate and transfer contract, signed in November 2019, on the way and 120MW capacity of utility scale solar PV renewable contracts under design and construction GPA continues to evaluate and plan for what is needed to improve customer services and support growth, reability and sustainabililty in the coming years.  The 2021 IRP is underway. `,
      anchor: `Click here for the latest IRP development`,
      link: `/corporate/news/integrated-resource-plan`,
    },
    {
      title: `Renewable Resource Acquisition`,
      body: `In 2008 GPA was authorized to initiate acquisition of contracts for renewable energy.  In its two phase approach GPA intends to acquire up 80 MW of renewable energy capacity through Power Purchase Agreements.  Phase I was awarded in 2011 and contracts for a total of 35 MW of renewable energy capacity were issued to Quantum Guam Power and Pacific Green Resources. `,
    },
    {
      title: `Smart Grid Initiative Grant (FY 2010 - FY2014)`,
      body: `Guam Power Authority received a $16.7 million ARRA Smart Grid Initiative Grant from the Department of Energy to implement a comprehensive deployment of Smart Grid technologies. GPA floated bonds in FY 2010 to come up with the matching $16.7 million.This project is a transformational project to bring Guam's power grid into the 21st century.`,
    },
    {
      title: `Utility Energy Services - Demand Side Management Program`,
      body: `In 2010, GPA issued two RFP's to acquire Energy Service Partners that would assist GPA in addressing demand side management opportunities for its Federal and Large Commercial Customers.  The intention of this program is to aid in identifying and implementing cost saving measures through energy conservation or small renewable projects at the customer's expense.  Through this program, GPA assisted the Guam Energy Office in executing ARRA Grant Funded projects to various government agencies and completed preliminary audits for several customers.  GPA continues to work with these customers in addressing their needs.`,
    },
    {
      title: `Clean Energy Plan`,
      body: `The most recent update to the evergreen Integrated Resource Plan (IRP) or Clean Energy Plan was approved by the CCU and PUC in May 2022. The Plan projects how GPA will meet future power requirements, providing a portfolio of $230M in projects and initiatives for integration of renewables. It discusses the optimization of infrastructure to enhance operations and improve costs, while continuously evaluating demand side management programs to assist in managing peak loads.`,
    },
    {
      title: `Grid Transformation`,
      body: `GPA will generate 25% of its energy from renewable resources by 2024 and 50% by 2030 while improving grid stability and resiliency.  Customers benefit through lower and less volatile fuel recovery (LEAC) rates, cleaner air, improved power quality, fewer outages, and reduced overall carbon footprint.`,
    },
    {
      title: `Renewable Energy Projects`,
      body: `The first of two utility-scale 60MW solar photovoltaic projects is projected to commission in June 2022, saving over $1M per month in fuel costs. The second utility-scale 60MW solar PV project is expected to come online at the end of 2023.`,
    },
    {
      title: `Piti Units 8 and 9 Conversions`,
      body: `Conversion of these slow speed diesel units to burn clean, ultra-low sulfur diesel fuel, to ensure compliance with the US EPA national emission standards continues. Post-conversion sulfur dioxide (SO2) and carbon monoxide (CO) emissions will be significantly reduced.`,
    },
    {
      title: `New Ukudu Baseload Power Plant`,
      body: `The new flexible and highly efficient 198MW power plant will support increasing renewable penetration and future load growth by April 2024.`,
    },
  ],
  note: `*updated 02/22/21`,
};
