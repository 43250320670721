import { API } from '@config';
import { AboutUsSupplyManagementAdministrationTable } from '@src/types/AboutUsSupplyManagementAdministrations';

export const getAboutUsSupplyManagementAdministration = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/about-us-supply-management-administrations?populate=File&sort=id%3Adesc`, options);
  const data: AboutUsSupplyManagementAdministrationTable = await response.json();

  return data;
};
