import {
  Home,
  WhatWereDoing,
  OtherResources,
  NewPowerPlantProject,
  ContactUs,
  // About,
  // Operations,
  // Financials,
  // HumanResources,
  Safety,
  CommunityNews,
  WaysToPay,
  PowerInYourHands,
} from '@pages';

export const Footer = [
  {
    navigation: 'About Us',
    routes: [
      {
        routeName: 'Contact Us',
        link: '/contact-us',
        page: ContactUs,
      },
      {
        routeName: 'Hours and Locations',
        link: '/contact-us#hours-and-locations',
        page: ContactUs,
      },
      {
        routeName: 'News and Media',
        link: '/news-and-media',
        page: CommunityNews,
      },
      {
        routeName: 'Newsletters',
        link: '/news-and-media',
        page: CommunityNews,
      },
      {
        routeName: 'CCU',
        link: 'https://guamccu.org/',
        page: Safety,
      },
      {
        routeName: 'CCU Meetings',
        link: 'https://ccumeetings.blogspot.com/',
        page: Safety,
      },
    ],
  },
  {
    navigation: 'GPA Initiatives',
    routes: [
      {
        routeName: 'Clean Energy Master Plan',
        link: '/gpa-initiatives/clean-energy-master-plan',
        page: NewPowerPlantProject,
      },
      {
        routeName: 'Power to Serve',
        link: '/corporate/operations',
        page: WhatWereDoing,
      },
      {
        routeName: 'Power to Save',
        link: 'https://energysense.guampowerauthority.com/savings-tips',
        page: '',
      },
      {
        routeName: 'Educational Resources',
        link: '/in-our-community/educational-resources',
        page: OtherResources,
      },
    ],
  },
  {
    navigation: 'Resources',
    routes: [
      {
        routeName: 'Ways to Pay',
        link: '/ways-to-pay',
        page: WaysToPay,
      },
      {
        routeName: 'PayGPA',
        link: 'https://paygpa.com/',
        page: '',
      },
      {
        routeName: 'Energy Sense',
        link: 'https://energysense.guampowerauthority.com/',
        page: '',
      },
      {
        routeName: 'Power Alerts',
        link: '/power-alerts',
        page: PowerInYourHands,
      },
      {
        routeName: 'Outages',
        link: 'https://web.guamalerts.com/notification/gpa',
        page: '',
      },
      {
        routeName: 'MyEnergyGuam',
        link: 'https://myenergyguam.com/',
        page: '',
      },
      {
        routeName: 'GWA',
        link: 'https://guamwaterworks.org/',
        page: '',
      },
    ],
  },
];

export const DefaultFooterRoutes = [
  {
    routeName: 'Home',
    link: '/',
    page: Home,
  },
];
