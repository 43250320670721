import React from 'react';
import { H1, Details, Content as ContentContainer } from '@core';
import { HighlightsAndEvents } from '@content';
import { HeaderGradient } from '@components';

export const Events = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={HighlightsAndEvents.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-full flex flex-col tablet:flex-row items-center">
            <section className="w-full">
              <H1 className="text-blue mb-5">{HighlightsAndEvents.title}</H1>
              {HighlightsAndEvents.contents.map((content) => {
                return (
                  <>
                    <Details>
                      <span className="font-bold">{content.intro}</span>
                      {content.body}
                    </Details>
                  </>
                );
              })}
            </section>
          </div>
        </div>
      </ContentContainer>
      <div className="desktop:h-32 desktopWide:h-1/3 w-full">&nbsp;</div>
    </>
  );
};
