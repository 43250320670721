import React from 'react';
import { H2, Details, Content as ContentContainer } from '@core';
import { EmployeeCerts } from '@content';
import { HeaderGradient } from '@components';

export const Certifications = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={EmployeeCerts.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12 laptop:w-7/12 mr-20">
            <section>
              {EmployeeCerts.contents.map((content) => {
                return (
                  <>
                    <H2 className='text-blue'>{content.title}</H2>
                    <Details>
                      {content.certs.map((value) => {
                      return (
                        <>
                          {value} <br />
                        </>
                      );
                    })}
                    </Details>
                    
                  </>
                );
              })}
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
