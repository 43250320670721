import { API } from '@config';
import { LeavesTable } from '@types';


export const getLeavesTableData = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/operations-hr-leaves-tables`, options);
  const data: LeavesTable = await response.json();

  return data;
};
