import React from 'react';
import { Content as ContentContainer, Details, H1, Images } from '@core';
import { HeaderGradient } from '@components';

export const CommunityPartersAndPrograms = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Community Partners and Programs"
      />
      <section className="h-full tablet:max-h-400px relative">
        {/* <div className="absolute h-full bg-black/50 cover__linear-gradient" /> */}
        <div
          className="absolute h-full w-full bg-top bg-cover bg-hero"
          style={{ backgroundImage: `url(${Images.CommunityPartnetsAndProgramsBanner})` }}
        />
        <div className="absolute inset-0  h-full bg-black bg-opacity-10 z-10 cover__linear-gradient" />
        <div
          className="h-full laptop:bg-center bg-left bg-cover bg-hero bg-no-repeat flex items-center
    laptop:px-28 tablet:px-20 phone:px-10 z-20 absolute"
        >
          {/* <div className=" max-w-xl desktop:w-5/12 tablet:w-4/6 phone:w-full flex flex-col tablet:items-start phone:items-center py-20"> */}
          <div className=" max-w-sm phone:w-full flex flex-col tablet:items-start phone:items-center py-20">
            <h1
              className="font-rubik font-medium text-white mb-5 tablet:text-4xl phone:text-4xl
      tablet:text-left phone:text-center"
            >
              Building a sustainable island community, together.
            </h1>
          </div>
        </div>
      </section>
      <ContentContainer>
        <section>
          <H1>UOG Sea Grant (Electric Vehicles)</H1>
          <Details>
            GPA is proud to partner with UOG through the Guam Energy Sense Program and seeks to
            expand its energy efficiency program through more partnerships like this with other
            Government of Guam agencies.”
          </Details>
          <Details>
            UOG Sea Grant is one of 33 programs across the nation. The National Sea Grant College
            Program is a partnership between universities and the Federal Government’s National
            Oceanic and Atmospheric Administration (NOAA), an agency within the Department of
            Commerce. The Sea Grant network includes more than 3,000 scientists, engineers, public
            outreach experts, educators, and students. UOG Sea Grant is based in the UOG Center for
            Island Sustainability.
          </Details>
          <Details>
            To learn more, see{' '}
            <a className="text-orange" href="https://www.uog.edu/seagrant/">
              www.uog.edu/seagrant
            </a>
            .
          </Details>
          <H1>Guam Energy Office</H1>
          <Details>
            <Details>
              The Guam Energy Office supports energy conservation, energy efficiency, renewable
              energy programs and practices. A line agency within the Executive Branch, the Guam
              Energy Office implements energy programs utilizing grants that have been awarded by
              the U.S. Department of Energy State Energy Program (SEP) and American Reinvestment and
              Recovery Act (ARRA).
            </Details>
            <Details>
              With grant funding, the Guam Energy Office provides energy efficient technical
              assistance contacts with consumers and businesses to aid them in implementing
              cost-effective energy efficiency solutions, rebates for electric vehicles, and
              assistance with reducing energy costs for eligible low-income households.
            </Details>
            <Details>
              To find out more about these programs, visit{' '}
              <a className="text-orange" href="https://energy.guam.gov/programs-2/">
                https://energy.guam.gov/programs-2/
              </a>
            </Details>
          </Details>
        </section>
      </ContentContainer>
    </>
  );
};
