export const LeavesContent = {
  pageTitle: `Work Leave / Sick Leave / Maternity Leave`,
  intro: [
    {
      title: `Annual Leave`,
      description: `Employees entitled to annual leave may accumulate up to 320 hours. Any annual leave earned by eligible employees in excess of 320 hours shall be credited to such employee's accumulated sick leave, provided that no more than 100 hours is credited to sick leave at the end of each fiscal year. For those employees who have accumulated annual leave in excess of the 320 hours as of February 28, 2003, may carry over their excess annual leave, prior to retirement or termination of service. At the time of retirement or termination of service, that portion permitted to be credited to sick leave shall be so credited and the remainder excess annual leave shall be lost. Lump sum compensation or retirement credit for annual leave shall be at 320 hours.`,
    },
  ],
  contents: [
    {
      title: `Sick Leave`,
      descriptions: [
        `Employees occupying permanent position shall accrue sick leave at the rate of one-half day (4 hours) for each bi-weekly pay period in which they are in pay status for the entire (10) days.`,
      ],
    },
    {
      title: `Parental Leave`,
      descriptions: [
        `Maternity Leave: Twenty (20) days maternity leave is granted to a female employee occupying a permanent position who is absent from work as a result of childbirth or adoption of a child(ren) five (5) years old or younger. Any additional leave taken for such childbirth or adoption purpose may be charged against accumulated sick leave, or may be upaid leave, at the option of the employee. Total leave, sick or unpaid leave shall not exceed six (6) months without supervisory approval.`,
        `Paternity Leave: Twenty (20) days paternity leave is granted to a male employee occupying a permanent position who is absent from work as a result of childbirth or adoption of a child(ren) five (5) years old or younger. Any additional leave taken for such childbirth or adoption purpose may be charged against accumulated sick leave, or may be unpaid leave, at the option of the employee. Total leave, sick or unpaid leave shall not exceed six (6) months without supervisory approval.`,
      ],
    },
    {
      title: `Parental Involvement`,
      descriptions: [
        `This program is to increase and support the involvement of parents in their children's education. It allows for an employee who is a parent, guardian, or in loco parentis, to use up to a maximum of four (4) hours every two (2) pay periods of administrative leave to meet with a teacher or other school official concerning the employee's child's performance or behavior or to volunteer parental-involvement time at their child's school.`,
      ],
    },
    {
      title: `Family Medical Leave Act (FMLA)`,
      descriptions: [
        `FMLA allows a total of 12 weeks of paid leave or leave without pay following the birth and/or adoption of a child, the serious illness of an employee, or an immediate family member who requires assistance from the employee during an illness.`,
      ],
    },
    {
      title: `Military Leave`,
      descriptions: [
        `All employees of the government of Guam who are members of the reserve components of the Department of Defense or Transportation, including, but not limited to, the United States Army, the United States Air Force, the Army National Guard, the Air National Guard and the United States Coast Guard, shall be entitled to fifteen (15) working days of military leave with pay per government of Guam fiscal year. If such (15) days military leave is not used in a fiscal year, it accumulates in the succeeding year until it totals (15) days at the beginning of the fiscal year.`,
      ],
    },
    {
      title: `Voluntary Leave Sharing Program`,
      descriptions: [
        `Employees may transfer annual or sick leave to another employee for the personal or medical reasons, not to exceed 90 working days per occasion, unless a medical certification is furnished for an additional 90 working days. No transfer shall be made by an employee to that person's supervisor or to any person above the employee in the supervisory, or to a member of the supervisor's or such supervisory person's immediate family.`,
      ],
    },
    {
      title: `Employee Assistance Program (EAP)`,
      descriptions: [
        `The following professional counseling services are provided to all GPA employees:`,
        `Clinical Psychology and Psychiatric Counseling Services`,
        `Supportive Counseling and Cognitive Behavioral Therapy Services`,
        `Neurological Examination`,
        `Post Traumatic and Critical Incident Stress Debriefing Services, and`,
        `Mediation Services`,
      ],
    },
  ],
};

export const LeavesTableContent = {
  table: [
    {
      th: [`Years of Service`, `Annual Leave Credit Bi-Weekly Pay Period`, `Total Year Credit`],
    },
  ],
};