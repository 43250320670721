export const OverviewMission = {
  title: `Human Resources`,
  contents: [
    {
      title: `Overview`,
      description: `The success of Guam Power Authority is built upon a set of core values: integrity, respect, customer service, safety, and learning. These values create a climate in which all individuals can pursue personal and career goals.`,
      ul: [],
    },
    {
      title: `Mission`,
      description: `To administer Guam Power Authority’s personnel management program, the Apprenticeship Training Program and enhance employee services.`,
      ul: [],
    },
    // {
    //   title: `Petitions`,
    //   description: `The Guam Power Authority petitions the Consolidated Commision on Utilites (CCU) the following:`,
    //   ul: [
    //     {
    //       anchor: `Creation of Position - GIS Supervisor`,
    //       link: ``,
    //     },
    //     {
    //       anchor: `Creation of Position - Safety and Physical Security Manager (GPA)`,
    //       link: ``,
    //     },
    //   ],
    // },
  ],
};

export const HROffice = {
  title: `GPA Human Resources Office`,
  hr: [
    {
      title: `GPA Human Resources Division`,
      phone: `Phone: (671) 648-3130`,
      fax: `Fax: (671) 648-3160`,
      email: `E-mail: `,
      emailText: `gpahr@gpagwa.com`,
      mailto: `mailto:gpahr@gpagwa.com`,
    },
  ],
  op: [
    {
      title: `Hours of Operation:`,
      days: `Monday – Friday`,
      time: `8:00 a.m. – 5:00 p.m.`,
      closed: `Closed on Weekends and Holidays`,
    },
  ],
  description: `GPA Human Resources Office is comprised of the following sections:`,
  admin: [
    {
      title: `HR Administration`,
      name: `Jon-Rey P. Aguigui`,
      position: `Personnel Services Administrator`,
      phone: `Phone: 671-648-3130/ 3037`,
      email: `E-mail: `,
      emailText: `jpaguigui@gpagwa.com`,
      mailto: `mailto:jpaguigui@gpagwa.com`,
    },
  ],
  links: [
    {
      title: `Recruitment/ Benefits/ Records Section`,
      anchor: `(link to Recruitment page)`,
      link: `/corporate/human-resources/employee-benefits`,
    },
    // {
    //   title: `Classification/ Employee Management Relations`,
    //   anchor: `(link to Classification/EMR page)`,
    //   link: ``,
    // },
    // {
    //   title: `Training & Development`,
    //   anchor: `(link to Training page)`,
    //   link: ``,
    // },
  ],
};

// {
//   title: `Human Resources Division`,
//   description: `A list of employment opportunities is also available at the Human Resources Division located at`,
//   ul: [
//     `Gloria B. Nelson Public Service Building`,
//     `688 Route 15 Fadian, Mangilao, Guam`,
//     `Phone: (671) 648-3130; Fax: (671) 648-3160`,
//     `8:00 am - 5:00 pm, Mon-Fri`,
//     `Closed on Weekends and Holidays`,
//   ],
//   phone: ``,
//   fax: ``,
//   email: ``,
// },
// {
//   title: `GPA Human Resources Office`,
//   description: ``,
//   ul: [],
//   phone: `Tel: (671) 648-3130`,
//   fax: `Fax: (671) 648-3160`,
//   email: `Email: gpahr@gpagwa.com`,
// },

// export const SpecialPrograms = {
//   title: `SPECIAL PROGRAMS OVERVIEW`,
//   contents: [
//     {
//       category: ``,
//       title: `Apprenticeship Program`,
//       description: `Report by Julie L. Quinata, Personnel Services Director - `,
//       file: `Download 44kB pdf`,
//     },
//     {
//       category: `Engineering Internship`,
//       title: `Engineering Scholarship Program:`,
//       description: `CCU Res. No. 2006-27 approving the GPA policy for Engineering Scholarship Program - `,
//       file: `Download 35 kB pdf`,
//     },
//     {
//       category: ``,
//       title: ``,
//       description: `Policy No. AP-068 and required documents - `,
//       file: `Download 542 kB pdf`,
//     },
//     {
//       category: ``,
//       title: `Training and Development Section`,
//       description: ``,
//       file: `About the Program`,
//     },
//   ],
// };

export const HRLinks = {
  links: [
    {
      title: `CAREERS AT GPA`,
      ul: [
        {
          anchor: `Current Openings`,
          link: `/how-do-i/find-job-openings`,
        },
        {
          anchor: `Employment Application`,
          link: `https://admin.guampowerauthority.com/uploads/Employment_App_Jan_2018_Form_Fill_FINAL_97b7d606a4.PDF?updated_at=2023-03-16T14:14:52.553Z`,
        },
        // `Petitions`,
      ],
    },
    {
      title: `EMPLOYEES`,
      ul: [
        {
          anchor: `Personnel Services`,
          link: `human-resources/personnel-services`,
        },
        {
          anchor: `Employee Benefits`,
          link: `human-resources/employee-benefits`,
        },
        {
          anchor: `Certifications`,
          link: `human-resources/certifications`,
        },
        {
          anchor: `Special Programs`,
          link: `human-resources/special-programs`,
        },
        {
          anchor: `Demographics`,
          link: `human-resources/demographics`,
        },
      ],
    },
    {
      title: `REQUIRED POSTINGS`,
      ul: [
        {
          anchor: `Staffing Patterns`,
          link: ``,
        },
        {
          anchor: `Petitions`,
          link: `/corporate/human-resources/petitions`,
        },
      ],
    },
  ],
};
