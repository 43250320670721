import { API } from '@config';
import { EnergySenseTable } from '@types';

export const getEnergySenseTableData = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/get-a-rebate-energy-sense-tables`, options);
  const data: EnergySenseTable = await response.json();

  return data;
};
