import React, { useEffect, useState } from 'react';
import { Content as ContentContainer, H2, List, UnorderedList } from '@core';
import { PetitionsContent } from '@content';
import { HeaderGradient } from '@components';
import { HRPETITIONS } from '@src/types/HrPetitions';
import { getHrPetitions } from '@src/services/HrPetitions';
import { API } from '@src/config';

export const Petitions = () => {
  const [petitions, setPetitions] = useState<HRPETITIONS[]>([]);

  const getData = async () => {
    const petitionsData = await getHrPetitions();
    setPetitions(petitionsData.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const clickItem = (link?: string, file?: any) => {
    if(file != null){
      window.open(`${API}${file}`);
    }else{
      window.open(`${link}`);
    }
  }

  console.log(petitions)

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={PetitionsContent.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <section>
              <H2>The Guam Power Authority petitions the Consolidated Commision on Utilites (CCU) the following:</H2>
              <UnorderedList>
                {petitions.map((content) => {
                  return(
                    <>
                        <List className='text-orange'>
                          <span>{content.attributes.Title} - </span>
                          {content.attributes.Description} &nbsp;
                          <button className='text-orange text-left' onClick={()=>clickItem(content.attributes.Link, content.attributes.File?.data != null ? content.attributes.File.data.attributes.url : content.attributes.File.data)} type="button">{content.attributes.Anchor}</button>
                        </List>
                    </>
                  )
                })}
              </UnorderedList>
            </section>
          </div>
        </div>
      </ContentContainer>
      <div className="desktop:h-1/6 w-full">&nbsp;</div>
    </>
  );
};
