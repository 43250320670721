import React, { useEffect } from 'react';
import { H1, Details, Content as ContentContainer, H2, Table } from '@core';
import {
  AnticipatedProjectsOverview,
  SmartGridProgram,
  EneryEfficientStreetlightProject,
} from '@content';
import { HeaderGradient } from '@components';
// import { getFileData } from '@services';
// import { FILEDATA } from '@types';
// import { API } from '@src/config';

export const AnticipatedProjects = () => {
  // const [file, setFile] = useState<FILEDATA[]>([]);

  const getData = async () => {
    // const fileDatas = await getFileData();
    // setFile(fileDatas.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={AnticipatedProjectsOverview.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-full">
            <section>
              <H1 className="text-blue mb-5">{AnticipatedProjectsOverview.title}</H1>
              <Details>
                {AnticipatedProjectsOverview.description}
                {/* {file
                  .filter(
                    (fileData) =>
                      fileData.attributes.AnchorText === AnticipatedProjectsOverview.anchor,
                  )
                  .map((fileData) => {
                    return (
                      <>
                        <Details>
                          <a
                            className="text-orange"
                            href={`${API}${fileData.attributes.File.data.attributes.url}`}
                            rel="noreferrer"
                          >
                            {fileData.attributes.AnchorText}
                          </a>
                        </Details>
                      </>
                    );
                  })} */}
              </Details>
              <H2 className="text-blue">{AnticipatedProjectsOverview.groupTitle}</H2>
            </section>
            <section>
              <H1>{SmartGridProgram.title}</H1>
              {SmartGridProgram.table.map((content) => {
                return (
                  <>
                    <H2 className="mt-5">{content.title}</H2>
                    <Details>{content.description}</Details>
                    <H2 className="text-blue text-center mt-10 mb-10">{content.tableTitle}</H2>
                    <div className="w-12/12 flex flex-col mt-10 mb-10 tabletWide:flex-row laptop:px-24">
                      <div className="w-auto tabletWide:w-1/2">
                        <Table>
                          {content.table1?.map((row) => {
                            return (
                              <tr className="w-12/12" key={row.heading}>
                                <th
                                  className="w-1/2 text-white bg-blue p-4 border border-white text-left"
                                  key={row.heading}
                                >
                                  {row.heading}
                                </th>
                                <td
                                  className="w-1/2 bg-lightgray p-4 border border-white text-left"
                                  key={row.data}
                                >
                                  {row.data}
                                </td>
                              </tr>
                            );
                          })}
                        </Table>
                      </div>
                      <div className="w-auto tabletWide:w-1/2">
                        <Table>
                          {content.table2?.map((row) => {
                            return (
                              <tr className="w-12/12" key={row.heading}>
                                <th
                                  className="w-1/2 text-white bg-blue p-4 border border-white text-left"
                                  key={row.heading}
                                >
                                  {row.heading}
                                </th>
                                <td
                                  className="w-1/2 bg-lightgray p-4 border border-white text-left"
                                  key={row.data}
                                >
                                  {row.data}
                                </td>
                              </tr>
                            );
                          })}
                        </Table>
                      </div>
                    </div>
                    {/* <Details className="laptop:px-24 mt-5 mb-10">
                      <a
                        className="text-orange"
                        href={content.link}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {content.anchor}
                      </a>
                    </Details> */}
                  </>
                );
              })}
              {SmartGridProgram.contents.map((content) => {
                return (
                  <>
                    <H2 className="mt-5">{content.title}</H2>
                    <Details>{content.description}</Details>
                  </>
                );
              })}
            </section>
            <hr className="border-gray mt-5 mb-10" />
            <section>
              <H1>{EneryEfficientStreetlightProject.title}</H1>
              <H2>{EneryEfficientStreetlightProject.subTitle}</H2>
              <Details>
                {EneryEfficientStreetlightProject.p1} <br />
                <br />
                {EneryEfficientStreetlightProject.p2} <br />
                <br />
                {/* <a
                  className="text-orange"
                  href={EneryEfficientStreetlightProject.link}
                  rel="noreferrer"
                  target="_blank"
                >
                  {EneryEfficientStreetlightProject.anchor}
                </a> */}
              </Details>
              <H1>{EneryEfficientStreetlightProject.emptyTitle}</H1>
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
