export const ApprenticeshipProgramDetails = {
  title: 'Apprenticeship Program',
  subtitle1: 'Apprenticeship Training Program',
  description1:
    'The Guam Power Authority’s Apprenticeship Training Program is a program designed to identify and recruit individuals interested in developing a career in the industrial trades. The Program offers two major components: (1) On-The-Job Training and (2) Academic Related Instruction. Apprentices in the Program may be trained in any of the following trades offered:',
  ul: [
    'Electric Meter Repairer',
    'Electrician, Substation',
    'Line Installer-Repairer',
    'Relay Technician',
    'Instrument Technician',
    'Machinist',
    'Power Plant Operator',
    'Load Dispatcher',
    'Welder, Arc',
    'Diesel Mechanic',
    'Electrician, Powerhouse',
    'Powerhouse Mechanic',
  ],
  description2:
    'The objectives are to develop highly skilled apprentices thoroughly and broadly to qualify as a journeyman. The program provides a potential source of key employees and supervisors and enhances the character, personality and industrial initiatives of the apprentices. The Authority’s apprenticeship program is recognized and certified by U.S. Department of Labor. Upon successful completion of the program, all apprentices will receive certification as a journey worker in their respective trade by the U.S. Department of Labor. The Authority’s apprenticeship program is also in partnership with the Guam Department of Labor and the Guam Community College.',
};
