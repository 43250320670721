export const PersonnelRulesRegulations = {
  pageTitle: `Personnel Services`,
  title: `GPA PERSONNEL RULES AND REGULATIONS`,
  description: `The GPA Personnel Rules and Regulations were promulgated on October 4, 1982, under Executive Order 82-18. Since then these rules and regulations have not been formally updated. However, certain local or federal laws that may amend provisions of these personnel rules and regulations will supersede. - `,
  anchor: `Download 733 kB`,
  ul: [
    {
      title: `P.L. 28-159 `,
      description: `- Approved GPA/GWA Personnel Rules & Regulations for Certified, Technical, & Professional Personnel - `,
      anchor: `Download 2.6 MB pdf`,
    },
    {
      title: `P.L. 28-113 `,
      description: `- Authorizing GPA and GWA to promulgate rules and regulations; and to provide limited authority to establish pay scales for certified, technical and professional personnel - `,
      anchor: `Download 306 kB pdf`,
    },
    {
      title: `CCU Resolution No. 01-FY2008 `,
      description: `- Relative to the Adoption of a New Compensation Policy for the Guam Power Authority and the Guam Waterworks Authority - `,
      anchor: `Download 235 kB pdf`,
    },
  ],
};

export const PayForPerformance = {
  title: `COMPENSATION & PAY FOR PERFORMANCE`,
  contents: [
    {
      title: ``,
      anchor: `List of CTP Positions`,
      description: ``,
    },
    {
      title: `Strategic Pay Schedule - `,
      anchor: `PAY SCALE`,
      description: ``,
    },
    {
      title: `Pay for Performance and Pay Adjustment Policy for CTP (revised) AP-085 - `,
      anchor: `Download 514KB`,
      description: ``,
    },
    {
      title: `Promotion/Demotion/Transfer Policy for CTP Employees - `,
      anchor: `Download 172 KB`,
      description: ``,
    },
    {
      title: `Work Planning & Performance Evaluation Forms `,
      anchor: ``,
      description: `On May 26, 2009, the Consolidated Commission on Utilities approved Resolution No. 2009-27 relative to the adoption of a new Performance Management System for Guam Power Authority and Guam Waterworks Authority employees in certified, technical and professional positions, as follows:`,
    },
  ],
};

export const PersonnelPolicies = {
  title: `POLICIES`,
  contents: [
    {
      title: `Drug and Alcohol Free Workplace Policy - `,
      anchor: `DFWP Policy and Procedures`,
    },
  ],
};

export const PersonnelServicesLinks = {
  links: [
    {
      title: `REQUIRED POSTINGS`,
      ul: [
        {
          title: ``,
          anchor: `FY 23 1Q GPA Staffing Pattern`,
          link: ``,
        },
        // `Petitions`,
      ],
      ul2: [
        {
          title: ``,
          anchor: `Job Announcements`,
          link: `/how-do-i/find-job-openings`,
        },
      ],
    },
    {
      title: `GPA FORMS`,
      ul: [
        {
          title: ``,
          anchor: `POSITION DESCRIPTION FORM`,
          link: ``,
        },
        {
          title: ``,
          anchor: `DOA ORG CIR NO. 08-006`,
          link: ``,
        },
        {
          title: ``,
          anchor: `SICK-ANNUAL LEAVE FOR MEDICAL REASONS FORM`,
          link: ``,
        },
        {
          title: ``,
          anchor: `ANNUAL LEAVE FOR PERSONAL REASONS FORM`,
          link: ``,
        },
        {
          title: ``,
          anchor: `GPA - FMLA POSTER`,
          link: ``,
        },
        {
          title: ``,
          anchor: `EMPLOYEE COUNSELING FORM`,
          link: ``,
        },
        {
          title: ``,
          anchor: `STATEMENT OF FORMAL GRIEVANCE FORM`,
          link: ``,
        },
        {
          title: ``,
          anchor: `FORMAL GRIEVANCE RESPONSE FORM`,
          link: ``,
        },
      ],
    },
    {
      title: `CONTACT US`,
      ul2: [
        // {
        //   title: `Classification/Employee-Management Relations`,
        //   anchor: `Go to Employee-Management Relations Page`,
        //   link: ``,
        // },
        {
          title: `Recruitment/Benefits/Records/Training`,
          anchor: `Go to Recruitment/Benefits/Records Page`,
          link: `employee-benefits`,
        },
        // {
        //   title: ``,
        //   anchor: `Go to Training Page`,
        //   link: ``,
        // },
      ],
    },
  ],
  outro: `"GPA is an affirmative action and equal opportunity employer."`,
};
