import { API } from '@config';
import { ContactUsMailingAddressesTable } from '@src/types/ContactUsMailingAddresses';

export const getMailingAddresses = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/contact-us-mailing-addresses?populate=File&sort=id%3Adesc`, options);
  const data: ContactUsMailingAddressesTable = await response.json();

  return data;
};
