import { API } from "@config";
import { CommunitySafetyStormTable } from "@src/types/CommunitySafetyStorm";

export const getCommunitySafetyStorm = async () => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${API}/api/community-safety-storms?populate=File&populate=Document&sort=id%3Adesc`,
    options
  );
  const data: CommunitySafetyStormTable = await response.json();

  return data;
};
