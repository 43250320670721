import React from 'react';
import { Icons } from '@core';
import { Footer as Content } from '@constants';

export const Footer = () => {
  return (
    <footer className="laptop:h-auto phone:h-auto flex flex-col w-full bg-blue laptop:pt-20 phone:pt-5">
      <div className="w-full h-full flex mt-8 laptop:px-20 tablet:px-12 phone:px-7 flex-row flex-wrap laptop:mb-0 phone:mb-20 justify-between">
        {Content.map((navigation) => {
          return (
            <div
              className="flex flex-col phone:mr-10 tablet:mr-0 phone:w-60 tablet:w-40 tabletWide:w-auto"
              key={navigation.navigation}
            >
              <h3 className="font-rubik font-bold text-white mb-4 laptop:mt-0 phone:mt-10">
                {navigation.navigation}
              </h3>
              {navigation.routes.map((route) => (
                <a
                  className="font-rubik hover:text-black mb-1 text-white"
                  href={route.link}
                  key={route.routeName}
                >
                  {route.routeName}
                </a>
              ))}
            </div>
          );
        })}
      </div>
      <div className="w-full mt-20 py-8 border-t-2 border-white flex flex-row laptop:px-20 tablet:px-12 phone:px-7 ">
        <div>
          <p className=" font-rubik font-semibold text-white laptop:text-base phone:text-sm">
            2022 Guam Power Authority
          </p>
          <div className="flex flex-row mt-2 laptop:flex-row phone:flex-col">
            <a
              className=" font-rubik text-white mr-10 laptop:text-base phone:text-sm cursor-pointer"
              href="/internet-policy"
            >
              Internet Policy
            </a>
            <a
              className=" font-rubik text-white laptop:text-base phone:text-sm cursor-pointer"
              href="/sop-index"
            >
              SOP Index
            </a>
          </div>
        </div>
        <div className="flex items-center ml-auto flex-row">
          <a href="https://www.facebook.com/GuamPowerAuthority">
            <img
              alt="Facebook"
              className="laptop:h-10 phone:h-7 laptop:mr-7 phone:mr-5 cursor-pointer"
              src={Icons.Facebook}
            />
          </a>
          <a href="https://www.instagram.com/guampowerauthority/">
            <img
              alt="Instagram"
              className="laptop:h-10 phone:h-7 laptop:mr-7 phone:mr-5 cursor-pointer"
              src={Icons.Instagram}
            />
          </a>
          <a href="https://www.youtube.com/@guampowerauthority4866/featured">
            <img
              alt="Youtube"
              className="laptop:h-10 phone:h-7 cursor-pointer"
              src={Icons.Youtube}
            />
          </a>
        </div>
      </div>
    </footer>
  );
};
