import React from 'react';
import { H1, H3, Details, Images, UnorderedList, List, Content as ContentContainer } from '@core';
import { FuturePlanning, ForumVideos, AboutIRP } from '@content';
import { HeaderGradient } from '@components';

export const InformationVideos = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={FuturePlanning.pageTitle}
      />
      <div
        className="h-1/4 tabletWide:h-1/3 laptop:h-1/2 bg-top bg-cover bg-hero"
        style={{ backgroundImage: `url(${Images.WhatWereDoing})` }}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <section>
              <div>
                {FuturePlanning.contents.map((content) => {
                  return (
                    <>
                      <H1>{content.title}</H1>
                      <a href={content.link} rel="noreferrer" target="_blank">
                        <img alt={content.imgAlt} className="my-5" src={content.img} />
                      </a>
                      <Details>
                        <a className="text-orange" href={content.link}>
                          {content.anchor}
                        </a>
                      </Details>
                    </>
                  );
                })}
              </div>
              <div>
                {FuturePlanning.sessions.map((content) => {
                  return (
                    <>
                      <H1 className="mt-10">{content.title}</H1>
                      <img alt={content.imgAlt} className="my-5" src={content.img} />
                    </>
                  );
                })}
              </div>
            </section>
            <section className="my-10 tablet:my-20 flex flex-wrap tablet:grid tablet:grid-cols-3 tablet:gap-10 laptop:gap-x-20 laptop:gap-y-10">
              {ForumVideos.contents.map((content) => {
                return (
                  <div
                    className="flex phone:flex-row tablet:flex-col tabletWide:flex-row phone:gap-5 tablet:gap-0 tabletWide:gap-5 w-full"
                    key={content.title}
                  >
                    <div className="text-center w-1/4 tablet:w-full tabletWide:w-1/4">
                      <a className="flex flex-col" href={content.link}>
                        <img alt="/" className="w-7 self-center" src={Images.PlayBtn} />
                        <Details className="text-orange">{content.duration}</Details>
                      </a>
                    </div>
                    <div className="w-3/4 tablet:w-full tabletWide:w-3/4">
                      <H3 className="text-blue">{content.title}</H3>
                      <Details>{content.description}</Details>
                    </div>
                  </div>
                );
              })}
            </section>
            <section className="mt-20 mb-10">
              <Details>
                <a className="text-orange" href={AboutIRP.link}>
                  {AboutIRP.anchor}
                </a>
              </Details>
              <Details className='mb-10'>
                {AboutIRP.p1}
                <br />
                <br />
                {AboutIRP.p2}
              </Details>
              <div className="flex flex-col tabletWide:flex-row tabletWide:gap-20">
                <div className="tabletWide:w-1/4 flex justify-center">
                  <img alt="/" className="py-10 tabletWide:py-0" src={AboutIRP.img} />
                </div>
                <UnorderedList className="tabletWide:w-3/4">
                  {AboutIRP.ul.map((value) => {
                    return <List key={value}>{value}</List>;
                  })}
                </UnorderedList>
              </div>
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
