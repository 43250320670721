import React from 'react';
import { H1, Details, Content as ContentContainer } from '@core';

import { HeaderGradient } from '@components';

export const InternetPolicy = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Internet Policy"
      />
      <ContentContainer>
        <section id="contact-us">
          <H1>Internet Policy</H1>
          <Details>
            This Privacy Statement applies only to this Site. Other websites that may be affiliated
            with Guam Power Authority which have other domain names may have different privacy
            statements. Guam Power Authority respects the privacy of users of this Site and believes
            that such information should be used responsibly and appropriately. In general, all
            users may visit this Site anonymously and without revealing any additional personal
            information. There are times, however, when we may need information from you, such as
            your name and address. This is usually required in order for us to promptly respond to
            your requests for information or to answer your questions. Only the people who need it
            to respond to your communication will use this information. You personal information
            will not be shared, sold or used for marketing services other than pertaining to this
            site. We do keep track of the countries and domains from which people visit us, the
            browser and/or Internet Service Provider used and other related data. We analyze this
            data for trends and statistics. We may also collect information automatically provided
            to us by your browser about the type of computer and operating system you are using. In
            general, we do not use cookies to help identify users. (Cookies are pieces of
            information that a Web site transfers to an individual's hard drive for record-keeping
            purposes.) If you have any questions, please contact Guam Power Authority at
            webmaster@guampowerauthority.com. This Privacy Policy does not extend to anything that
            is inherent in the operation of the Internet, and beyond the control of Guam Power
            Authority, and is not to be applied in any manner contrary to applicable laws, rules or
            regulations.
          </Details>
        </section>
      </ContentContainer>
    </>
  );
};
