import React from 'react';
import { Details, Content as ContentContainer, H2, List, OrderedList, UnorderedList } from '@core';
import { CafeteriaAccounts, CafeteriaPlanContent } from '@content';
import { HeaderGradient } from '@components';

export const CafeteriaPlan = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={CafeteriaPlanContent.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <section>
              <Details>
                {CafeteriaPlanContent.description} <br /> <br /> {CafeteriaPlanContent.info}
              </Details>
            </section>
            <section>
              <H2>{CafeteriaAccounts.question}</H2>
              <div>
                <H2>
                  <i>{CafeteriaAccounts.title1}</i>
                </H2>
                <Details className="mt-5 mb-0.5">{CafeteriaAccounts.category1}</Details>
                <OrderedList className="pl-6">
                  {CafeteriaAccounts.list1.map((value) => {
                    return <Details key={value}>{value}</Details>;
                  })}
                </OrderedList>
              </div>
              <div>
                <H2>
                  <i>{CafeteriaAccounts.title2}</i>
                </H2>
                <Details className="mt-5 mb-0.5">{CafeteriaAccounts.category2}</Details>
                <UnorderedList className="pl-8">
                  {CafeteriaAccounts.list2.map((value) => {
                    return <List key={value}>{value}</List>;
                  })}
                </UnorderedList>
              </div>
              <div>
                <Details className="mt-5 mb-0.5">{CafeteriaAccounts.category3}</Details>
                <OrderedList className="pl-6">
                  {CafeteriaAccounts.list3.map((value) => {
                    return <Details key={value}>{value}</Details>;
                  })}
                </OrderedList>
              </div>
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
