import { API } from '@config';
import {
  TendersTable,
  BidsTable,
  InventoryTable,
  NonInventoryTable,
  GenApprovalTable,
  CommissionTable,
  POCTable,
} from '@types';

export const getTendersTableData = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${API}/api/procurement-available-tenders-tables?populate=File`,
    options,
  );
  const data: TendersTable = await response.json();

  return data;
};

export const getBidsTableData = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${API}/api/procurement-bids-tables?populate=TitleFile&populate=OpenCloseDateFile&populate=AwardeeFile`,
    options,
  );
  const data: BidsTable = await response.json();

  return data;
};

export const getInventoryTableData = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${API}/api/procurement-inventory-tables?populate=File`,
    options,
  );
  const data: InventoryTable = await response.json();

  return data;
};

export const getNonInventoryTableData = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/procurement-non-inventory-tables?populate=File`, options);
  const data: NonInventoryTable = await response.json();

  return data;
};

export const getGenApprovalTableData = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${API}/api/procurement-general-approval-tables?populate=ContractFile&populate=POFile`,
    options,
  );
  const data: GenApprovalTable = await response.json();

  return data;
};

export const getCommissionTableData = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${API}/api/procurement-commission-tables?populate=ContractFile&populate=POFile`,
    options,
  );
  const data: CommissionTable = await response.json();

  return data;
};

export const getPOCTableData = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${API}/api/procurement-poc-tables?populate=ContractFile&populate=POFile`,
    options,
  );
  const data: POCTable = await response.json();

  return data;
};