import React, { useEffect, useState } from 'react';
import { H1, H2, Content as ContentContainer, Details, UnorderedList, List, Images } from '@core';
import { HeaderGradient, WattsNew } from '@components';
import { IssuesInfo, DisasterRecovery, NewsArchive } from '@content';
import { getFileData } from '@services';
import { FILEDATA } from '@types';
import { API } from '@src/config';

export const CorporateNews = () => {
  const [file, setFile] = useState<FILEDATA[]>([]);

  const getData = async () => {
    const fileDatas = await getFileData();
    setFile(fileDatas.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Corporate News"
      />
      <div
        className="h-1/4 tabletWide:h-1/3 laptop:h-1/2 bg-top bg-cover bg-hero"
        style={{ backgroundImage: `url(${Images.CorporateNewsBanner})` }}
      />
      <ContentContainer>
        <section className="mb-10 tablet:mb-20">
          <div className="mb-auto pb-auto">
            <WattsNew />
          </div>
        </section>
        <section className="my-10 tablet:my-20">
          <H1>{IssuesInfo.title}</H1>
          {IssuesInfo.contents.map((content) => {
            return (
              <>
                <H2>{content.title}</H2>
                <Details>
                  <a className="text-orange" href={content.link}>
                    {content.anchor}
                  </a>
                </Details>
              </>
            );
          })}
          {/* NOTE: Temporarily hidden, file not found from the old website
          {IssuesInfo.contentsLink.map((content) => {
            return (
              <>
                <H2>{content.title}</H2>
                {file
                  .filter((fileData) => fileData.attributes.AnchorText === content.anchor)
                  .map((fileData) => {
                    return (
                      <>
                        <Details className="text-orange">
                          <a
                            href={`${API}${fileData.attributes.File.data.attributes.url}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {fileData.attributes.AnchorText}
                          </a>
                        </Details>
                      </>
                    );
                  })}
                <Details>
                  <a className="text-orange" href={content.link}>
                    {content.anchor}
                  </a>
                </Details>
              </>
            );
          })} */}
          {IssuesInfo.additionals.map((content) => {
            return (
              <>
                <H2 className="text-blue">{content.title}</H2>
                {file
                  .filter((fileData) => fileData.attributes.AnchorText === content.anchor)
                  .map((fileData) => {
                    return (
                      <>
                        <Details className="text-orange">
                          <a
                            href={`${API}${fileData.attributes.File.data.attributes.url}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {fileData.attributes.AnchorText}
                          </a>
                        </Details>
                      </>
                    );
                  })}
                <Details>{content.contents[0].description}</Details>
                {/* <Details>
                  <a className="text-orange" href={content.link}>
                    {content.anchor}
                  </a>
                </Details> */}
              </>
            );
          })}
        </section>
        <section
          className="my-10 tablet:my-20
            bg-shadow laptop:-mx-20 tablet:-mx-12 phone:-mx-7 laptop:py-14 laptop:px-20 tablet:p-12 phone:p-7"
        >
          <H1 className="mb-5">{DisasterRecovery.title}</H1>
          {file
            .filter((fileData) => fileData.attributes.AnchorText === DisasterRecovery.anchor)
            .map((fileData) => {
              return (
                <>
                  <Details className="text-orange">
                    <a
                      href={`${API}${fileData.attributes.File.data.attributes.url}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {fileData.attributes.AnchorText}
                    </a>
                  </Details>
                </>
              );
            })}
          {/* <Details>
            <a className="text-orange" href={DisasterRecovery.link}>
              {DisasterRecovery.anchor}
            </a>
          </Details> */}
          {DisasterRecovery.contents.map((content) => {
            return (
              <>
                <H2>{content.title}</H2>
                {content.description.map((paragraph) => {
                  return (
                    <Details key={paragraph.paragraph}>
                      {paragraph.paragraph}
                      <br />
                    </Details>
                  );
                })}
              </>
            );
          })}
        </section>
        <section className="mt-10 tablet:mt-20">
          <H1 className="mb-5">{NewsArchive.title}</H1>
          <H2>{NewsArchive.netMeter}</H2>
          <Details className="text-orange mt-5">
            {NewsArchive.ulVideos.map((value) => {
              return (
                <a href={value.link} key={value.anchor}>
                  {value.anchor}
                </a>
              );
            })}
          </Details>
          {NewsArchive.description.map((paragraph) => {
            return (
              <Details key={paragraph.paragraph}>
                {paragraph.paragraph}
                <br />
              </Details>
            );
          })}
          <UnorderedList>
            {NewsArchive.ulArchives.map((value) => {
              return (
                <>
                  {file
                    .filter((fileData) => fileData.attributes.AnchorText === value.anchor)
                    .map((fileData) => {
                      return (
                        <>
                          <List className="text-orange">
                            <a
                              href={`${API}${fileData.attributes.File.data.attributes.url}`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              {fileData.attributes.AnchorText}
                            </a>
                          </List>
                        </>
                      );
                    })}
                  {/* <List className="text-orange" key={value.anchor}>
                    <a href={value.link} rel="noreferrer" target="_blank">
                      {value.anchor}
                    </a>
                  </List> */}
                </>
              );
            })}
          </UnorderedList>
          {/* NOTE: Temporarily hidden, file not found.
          <H2 className="text-blue mt-5">{NewsArchive.pressRelease}</H2>
          <Details>
            <a className="text-orange" href={NewsArchive.link}>
              {NewsArchive.anchor}
            </a>
          </Details> */}
        </section>
      </ContentContainer>
    </>
  );
};
