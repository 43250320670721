import React from 'react';
import { HeaderGradient, WattsNew } from '@components';
import { Content as ContentContainer, Images } from '@core';

export const SeeWattsNew = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-misc-orange"
        rightColor="to-misc-yellow"
        title="See Watts New"
      />

      <section className=" relative ">
        <div className="absolute h-full bg-black/50 " />
        <img
          alt="News And Media Banner"
          className="aspect-ratio__16-5"
          src={Images.NewsMediaBanner}
        />
        {/* <div className="absolute inset-0  h-full bg-black bg-opacity-10 z-10" /> */}
        <a
          className="absolute see-watts-new__connect-with-us "
          href="/in-our-community/#e-newsletter"
        >
          <button
            className="py-1 px-4 bg-orange rounded-full w-full phoneWide:w-auto text-white text-bold tablet:px-6 tablet:py-3 phone:text-sm"
            type="button"
          >
            Connect with us
          </button>
        </a>
      </section>
      <ContentContainer className="mb-auto pb-auto">
        <WattsNew />
      </ContentContainer>
      <div className="desktop:h-52 w-full">&nbsp;</div>
    </>
  );
};
