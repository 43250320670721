export const MedicalDental = {
  pageTitle: `Benefits`,
  title: `Medical / Dental`,
  description: `Open Enrollment for government of Guam members to enroll in a medical/dental plan usually is during the month of September.  Coverage begins October 1st through September 30th for the fiscal year.  During the open enrollment period, GPA employees may sign up with participating providers for health/dental coverage to include any pre-existing conditions without a medical exam.  Employees may add or delete dependents during this time.  You also may cancel your medical and dental coverage in its entirety during open enrollment.  Most dental coverages have lock-in provisions and can be cancelled only during open enrollment.`,
  contents: [
    {
      title: `FY 21 Health Insurance Provider`,
      name: `Aetna International`,
      phone: `Phone: (671) 47 AETNA (472-3862)`,
      info: `24/7 Customer Service Toll Free: 800-231-7729`,
    },
  ],
};

export const Eligibility = {
  title: `Eligibility Information:`,
  subTitle: `Who is eligible? `,
  contents: [
    {
      title: `Subscriber:`,
      ul: [
        `Be a Government of Guam employee working at least 30 hours per week`,
        `Be a retiree or survivor of a retired employee of GovGuam`,
        `Maintain legal residency in Guam/CNMI and not be absent for more than 182 consecutive days (excluding members who are receiving continuous care off-island and their insured companion, as approved by Aetna, and child dependents up to age 26)`,
        `Be continuously enrolled for the entire plan year in both Medicare Part A and Part B (with Medical Care primary plan) to be enrolled in RSP Plan.`,
      ],
    },
    {
      title: `Dependent:`,
      ul: [
        `Legal spouse or domestic partner. Domestic partner must be at least 18 years old and has lived with you for at least 2 consecutive years. (Domestic partner may only be enrolled during open enrollment or within 30 days from the date they first become eligible to enroll in the plan)`,
        `Biological child, Adopted child, Stepchild, Child under legal guardianship/custody, Foster Child, Disabled child. *Legal documents and/or medical certifications may be required. See plan details for more information.`,
      ],
    },
  ],
  header: `Whos eligible to enroll:`,
  description: `To enroll in an Aetna international Health Plan, you and your dependent must first meet eligibility requirements defined by the Government of Guam and submit your enrolment form to your Employer with any other required documentation during an open enrollment peiod or within 30 days from the date you first become eligible, or within 30 days from the date of a qualifiing event. GovGuam Policy allows you to enroll in a Medical Only Plan or both Medical and Dental Plans. You cannot enroll only in a Dental Plan.`,
  outro: `For additional information you may contact the GPA's Human Resources office at 671-648-3130 or e-mail `,
  email: `gpahr@gpagwa.com`,
  link: `mailto:gpahr@gpagwa.com`,
};

export const InsuranceResources = {
  title: `Group Health Insurance Resources:`,
  ul: [
    `DOA Organizational Circular No. 2020-026 & GHI Program Rates`,
    `Gov-Guam Netcare/Aetna International Provider Directory`,
    `FY21 AETNA Brochure`,
    `Aetna Open Enrollment Form`,
    `Aetna Enrollment Flyer`,
    `FY21 Aetna Rate Sheet`,
    `AETNA Open Enrollment FAQs`,
  ],
};