import { API } from '@config';
import { AboutUsGMPerformancesTable } from '@src/types/AboutUsGMPerformances';

export const getAboutUsGMPerformance = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/about-us-gm-performances?populate=File&sort=id%3Adesc`, options);
  const data: AboutUsGMPerformancesTable = await response.json();

  return data;
};
