import React, { useEffect, useState } from 'react';
import {
  H1,
  H3,
  Details,
  Table,
  Images,
  OrderedList,
  List,
  TableCell as TD,
  Content as ContentContainer,
} from '@core';
import { JobOpenings } from '@content';
import { HeaderGradient } from '@components';
import { HRCURRENOPENINGS } from '@src/types/HrCurrentOpenings';
import { getHrCurrentOpenings } from '@src/services/HrCurrentOpenings';
import { API } from '@src/config';
// import { getJobOpeningsTableData, getFileData } from '@services';
// import { JOBOPENINGSDATA, FILEDATA } from '@types';

export const FindJobOpenings = () => {
  // const [tableData, setTableData] = useState<JOBOPENINGSDATA[]>([]);
  // const [file, setFile] = useState<FILEDATA[]>([]);
  const [currentOpenings, setCurrentOpenings] = useState<HRCURRENOPENINGS[]>([]);

  const getData = async () => {
    // const tableDatas = await getJobOpeningsTableData();
    // const fileDatas = await getFileData();
    // setTableData(tableDatas.data);
    // setFile(fileDatas.data);
    const currentOpeningsData = await getHrCurrentOpenings();
    setCurrentOpenings(currentOpeningsData.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={JobOpenings.title}
      />
      <div
        className="h-1/4 tabletWide:h-1/3 laptop:h-1/2 bg-top bg-cover bg-hero"
        style={{ backgroundImage: `url(${Images.JobOpeningsImg})` }}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <section>
              {JobOpenings.openings.map((content) => {
                return (
                  <>
                    <H1 className="text-blue mb-5">{content.title}</H1>
                    <Details>{content.description}</Details>
                    {/* {file
                      .filter((fileData) => fileData.attributes.AnchorText === content.anchor)
                      .map((fileData) => {
                        return (
                          <>
                            <Details className="list-none text-orange">
                              <a
                                href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <strong>{fileData.attributes.AnchorText}</strong>
                              </a>
                            </Details>
                          </>
                        );
                      })} */}
                    <Details className="list-none text-orange">
                      <a href={`${API}${content.link}`} rel="noreferrer" target="_blank">
                        <strong>{content.anchor}</strong>
                      </a>
                    </Details>
                  </>
                );
              })}
            </section>
            <section>
              {JobOpenings.table.map((content) => {
                return (
                  <>
                    <div className="text-center">
                      <H1 className="mt-10 text-blue">{content.title.title}</H1>
                      <H3>{content.title.subTitle}</H3>
                    </div>
                    <Table className="w-full text-center">
                      <tr>
                        {content.th.map((heading) => {
                          return (
                            <TD key={heading} rowType="heading">
                              {heading}
                            </TD>
                          );
                        })}
                      </tr>

                      {currentOpenings.map((value) => {
                        return (
                          <tr key={value.attributes.createdAt}>
                            <TD>{value.attributes.Announcement_No}</TD>
                            <TD className="text-left">
                              {value.attributes.File.data != null ? (
                                <a
                                  className="text-orange"
                                  href={`${API}/${value.attributes?.File?.data?.attributes?.url}`}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <strong>{value.attributes.Position_Title}</strong>
                                </a>
                              ) : (
                                <Details>
                                  <strong>{value.attributes.Position_Title}</strong>
                                </Details>
                              )}
                            </TD>
                            <TD>{value.attributes.Announcement_Type}</TD>
                            <TD>{value.attributes.Open_to_Public}</TD>
                            <TD>{value.attributes.Opening_Date}</TD>
                            <TD>
                              {value.attributes.Closing_Date != null
                                ? value.attributes.Closing_Date
                                : 'Continuous'}
                            </TD>
                          </tr>
                        );
                      })}

                      {/* {tableData.map((data) => {
                        return (
                          <>
                            <tr>
                              <TD>{data.attributes.Announcement}</TD>

                              <TD className="text-left">
                                <a
                                  className="text-orange"
                                  href={`${API}${data.attributes.File.data.attributes.url}`}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <strong>{data.attributes.Position}</strong>
                                </a>
                              </TD>
                              <TD>{data.attributes.Type}</TD>
                              <TD>{data.attributes.Open}</TD>
                              <TD>{data.attributes.OpeningDate}</TD>
                              <TD>{data.attributes.ClosingDate}</TD>
                            </tr>
                          </>
                        );
                      })} */}
                    </Table>
                  </>
                );
              })}
            </section>
            <section>
              {JobOpenings.apply.map((content) => {
                return (
                  <>
                    <H1>{content.title}</H1>
                    <Details>{content.description}</Details>
                    {/* {file
                      .filter((fileData) => fileData.attributes.AnchorText === content.anchor)
                      .map((fileData) => {
                        return (
                          <>
                            <Details className="list-none text-orange">
                              <a
                                href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <strong>{fileData.attributes.AnchorText}</strong>
                              </a>
                            </Details>
                          </>
                        );
                      })} */}
                    <Details className="list-none text-orange">
                      <a href={`${API}${content.link}`} rel="noreferrer" target="_blank">
                        <strong>{content.anchor}</strong>
                      </a>
                    </Details>
                  </>
                );
              })}
            </section>
            <section>
              {JobOpenings.return.map((content) => {
                return (
                  <>
                    <H1 className="mb-5 mt-10">{content.title}</H1>
                    <OrderedList>
                      {content.ol.map((value) => {
                        return (
                          <>
                            <List key={value.description}>
                              {value.description}
                              <div className="ml-5">
                                <p className="-mb-0.5 font-bold">{value.content1}</p>
                                <Details className="-mb-0.5">{value.content2}</Details>
                                <Details className="-mb-0.5">{value.content3}</Details>
                                <Details className="-mb-0.5 ">
                                  <a href={value.mailto}>
                                    {value.text}&nbsp;
                                    <span className="text-orange">{value.email}</span>
                                  </a>
                                </Details>
                              </div>
                            </List>
                          </>
                        );
                      })}
                    </OrderedList>
                  </>
                );
              })}
            </section>
            <section>
              {JobOpenings.equality.map((content) => {
                return (
                  <>
                    <Details className="text-center text-blue">{content.description}</Details>
                  </>
                );
              })}
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
