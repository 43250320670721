export const EnergyConsumption = {
  title: `Get a Rebate`,
  subtitle: `Lower Energy Consumption on Clothes Washers & Dryers`,
  p1: `Replace washers and dryers with energy efficient models to lower your energy consumption. GPA offers rebates when you buy appliances that are ENERGY STAR® qualified. Rebates are $200 per qualified appliance.`,
  p2: `Contact a Guam vendor listed here to purchase appliances that qualify. Qualified vendors provide rebate forms to customers at the time of purchase. You must properly dispose of old equipment with a professional recycler in order to qualify for a rebate.`,
  p3: `You must properly dispose of old equipment with either Guam Solid Waste Authority (GSWA) or a professional recycler in order to qualify for a rebate. Visit GSWA or the Recycling Association of Guam for more information on how to dispose of your old equipment. Failure to comply with, or attempts to circumvent, program requirements would negate your rebate, which would occur as an additional charge on your GPA bill.`,
  pdfs: [
    {
      text: `GPA Energy Sense Program Approved Washer/Dryer Models pdf.`,
      link: ``,
    },
    {
      text: `Form W-9 pdf.`,
      link: ``,
    },
  ],
};

export const EnergySaving = {
  title: `Energy-Saving Tips`,
  ul: [
    `Use cold water for washing clothes to reduce your energy consumption. Unless your clothes have oily stains, cool water will usually work well.`,
    `Wash and dry only full loads.`,
    `Dry towels and heavy items separately from lighter ones. Don’t over dry your clothes. Hang them to dry, if possible.`,
    `Clean the lint screen every load to reduce energy costs and help prevent house fires. Periodically inspect your dryer vent to be sure it is not blocked.`,
  ],
};

export const EnergyProgram = {
  table: [
    {
      title: {
        title: `GPA Energy Sense Program Approved Clothes Washer/Dryer Vendors`,
        subTitle: `As of 02/26/18`,
      },
      th: [`Vendor`, `Address`, `Phone No.`],
      tr: [
        {
          td: [
            {
              vendor: `AFM Wholesale`,
              address: `975 Army Drive, Lower Barrigada Heights, Guam 96913`,
              phone: `734-7789`,
            },
            {
              vendor: `Home Depot`,
              address: `295 Chalan Pasaheru, Route 10A, Tamuning, Guam 96913`,
              phone: `648-0440`,
            },
            {
              vendor: `Kmart`,
              address: `404 N. Marine Corps Dr., Route 1, Tamuning, Guam 96913`,
              phone: `649-9878`,
            },
            {
              vendor: `Navy Exchange`,
              address: `Bldg 700, Naval Activities Guam, Santa Rita, Guam 96910`,
              phone: `564-3211`,
            },
            {
              vendor: `Townhouse Furniture and Appliances`,
              address: `601 Route 8, Maite, Guam 96910`,
              phone: `477-2154`,
            },
          ],
        },
      ],
    },
  ],
};