import { ARImages } from '@core';

export const AnnualReports = {
  title: `Financials`,
  contents: [
    {
      image: ARImages.ar2021,
      title: `2021 Annual Report`,
      anchor: `Download 6.21 mB pdf`,
      link: ``,
    },
    {
      image: ARImages.ar2020,
      title: `2020 Annual Report`,
      anchor: `Download 4.7 mB pdf`,
      link: ``,
    },
    {
      image: ARImages.ar2019,
      title: `2019 Annual Report`,
      anchor: `Download 14.9 mB pdf`,
      link: ``,
    },
    {
      image: ARImages.ar2018,
      title: `2018 Annual Report`,
      anchor: `Download 13.5 mB pdf`,
      link: ``,
    },
    {
      image: ARImages.ar2017,
      title: `2017 Annual Report`,
      anchor: `Download 5.9 mB pdf`,
      link: ``,
    },
    {
      image: ARImages.ar2016,
      title: `2016 Annual Report`,
      anchor: `Download 5.3 mB pdf`,
      link: ``,
    },
    {
      image: ARImages.ar2014,
      title: `2014 Annual Report`,
      anchor: `Download 6.5 mB pdf`,
      link: ``,
    },
    {
      image: ARImages.ar2013,
      title: `2013 Annual Report`,
      anchor: `Download 12.63 mB pdf`,
      link: ``,
    },
    {
      image: ARImages.ar2010,
      title: `2010 Annual Report`,
      anchor: `Download 6.3 mB pdf`,
      link: ``,
    },
    {
      image: ARImages.ar2009,
      title: `2009 Annual Report`,
      anchor: `Download 3.8 mB pdf`,
      link: ``,
    },
    {
      image: ARImages.ar2008,
      title: `2007-2008 Annual Report`,
      anchor: `Download 3 mB pdf`,
      link: ``,
    },
    // NOTE: File not found on old GPA website
    // {
    //   image: ARImages.ar0607,
    //   title: `2006-2007 Annual Report`,
    //   anchor: `Download 7.5 mB pdf`,
    //   link: ``,
    // },
  ],
};

export const FinDescription = {
  operational: {
    title: `MANAGEMENT & OPERATIONAL ASSESSMENT`,
    description: `Baker Tilly Virchow Krause, LLP submitted this final report related to the Management and Operational Assessment of Guam Power Authority on September 23, 2009. The final report is comprised of an executive summary and nine sections covering the areas of our review. In addition, the staffing and productivity study is summarized in a written report.`,
  },
  finStatement: {
    title: `FINANCIAL STATEMENTS`,
    description: `Online Posting Requirements: GPA posts its audited financial statements as part of its bond covenant responsibilities. Guam Power Authority's bonds are triple tax exempt and are rated by Standard & Poor's and Moody's.`,
  },
};

export const FinLinks = {
  operational: {
    title: `Sections`,
    contents: [
      {
        ul: [
          `GPA Transmittal Letter`,
          `Executive Summary 092309`,
          `Staffing and Productivity 092309`,
          `Corporate Governance 092309`,
          `Strategic Planning 092309`,
          `Accountability and Performance 092309`,
        ],
      },
      {
        ul: [
          `Transmission & Distribution 092309`,
          `Generation Report 092309`,
          `Customer Service 092309`,
          `Information Technology 092309`,
          `Financial Strength 092309`,
          `Staffing & Productivity Study Report 092309`,
        ],
      },
    ],
  },
  finStatement1: {
    title: `Audited Financial Statements Archive`,
    contents: [
      {
        ul: [
          `Fiscal Years 1996-1997`,
          `Fiscal Years 1997-1998`,
          `Fiscal Years 1998-1999`,
          `Fiscal Years 1999-2000`,
          `Fiscal Years 2000-2001`,
          `Fiscal Years 2001-2002`,
          `Fiscal Years 2002-2003`,
          `Fiscal Years 2003-2004`,
          `Fiscal Years 2004-2005`,
        ],
      },
      {
        ul: [
          `Fiscal Years 2005-2006`,
          `Fiscal Years 2006-2007`,
          `Fiscal Years 2007-2008`,
          `Fiscal Years 2008-2009`,
          `Fiscal Years 2009-2010`,
          `Fiscal Years 2010-2011`,
          `Fiscal Years 2011-2012`,
          `Fiscal Years 2012-2013`,
        ],
      },
      {
        ul: [
          `Fiscal Years 2013-2014`,
          `Fiscal Years 2014-2015`,
          `Fiscal Years 2015-2016`,
          `Fiscal Years 2016-2017`,
          `Fiscal Years 2017-2018`,
          `Fiscal Years 2018-2019`,
          `Fiscal Years 2019-2020`,
          `Fiscal Years 2020-2021`,
        ],
      },
    ],
  },
  finStatement2: {
    title: `Un-Audited Financial Statements Archive`,
    contents: [
      {
        ul: [
          `GPA Un-Audited Financials as of  November2020`,
          `GPA Un-Audited Financials as of December2020`,
          `GPA Un-Audited Financials as of  January 2021`,
          `GPA Un-Audited Financials as of February 2021`,
          `GPA Un-Audited Financials as of March 2021`,
          `GPA Un-Audited Financials as of April 2021`,
          `GPA Un-Audited Financials as of  May 2021`,
        ],
      },
      {
        ul: [
          `GPA Un-Audited Financials as of June2021`,
          `GPA Un-Audited Financials as of July2021`,
          `GPA Un-Audited Financials as of August2021`,
          `GPA Un-Audited Financials as of September2021`,
          `GPA Un-Audited Financials as of October2021`,
          `GPA Un-Audited Financials as of November2021`,
          `GPA Un-Audited Financials as of December2021`,
        ],
      },
      {
        ul: [
          `GPA Un-Audited Financials as of January 2022`,
          `GPA Un-Audited Financials as of February 2022`,
          `GPA Un-Audited Financials as of March 2022`,
          `GPA Un-Audited Financials as of April 2022`,
          `GPA Un-Audited Financials as of May 2022`,
          `GPA Un-Audited Financials as of June 2022`,
        ],
      },
    ],
  },
  CitizenCentricReport: {
    title: `Citizen Centric Report (PL30-127)`,
    contents: [
      {
        ul: [`Fiscal Year 2010`, `Fiscal Year 2011`, `Fiscal Year 2012`, `Fiscal Year 2013`],
      },
      {
        ul: [`Fiscal Year 2014`, `Fiscal Year 2015`, `Fiscal Year 2016`, `Fiscal Year 2017`],
      },
      {
        ul: [`Fiscal Year 2018`, `Fiscal Year 2019`, `Fiscal Year 2020`, `Fiscal Year 2021`],
      },
    ],
  },
};

export const OperationalAssessment = {
  title: `MANAGEMENT & OPERATIONAL ASSESSMENT`,
  contents: [
    {
      title: `Operating Data  (Rule 15C2-12)`,
      anchor: `FY2021 Operating Data`,
    },
    {
      title: `Operating Data  (Rule 15C2-12)`,
      anchor: `Self insurance protocol`,
    },
  ],
};

export const EnergyAdjustment = {
  sectionTitle: `FUELS`,
  title: `Levelized Energy Adjustment Clause Filings and Approvals`,
  filings: [
    {
      title: `GPA LEAC Filings with the Guam Public Utilities Commission`,
      th: [`Recent Filings`, `Docket`, `Date`],
    },
  ],
  orders: [
    {
      title: `Guam Public Utilities Commission LEAC Orders`,
      th: [`Recent Filings`, `Docket`, `Date`],
    },
  ],
};

export const LEACCosts = {
  title: `LEAC Costs`,
  p1: `There is more to the LEAC than simply the fuel oil commodity price. These costs are included under the general heading of Fuel Handling and Other Costs.These costs include but are not limited to the following:`,
  ul: [
    `Dock Usage Fees`,
    `Excess Laytime/Overtime Charges (Shell)`,
    `Storage Tank Rental (Shell)`,
    `Ship Demurrage Charges`,
    `Fuel Hedging Costs`,
    `Fuel Trucking Delivery Charges`,
    `Letter of Credit and other Bank Charges`,
    `Fuel Pipeline Use Fees (Shell)`,
    `Fuel Testing and other Contractor Services`,
    `GPA Fuel Management Labor`,
  ],
  p2: `All LEAC expenses are publicly declared by GPA and audited by the Guam Public Utilities Commission (GPUC). GPA publishes LEAC filings with GPUC as well as LEAC orders from the GPUC on its website.`,
};

export const OilShipments = {
  title: `Next and Recent Residual Fuel Oil Shipments`,
  table: [
    {
      th: [
        `Vessel`,
        `Guam Port Date`,
        `LSFO (bbls)`,
        `HSFO (bbls)`,
        `Commodity Purchase Price ($/bbl)`,
        `LSFO Premium Charge ($/bbl)`,
        `HSFO Premium Charge ($/bbl)`,
      ],
    },
  ],
};

export const FuelContracts = {
  title: `GPA Fuel Related Contracts`,
  table: [
    {
      th: [`Description`, `Firm`, `Expiration Date`, `Extension Date`],
    },
  ],
};

export const FuelInvoices = {
  title: `Residual Fuel Oil Invoices`,
  table: [
    {
      th: [`Invoice`, `Date`, `Fuel Type`, `Invoice amount ($)`],
    },
  ],
  note: `*HSFO - High Sulfur Fuel Oil ; LSFO - Low Sulfur Fuel Oil`,
};

export const Fuels = {
  // title: `FUELS`,
  // NOTE: File not found
  // description: `Description of Major Fuel Types`,
  contents: [
    // TODO: Comment this section and create tables for them
    // {
    //   title: `Levelized Energy Adjustment Clause Filings and Approvals`,
    //   ul: [
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `FY 2009 Filing and Approval`,
    //     },
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `LEAC Costs`,
    //     },
    //     {
    //       anchor: `LEAC Update 437 kB pdf`,
    //       link: ``,
    //       placeholder: ``,
    //     },
    //   ],
    // },
    // {
    //   title: `Residual Fuel Oil Shipments`,
    //   ul: [
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `FY 2009 Shipments`,
    //     },
    //   ],
    // },
    // {
    //   title: `Fuel Related Contracts in Force`,
    //   ul: [
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `Fuel Contracts`,
    //     },
    //   ],
    // },
    // {
    //   title: `Fuel Invoices`,
    //   ul: [
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `FY 2009 Invoice`,
    //     },
    //   ],
    // },
    // {
    //   title: `P.L. 30-29 Disclosure - Fuel Purchases`,
    //   ul: [
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `June 2009`,
    //     },
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `July 2009`,
    //     },
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `August 2009`,
    //     },
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `September 2009`,
    //     },
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `October-December 2009`,
    //     },
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `January 2010 `,
    //     },
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `February 2010`,
    //     },
    //   ],
    // },
    // {
    //   title: ``,
    //   ul: [
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `March 2010`,
    //     },
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `April 2010`,
    //     },
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `May 2010`,
    //     },
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `June 2010`,
    //     },
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `July 2010`,
    //     },
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `August 2010`,
    //     },
    //     {
    //       anchor: ``,
    //       link: ``,
    //       placeholder: `September 2010`,
    //     },
    //   ],
    // },
    {
      title: `P.L. 30-29 Disclosure - Fuel Purchases`,
      ul: [
        {
          anchor: `FY2013 Fuel Purchases`,
          link: ``,
          placeholder: ``,
        },
        {
          anchor: `FY2014 Fuel Purchases`,
          link: ``,
          placeholder: ``,
        },
        {
          anchor: `FY2015 Fuel Purchases`,
          link: ``,
          placeholder: ``,
        },
        {
          anchor: `FY2016 Fuel Purchases`,
          link: ``,
          placeholder: ``,
        },
        {
          anchor: `FY2017 Fuel Purchases`,
          link: ``,
          placeholder: ``,
        },
        {
          anchor: `FY2018 Fuel Purchases`,
          link: ``,
          placeholder: ``,
        },
        {
          anchor: `FY2019 Fuel Purchases`,
          link: ``,
          placeholder: ``,
        },
        {
          anchor: `FY2020 Fuel Purchases`,
          link: ``,
          placeholder: ``,
        },
        {
          anchor: `FY2021 Fuel Purchases`,
          link: ``,
          placeholder: ``,
        },
      ],
    },
  ],
};

export const Others = {
  contents: [
    {
      title: `Useful Information`,
      ul: [
        {
          anchor: ``,
          link: `https://www.bloomberg.com/energy`,
          placeholder: `Bloomberg Energy Prices`,
        },
      ],
    },
  ],
};

export const BondOfficialStatements = {
  title: `BOND OFFICIAL STATEMENTS`,
  contents: [
    {
      title: `Bond Official Statements`,
      ul: [
        `2010 Bonds Refunding`,
        `2012 Bonds Refunding`,
        `2014 Bonds Refunding`,
        `2017 Bonds Refunding`,
        `2022 Bonds Refunding`,
      ],
    },
  ],
};

export const BondCreditReviews = {
  title: `BOND CREDIT RATING REVIEWS`,
  descriptions: [
    {
      title: ``,
      description: `Guam Power Authority's bonds are triple tax exempt and are rated by Standard & Poor's, Fitch and Moody's.`,
      file: ``,
    },
    {
      title: `28 January 2009 `,
      description: `Fitch Ratings has affirmed the BB+ with a Positive Outlook Fitch\xa0`,
      file: ``,
    },
    {
      title: `December 2008 `,
      description: `Standard & Poor's Ratings Services raised its standard long term rating and underlying rating (SPUR) on GPA's existing revenue bonds to "BBB-" from "BB+". The upgrade reflects the authority's sustained trend of improved operational and financial performance. Standard Poors\xa0`,
      file: `Standard Poors Download 334 KB pdf`,
    },
    {
      title: `27 August 2007 `,
      description: `Moody's Investors Service has affirmed the Ba1 credit rating on GPA's revenue bonds. Moody's has also changed the outlook from stable to positive on the bonds.\xa0`,
      file: `Download 67 kB pdf`,
    },
    {
      title: `12 April 2007 `,
      description: `Standard & Poor's revises GPA outlook to stable from negative based on the Authority's improved operational and fiscal performance over the last two years.\xa0`,
      file: `Download 180 kB pdf`,
    },
    {
      title: `11 February 2005 `,
      description: `Standard & Poor's rating of GPA Bonds\xa0`,
      file: `Download 68 kB pdf`,
    },
    {
      title: `20 April 2004 `,
      description: `Moody's Investors Service rating of GPA bonds\xa0`,
      file: `Download 149 kB pdf`,
    },
  ],
  contents: [
    {
      title: `FY2015 Credit ratings`,
      ul: [
        `FY2015 Moody's Investors Service`,
        `FY2015 Fitch Ratings`,
        `FY2015 Standard & Poors Ratings Services`,
      ],
    },
    {
      title: `FY2016 Credit ratings`,
      ul: [
        `FY2016 Moody's Investors Service`,
        `FY2016 Fitch Ratings`,
        `FY2016 Standard & Poors Ratings Services`,
      ],
    },
    {
      title: `FY2017 Credit ratings`,
      ul: [`FY2017 Moody's ICredit Opinion`],
    },
    {
      title: `FY2018 Credit ratings`,
      ul: [
        `FY2018 Moody's Investors Service`,
        `FY2018 Fitch Ratings`,
        `FY2018 Standard & Poors Ratings Services`,
      ],
    },
    {
      title: `FY2019 Credit ratings`,
      ul: [`FY2019 Standard & Poors Ratings Services`, `FY2019 Moody's Investors Service`],
    },
    {
      title: `FY2020 Credit ratings`,
      ul: [`FY2020 Fitch Ratings`],
    },
  ],
};
