import React from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { H3, Details } from '@core';
// import { useWindowSize } from '@hooks';

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface SeeWattsNewItemProps {
  title: string;
  date: string;
  description: string;
  pdfLink: string;
}

export const SeeWattsNewItem: React.FC<SeeWattsNewItemProps> = ({
  title,
  description,
  pdfLink,
}) => {
  // const { width } = useWindowSize();

  return (
    <a
      className="w-full flex flex-col cursor-pointer h-full items-stretch justify-between border-solid rounded-md border-gray border p-4 desktop:p-10"
      href={pdfLink}
      rel="noopener noreferrer"
      target="_blank"
    >
      <H3 className="text-blue mb-5">{title}</H3>
      <Details>
        {/* <strong>&nbsp;</strong> */}
        {description}...
      </Details>
      <div className="flex justify-start tabletWide:justify-end">
        <button
          className="bg-blue rounded-full text-white text-bold tabletWide:px-5 tabletWide:py-3 w-full tabletWide:w-auto phone:py-3"
          type="button"
        >
          Continue Reading
        </button>
      </div>

      {/* <div className="mt-auto">
          <Document file={pdfLink}>
            <Page pageNumber={1} width={width <= 1499 ? 300 : 400} />
          </Document>
        </div> */}
    </a>
  );
};
