import React, { useEffect, useState } from 'react';
import { Details, Content as ContentContainer } from '@core';
import { LifeInsurance} from '@content';
import { HeaderGradient } from '@components';
import { getFileData } from '@services';
import { FILEDATA } from '@types';
import { API } from '@src/config';

export const LifeInsuranceBenefit = () => {
    const [file, setFile] = useState<FILEDATA[]>([]);

    const getData = async () => {
      const fileDatas = await getFileData();
      setFile(fileDatas.data);
    };

    useEffect(() => {
      getData();
    }, []);
  
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={LifeInsurance.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <section>
              <Details>{LifeInsurance.description}</Details>
              <Details>
                <span className="font-bold">{LifeInsurance.title}</span> <br />
                {file
                  .filter((fileData) => fileData.attributes.AnchorText === LifeInsurance.anchor)
                  .map((fileData) => {
                    return (
                      <>
                        <a
                          className="text-orange"
                          href={`${API}${fileData.attributes.File.data.attributes.url}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {LifeInsurance.anchor}
                        </a>
                      </>
                    );
                  })}
              </Details>
            </section>
          </div>
        </div>
      </ContentContainer>
      <div className="desktop:h-1/6 w-full">&nbsp;</div>
    </>
  );
};
