export const IssuesInfo = {
  title: `ISSUES & INFORMATION`,
  contents: [
    {
      title: `GPA Integrated Resource Plan Forum (April 14, 2014)`,
      anchor: `Information and videos`,
      link: `/gpa-initiatives/what-were-doing/information-videos`,
    },
    {
      title: `GPA Integrated Resource Plan (IRP)`,
      anchor: `2021 Integrated Resource Plan (IRP)`,
      link: `/corporate/news/integrated-resource-plan`,
    },
  ],
  // NOTE: Temporarily hidden, file not found
  // contentsLink: [
  //   {
  //     title: `GPA Centric Report`,
  //     anchor: `Downloadable Report 4-pages (Released June 25, 2012) - pdf, 774 KB`,
  //     link: ``,
  //   },
  //   {
  //     title: `Renewable Resource Acquisition`,
  //     anchor: `Renewable Resource Acquisition`,
  //     link: ``,
  //   },
  // ],
  additionals: [
    {
      title: `LEAC`,
      anchor: `pdf document`,
      link: ``,
      contents: [
        {
          description: 'Press Release Archive (Link)',
        },
      ],
    },

    // NOTE: Temporarily hidden, file not found
    // {
    //   title: `MILITARY BUILDUP`,
    //   anchor: `GPA/GWA Legislature Presentation of Draft Environmental Impact Statement (DEIS) - pdf, 2.4 MB`,
    //   link: ``,
    // },
  ],
};

export const DisasterRecovery = {
  title: `DISASTER RECOVERY`,
  anchor: `How Power is Recovered`,
  link: ``,
  contents: [
    {
      title: `Typhoon Restoration`,
      description: [
        {
          paragraph: `Guam experiences some of the most intense tropical cyclones (typhoons) in the world. Guam is exposed to substantial typhoon risk due to its location in the southern end of the Mariana Island chain. Typhoons in this region pose a threat all year long, but are most frequent between the months of June and December. During the past 57 years, 19 storms have passed over Guam, and many more have passed nearby. Because the diameter of a typhoon can measure over 100 miles, typhoons do not have to pass directly over Guam to cause damage.`,
        },
        {
          paragraph: `One of the most intense typhoons to ever strike Guam was typhoon Karen, which passed over the southern part of the island in 1962. Wind gusts estimated near 185 mph destroyed 95% of all homes on the island.`,
        },
        {
          paragraph: `Other notable typhoons impacting Guam include Super Typhoon Pongsonga in 2002 with measured wind gusts up to 184 mph.`,
        },
      ],
    },
    {
      title: `Guam Earthquakes`,
      description: [
        {
          paragraph: `Earthquake risk in Guam is caused by the island’s proximity to the Mariana Trench, where the subduction of the Pacific Plate beneath the Philippine Plate occurs. This motion leads to earthquakes throughout the Mariana Island chain.`,
        },
        {
          paragraph: `The 1849 Guam earthquake and tsunami caused extensive damage to Agana, the largest city on Guam, as did the 1902, 1975, and 1978 earthquakes. The 1993 Guam earthquake measured Mw7.8, which was larger than the maximum event estimated by most scientists for that area at the time. Several high-rise buildings in Tumon Bay, mostly hotels, sustained enough damage to warrant demolition, while liquefaction and lateral spreading resulted in an estimated $8-10 million in repair costs to the main port for Guam.`,
        },
      ],
    },
  ],
};

export const NewsArchive = {
  title: `NEWS ARCHIVE`,
  netMeter: `Net Metering Program Meeting Videos`,
  ulVideos: [
    {
      anchor: `Part 1 | `,
      link: `https://youtu.be/MlXbqu1QMyA`,
    },
    {
      anchor: `Part 2 | `,
      link: `https://youtu.be/gcF7JnVyVSU`,
    },
    {
      anchor: `Part 3 | `,
      link: `https://youtu.be/3nIAeYeyuUw`,
    },
    {
      anchor: `Part 4 | `,
      link: `https://youtu.be/WAEDsYfhhSk`,
    },
    {
      anchor: `Part 5`,
      link: `https://youtu.be/KSFQ88-Yi-4`,
    },
  ],
  description: [
    {
      paragraph: `Held July 18, 2018, at Gloria B. Nelson Public Service Bldg., Board Conf. Rm.`,
    },
    {
      paragraph: `The Guam Power Authority solicits stakeholder input regarding the Net Metering (NEM) Program. GPA has prepared policy recommendations for review and action by the Consolidated Commission on Utilities. The final NEM policy will then be forwarded to the Public Utilities Commission for action. Your feedback is crucial in this process.`,
    },
  ],
  moreArchive: `More Archives`,
  ulArchives: [
    {
      anchor: `Brown Treesnake Control Annual Progress Report For Guam Power Authority, Fiscal Year 2005`,
      link: ``,
    },
    {
      anchor: `Joaquin C. Flores Presentation to GEO Conference (June 2006)`,
      link: ``,
    },
    {
      anchor: `John Benavente Presentation to CCU - "GWA: Entering into a Public / Private`,
      link: ``,
    },
    {
      anchor: `Partnership" (November 2005)`,
      link: ``,
    },
    {
      anchor: `Presentation to Guam Contractor's Association - "Update on GPA and GWA" (November 2005)`,
      link: ``,
    },
    {
      anchor: `GPA Employee Briefing (November 2005)`,
      link: ``,
    },
    {
      anchor: `GPA implemented new billing software from AMX International in October 2005. PayGPA online, credit cards, and debit cards accepted November 2005.`,
      link: ``,
    },
    {
      anchor: `14th Pacific Power Association Conference (August 2005)`,
      link: ``,
    },
    {
      anchor: `View 3 papers presented by GPA`,
      link: ``,
    },
    {
      anchor: `2005 Guam Economic Development Conference`,
      link: ``,
    },
    {
      anchor: `4th Western Micronesian Chief Executive Summit (April 2005)`,
      link: ``,
    },
  ],
  // pressRelease: `Press Release Archive`,
  // anchor: `Press Release Archive`,
  // link: ``,
};
