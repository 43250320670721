import { Dialog } from '@headlessui/react';
import { HeaderGradient } from '@src/components';
import { Images, Content as ContentContainer, Details, H1, H2 } from '@src/core';
import { subscribeToNewsLetter } from '@src/services';
import React, { useState } from 'react';

export const InOurCommunity = () => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setFailedModalOpen] = useState(false);

  const onFormSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (firstName === '' || lastName === '' || phoneNumber === '' || email === '') {
      setFailedModalOpen(true);
    } else {
      setSuccessModalOpen(true);
      subscribeToNewsLetter(firstName, lastName, phoneNumber, email);
      setFirstName('');
      setLastName('');
      setPhoneNumber('');
      setEmail('');
    }
  };

  function successDialog() {
    return (
      <Dialog
        className="relative z-50 "
        onClose={() => setSuccessModalOpen(false)}
        open={isSuccessModalOpen}
      >
        <div aria-hidden="true" className="fixed inset-0 bg-black opacity-20" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-white p-8">
            <Dialog.Title>
              <H2>Newsletter Subscription</H2>
            </Dialog.Title>
            <Dialog.Description>
              <Details>Thank you for subscribing to our newsletter</Details>
            </Dialog.Description>

            <button
              className="bg-orange text-white text-rubik w-28 rounded-full py-2"
              onClick={() => setSuccessModalOpen(false)}
              type="button"
            >
              OK
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    );
  }
  function failedDialog() {
    return (
      <Dialog
        className="relative z-50 "
        onClose={() => setFailedModalOpen(false)}
        open={isFailedModalOpen}
      >
        <div aria-hidden="true" className="fixed inset-0 bg-black opacity-20" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-white p-8">
            <Dialog.Title>
              <H2>Warning</H2>
            </Dialog.Title>
            <Dialog.Description>
              <Details>You need to fill up the required fields</Details>
            </Dialog.Description>

            <button
              className="bg-orange text-white text-rubik w-28 rounded-full py-2"
              onClick={() => setFailedModalOpen(false)}
              type="button"
            >
              OK
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    );
  }

  return (
    <>
      {successDialog()}
      {failedDialog()}
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="In Our Community"
      />
      <section className=" relative">
        <div className="absolute h-full bg-black/50" />
        <div className="absolute inset-0  h-full bg-black bg-opacity-10 z-10" />
        <div className="h-full flex items-center laptop:px-28 tablet:px-20 phone:px-10 z-20 absolute">
          <div className=" tablet:w-500px phone:w-full flex flex-col tablet:items-start phone:items-center py-20">
            <h1
              className="font-rubik font-bold text-white tabletWide:text-5xl phoneWide:text-3xl phone:text-lg
              tablet:text-left phone:text-center"
            >
              Community-owned and not-for-profit means you’re at the heart of what we do.
            </h1>
          </div>
        </div>
        <img
          alt="News And Media Banner"
          className="w-full aspect-ratio__16-5"
          src={Images.InOurCommunityBanner}
        />
      </section>
      <section className="flex items-center flex-col bg-grayC410 laptop:py-32 phone:py-28 tablet:px-20 phone:px-10">
        <div className="w-full flex flex-col items-center">
          <h1
            className="font-jubilat text-center text-blue laptop:mb-14 phone:mb-10 laptop:text-5xl 
        tablet:text-5xl phone:text-4xl"
          >
            PROGRAMS AND SERVICES
          </h1>
          <div className="flex flex-col items-center tablet:items-stretch tablet:flex-row">
            <a
              className="w-full tabletWide:w-1/3 overflow-hidden bg-darkblue mr-0 tablet:mr-2 tabletWide:mr-8 mb-8 tabletWide:mb-0 relative"
              href="/in-our-community/educational-resources"
            >
              <div className="thumbnail__linear-gradient absolute top-0 left-0 w-full h-full" />
              <div className=" bg-transparent absolute bottom-0 left-0 p-4">
                <h6 className="text-white text-lg">Educational Resources</h6>
              </div>
              <img
                alt="Clean Energy Master Plan"
                className="w-full h-full object-cover"
                src={Images.EducationalResourcesSmallPhoto}
              />
            </a>
            <a
              className="w-full tabletWide:w-1/3 overflow-hidden bg-darkblue mr-0 tablet:mr-2 tabletWide:mr-8 mb-8 tabletWide:mb-0 relative"
              href="/in-our-community/safety"
            >
              <div className="thumbnail__linear-gradient absolute top-0 left-0 w-full h-full" />
              <div className=" bg-transparent absolute bottom-0 left-0 p-4">
                <h6 className="text-white text-lg">Safety</h6>
              </div>
              <img
                alt="Clean Energy Master Plan"
                className="w-full h-full object-cover"
                src={Images.SafetySmallPhoto}
              />
            </a>
            <a
              className="w-full tabletWide:w-1/3 overflow-hidden bg-darkblue mr-0 tablet:mr-2 tabletWide:mr-8 mb-8 tabletWide:mb-0 relative"
              href="/in-our-community/community-partners-and-programs"
            >
              <div className="thumbnail__linear-gradient absolute top-0 left-0 w-full h-full" />
              <div className=" bg-transparent absolute bottom-0 left-0 p-4">
                <h6 className="text-white text-lg">Community Partners and Programs</h6>
              </div>
              <img
                alt="Clean Energy Master Plan"
                className="w-full h-full object-cover"
                src={Images.CommunityPartnerSmallPhoto}
              />
            </a>
          </div>
        </div>
      </section>
      <section>
        <ContentContainer>
          <div className="h-auto tabletWide:max-h-300px w-full flex tabletWide:flex-row phone:flex-col">
            <div className="w-auto tabletWide:w-3/5 mr-auto flex flex-col justify-center laptop:p-14 phone:p-8 bg-blue">
              <h1 className="font-jubilat text-white laptop:text-5xl phone:text-4xl">
                Community Powered
              </h1>
              <p className="font-rubik font-normal text-white mt-5 laptop:text-xl phone:text-lg">
                We’re an integral part of the community we serve, and look for ways to give back
                through our programs, education, and volunteering. We take the "power to serve"
                mindset into the community, helping in any way that we can.
              </p>
              <a
                href="https://gpa.guamunity.com/community-powered"
                rel="noreferrer"
                target="_blank"
              >
                <button
                  className="mt-5 py-3 bg-orange rounded-full text-white text-bold laptop:px-8 phone:px-6 phone:text-sm"
                  type="button"
                >
                  Click Here
                </button>
              </a>

              {/* <div className="flex flex-col phoneWide:flex-row mt-5 gap-2">
                <a href="/how-do-i/get-financial-assistance">
                  <button
                    className="py-3 bg-orange rounded-full w-full phoneWide:w-auto text-white text-bold laptop:px-8 phone:px-6 phone:text-sm"
                    type="button"
                  >
                    Read More
                  </button>
                </a>
              </div> */}
            </div>
            <div className="h-auto w-6 bg-orange" />
            <img
              alt="Heart hand"
              className="w-full tabletWide:w-2/5  overflow-hidden object-cover"
              src={Images.CommunityPoweredImage}
            />
          </div>
        </ContentContainer>
      </section>
      <section id="e-newsletter">
        <ContentContainer>
          <div className="flex items-center justify-center">
            <div className="shadow-lg flex flex-col tabletWide:flex-row max-w-lg tabletWide:max-w-none">
              <div>
                <img
                  alt="Sign-up Newsletter"
                  className="block w-full h-full object-cover object-right-bottom"
                  src={Images.SignUpNewsletterImage}
                />
              </div>
              <div className="p-16 tabletWide:max-w-md">
                <H1>CONNECT WITH US</H1>
                <Details>
                  Get the latest updates on GPA news, projects, and helpful power tips. Fill out the
                  form below to subscribe.
                </Details>
                <form onSubmit={onFormSubmitHandler}>
                  <div className="flex flex-col mb-4">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="first-name">
                      First Name<span className="text-orange">*</span>
                    </label>
                    <input
                      id="first-name"
                      name="firstName"
                      onChange={(e) => setFirstName(e.target.value)}
                      type="text"
                      value={firstName}
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="last-name">
                      Last Name<span className="text-orange">*</span>
                    </label>
                    <input
                      id="last-name"
                      name="lastName"
                      onChange={(e) => setLastName(e.target.value)}
                      type="text"
                      value={lastName}
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="phone-number">
                      Phone Number<span className="text-orange">*</span>
                    </label>
                    <input
                      id="phone-number"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      type="number"
                      value={phoneNumber}
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="email-address">
                      Email Address<span className="text-orange">*</span>
                    </label>
                    <input
                      id="email-address"
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      value={email}
                    />
                  </div>
                  <button
                    className="bg-blue text-white cursor-pointer h-12 w-32 flex items-center justify-center"
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </ContentContainer>
      </section>
    </>
  );
};
