import { API } from '@config';
import { LEACTable } from '@types';

export const getLEACTableData = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/leac-rates-tables`, options);
  const data: LEACTable = await response.json();

  return data;
};
