import React from 'react';

export const Pagination = ({
  currentPage,
  itemsPerPage,
  paginate,
  totalItems,
}: {
  currentPage: number;
  itemsPerPage: number;
  paginate: any;
  totalItems: number;
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="py-2">
      <nav className="block">
        <ul className="flex pl-0 list-none flex-wrap">
          {pageNumbers.map((number) => (
            <li className="page-item" key={number}>
              <button
                className={
                  currentPage === number
                    ? 'text-blue bg-white border-gray-300  hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border rounded-sm text-sm font-medium'
                    : 'text-gray-500 hover:text-blue relative inline-flex items-center px-4 py-2 text-sm font-medium'
                }
                onClick={() => paginate(number)}
                type="button"
              >
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    // <nav>
    //   <ul className="pagination">
    //     {pageNumbers.map((number) => (
    //       <li className="page-item" key={number}>
    //         <a className="page-link" href="!#" onClick={() => paginate(number)}>
    //           {number}
    //         </a>
    //       </li>
    //     ))}
    //   </ul>
    // </nav>
  );
};
