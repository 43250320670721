export const PlanningDataLinks = {
  pageTitle: `Strategic Planning and Operations Research`,
  title: `Planning Data`,
  contents: [
    {
      title: `Hourly System Loads`,
      anchor: `Download 290 kB xls`,
    },
    {
      title: `Demand Forecast Scenario - Baseline 2006`,
      anchor: `Download 16 kB xls`,
    },
  ],
};