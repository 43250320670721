import { API } from '@config';
import { ContactUsDepartmentNumbersTable } from '@src/types/ContactUsDepartmentNumbers';

export const getDepartmentNumbers = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/contact-us-department-numbers?populate=File?sort=title`, options);
  const data: ContactUsDepartmentNumbersTable = await response.json();

  return data;
};
