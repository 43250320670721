export const SpecialProgramsContent = {
  pageTitle: `Special Programs`,
};

export const ApprenticeshipProgram = {
  title: `Apprenticeship Program`,
  linkText: 'About the Program',
  link: '/corporate/human-resources/special-programs/apprenticeship-program',
  ul: [
    {
      description: 'Report by Julie L. Quinata, Personnel Servicess Director - ',
      anchor: 'Download 44kB pdf',
    },
  ],
};

export const Engineering = {
  title: `Engineering`,
  ul: [
    {
      description:
        'CCU Res. No. 2006-27 approving the GPA policy for Engineering Scholarship Program - ',
      anchor: 'Download 35 kB pdf',
    },
    {
      description: 'Policy No. AP-068 and required documents - ',
      anchor: 'Download 542 kB pdf',
    },
  ],
};

export const TrainingAndDevelopmentSection = {
  title: 'Training and Development Section',
  linkText: 'About the Program',
  link: '/corporate/human-resources/special-programs/training-and-development',
};
