import { API } from '@config';
import { HrEmployeeBenefitsTable } from '@src/types/HrEmployeeBenefits';

export const getHrEmployeeBenefits = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/hr-employee-benefits?populate=File&sort=id%3Adesc`, options);
  const data: HrEmployeeBenefitsTable = await response.json();

  return data;
};
