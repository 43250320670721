import React, { FC, PropsWithChildren, HTMLAttributes } from 'react';

interface ListProp extends HTMLAttributes<HTMLLIElement> {
  className?: string;
}

type ListProps = PropsWithChildren<ListProp>;

export const List: FC<ListProps> = ({ children, className }, props) => {
  return (
    <li
      className={`font-rubik font-light text-black mb-2 ml-3 phone:text-sm tablet:text-base laptop:text-lg list-outside
      desktop:text-lg ${className}`}
      {...props}
    >
      {children}
    </li>
  );
};
