import React from 'react';

interface LearnMoreItemProps {
  icon: string;
  title: string;
  link: string;
}

export const LearnMoreItem: React.FC<LearnMoreItemProps> = ({ icon, title, link }) => {
  return (
    <div className="flex flex-col justify-center items-center w-52">
      <div className="h-28 w-28 flex items-center justify-center bg-darkblue rounded-full mb-5">
        <img alt="Bold" className="h-20" src={icon} />
      </div>
      <h2 className="font-rubik text-center text-black font-semibold mb-2 laptop:text-3xl tablet:text-2xl phone:text-xl">
        {title}
      </h2>
      <a
        className="font-rubik text-blue font-medium mb-5 laptop:text-xl tablet:text-lg phone:text-lg"
        href={link}
      >
        Learn More
      </a>
    </div>
  );
};
