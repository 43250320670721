import React, { FC, PropsWithChildren, HTMLAttributes } from 'react';

interface TableCellProp extends HTMLAttributes<HTMLHeadElement> {
  className?: string;
  rowType?: string;
  colSpan?: number;
  rowSpan?: number;
}

type TableCellProps = PropsWithChildren<TableCellProp>;

export const TableCell: FC<TableCellProps> = (
  { children, rowType, className, colSpan, rowSpan },
  props,
) => {
  switch (rowType) {
    case 'heading':
      return (
        <th
          className={`bg-blue text-white p-5 border border-white ${className}`}
          colSpan={colSpan}
          rowSpan={rowSpan}
          {...props}
        >
          {children}
        </th>
      );
    default:
      return (
        <td
          className={`bg-lightgray p-5 border border-white ${className}`}
          colSpan={colSpan}
          rowSpan={rowSpan}
          {...props}
        >
          {children}
        </td>
      );
  }
};

TableCell.defaultProps = {
  rowType: 'body',
};
