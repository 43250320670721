import { API } from '@config';
import { HrCurrentOpeningsTable } from '@src/types/HrCurrentOpenings';

export const getHrCurrentOpenings = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/hr-current-openings?populate=File&sort=id%3Adesc`, options);
  const data: HrCurrentOpeningsTable = await response.json();

  return data;
};
