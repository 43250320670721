export const DefinedBenefitPlan = {
  pageTitle: `Retirement Plans`,
  title: `Defined Benefit Plan`,
  contents: [
    `Employees hired on or before September 30, 1995`,
    `In general, all permanent Government of Guam employees hired on or before September 30, 1995 must participate in the Fund as a condition of employment, at their original date of entry into service. Inactive members, including retirees, generally must rejoin the Fund regardless of their age upon re-employment. Membership is optional for contract hire employees, employees of certain public corporations, and persons whose employment is purely temporary, seasonal, intermittent, or part-time.`,
    `Employees become eligible for certain benefits immediately upon entrance into government service. Failure to submit the required information may result in a denial of membership or loss of benefits. It is very important that complete processing for membership be accomplished. The proper forms for enrollment are normally provided by the Retirement Fund. Each new member should complete and/or submit the following documents for membership purposes:`,
  ],
  ol: [
    `Enrollment Application (upon processing).`,
    `Physical Examination (within 60 days).`,
    `Designation Beneficiary (upon processing).`,
    `Claims forms for Military or Previous Refunded Government of Guam Service (within 180 days of employment).`,
    `Claim form for active duty military service after May 1, 1951 (within 180 days for employees on August 2, 1988 or 60 days for those hired after August 22, 1988).`,
  ],
  outro: `You will also be asked to provide a copy of your birth certificate and those of your immediate family members.  All these documents may be submitted to the Retirement Fund.`,
};

export const ContributionRates = {
  title: `Contribution Rates`,
  description: `Members’ contribution rates are currently 9.5% of all regular pay. This rate commenced on 10/3/93. Contributions are automatically deducted from payroll checks and are withheld on an after-tax basis. Non-base pay, such as overtime night differential, lump sum annual leave payments upon termination or retirement, and salary from other employment in the GovGuam are exempt from retirement deduction for all new members after 3/29/93. Members, who filed non-base pay options prior to this date, can continue the so called "high option." The members’ rate on such pay is 10.5%. Contribution rates are set by law and are subject to change during membership.`,
};

export const CreditableService = {
  title: `Creditable Service`,
  description: `In addition to membership services for which contributions have not been refunded (or for which payment arrangement has been made: see REFUNDS OF CONTRIBUTIONS), the following may be credited for retirement purposes:`,
  ol: [
    `Military service prior to May 1, 1951.`,
    `Up to 3 or 5 years of military service rendered subsequent to May 1, 1951, if application is received before 02/18/89 or within 60 days of employment for newer members.`,
    `Up to 5 years of Federal Civil Service rendered subsequent to May 1, 1951, provided such service was not subject to Social Security coverage and membership began before 06/01/89.`,
    `Time spent for educational training purposes when the employee terminates or goes on leave of absence if membership began before 06/01/89.`,
    `Unused Sick and Annual Leave - 96 hours per month of credit.`,
    `Casual or temporary service rendered to Government of Guam or the Capital Improvement Project, if not originally subject to Social Security coverage. With the exception of service credit for Unused Sick and Annual Leave, members must apply and make contribution deposits to obtain these credits. Inquiries, claims for credit, or arrangements to pay in deposits of contributions should be made with the Members Services Staff of the Fund.`,
  ],
};

export const RetirementEligibility = {
  title: `Retirement Eligibility`,
  contents: [
    {
      intro: `There is no compulsory retirement.`,
      description: `Members entering service since October 1, 1981 may retire at their own request if they have:`,
      ul: [
        `30 years of total service regardless of age;`,
        `at least 5 years creditable service in the Government of Guam and are at least 60, or 55 in the case of uniformed personnel; or`,
        `25-29 years of total service.`,
      ],
    },
    {
      intro: `Under this plan (optional retirement) the computed annuity will be reduced by 3% for each year the member is below the age of 60 at his/her date of retirement.`,
      description: `Persons whose membership began before October 1, 1981 or who have made deposit arrangements and paid in full for Government of Guam services rendered prior to 10/1/81 can retire if they have:`,
      ul: [
        `25 years of total service regardless of age;`,
        `3 years of government service at age 60 or above; or`,
        `20-24 years of total service for optimal retirement subject to the aforementioned age reduction.`,
      ],
    },
  ],
  description: `Members entering service on or after August 22, 1984 may retire at their own request if they have:`,
  ul: [
    `30 years of total service regardless of age;`,
    `at least 5 years of creditable government service at age 65 or age 60 in the case of uniformed personnel; or`,
    `25-29 years of total service for optional retirement`,
  ],
};

export const DefinedContributionPlan = {
  title: `Defined Contribution Plan`,
  intro: `Employees hired by Government of Guam beginning October 1, 1995`,
  description: `If you were hired by Government of Guam beginning October 1, 1995, you are required to participate.
  Voluntary participation is also granted to any member with less than twenty (20) years service credit, recognized under the existing retirement system. The deadline for voluntary transfers is December 31, 1999.
  The Plan does not allow participation by employees who are:`,
  ul: [
    `Employees in the Defined Benefit Plan.`,
    `Persons whose services are compensated on a fee basis.`,
    `Independent contractors.`,
    `Persons whose employment is for a specific project.`,
    `Persons who are employed in the Senior Citizens Community Employment Program.`,
  ],
  contents: [
    {
      title: `Contribution Rates`,
      description: `Through payroll deduction, you can make pre-tax contributions to your 401 (a) plan from 5% of your eligible pay. Contributions are automatically deducted from payroll checks. Government of Guam makes a nondiscretionary contribution in an amount equal to 5% of your eligible compensation. These contributions benefit all participating employees.`,
    },
    {
      title: `Vesting`,
      description: `Vesting refers to the percentage of employer contributions a participant is entitled to receive upon distribution from the Plan. Your contributions are always 100% vested (including any rollover/transfer contributions you are allowed to make to the Plan), plus any earnings they generate. Employer contributions to the Plan, plus any earnings they generate, are vested as follows:`,
    },
  ],
  percentages: [
    {
      title: `Years of Service Vesting Percentage`,
      ul: [`5 or less, 0%`, `5 or more, 100%`],
    },
  ],
  options: [
    {
      title: `Investment Options`,
      description: `For your 401 (a) plan, you can direct your money into one or more of a variety of investment options managed by some of the most reputable and recognized names in the industry. These choices include variable investment options. The variable options provide variable returns, which do not guarantee principal and are not guaranteed as to a fixed dollar amount. The rate of return you receive on a variable option may increase or decrease depending upon the performance of the underlying investments in the portfolio.
      The maximum annual leave lump sum pay out is 320 hours.  For members of the Defined Benefit Plan, unused sick leave is converted into service credit.  For members of the Defined Contribution Plan, lump payment is made upon retirement for half of the members’ unused sick leave.
      For more information regarding your retirement plan, you may visit`,
      anchor: `www.ggrf.com`,
      link: `https://www.ggrf.com/`
    },
  ],
};
