import React, { useEffect, useState } from 'react';
import { H2, Details, Content as ContentContainer } from '@core';
import {
  ApprenticeshipProgram,
  Engineering,
  SpecialProgramsContent,
  TrainingAndDevelopmentSection,
} from '@content';
import { HeaderGradient } from '@components';
import { getFileData } from '@src/services';
import { FILEDATA } from '@types';
import { API } from '@src/config';

export const SpecialPrograms = () => {
  const [file, setFile] = useState<FILEDATA[]>([]);

  const getData = async () => {
    const fileDatas = await getFileData();
    setFile(fileDatas.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={SpecialProgramsContent.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="mr-20">
            <section>
              <H2>{ApprenticeshipProgram.title}</H2>
              <Details>
                <a className="text-orange" href={ApprenticeshipProgram.link}>
                  {ApprenticeshipProgram.linkText}
                </a>
                {ApprenticeshipProgram.ul.map((list, x) => {
                  return (
                    <div key={x}>
                      <span>{list.description}</span>
                      {file
                        .filter((fileData) => fileData.attributes.AnchorText === list.anchor)
                        .map((fileData, index) => {
                          return (
                            <a
                              className="text-orange"
                              href={`${API}${fileData.attributes.File.data.attributes.url}`}
                              key={index}
                              rel="noreferrer"
                              target="_blank"
                            >
                              {list.anchor}
                            </a>
                          );
                        })}
                    </div>
                  );
                })}
              </Details>
            </section>
            <section>
              <H2>{Engineering.title}</H2>
              <Details>
                {Engineering.ul.map((list, x) => {
                  return (
                    <div key={x}>
                      <span>{list.description}</span>
                      {file
                        .filter((fileData) => fileData.attributes.AnchorText === list.anchor)
                        .map((fileData, index) => {
                          return (
                            <a
                              className="text-orange"
                              href={`${API}${fileData.attributes.File.data.attributes.url}`}
                              key={index}
                              rel="noreferrer"
                              target="_blank"
                            >
                              {list.anchor}
                            </a>
                          );
                        })}
                    </div>
                  );
                })}
              </Details>
            </section>
            <section>
              <H2>{TrainingAndDevelopmentSection.title}</H2>
              <Details>
                <a className="text-orange" href={TrainingAndDevelopmentSection.link}>
                  {TrainingAndDevelopmentSection.linkText}
                </a>
              </Details>
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
