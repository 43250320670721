import { API } from '@config';
import { CorporateOperationsTable } from '@src/types/CorporateOperations';

export const getOperationsLeacBaseloadDispatch = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/operations-leac-baseload-dispatches?populate=File&sort=id%3Adesc`, options);
  const data: CorporateOperationsTable = await response.json();

  return data;
};
