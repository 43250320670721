export const ProcurMatManagement = {
  pageTitle: `Procurement`,
  title: `PROCUREMENT / MATERIALS MANAGEMENT `,
  subTitle: `Mission Statements:`,
  statements: [
    {
      title: `Purchasing Department Mission Statement`,
      description: `To properly acquire mission essentials most economical and efficient as possible while providing the necessary safeguards and ensuring availability and controls in support of Guam Power Authority's Mission.`,
    },
    {
      title: `Purchasing Department Vision Statement`,
      description: `To strive and achieve or exceed our Goals and Objectives with whatever means available and still provide the very best for our customers most deserving of our dedication.`,
    },
  ],
  links: [
    {
      anchor: `P/MM-Procurement Quick Reference`,
      link: ``,
    },
    {
      anchor: `P/MM-Inventory Management Quick Reference Guide`,
      link: ``,
    },
    {
      anchor: `P/MM-Supply Management Quick Reference Guide`,
      link: ``,
    },
    // {
    //   anchor: `Available Tenders`,
    //   link: `#available-tenders`,
    // },
    // {
    //   anchor: `Inventory & Non-Inventory Specifications`,
    //   link: `#technical-specifications`,
    // },
    // {
    //   anchor: `Existing Contracts`,
    //   link: `#gpa-contracts`,
    // },
    // Remove this as it is already on the top content of the page.
    // {
    //   anchor: `Supply Warehouse`,
    //   link: ``,
    // },
  ],
  personnel: [
    {
      name: `JAMIE LYNN C. PANGELINAN`,
      position: `Supply Management Administrator`,
      telephone: `T: 671-648-3054/55`,
      fax: `F: 671-648-3165`,
      email: `jpangelinan@gpagwa.com`,
      emailLink: `mailto:jpangelinan@gpagwa.com`,
    },
  ],
  hours: [
    {
      title: `HOURS OF OPERATIONS`,
      schedule: `Monday thru Friday, 8:00 A.M. - 5:00 P.M.`,
      closed: `Closed on Weekends and Holidays`,
    },
  ],
};

export const ProcAccordion = {
  statements: [
    {
      title: `Purchasing Department Mission Statement`,
      description: `To properly acquire mission essentials most economical and efficient as possible while providing the necessary safeguards and ensuring availability and controls in support of Guam Power Authority's Mission.`,
    },
    {
      title: `Purchasing Department Vision Statement`,
      description: `To strive and achieve or exceed our Goals and Objectives with whatever means available and still provide the very best for our customers most deserving of our dedication.`,
    },
  ],
  contacts: [
    {
      name: `JAMIE LYNN C. PANGELINAN`,
      position: `Supply Management Administrator`,
      telephone: `T: 671-648-3054/55`,
      fax: `F: 671-648-3165`,
      email: `jpangelinan@gpagwa.com`,
      emailLink: `mailto:jpangelinan@gpagwa.com`,
    },
    {
      name: `JOSEPHINA F. NAPUTI - P/MM Purchasing Management`,
      position: `Buyer Supervisor II`,
      telephone: `T: 671-648-3045/55`,
      fax: `F: 671-648-3165`,
      email: `jfnaputi@gpagwa.com`,
      emailLink: `mailto:jfnaputi@gpagwa.com`,
    },
    {
      name: `ANGELA BALAJADIA - P/MM Inventory/Supply Management`,
      position: `Inventory Management Officer`,
      telephone: `T: 671-635-2077`,
      fax: `F: 671-635-2070`,
      email: `abalajadia@gpagwa.com`,
      emailLink: `mailto:abalajadia@gpagwa.com`,
    },
    {
      name: `JANET D. PANGELINAN - P/MM-Administration`,
      position: `Administrative Officer`,
      telephone: `T: 671-648-3045/54/55`,
      fax: `F: 671-648-3165`,
      email: `janetdpangelinan@gpagwa.com`,
      emailLink: `mailto:janetdpangelinan@gpagwa.com`,
    },
    {
      name: `HOURS OF OPERATIONS - P/MM Procurement`,
      position: `Monday thru Friday, 8:00 A.M. - 5:00 P.M.`,
      telephone: `Closed on Weekends and Holidays`,
      fax: ``,
      email: ``,
      emailLink: ``,
    },
    {
      name: `HOURS OF OPERATIONS - P/MM-Inventory/Supply Management`,
      position: `Monday thru Friday, 7:00 A.M. - 3:30 P.M.`,
      telephone: `Lunch - 11:00 A.M. - 11:30 P.M.`,
      fax: `Closed on Weekends and Holidays`,
      email: ``,
      emailLink: ``,
    },
    {
      name: `DELIVERY TIME`,
      position: `9:00 A.M. - 10:30 A.M. (Morning)`,
      telephone: `12:00 P.M. - 2:30 P.M. (Afternoon)`,
      fax: ``,
      email: ``,
      emailLink: ``,
    },
  ],
  notice: [
    {
      intro: `To All Interested Parties:`,
      description: `Most pandemic-related mandates were lifted effective May 3, 2022. To ensure the continued safety and well-being of GPA personnel, prospective bidders, and guests, please be advised of the following: `,
      olMain: [
        `Virtual meetings and/or conferences are preferred and will be coordinated through the GPA Procurement Division.`,
        `For In-Person meetings and/or conferences:`,
      ],
      olSub: [
        `a. Limit of one (1) representative per prospective bidder when submitting any procurement proposal, and to attend public bid openings.`,
        `b. Face masks and social distancing may be employed at the option of the individual.`,
        `c. Persons displaying cold or flu-like symptopms will be asked to leave.`,
      ],
    },
  ],
  addons: [
    {
      anchor: `5 GCA (Guam Code Annotated)`,
      link: ``,
    },
    {
      anchor: `P.L. 32-131:1-5GCA §5141 “Training and Certification of Purchasing Personnel and Vendors”`,
      link: ``,
    },
    {
      anchor: `2 GAR (Guam Administrative Rules and Regulations)`,
      link: ``,
    },
    {
      anchor: `P.L. 36-25 (Ethics in Government Training)`,
      link: ``,
    },
    {
      anchor: `P.L. 36-26 (Women-Owned Business)`,
      link: ``,
    },
    {
      anchor: `P.L. 31-115 (Disabled Veteran Owned)`,
      link: ``,
    },
    {
      anchor: `P.L. 34-35 (Small Purchase)`,
      link: ``,
    },
    {
      anchor: `P.L. 36-35 (Sole Source/Emergency Procurement)`,
      link: ``,
    },
    {
      anchor: `P.L. 36-70 (Sole Source over 50k)`,
      link: ``,
    },
  ],
};

export const ProcTendersTable = {
  th: [
    `Type`,
    `Description (PDFs)`,
    `Announcement #`,
    `Issuance Date`,
    `Due Time & Date (Guam Chamorro Standard Time; ChST)`,
  ],
  tr: [
    {
      td: [
        {
          type: `BID`,
          description: [
            {
              title: [
                {
                  cellTitle: `2018, 2-Wheel Drive Steel Body Step Van`,
                  detail: ``,
                  schedule: ``,
                },
              ],
              file: [
                {
                  text: `Download 1.749 MB`,
                  link: ``,
                },
                {
                  text: `Download Amendment No. I 180 KB`,
                  link: ``,
                },
              ],
            },
          ],
          announcement: [`GPA-081-18`],
          date: `5/31/2018`,
          due: [`"STAY OF PROCUREMENT"`],
        },
      ],
    },
    {
      td: [
        {
          type: `BID`,
          description: [
            {
              title: [
                {
                  cellTitle: `Vacuum Circuit Breakers, 13.8kV`,
                  detail: `Pre-Bid / Site Visit (Non-Mandatory):`,
                  schedule: `10:00 A.M., Thursday, April 7, 2022 (CHST)`,
                },
                {
                  cellTitle: ``,
                  detail: `Cut-Off Date for Receipt of Questions:`,
                  schedule: `5:00 P.M., Tuesday, April 12, 2022 (CHST)`,
                },
              ],
              file: [
                {
                  text: `Download 706 KB`,
                  link: ``,
                },
              ],
            },
          ],
          announcement: [`RE-BID`, `GPA-011-22`],
          date: `03/29/2022`,
          due: [`10:00 A.M.`, `04/26/2022`],
        },
      ],
    },
    {
      td: [
        {
          type: `BID`,
          description: [
            {
              title: [
                {
                  cellTitle: `Voice and Data Services`,
                  detail: ``,
                  schedule: ``,
                },
              ],
              file: [
                {
                  text: `Download 579 KB`,
                  link: ``,
                },
                {
                  text: `Download Amendment No. I 17 KB`,
                  link: ``,
                },
              ],
            },
          ],
          announcement: [`GPA-043-20`],
          date: `06/02/2020`,
          due: [`"STAY OF PROCUREMENT"`],
        },
      ],
    },
    {
      td: [
        {
          type: `BID`,
          description: [
            {
              title: [
                {
                  cellTitle: `MISCELLANEOUS STREETLIGHT HEADS`,
                  detail: ``,
                  schedule: ``,
                },
              ],
              file: [
                {
                  text: `Download 1.103 MB`,
                  link: ``,
                },
              ],
            },
          ],
          announcement: [`GPA-028-22`],
          date: `04/21/2022`,
          due: [`10:00 A.M.`, `05/05/2022`],
        },
      ],
    },
    {
      td: [
        {
          type: `BID`,
          description: [
            {
              title: [
                {
                  cellTitle: `Fuse, Cutout`,
                  detail: ``,
                  schedule: ``,
                },
              ],
              file: [
                {
                  text: `Download 375 KB`,
                  link: ``,
                },
                {
                  text: `Download Amendment No. I 88 KB`,
                  link: ``,
                },
                {
                  text: `Download Amendment No. II 114 KB`,
                  link: ``,
                },
                {
                  text: `Download Amendment No. III 146 KB`,
                  link: ``,
                },
              ],
            },
          ],
          announcement: [`GPA-030-22`],
          date: `03/22/2022`,
          due: [`11:00 A.M.`, `04/27/2022`],
        },
      ],
    },
    {
      td: [
        {
          type: `BID`,
          description: [
            {
              title: [
                {
                  cellTitle: `New Turbo Charger Complete Assembly`,
                  detail: ``,
                  schedule: ``,
                },
              ],
              file: [
                {
                  text: `Download 939 KB`,
                  link: ``,
                },
                {
                  text: `Download Amendment No. I 760 KB`,
                  link: ``,
                },
              ],
            },
          ],
          announcement: [`GPA-031-22`],
          date: `03/29/2022`,
          due: [`10:00 A.M.`, `04/29/2022`],
        },
      ],
    },
    {
      td: [
        {
          type: `BID`,
          description: [
            {
              title: [
                {
                  cellTitle: `Services for the Transfer and Hauling of Used Oil from Tanks Located at Various GPA Locations to Guam Port`,
                  detail: ``,
                  schedule: ``,
                },
              ],
              file: [
                {
                  text: `Download 977 KB`,
                  link: ``,
                },
                {
                  text: `Amendment I`,
                  link: ``,
                },
              ],
            },
          ],
          announcement: [`GPA-032-22`],
          date: `03/29/2022`,
          due: [`11:00 A.M.`, `04/21/2022`],
        },
      ],
    },
    {
      td: [
        {
          type: `BID`,
          description: [
            {
              title: [
                {
                  cellTitle: `Unarmed Uniformed Security Guard Services`,
                  detail: `Pre-Bid / Site Visit (Non-Mandatory):`,
                  schedule: `9:00 A.M., Thursday, April 21, 2022 (CHST)`,
                },
                {
                  cellTitle: ``,
                  detail: `Cut-Off Date for Receipt of Questions:`,
                  schedule: `5:00 P.M., Friday, April 22, 2022 (CHST)`,
                },
              ],
              file: [
                {
                  text: `Download 667 KB`,
                  link: ``,
                },
              ],
            },
          ],
          announcement: [`GPA-033-22`],
          date: `04/12/2022`,
          due: [`10:00 A.M.`, `05/06/2022`],
        },
      ],
    },
    {
      td: [
        {
          type: `Multi-step`,
          description: [
            {
              title: [
                {
                  cellTitle: `Supply of Diesel Fuel Oil No. 2 for GPA’s Water Systems Distribution (WSD) & GWA Emergency Generators`,
                  detail: `Pre-Bid Conference (Non-Mandatory):`,
                  schedule: `10:00 A.M., Friday, March 25, 2022 (CHST)`,
                },
                {
                  cellTitle: ``,
                  detail: `Cut-Off Date for Receipt of Questions:`,
                  schedule: `5:00 P.M., Friday, April 8, 2022 (CHST)`,
                },
              ],
              file: [
                {
                  text: `Download 1,013 KB`,
                  link: ``,
                },
              ],
            },
          ],
          announcement: [`RE-BID`, `GPA-012-22`],
          date: `03/17/2022`,
          due: [`2:00 P.M.`, `04/29/2022`, `(Step 1 Technical & Price Proposal)`],
        },
      ],
    },
    {
      td: [
        {
          type: `Multi-step`,
          description: [
            {
              title: [
                {
                  cellTitle: `Performance Management Contract for the Guam Power Authority Fuel Bulk Storage Facility`,
                  detail: `Pre-Bid Conference (Non-Mandatory):`,
                  schedule: `9:00 A.M., Tuesday, January 18, 2022`,
                },
                {
                  cellTitle: ``,
                  detail: `Site Visit (Non-Mandatory):`,
                  schedule: `9:00 A.M., Wednesday, January 26, 2022`,
                },
                {
                  cellTitle: ``,
                  detail: `Cut-Off Date for Receipt of Questions:`,
                  schedule: `4:00 P.M., Friday, April 22, 2022`,
                },
              ],
              file: [
                {
                  text: `Download 5.608 MB`,
                  link: ``,
                },
                {
                  text: `Download Amendment No. I 366 KB`,
                  link: ``,
                },
                {
                  text: `Download Amendment No. II 361 KB`,
                  link: ``,
                },
                {
                  text: `Download Amendment No. III 252 KB`,
                  link: ``,
                },
                {
                  text: `Download Amendment IV 357KB`,
                  link: ``,
                },
                {
                  text: `Download Qualitative Proposal Scoring Worksheet 87 KB`,
                  link: ``,
                },
                {
                  text: `Download Price Proposal Worksheet 136 KB`,
                  link: ``,
                },
                {
                  text: `Download Amendment V 57.60 MB`,
                  link: ``,
                },
                {
                  text: `Download MS IFB GPA-015-22 STEP 2 (PriceProposalWorksheet)- attachment Amd. V 80 KB`,
                  link: ``,
                },
                {
                  text: `Download Amendment VI 200 KB`,
                  link: ``,
                },
                {
                  text: `Download Amendment VII 458 KB`,
                  link: ``,
                },
                {
                  text: `Download Step 2 Price Proposal Worksheet-attachment VII 80 KB`,
                  link: ``,
                },
              ],
            },
          ],
          announcement: [`GPA-015-22`],
          date: `01/06/2022`,
          due: [`4:00 P.M.`, `04/22/2022`, `(Step 1 Technical & Price Proposal)`],
        },
      ],
    },
    {
      td: [
        {
          type: `RFP`,
          description: [
            {
              title: [
                {
                  cellTitle: `Engineering, Procurement & Construction Management (EPCM) Contract`,
                  detail: `Cut-Off Date for Receipt of Questions:`,
                  schedule: `4:00 P.M., Tuesday, April 19, 2022`,
                },
              ],
              file: [
                {
                  text: `Download 560.93 KB`,
                  link: ``,
                },
                {
                  text: `Download Amendment No. I 161 KB`,
                  link: ``,
                },
                {
                  text: `Download Amendment No. II 225 KB`,
                  link: ``,
                },
                {
                  text: `Download Amendment No. III 150 KB`,
                  link: ``,
                },
              ],
            },
          ],
          announcement: [`RE-SOLICITATION`, `GPA-RFP-21-010`],
          date: `03/24/2022`,
          due: [`4:00 P.M.`, `05/06/2022`],
        },
      ],
    },
    {
      td: [
        {
          type: `RFP`,
          description: [
            {
              title: [
                {
                  cellTitle: `GPWA Customer Information System (CIS) System Support`,
                  detail: `Cut-Off Date for Receipt of Questions:`,
                  schedule: `4:00 P.M., Thursday, May 05, 2022`,
                },
              ],
              file: [
                {
                  text: `Download 832 KB`,
                  link: ``,
                },
              ],
            },
          ],
          announcement: [`GPA-RFP-22-003`],
          date: `04/21/2022`,
          due: [`4:00 P.M.`, `05/19/2022`],
        },
      ],
    },
    {
      td: [
        {
          type: `RFP`,
          description: [
            {
              title: [
                {
                  cellTitle: `Utility Resource Planning Software`,
                  detail: `Cut-Off Date for Receipt of Questions:`,
                  schedule: `4:00 P.M., (ChST) Thursday, May 05, 2022`,
                },
              ],
              file: [
                {
                  text: `Download 1.689 MB`,
                  link: ``,
                },
              ],
            },
          ],
          announcement: [`GPA-RFP-22-004`],
          date: `04/21/2022`,
          due: [`4:00 P.M. (ChST)`, `05/19/2022`],
        },
      ],
    },
  ],
};

export const ProcBidsTable = {
  th: [`Bid/RFP Number`, `Title`, `Award Date`, `Date Posted`, `Open/Close Date`, `	Awardee`],
  tr: [
    {
      td: [
        {
          number: `GPA-001-22`,
          titles: [
            {
              name: `Pad Mounted Transformers`,
              link: [
                {
                  text: `IFB issuance`,
                  href: ``,
                },
              ],
            },
          ],
          awardDate: `February 16, 2022`,
          datePosted: `October 28, 2021`,
          openCloseDates: [
            {
              date: `November 23, 2021`,
              text: `Bid Abstract`,
              href: ``,
            },
          ],
          awardee: [
            {
              name: `Benson Guam Enterprises Inc. OP 29944`,
              text: `Awarded OP`,
              href: ``,
            },
          ],
        },
      ],
    },
    {
      td: [
        {
          number: `GPA-002-22`,
          titles: [
            {
              name: `Pad Mounted Transformers`,
              link: [
                {
                  text: `IFB issuance`,
                  href: ``,
                },
              ],
            },
          ],
          awardDate: `March 3, 2022`,
          datePosted: `October 28, 2021`,
          openCloseDates: [
            {
              date: `November 23, 2021`,
              text: `Bid Abstract`,
              href: ``,
            },
          ],
          awardee: [
            {
              name: `Benson Guam Enterprises Inc. OP 29888`,
              text: `Awarded OP`,
              href: ``,
            },
          ],
        },
      ],
    },
    {
      td: [
        {
          number: `GPA-003-22`,
          titles: [
            {
              name: `Wires`,
              link: [
                {
                  text: `IFB issuance`,
                  href: ``,
                },
              ],
            },
          ],
          awardDate: `February 18, 2022`,
          datePosted: `October 28, 2021`,
          openCloseDates: [
            {
              date: `November 16, 2021`,
              text: `Bid Abstract`,
              href: ``,
            },
          ],
          awardee: [
            {
              name: `Benson Guam Enterprises Inc. OP 29950`,
              text: `Awarded OP`,
              href: ``,
            },
          ],
        },
      ],
    },
    {
      td: [
        {
          number: `GPA-004-22`,
          titles: [
            {
              name: `Wire`,
              link: [
                {
                  text: `Link for IFB issuance`,
                  href: ``,
                },
              ],
            },
          ],
          awardDate: `March 24, 2022`,
          datePosted: `November 09, 2021`,
          openCloseDates: [
            {
              date: `November 30, 2021`,
              text: `Link for Bid abstract`,
              href: ``,
            },
          ],
          awardee: [
            {
              name: `Benson Guam Enterprises Inc. OP 30531`,
              text: `Link for OP`,
              href: ``,
            },
          ],
        },
      ],
    },
    {
      td: [
        {
          number: `GPA-006-22`,
          titles: [
            {
              name: `Wire`,
              link: [
                {
                  text: `Link for IFB issuance`,
                  href: ``,
                },
              ],
            },
          ],
          awardDate: `March 28, 2022`,
          datePosted: `November 18, 2021`,
          openCloseDates: [
            {
              date: `December 14, 2021`,
              text: `Link for Bid abstract`,
              href: ``,
            },
          ],
          awardee: [
            {
              name: `Benson Guam Enterprises Inc. OP 30345`,
              text: `Link for OP`,
              href: ``,
            },
          ],
        },
      ],
    },
  ],
};

export const TechnicalDocuments = {
  title: `GPA Technical Documents`,
  subTitle: `INVENTORY & NON-INVENTORY TECHNICAL SPECIFICATIONS`,
  description: `Below are GPA Engineering Specifications. If you are interested in a particular specification, be certain to click on the specific link to the GPA Specification Number.`,
  links: [
    {
      anchor: `P/MM-Inventory Management Quick Reference Guide`,
      link: ``,
    },
    {
      anchor: `P/MM-Supply Management Quick Reference Guide`,
      link: ``,
    },
  ],
  personnel: [
    {
      name: `JAMIE LYNN C. PANGELINAN`,
      position: `Supply Management Administrator`,
      telephone: `T: 671-648-3054/55`,
      fax: `F: 671-648-3165`,
      email: `jpangelinan@gpagwa.com`,
      emailLink: `mailto:jpangelinan@gpagwa.com`,
    },
    {
      name: `ANGELA BALAJADIA`,
      position: `Inventory Management Officer`,
      telephone: `T: 671-635-2077`,
      fax: `F: 671-635-2070`,
      email: `abalajadia@gpagwa.com`,
      emailLink: `mailto:abalajadia@gpagwa.com`,
    },
    {
      name: `JOSHUA C. JIMENEZ - DEDEDO SUPPLY WAREHOUSE`,
      position: `Warehouse Supervisor II`,
      telephone: `T: 671-635-2073/4`,
      fax: `F: 671-635-2070`,
      email: `jcjimenez@gpagwa.com`,
      emailLink: `mailto:jcjimenez@gpagwa.com`,
      note: `Call to schedule delivery`,
    },
    {
      name: `EDWARD V. VILLANUEVA - CABRAS/TRANSPORTATION SUPPLY WAREHOUSE`,
      position: `Warehouse Supervisor II`,
      telephone: `T: 671-475-5319`,
      fax: `F: 671-475-5319`,
      email: `evillanueva@gpagwa.com`,
      emailLink: `mailto:evillanueva@gpagwa.com`,
      note: `Call to schedule delivery`,
    },
    {
      name: `HOURS OF OPERATIONS/RECEIVING SECTION`,
      position: `Monday thru Friday, 7:00 A.M. - 3:30 P.M.`,
      telephone: `Lunch - 11:00 A.M. - 11:30 P.M.`,
      fax: `Closed on Weekends and Holidays`,
      email: ``,
      emailLink: ``,
    },
    {
      name: `DELIVERY TIME`,
      position: `9:00 A.M. - 10:30 P.M. (Morning)`,
      telephone: `12:00 P.M. - 2:30 P.M. (Afternoon)`,
      fax: ``,
      email: ``,
      emailLink: ``,
    },
  ],
};

export const InventorySpecsTable = {
  title: `1. GPA INVENTORY SPECIFICATIONS`,
  th: [`Specification Number/pdf`, `Dimensions`],
  tr: [
    {
      td: [
        {
          name: `E-001 Rev.2 377 kB pdf`,
          link: ``,
          dimension: `15 kV Underground Power Cable, Single Conductor with Concentric Neutral, Type MV-90, Extruded TR-XLPE Insulation`,
        },
      ],
    },
    {
      td: [
        {
          name: `E-002 Rev.1 513 kB pdf`,
          link: ``,
          dimension: `Connectors; 15 kV Load Break Separable`,
        },
      ],
    },
    {
      td: [
        {
          name: `E-003 Rev.11 451 kB pdf`,
          link: ``,
          dimension: `Single Phase Pad Mounted Distribution Transformer`,
        },
      ],
    },
    {
      td: [
        {
          name: `E-004 Rev.9 358 kB pdf`,
          link: ``,
          dimension: `Three Phase Pad Mounted Distribution Transformer`,
        },
      ],
    },
    {
      td: [
        {
          name: `E-005 Rev.4 224 kB pdf`,
          link: ``,
          dimension: `High Pressure Sodium Luminaries and Mast Arms`,
        },
      ],
    },
  ],
};

export const NonInventorySpecsTable = {
  title: `2. GPA NON-INVENTORY SPECIFICATIONS`,
  th: [`Specification Number/pdf`, `Dimensions`],
  tr: [
    {
      td: [
        {
          name: `E-006 Rev.1 157 kB pdf`,
          link: ``,
          dimension: `34.5 kV Station Type Surge Arresters`,
        },
      ],
    },
    {
      td: [
        {
          name: `E-007 Rev.2 1.04 MB pdf`,
          link: ``,
          dimension: `Potential Transformer, Outdoor Type`,
        },
      ],
    },
    {
      td: [
        {
          name: `E-017 Rev.5 1.781 MB pdf`,
          link: ``,
          dimension: `34.5 kV AC Power Gas Circuit Breakers Outdoor Type`,
        },
      ],
    },
    {
      td: [
        {
          name: `E-020 Rev.3 712 kB pdf`,
          link: ``,
          dimension: `Pole Mounted Distribution Capacitors`,
        },
      ],
    },
    {
      td: [
        {
          name: `E-025 Rev.2 604 kB pdf`,
          link: ``,
          dimension: `Control & Relay Panel`,
        },
      ],
    },
  ],
};

export const ProcurementContracts = {
  approval: [
    {
      title: {
        name: `GENERAL MANAGER APPROVAL $0.01 - $1,000,000.00`,
        anchor: `Resolution 2019-12`,
        link: ``,
      },
      th: [
        `Vendor and Contract Description`,
        `Base Contract Length and Optional Contract Extensions`,
        `Contract PDF Link`,
        `Purchase Order PDF Link`,
      ],
      tr: [
        {
          td: [
            {
              description: ``,
              years: `No data available in table`,
              pdf: ``,
              pdfLink: ``,
              order: ``,
              orderLink: ``,
            },
          ],
        },
      ],
    },
  ],
  utilities1: [
    {
      title: {
        name: `CONSOLIDATED COMMISSION ON UTILITIES`,
        anchor: `Resolution 2019-12`,
        link: ``,
      },
      th: [
        `Vendor and Contract Description`,
        `Base Contract Length and Optional Contract Extensions`,
        `Contract PDF Link`,
        `Purchase Order PDF Link`,
      ],
      tr: [
        {
          td: [
            {
              description: ``,
              years: `No data available in table`,
              pdf: ``,
              pdfLink: ``,
              order: ``,
              orderLink: ``,
            },
          ],
        },
      ],
    },
  ],
  utilities2: [
    {
      title: {
        name: `PUBLIC UTILITIES COMMISSION (PUC) APPROVAL - $1,500,000.00 - AND ABOVE`,
        anchor: ``,
        link: ``,
      },
      th: [
        `Vendor and Contract Description`,
        `Base Contract Length and Optional Contract Extensions`,
        `Contract PDF Link`,
        `Purchase Order PDF Link`,
      ],
      tr: [
        {
          td: [
            {
              description: `Independent Power Producer - Pruvient Energy (originally contracted with Hawaiian Electric Inc.)`,
              years: `20 Years`,
              pdf: `Energy Conversion Agreement - Tanguisson (HEI)`,
              pdfLink: ``,
              order: ``,
              orderLink: ``,
            },
          ],
        },
        {
          td: [
            {
              description: `Independent Power Producer - Marianas Electric Company (formerly ENRON/TOMEN)`,
              years: `20 Years`,
              pdf: `Energy Conversion Agreement - MEC (ENRON)`,
              pdfLink: ``,
              order: ``,
              orderLink: ``,
            },
          ],
        },
        {
          td: [
            {
              description: `Independent Power Producer - Taiwan Electrical & Mechanical Engineering Services, Inc. (TEMES)`,
              years: `20 Years`,
              pdf: `Energy Conversion Agreement - TEMES`,
              pdfLink: ``,
              order: ``,
              orderLink: ``,
            },
          ],
        },
        {
          td: [
            {
              description: `Residual Fuel Oil Supply Contract`,
              years: `3 Years with option of Two 1-Year Terms, Renewed Annually`,
              pdf: `RFO Supply Contract - (4MB, pdf)`,
              pdfLink: ``,
              order: ``,
              orderLink: ``,
            },
          ],
        },
        {
          td: [
            {
              description: `Diesel Fuel Oil Supply Contract (Fast Tracks, Baseloads, TEMES CT, Tenjo Vista)`,
              years: `3 Years with option of Two 1-Year Terms, Renewed Annually`,
              pdf: `DFO Supply Contract (FT,Baseloads,TEM CT,Tenjo) - (4MB, pdf)`,
              pdfLink: ``,
              order: ``,
              orderLink: ``,
            },
          ],
        },
        {
          td: [
            {
              description: `Diesel Fuel Oil Supply Contract (CT1, CT2, Marbo, Mach, Yigo, Ded Dsl)`,
              years: `3 Years with option of Two 1-Year Terms, Renewed Annually`,
              pdf: `DFO Supply Contract (CT1, CT2,Marbo,Mach,Yigo,DedDsl) - (4.4MB, pdf)`,
              pdfLink: ``,
              order: ``,
              orderLink: ``,
            },
          ],
        },
      ],
    },
  ],
};

export const AwardedSoleSource = {
  title: `Procurement / Materials Management - Awarded Sole Source`,
  anchor: `Awarded Sole Source P.L. 36-70`,
  link: ``,
  contacts: [
    {
      name: `JAMIE LYNN C. PANGELINAN`,
      position: `Supply Management Administrator`,
      telephone: `T: 671-648-3054/55`,
      fax: `F: 671-648-3165`,
      email: `jpangelinan@gpagwa.com`,
      emailLink: `mailto:jpangelinan@gpagwa.com`,
    },
    {
      name: `JOSEPHINA F. NAPUTI - P/MM Purchasing Management`,
      position: `Buyer Supervisor II`,
      telephone: `T: 671-648-3045/55`,
      fax: `F: 671-648-3165`,
      email: `jfnaputi@gpagwa.com`,
      emailLink: `mailto:jfnaputi@gpagwa.com`,
    },
  ],
};
