import React from 'react';
import { H1, H2, List, UnorderedList } from '@core';
import { NewPowerPlantProjects as Content } from '@content';

export const NewPowerPlantProject = () => {
  return (
    <>
      {Content.contents.map((content) => {
        return (
          <>
            <H1>{content.title}</H1>
            <H2>{content.heading}</H2>
            <UnorderedList>
              {content.ul?.map((value) => {
                return <List key={value}>{value}</List>;
              })}
            </UnorderedList>
          </>
        );
      })}
    </>
  );
};
