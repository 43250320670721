import { API } from '@config';
import { GpaInitiativesLinksTable } from '@src/types/GpaInitiativesLinks';

export const getGpaInitiativesOtherLinks = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/gpa-initiatives-other-links?populate=File&sort=id%3Adesc`, options);
  const data: GpaInitiativesLinksTable = await response.json();

  return data;
};
