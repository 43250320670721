import React, { Fragment, useEffect, useState } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { FaChevronUp } from 'react-icons/fa';
import {
  H1,
  H2,
  Details,
  Table,
  OrderedList,
  List,
  TableCell as TD,
  Content as ContentContainer,
  // UnorderedList,
} from '@core';
import {
  ProcurMatManagement,
  ProcAccordion,
  ProcTendersTable,
  ProcBidsTable,
  TechnicalDocuments,
  InventorySpecsTable,
  NonInventorySpecsTable,
  ProcurementContracts,
  AwardedSoleSource,
  // EnergyAdjustment,
  // LEACCosts,
  // Fuels,
  // Others,
} from '@content';
import { HeaderGradient } from '@components';
import {
  getTendersTableData,
  getBidsTableData,
  getInventoryTableData,
  getNonInventoryTableData,
  getGenApprovalTableData,
  getCommissionTableData,
  getPOCTableData,
  getFileData,
} from '@services';
import {
  TENDERSDATA,
  BIDSDATA,
  INVENTORYDATA,
  NONINVENTORYDATA,
  GENAPPROVALDATA,
  FILEDATA,
} from '@types';
import { API } from '@src/config';

export const Procurement = () => {
  // const [activeIndex, setActiveIndex] = useState(1);

  const [tendersTableData, setTendersTableData] = useState<TENDERSDATA[]>([]);
  const [bidsTableData, setBidsTableData] = useState<BIDSDATA[]>([]);
  const [inventoryTableData, setInventoryTableData] = useState<INVENTORYDATA[]>([]);
  const [nonInventoryTableData, setNonInventoryTableData] = useState<NONINVENTORYDATA[]>([]);
  const [genApprovalTableData, setGenApprovalTableData] = useState<GENAPPROVALDATA[]>([]);
  const [commissionTableData, setCommissionTableData] = useState<GENAPPROVALDATA[]>([]);
  const [pocTableData, setPOCTableData] = useState<GENAPPROVALDATA[]>([]);
  const [file, setFile] = useState<FILEDATA[]>([]);

  const getData = async () => {
    const tenderTableDatas = await getTendersTableData();
    const bidsTableDatas = await getBidsTableData();
    const inventoryTableDatas = await getInventoryTableData();
    const nonInventoryTableDatas = await getNonInventoryTableData();
    const genApprovalTableDatas = await getGenApprovalTableData();
    const commissionTableDatas = await getCommissionTableData();
    const pocTableDatas = await getPOCTableData();
    const fileDatas = await getFileData();
    setTendersTableData(tenderTableDatas.data);
    setBidsTableData(bidsTableDatas.data);
    setInventoryTableData(inventoryTableDatas.data);
    setNonInventoryTableData(nonInventoryTableDatas.data);
    setGenApprovalTableData(genApprovalTableDatas.data);
    setCommissionTableData(commissionTableDatas.data);
    setPOCTableData(pocTableDatas.data);
    setFile(fileDatas.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={ProcurMatManagement.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-full">
            <section className="mb-24">
              <H1 className="mb-5">
                PROCUREMENT / MATERIALS MANAGEMENT - PROCUREMENT AVAILABLE TENDERS
              </H1>
              <div className="flex flex-col justify-center w-full">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between p-4 mt-2 bg-bluegray text-left">
                        MISSION STATEMENTS
                        <FaChevronUp className={`${open ? '' : 'transform rotate-180'}`} />
                      </Disclosure.Button>
                      <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel className="shadow-3xl rounded-2xl shadow-cyan-500/50 p-4 mb-6">
                          {ProcAccordion.statements.map((content) => {
                            return (
                              <>
                                <H2>{content.title}</H2>
                                <Details>{content.description}</Details>
                              </>
                            );
                          })}
                          {/* <OrderedList className="list-inside px-6">
                            {ProcurMatManagement.links.map((content) => {
                              return (
                                <>
                                  <List className="text-orange">
                                    {file
                                      .filter(
                                        (fileData) =>
                                          fileData.attributes.AnchorText === content.anchor,
                                      )
                                      .map((fileData) => {
                                        return (
                                          <a
                                            className="text-orange"
                                            href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                            key={content.anchor}
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            {content.anchor}
                                          </a>
                                        );
                                      })}
                                  </List>
                                </>
                              );
                            })}
                          </OrderedList> */}
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between p-4 mt-2 bg-bluegray text-left">
                        CONTACT INFORMATION & HOURS
                        <FaChevronUp className={`${open ? '' : 'transform rotate-180'}`} />
                      </Disclosure.Button>
                      <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel className="shadow-3xl rounded-2xl shadow-cyan-500/50 p-4 mb-6">
                          {ProcAccordion.contacts.map((content) => {
                            return (
                              <>
                                <H2>{content.name}</H2>
                                <Details>
                                  {content.position}
                                  <br />
                                  {content.telephone}
                                  <br />
                                  {content.fax}
                                  <br />
                                  <a className="text-orange" href={content.emailLink}>
                                    {content.email}
                                  </a>
                                </Details>
                              </>
                            );
                          })}
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between p-4 mt-2 bg-bluegray text-left">
                        GLOBAL NOTICE: COVID-19 PANDEMIC
                        <FaChevronUp className={`${open ? '' : 'transform rotate-180'}`} />
                      </Disclosure.Button>
                      <Transition
                        enter="transition duration-100 "
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel className="shadow-3xl rounded-2xl shadow-cyan-500/50 p-4 mb-6">
                          {ProcAccordion.notice.map((content) => {
                            return (
                              <>
                                <Details>{content.description}</Details>
                                <OrderedList className="list-inside pl-6">
                                  {content.olMain.map((value) => {
                                    return (
                                      <>
                                        <List key={value}>{value}</List>
                                      </>
                                    );
                                  })}
                                  {content.olSub.map((value) => {
                                    return (
                                      <>
                                        <Details className="list-inside pl-6" key={value}>
                                          {value}
                                        </Details>
                                      </>
                                    );
                                  })}
                                </OrderedList>
                              </>
                            );
                          })}
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between p-4 mt-2 bg-bluegray text-left">
                        ADD-ON LINKS
                        <FaChevronUp className={`${open ? '' : 'transform rotate-180'}`} />
                      </Disclosure.Button>
                      <Transition
                        enter="transition duration-100 "
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel className="shadow-3xl rounded-2xl shadow-cyan-500/50 p-4 mb-6">
                          <OrderedList className="list-inside px-2">
                            {ProcAccordion.addons.map((content) => {
                              return (
                                <>
                                  <List className="text-orange">
                                    {file
                                      .filter(
                                        (fileData) =>
                                          fileData.attributes.AnchorText === content.anchor,
                                      )
                                      .map((fileData) => {
                                        return (
                                          <a
                                            className="text-orange"
                                            href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                            key={content.anchor}
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            {content.anchor}
                                          </a>
                                        );
                                      })}
                                  </List>
                                </>
                              );
                            })}
                          </OrderedList>
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>
            </section>
            <section className="mb-24">
              <H1 className="mb-5">
                PROCUREMENT / MATERIALS MANAGEMENT - STAY OF PROCUREMENT BIDS AND RFPs
              </H1>
              <Table>
                <tr>
                  {ProcTendersTable.th.map((heading) => {
                    return (
                      <TD className="text-left" key={heading} rowType="heading">
                        {heading}
                      </TD>
                    );
                  })}
                </tr>
                {tendersTableData.map((cell) => {
                  return (
                    <>
                      <tr>
                        <TD>{cell.attributes.Type}</TD>
                        <TD>
                          <H2 className="">{cell.attributes.DescriptionTitle}</H2>
                          <Details>
                            {cell.attributes.DescriptionLine1}
                            <br />
                            {cell.attributes.DescriptionLine2}
                          </Details>
                          {cell.attributes.File.data.map((TableFile) => {
                            return (
                              <Details key={TableFile.id}>
                                <a
                                  href={`${API}${TableFile.attributes.url}`}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <u>{TableFile.attributes.caption}</u>
                                  <br />
                                </a>
                              </Details>
                            );
                          })}
                        </TD>
                        <TD>{cell.attributes.Announcement}</TD>
                        <TD>{cell.attributes.Date}</TD>
                        <TD>
                          <H2>{cell.attributes.Due}</H2>
                        </TD>
                      </tr>
                    </>
                  );
                })}
              </Table>
            </section>
            <section className="mb-24">
              <div>
                <H1 className="mb-5">PROCUREMENT / MATERIALS MANAGEMENT - AWARDED BIDS AND RFP</H1>
                {ProcurMatManagement.personnel.map((content) => {
                  return (
                    <>
                      <H2>{content.name}</H2>
                      <Details>
                        {content.position}
                        <br />
                        {content.telephone}
                        <br />
                        {content.fax}
                        <br />
                        Email:&nbsp;
                        <a className="text-orange" href={content.emailLink}>
                          {content.email}
                        </a>
                      </Details>
                    </>
                  );
                })}
              </div>
              <div>
                <Table className="-my-2">
                  <tr>
                    {ProcBidsTable.th.map((heading) => {
                      return (
                        <TD className="text-left" key={heading} rowType="heading">
                          {heading}
                        </TD>
                      );
                    })}
                  </tr>
                  {bidsTableData.map((cell) => {
                    return (
                      <>
                        <tr>
                          <TD>{cell.attributes.Bid}</TD>
                          <TD>
                            <H2 className="">{cell.attributes.Title}</H2>
                            {cell.attributes.TitleFile.data.map((TableFile) => {
                              return (
                                <Details key={TableFile.id}>
                                  <a
                                    href={`${API}${TableFile.attributes.url}`}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    <u>{TableFile.attributes.caption}</u>
                                    <br />
                                  </a>
                                </Details>
                              );
                            })}
                          </TD>
                          <TD>{cell.attributes.AwardDate}</TD>
                          <TD>{cell.attributes.DatePosted}</TD>
                          <TD>
                            {cell.attributes.OpenCloseDate} <br />
                            {cell.attributes.OpenCloseDateFile.data.map((TableFile) => {
                              return (
                                <Details key={TableFile.id}>
                                  <a
                                    href={`${API}${TableFile.attributes.url}`}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    <u>{TableFile.attributes.caption}</u>
                                    <br />
                                  </a>
                                </Details>
                              );
                            })}
                          </TD>
                          <TD>
                            {cell.attributes.Awardee} <br />
                            {cell.attributes.AwardeeFile.data.map((TableFile) => {
                              return (
                                <Details key={TableFile.id}>
                                  <a
                                    href={`${API}${TableFile.attributes.url}`}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    <u>{TableFile.attributes.caption}</u>
                                    <br />
                                  </a>
                                </Details>
                              );
                            })}
                          </TD>
                        </tr>
                      </>
                    );
                  })}
                  {/* {ProcBidsTable.tr.map((row) => {
                    return (
                      <>
                        <tr className="align-top">
                          {row.td.map((data) => {
                            return (
                              <>
                                <TD>
                                  <H2>{data.number}</H2>
                                </TD>
                                <TD>
                                  {data.titles.map((value) => {
                                    return (
                                      <>
                                        <H2>{value.name}</H2>
                                        {value.link.map((item) => {
                                          return (
                                            <>
                                              <div className="py-2">
                                                <a href={item.href}>
                                                  <u>{item.text}</u>
                                                </a>
                                              </div>
                                            </>
                                          );
                                        })}
                                      </>
                                    );
                                  })}
                                </TD>
                                <TD>{data.awardDate}</TD>
                                <TD>{data.datePosted}</TD>
                                <TD>
                                  {data.openCloseDates.map((value) => {
                                    return (
                                      <>
                                        <Details>{value.date}</Details>
                                        <div className="py-2">
                                          <a href={value.href}>
                                            <u>{value.text}</u>
                                          </a>
                                        </div>
                                      </>
                                    );
                                  })}
                                </TD>
                                <TD>
                                  {data.awardee.map((value) => {
                                    return (
                                      <>
                                        <Details>{value.name}</Details>
                                        <div className="py-2">
                                          <a href={value.href}>
                                            <u>{value.text}</u>
                                          </a>
                                        </div>
                                      </>
                                    );
                                  })}
                                </TD>
                              </>
                            );
                          })}
                        </tr>
                      </>
                    );
                  })} */}
                </Table>
              </div>
            </section>
            <section className="mb-24">
              <div>
                <H1>{TechnicalDocuments.title}</H1>
                <OrderedList className="list-inside px-2">
                  {TechnicalDocuments.links.map((content) => {
                    return (
                      <>
                        <List className="text-orange">
                          {file
                            .filter((fileData) => fileData.attributes.AnchorText === content.anchor)
                            .map((fileData) => {
                              return (
                                <a
                                  className="text-orange"
                                  href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                  key={content.anchor}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  {content.anchor}
                                </a>
                              );
                            })}
                        </List>
                      </>
                    );
                  })}
                </OrderedList>
                <H2 className="text-blue mt-5 mb-6">{TechnicalDocuments.subTitle}</H2>
                {TechnicalDocuments.personnel.map((content) => {
                  return (
                    <>
                      <H2>{content.name}</H2>
                      <Details>
                        {content.position}
                        <br />
                        {content.telephone}
                        <br />
                        {content.fax}
                        <br />
                        <a className="text-orange" href={content.emailLink}>
                          {content.email}
                        </a>
                        <br />
                        <i className="text-blue">{content.note}</i>
                      </Details>
                    </>
                  );
                })}
                <H2>{TechnicalDocuments.description}</H2>
              </div>
              <div className="my-10">
                <H1>{InventorySpecsTable.title}</H1>
                <Table className="w-full -my-2">
                  <tr className="w-12/12">
                    {InventorySpecsTable.th.map((heading) => {
                      return (
                        <TD className="w-6/12 text-left" key={heading} rowType="heading">
                          {heading}
                        </TD>
                      );
                    })}
                  </tr>
                  {inventoryTableData.map((cell) => {
                    return (
                      <>
                        <tr className="w-12/12">
                          <TD className="w-6/12">
                            {cell.attributes.File.data.map((TableFile) => {
                              return (
                                <>
                                  <a
                                    href={`${API}${TableFile.attributes.url}`}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    <u>{TableFile.attributes.caption}</u>
                                  </a>
                                </>
                              );
                            })}
                          </TD>
                          <TD>{cell.attributes.Dimensions}</TD>
                        </tr>
                      </>
                    );
                  })}
                </Table>
              </div>
              <div className="my-10">
                <H1 className="mt-10">{NonInventorySpecsTable.title}</H1>
                <div className="mb-1" id="gpa-contracts" />
                <Table className="w-full -my-2">
                  <tr className="w-12/12">
                    {NonInventorySpecsTable.th.map((heading) => {
                      return (
                        <TD className="w-6/12 text-left" key={heading} rowType="heading">
                          {heading}
                        </TD>
                      );
                    })}
                  </tr>
                  {nonInventoryTableData.map((cell) => {
                    return (
                      <>
                        <tr className="w-12/12">
                          <TD className="w-6/12">
                            {cell.attributes.File.data.map((TableFile) => {
                              return (
                                <>
                                  <a
                                    href={`${API}${TableFile.attributes.url}`}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    <u>{TableFile.attributes.caption}</u>
                                  </a>
                                </>
                              );
                            })}
                          </TD>
                          <TD>{cell.attributes.Dimensions}</TD>
                        </tr>
                      </>
                    );
                  })}
                </Table>
              </div>
            </section>
            <section className="mb-24">
              <div>
                <H1 className="my-5">GPA Contracts</H1>
              </div>
              <div className="my-10">
                {ProcurementContracts.approval.map((content) => {
                  return (
                    <>
                      <div>
                        {file
                          .filter(
                            (fileData) => fileData.attributes.AnchorText === content.title.name,
                          )
                          .map((fileData) => {
                            return (
                              <>
                                <H1 className="mt-10">
                                  <span className="text-blue">{content.title.name}</span>
                                  &nbsp;
                                  <a
                                    className="text-orange"
                                    href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    <u>{content.title.anchor}</u>
                                  </a>
                                </H1>
                              </>
                            );
                          })}
                      </div>
                      <Table className="w-full text-center -my-2">
                        <tr className="w-12/12">
                          {content.th.map((heading) => {
                            return (
                              <TD className="w-3/12 text-left" key={heading} rowType="heading">
                                {heading}
                              </TD>
                            );
                          })}
                        </tr>
                        {genApprovalTableData.map((cell) => {
                          return (
                            <>
                              <tr className="w-12/12">
                                <TD className="w-3/12">{cell.attributes.Description}</TD>
                                <TD className="w-3/12">{cell.attributes.BaseContract}</TD>
                                <TD className="w-3/12">
                                  {/* {cell.attributes.ContractFile.data.map((file) => {
                                    return (
                                      <>
                                        <a
                                          href="/" // {`${API}${file.attributes.url}`}
                                          rel="noreferrer"
                                          target="_blank"
                                        >
                                          <u>{file.attributes.name}</u>
                                        </a>
                                      </>
                                    );
                                  })} */}
                                </TD>
                                <TD className="w-3/12">
                                  {/* {cell.attributes.POFile.data.map((file) => {
                                    return (
                                      <>
                                        <a
                                          href="/" // {`${API}${file.attributes.url}`}
                                          rel="noreferrer"
                                          target="_blank"
                                        >
                                          <u>{file.attributes.name}</u>
                                        </a>
                                      </>
                                    );
                                  })} */}
                                </TD>
                              </tr>
                            </>
                          );
                        })}
                        {/* {content.tr.map((row) => {
                          return (
                            <>
                              <tr>
                                {row.td.map((data) => {
                                  return (
                                    <>
                                      <TD>{data.description}</TD>
                                      <TD>{data.years}</TD>
                                      <TD>
                                        <a href={data.pdfLink}>
                                          <u>{data.pdf}</u>
                                        </a>
                                      </TD>
                                      <TD>
                                        <a href={data.orderLink}>
                                          <u>{data.order}</u>
                                        </a>
                                      </TD>
                                    </>
                                  );
                                })}
                              </tr>
                            </>
                          );
                        })} */}
                      </Table>
                    </>
                  );
                })}
              </div>
              <div className="my-10">
                {ProcurementContracts.utilities1.map((content) => {
                  return (
                    <>
                      <div>
                        {file
                          .filter(
                            (fileData) => fileData.attributes.AnchorText === content.title.name,
                          )
                          .map((fileData) => {
                            return (
                              <>
                                <H1 className="mt-10">
                                  <span className="text-blue">{content.title.name}</span>
                                  &nbsp;
                                  <a
                                    className="text-orange"
                                    href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    <u>{content.title.anchor}</u>
                                  </a>
                                </H1>
                              </>
                            );
                          })}
                        {/* <H1 className="mt-10">
                          <span className="text-blue">{content.title.name}</span>
                          &nbsp;
                          <a className="text-orange" href={content.title.link}>
                            <u>{content.title.anchor}</u>
                          </a>
                        </H1> */}
                      </div>
                      <Table className="w-full text-center -my-2">
                        <tr className="w-12/12">
                          {content.th.map((heading) => {
                            return (
                              <TD className="w-3/12 text-left" key={heading} rowType="heading">
                                {heading}
                              </TD>
                            );
                          })}
                        </tr>
                        {commissionTableData.map((cell) => {
                          return (
                            <>
                              <tr className="w-12/12">
                                <TD className="w-3/12">{cell.attributes.Description}</TD>
                                <TD className="w-3/12">{cell.attributes.BaseContract}</TD>
                                <TD className="w-3/12">
                                  {/* {cell.attributes.ContractFile.data.map((file) => {
                                    return (
                                      <>
                                        <a
                                          href="/" // {`${API}${file.attributes.url}`}
                                          rel="noreferrer"
                                          target="_blank"
                                        >
                                          <u>{file.attributes.name}</u>
                                        </a>
                                      </>
                                    );
                                  })} */}
                                </TD>
                                <TD className="w-3/12">
                                  {/* {cell.attributes.POFile.data.map((file) => {
                                    return (
                                      <>
                                        <a
                                          href={`${API}${file.attributes.url}`}
                                          rel="noreferrer"
                                          target="_blank"
                                        >
                                          <u>{file.attributes.name}</u>
                                        </a>
                                      </>
                                    );
                                  })} */}
                                </TD>
                              </tr>
                            </>
                          );
                        })}
                        {/* {content.tr.map((row) => {
                          return (
                            <>
                              <tr>
                                {row.td.map((data) => {
                                  return (
                                    <>
                                      <TD>{data.description}</TD>
                                      <TD>{data.years}</TD>
                                      <TD>
                                        <a href={data.pdfLink}>
                                          <u>{data.pdf}</u>
                                        </a>
                                      </TD>
                                      <TD>
                                        <a href={data.orderLink}>
                                          <u>{data.order}</u>
                                        </a>
                                      </TD>
                                    </>
                                  );
                                })}
                              </tr>
                            </>
                          );
                        })} */}
                      </Table>
                    </>
                  );
                })}
              </div>
              <div className="mt-10">
                {ProcurementContracts.utilities2.map((content) => {
                  return (
                    <>
                      <div>
                        <H1 className="mt-10">
                          <span className="text-blue">{content.title.name}</span>
                          &nbsp;
                          <a className="text-orange" href={content.title.link}>
                            <u>{content.title.anchor}</u>
                          </a>
                        </H1>
                      </div>
                      <Table className="w-full text-center -my-2">
                        <tr className="w-12/12">
                          {content.th.map((heading) => {
                            return (
                              <TD className="w-3/12 text-left" key={heading} rowType="heading">
                                {heading}
                              </TD>
                            );
                          })}
                        </tr>
                        {pocTableData.map((cell) => {
                          return (
                            <>
                              <tr className="w-12/12">
                                <TD className="w-3/12">{cell.attributes.Description}</TD>
                                <TD className="w-3/12">{cell.attributes.BaseContract}</TD>
                                <TD className="w-3/12">
                                  {cell.attributes.ContractFile.data.map((contractFile) => {
                                    return (
                                      <>
                                        <a
                                          href={`${API}${contractFile.attributes.url}`}
                                          rel="noreferrer"
                                          target="_blank"
                                        >
                                          <u>{contractFile.attributes.caption}</u>
                                        </a>
                                      </>
                                    );
                                  })}
                                </TD>
                                <TD className="w-3/12">
                                  {/* {cell.attributes.POFile.data.map((file) => {
                                    return (
                                      <>
                                        <a
                                          href="/" // {`${API}${file.attributes.url}`}
                                          rel="noreferrer"
                                          target="_blank"
                                        >
                                          <u>{file.attributes.name}</u>
                                        </a>
                                      </>
                                    );
                                  })} */}
                                </TD>
                              </tr>
                            </>
                          );
                        })}
                        {/* {content.tr.map((row) => {
                          return (
                            <>
                              <tr>
                                {row.td.map((data) => {
                                  return (
                                    <>
                                      <TD>{data.description}</TD>
                                      <TD>{data.years}</TD>
                                      <TD>
                                        <a href={data.pdfLink}>
                                          <u>{data.pdf}</u>
                                        </a>
                                      </TD>
                                      <TD>
                                        <a href={data.orderLink}>
                                          <u>{data.order}</u>
                                        </a>
                                      </TD>
                                    </>
                                  );
                                })}
                              </tr>
                            </>
                          );
                        })} */}
                      </Table>
                    </>
                  );
                })}
              </div>
            </section>
            <section className="mb-24">
              <H1>{AwardedSoleSource.title}</H1>
              <H2 className="text-orange">
                <u>{AwardedSoleSource.anchor}</u>
              </H2>
              {AwardedSoleSource.contacts.map((content) => {
                return (
                  <>
                    <H2>{content.name}</H2>
                    <Details>
                      {content.position}
                      <br />
                      {content.telephone}
                      <br />
                      {content.fax}
                      <br />
                      <a className="text-orange" href={content.emailLink}>
                        {content.email}
                      </a>
                    </Details>
                  </>
                );
              })}
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
