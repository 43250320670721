import React, { FC, PropsWithChildren, HTMLAttributes } from 'react';

interface H3Prop extends HTMLAttributes<HTMLHeadElement> {
  className?: string;
}

type H3Props = PropsWithChildren<H3Prop>;

export const H3: FC<H3Props> = ({ children, className }, props) => {
  return (
    <h2
      className={`font-rubik font-medium phone:text-sm tablet:text-base laptop:text-lg 
  desktop:text-lg ${className}`}
      {...props}
    >
      {children}
    </h2>
  );
};
