import { API } from '@config';
import { WorkOrdersTable } from '@src/types/WorkOrders';

export const getWorkOrders = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/business-fn-p-work-orders?populate=File&sort=id%3Adesc`, options);
  const data: WorkOrdersTable = await response.json();

  return data;
};
