import { API } from '@config';
import { GpaInitiativesIrpSummaryTable } from '@src/types/GpaInitiativesIrpSummary';

export const getGpaInitiativesIrpSummary = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${API}/api/gpa-initiatives-irp-summaries?populate=File&sort=id%3Aasc`,
    options,
  );
  const data: GpaInitiativesIrpSummaryTable = await response.json();

  return data;
};
