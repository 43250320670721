import React from 'react';

interface HomeLearnMoreItemProps {
  icon: string;
  title: string;
  link: string;
}

export const HomeLearnMoreItem: React.FC<HomeLearnMoreItemProps> = ({ icon, title, link }) => {
  return (
    <a
      className="w-12/12 flex p-8 tablet:p-4 gap-2 h-28 tablet:h-auto font-rubik text-blue transition font-medium items-center rounded-md bg-white border-bluegray border-2 hover:border-blue laptop:text-xl tablet:text-lg phone:text-lg"
      href={link}
    >
      <div className="1/12">
        <img alt="Bold" className="h-6" src={icon} />
      </div>

      <div className="w-11/12">
        <p className="pl-3 font-rubik font-semibold text-xl">{title}</p>
      </div>
    </a>
    // Old design, keep while not deployed in case needed
    // <div className="flex flex-col justify-center items-center">
    //   <div className="h-28 w-28 flex items-center justify-center bg-darkblue rounded-full mb-5">
    //     <img alt="Bold" className="h-20" src={icon} />
    //   </div>
    //   <h2 className="font-rubik text-center text-black font-semibold mb-2 laptop:text-3xl tablet:text-2xl phone:text-xl">
    //     {title}
    //   </h2>
    //   <a
    //     className="font-rubik text-blue font-medium mb-5 laptop:text-xl tablet:text-lg phone:text-lg"
    //     href={link}
    //   >
    //     Learn More
    //   </a>
    // </div>
  );
};
