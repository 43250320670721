import React from 'react';
import { AccordionBlue, AccordionWhite, HeaderGradient, LookingFor } from '@components';
import {
  H1,
  // Details,
  // OrderedList,
  // List,
  // UnorderedList,
  Content as ContentContainer,
  Images,
} from '@src/core';

import {
  AboutGpaPowerAlerts,
  CancellingOrEnrolling,
  LookingForSomethingElse,
  OtherFAQ,
  UsingPowerAlertsMessage,
} from '@src/content';

export const PowerInYourHands = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Power Alerts"
      />
      <div className="w-full">
        <img alt="Power Alert" className="block w-full" src={Images.PowerAlert} />
      </div>
      <ContentContainer>
        <div className="flex flex-col tabletWide:flex-row desktopWide:mx-auto desktopWide:max-w-3/4">
          <div className="w-full tabletWide:w-3/5 desktopWide:w-4/6">
            <H1>{AboutGpaPowerAlerts.subtitle}</H1>
            {AboutGpaPowerAlerts.accordionArray.map(
              (accordion: { question: string; answer: string }, index: number) => {
                return (
                  <AccordionBlue
                    content={accordion.answer}
                    key={index}
                    title={accordion.question}
                  />
                );
              },
            )}
          </div>
          <div className="w-full flex items-start justify-center tabletWide:w-2/5 desktopWide:w-2/6">
            <div className="mt-8 p-1 shadow-md border border-blue ">
              <img alt="Power In Your Hands" className="block" src={Images.PowerInYourHands} />
            </div>
          </div>
        </div>
      </ContentContainer>
      <section className="bg-darkerblue">
        <ContentContainer>
          <div className="container max-w-4xl mx-auto">
            <h1
              className="text-white text-center max-w-md mx-auto phone:text-lg tablet:text-xl laptop:text-2xl 
  desktop:text-2xl mb-8 phone:mt-0 tablet:mt-5"
            >
              {CancellingOrEnrolling.title}
            </h1>
            {CancellingOrEnrolling.accordionArray.map(
              (accordion: { question: string; answer: string }, index: number) => {
                return (
                  <AccordionWhite
                    content={accordion.answer}
                    key={index}
                    title={accordion.question}
                  />
                );
              },
            )}
          </div>
        </ContentContainer>
      </section>
      <section>
        <ContentContainer>
          <div className="container max-w-4xl mx-auto">
            <H1 className="text-center mb-8 mx-auto">
              {UsingPowerAlertsMessage.title[0]}
              <br />
              {UsingPowerAlertsMessage.title[1]}
              <br />
              {UsingPowerAlertsMessage.title[2]}
            </H1>
            {UsingPowerAlertsMessage.descriptionArray.map(
              (description: { word: string; meaning: string }, index: number) => {
                return (
                  <div
                    className="flex flex-col power-alerts__shadow mb-8 tabletWide:flex-row"
                    key={index}
                  >
                    <div className="text-white text-2xl text-center w-full power-alerts__shadow bg-blue py-4 tabletWide:w-1/4 tabletWide:py-8">
                      {description.word}
                    </div>
                    <div className="flex items-center text-xl w-full px-4 py-4 tabletWide:w-3/4 tabletWide:px-8 tabletWide:py-0">
                      {description.meaning}
                    </div>
                  </div>
                );
              },
            )}
          </div>
        </ContentContainer>
      </section>
      <section className="bg-section-dark">
        <ContentContainer>
          <div className="container max-w-4xl mx-auto">
            <H1 className="text-center mb-8">{OtherFAQ.title}</H1>
            {OtherFAQ.accordionArray.map(
              (accordion: { question: string; answer: string }, index: number) => {
                return (
                  <AccordionBlue
                    content={accordion.answer}
                    key={index}
                    title={accordion.question}
                  />
                );
              },
            )}
          </div>
        </ContentContainer>
      </section>
      <section className="bg-orange">
        <ContentContainer>
          <div className="container max-w-4xl mx-auto laptop:pt-8">
            <h1
              className="text-white text-center phone:text-lg tablet:text-xl laptop:text-2xl 
  desktop:text-2xl"
            >
              Need additional assistance
              <br />
              Please contact{' '}
              <a className="font-bold" href="tel:+16716475787">
                GPA Customer Service (671) 647-5787
              </a>
              .
            </h1>
          </div>
        </ContentContainer>
      </section>
      <section className="bg-section-dark">
        <ContentContainer>
          <H1 className="text-center mb-8">{LookingForSomethingElse.title}</H1>
          <div
            className="w-full flex items-center justify-center px-10 tabletWide:px-24 laptop:px-10 py-10 flex-wrap
      gap-x-20 gap-y-10"
          >
            {LookingForSomethingElse.lookingFor.map((value) => (
              <LookingFor
                icon={value.icon}
                key={value.title}
                link={value.link}
                title={value.title}
              />
            ))}
          </div>
        </ContentContainer>
      </section>
      {/* <section>
        <ContentContainer>
          <div className="flex flex-col laptop:pt-8 tablet:flex-row tablet:justify-between desktopWide:mx-auto desktopWide:max-w-3/4">
            <a className="block flex-1 mb-4 tablet:mb-0 tablet:mr-8 desktopWide:mr-16" href="/">
              <img alt="My Home" className="block w-full" src={Images.PowerAlertMyHome} />
            </a>
            <a
              className="block flex-1 mb-4 tablet:mb-0 tablet:mr-8 desktopWide:mr-16"
              href="/business/manage-my-account"
            >
              <img alt="My Business" className="block w-full" src={Images.PowerAlertMyBusiness} />
            </a>
            <a className="block flex-1 " href="https://gparebates.com/">
              <img alt="Our Gpa" className="block w-full" src={Images.PowerAlertOurGpa} />
            </a>
          </div>
        </ContentContainer>
      </section> */}
    </>
  );
};
