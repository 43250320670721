import { API } from '@config';
import { AboutUsCCUMeetingRecordingsTable } from '@src/types/AboutUsCCUMeetingRecordings';

export const getAboutUsCCUMeetingRecordings = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/about-us-ccu-meeting-recordings?populate=File&sort=id%3Adesc`, options);
  const data: AboutUsCCUMeetingRecordingsTable = await response.json();

  return data;
};
