import React, { useEffect } from "react";

const Milestone = () => {
  useEffect(() => {
    window.location.replace(
      "https://admin.guampowerauthority.com/uploads/GPA_20_Year_Milestones_da4230726d.pdf"
    );
  }, []);

  return <div>Milestone</div>;
};

export default Milestone;
