import React from 'react';
import { Details, Content as ContentContainer, H2 } from '@core';
import { PayCodesContent } from '@content';
import { HeaderGradient } from '@components';

export const PayCodes = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={PayCodesContent.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <section>
              {PayCodesContent.contents.map((content) => {
                return (
                  <>
                    <H2>{content.title}</H2>
                    <Details>{content.description}</Details>
                  </>
                );
              })}
              <Details>
                {PayCodesContent.outro}{' '}
                {/* <a className="text-orange" href={PayCodesContent.link}>
                  {PayCodesContent.anchor}
                </a> */}
              </Details>
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
