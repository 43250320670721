import React, {useEffect, useState} from 'react';
import { H2, Details, List, UnorderedList, Content as ContentContainer } from '@core';
import { Benefits } from '@content';
import { HeaderGradient } from '@components';
import { HREMPLOYEEBENEFITS } from '@src/types/HrEmployeeBenefits';
import { getHrEmployeeBenefits } from '@src/services/HrEmployeeBenefits';
import { API } from '@src/config';

export const EmployeeBenefits = () => {
  const [benefits, setBenefits] = useState<HREMPLOYEEBENEFITS[]>([]);

  const getData = async () => {
    const benefitsData = await getHrEmployeeBenefits();
    setBenefits(benefitsData.data);
  }

  useEffect(() => {
    getData();
  }, []);

  const clickItem = (link?: string, file?: any) => {
    if(file != null){
      window.open(`${API}${file}`);
    }else{
      window.location.replace(`${link}`);
    }
  }
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={Benefits.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12 laptop:w-7/12 mr-20">
            <section>
              <UnorderedList>
                {benefits.map((value) => {
                  return(
                    <List className="text-orange" key={value.attributes.createdAt}>
                      <button className='text-left' onClick={()=>clickItem(value.attributes.Link, value.attributes.File?.data != null ? value.attributes.File.data.attributes.url : value.attributes.File.data)} type="button">{value.attributes.Anchor.length > 35 ? `${value.attributes.Anchor.substring(0,35)}...`:value.attributes.Anchor}</button>
                    </List>
                  )
                })}
              </UnorderedList>
            </section>
            <section className="mt-5">
              <H2 className="text-blue">{Benefits.title}</H2>
              <Details className='mb-0.5'>
                {Benefits.description} <br />{' '}
                
              </Details>
              <Details>
                
                <a className="text-orange" href={Benefits.link} rel="noreferrer" target="_blank">
                  {Benefits.anchor}
                </a>
              </Details>
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
