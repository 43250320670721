import React, { useEffect, useState } from 'react';
import { H2, Details, List, UnorderedList, Content as ContentContainer } from '@core';
import { MedicalDental, Eligibility, InsuranceResources } from '@content';
import { HeaderGradient } from '@components';
import { getFileData } from '@services';
import { FILEDATA } from '@types';
import { API } from '@src/config';

export const MedicalDentalBenefit = () => {
  const [file, setFile] = useState<FILEDATA[]>([]);

  const getData = async () => {
    const fileDatas = await getFileData();
    setFile(fileDatas.data);
  };

  useEffect(() => {
    getData();
  }, []);
  
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={MedicalDental.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <section>
              <H2 className="text-blue">{MedicalDental.title}</H2>
              <Details>{MedicalDental.description}</Details>
              {MedicalDental.contents.map((content) => {
                return (
                  <>
                    <H2>{content.title}</H2>
                    <Details>
                      <span className="font-bold">{content.name}</span> <br />
                      {content.phone} <br />
                      {content.info}
                    </Details>
                  </>
                );
              })}
            </section>
            <section className="mt-5">
              <H2>{Eligibility.title}</H2>
              <H2 className="text-blue">{Eligibility.subTitle}</H2>
              {Eligibility.contents.map((content) => {
                return (
                  <>
                    <H2 className="mt-5">{content.title}</H2>
                    <UnorderedList>
                      {content.ul.map((value) => {
                        return <List key={value}>{value}</List>;
                      })}
                    </UnorderedList>
                  </>
                );
              })}
              <H2 className="mt-5">{Eligibility.header}</H2>
              <Details>{Eligibility.description}</Details>
              <Details>
                {Eligibility.outro}{' '}
                <a className="text-orange" href={Eligibility.link}>
                  {Eligibility.email}
                </a>
              </Details>
            </section>
            <section className="mt-5">
              <H2>{InsuranceResources.title}</H2>
              <UnorderedList>
                {InsuranceResources.ul.map((value) => {
                  return (
                    <>
                      {file
                      .filter(
                        (fileData) => fileData.attributes.AnchorText === value,
                      )
                      .map((fileData) => {
                        return (
                          <>
                            <List className="text-orange" key={value}>
                              <a
                                className="text-orange"
                                href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                rel="noreferrer"
                                target="_blank"
                              >{value}</a>
                            </List>
                          </>
                        );
                      })}
                    </>
                    
                    
                  );
                })}
              </UnorderedList>
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
