import { API } from '@config';
import { ARTICLEDATA } from '@types';

// interface DATA {
//   id: number;
//   attributes: {
//     Title: string;
//     createdAt: string;
//     updatedAt: string;
//     publishedAt: string;
//     Date: any;
//     Description: string;
//     File: {
//       data: {
//         id: number;
//         attributes: {
//           name: string;
//           alternativeText: string;
//           caption: string;
//           width: any;
//           height: any;
//           formats: any;
//           hash: string;
//           ext: string;
//           mime: string;
//           size: number;
//           url: string;
//           previewUrl: any;
//           provider: string;
//           provider_metadata: any;
//           createdAt: string;
//           updatedAt: string;
//         };
//       };
//     };
//   };
// }

interface Articles {
  data: ARTICLEDATA[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}

export const getArticles = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/articles?populate=*`, options);
  const data: Articles = await response.json();

  return data;
};
