export const HighlightsAndEvents = {
  pageTitle: `Strategic Planning and Operations Research`,
  title: `Highlights and Significant Events`,
  contents: [
    {
      intro: `Voice Over IP Phone System: `,
      body: `This project addressed GPA's high recurring phone system lease and maintenance costs. Pacific Data Systems (PDS) was contracted to purchase, install and maintain the new phone system for the Harmon Main Office, T&D and Dededo Warehouse Compound, and Cabras Compound. Additional sites such as Dededo Power Plant Compound and Tenjo Vista Power Plant are future sites for this new phone system.`,
    },
    {
      intro: `Battery Energy Storage System (BESS): `,
      body: `GPA officially commissioned Guam's first Battery Energy Storage System in March 2021. The system is capable of storing and dispatching at total of 40 MW, addressing intermittency and similar issues.`,
    },
  ],
};