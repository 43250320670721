export const PayCodesContent = {
  pageTitle: `Pay Codes`,
  contents: [
    {
      title: `Overtime Pay`,
      description: `Overtime may be authorized for employees who work in excess of their administrative workweek and shall be compensated at one and one-half (1 1/2) times the employee's hourly rate of pay.`,
    },
    {
      title: `Night Differential Pay`,
      description: `All employees except department or agency heads and Deputy Directors, shall be entitled to night differential pay, calculated at the rate of their regular wage plus 10% for all periods worked between the hours of 6:00 p.m. and 6:00 a.m., provided that they work a minimum of (4) consecutive hours within that time period.`,
    },
    {
      title: `Hazardous/Environmental Pay`,
      description: `GPA utilizes the Hazardous/Environmental Pay Policy and Procedures guidelines established by the Civil Service Commission relative to payment differential authorized for employees who perform work involving exposure to hazardous conditions or physical hardship (environmental) conditions.`,
    },
  ],
  outro: `Hazardous/Environmental Pay Policy and Procedures `,
  anchor: `Download 212 kb pdf`,
  link: ``,
};