export const PlanningDocumentOverview = {
  pageTitle: `Strategic Planning and Operations Research`,
  title: `Planning Document Archive`,
  quote: `"Planning at all levels and horizons is necessary to stay out of crisis management. Planning is the foundation of prudent management."`,
  speaker: `-- John M. Benavente, P.E.`,
};

export const PlanningDocumentLibrary = {
  contents: [
    {
      title: `Current Planning Document Library`,
      paragraph: `2006 Documents are being prepared.`,
    },
    {
      title: `Quality Management Planning Document Library`,
      paragraph: `GPA believes that the ability to execute on strategic objectives requires that the organization be aligned with these goals and have the resources and organziational strengths required to execute these plans well. This starts with having the appropriate processes and organizational infrastructure to produce exceptional quality results. GPA creates Quality Management Plans and the supporting Quality Management Systems to ensure exceptional results.`,
    },
  ],
  text: `Quality Management Plan for Cost-Effective Reduction of Unaccounted For Energy `,
  anchor: `Download 3.04 MB pdf`,
  link: ``,
};

export const DocumentArchive = {
  title: `Planning Document Archive`,
  contents: [
    {
      title: `Automatic Generation Control Feasibility Study - FY 1992`,
      anchor: `Download 3.7 MB pdf`,
      link: ``,
    },
    {
      title: `Distribution Analysis - FY 1992-1995`,
      anchor: `Download 16.7 MB pdf`,
      link: ``,
    },
    {
      title: `E&Y Performance Report - FY 1995`,
      anchor: `Download 14.1 MB pdf`,
      link: ``,
    },
    {
      title: `GPA Business Plan - 1998`,
      anchor: `Download 12.4 MB pdf`,
      link: ``,
    },
    {
      title: `Integrated Resource Plan - FY 1999`,
      anchor: `Download 2.9 MB pdf`,
      link: ``,
    },
    {
      title: `Integrated Resource Plan - FY 1996`,
      anchor: `Download 18.6 MB pdf`,
      link: ``,
    },
    {
      title: `Long Range Transmission Plan - FY 1997`,
      anchor: `Download 10.7 MB pdf`,
      link: ``,
    },
    {
      title: `Load Study Report (Part 1 of 5) - FY 1994`,
      anchor: `Download 4.5 MB pdf`,
      link: ``,
    },
    {
      title: `Load Study Report (Part 2 of 5) - FY 1994`,
      anchor: `Download 6.0 MB pdf`,
      link: ``,
    },
    {
      title: `Load Study Report (Part 3 of 5) - FY 1994`,
      anchor: `Download 4.4 MB pdf`,
      link: ``,
    },
    {
      title: `Load Study Report (Part 4 of 5) - FY 1994`,
      anchor: `Download 3.8 MB pdf`,
      link: ``,
    },
    {
      title: `Load Study Report (Part 5 of 5) - FY 1994`,
      anchor: `Download 3.9 MB pdf`,
      link: ``,
    },
    {
      title: `Cabras-Piti Development Study (RWBeck, Feb 2000)`,
      anchor: `Download 7.4 MB pdf`,
      link: ``,
    },
  ],
};