import { API } from '@config';
import { WorkOthersTable } from '@src/types/WorkOthers';

export const getWorkOthers = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/business-fn-p-work-others?populate=File&sort=id%3Adesc`, options);
  const data: WorkOthersTable = await response.json();

  return data;
};
