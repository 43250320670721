import React, { useEffect, useState } from 'react';
import { Details, Content as ContentContainer, H2, Table, TableCell as TD } from '@core';
import { LeavesContent, LeavesTableContent } from '@content';
import { HeaderGradient } from '@components';
import { getLeavesTableData } from '@services';
import { LEAVESDATA } from '@types';

export const Leaves = () => {
  const [tableData, setTableData] = useState<LEAVESDATA[]>([]);

  const getData = async () => {
    const tableDatas = await getLeavesTableData();
    setTableData(tableDatas.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={LeavesContent.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <section>
              {LeavesContent.intro.map((content) => {
                return (
                  <>
                    <H2>{content.title}</H2>
                    <Details>{content.description}</Details> <br />
                  </>
                );
              })}
            </section>
            <section>
              {LeavesTableContent.table.map((cell) => {
                return (
                  <>
                    <div>
                      <Table className="mb-16">
                        <tr>
                          {cell.th.map((heading) => {
                            return (
                              <TD className="text-left" key={heading} rowType="heading">
                                {heading}
                              </TD>
                            );
                          })}
                        </tr>
                        {tableData.map((data) => {
                          return (
                            <>
                              <tr>
                                <TD>{data.attributes.YearsOfService}</TD>
                                <TD>{data.attributes.AnnualLeave}</TD>
                                <TD>{data.attributes.TotalYearCredit}</TD>
                              </tr>
                            </>
                          );
                        })}
                      </Table>
                    </div>
                  </>
                );
              })}
            </section>
            <section>
              {LeavesContent.contents.map((content) => {
                return (
                  <>
                    <H2 className="-mt-5">{content.title}</H2>
                    <Details>
                      {content.descriptions.map((description) => {
                        return (
                          <>
                            {description} <br /> <br />
                          </>
                        );
                      })}
                    </Details>
                  </>
                );
              })}
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
