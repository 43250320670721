export const OperationsOverview = {
  bannerTitle: `Operations`,
  title: ``,
  subTitle: ``,
  contents1: [
    {
      title: `Internal Audit`,
      subTitle: `Mission`,
      paragraph1: `The GPA Internal Audit Office provides independent, objective assurance and consulting services designed to add value and improve GPA’s operations. The IAO aggressively administers the Revenue Protection Program for the prevention and detection of unauthorized electricity use. Under this mission, the IAO`,
      ul: [
        {
          text: `Conducts both operational and financial audits for effective, efficient and economic operation of GPA;`,
          anchor: ``,
          link: ``,
        },
        {
          text: `Assists the GPA Management Team and Consolidated Commission on Utilities (CCU) in the effective discharge of their responsibilities by furnishing independent analysis, appraisals, recommendations, counsel and information;`,
          anchor: ``,
          link: ``,
        },
        {
          text: `Maintains a viable and aggressive Revenue Protection Program for the prevention/detection of fraud, irregularities, and safeguarding GPA assets.`,
          anchor: ``,
          link: ``,
        },
      ],
      paragraph2: `As opportunities for improving management control, governance processes, risk management, profitability, and GPA’s image may be identified during audits, they are communicated to the appropriate level of management.`,
    },
  ],
  contents2: [
    {
      title: `Revenue Protection Service Program`,
      subTitle: ``,
      paragraph1: `Hundreds of thousands of dollars are stolen from GPA annually due to energy theft - the unauthorized use of electricity through deliberate and intentional act by a customer to obtain unmetered or partially unmetered power without proper compensation. Consequently, these costs are somehow passed to upstanding customers. The GPA Revenue Protection Service (RPS) is dedicated to preventing, detecting, investigating, and recovering lost revenue due to electricity theft. It is our goal to mitigate energy theft, thereby keeping rates from reflecting any theft-related losses. GPA has established several options for the public to confidentially report suspected fraud, waste, or abuse.`,
      ul: [],
      paragraph2: ``,
    },
    {
      title: `Reporting Energy Theft`,
      subTitle: ``,
      paragraph1: `An increasing number of energy theft or “meter tampering cases” has been reported and confirmed. In Guam alone, the estimated amount of money lost each year to energy theft is in hundreds of thousands. Unfortunately, in the end, it's the customer that ends up paying for the loss - a key reason why GPA is working hard to prevent the problem. Energy theft is a crime punishable by law, and it can be highly dangerous. Many meter tampering and energy theft activities result in electrocution, fire, explosion - even death.`,
      ul: [],
      paragraph2: ``,
    },
    {
      title: ``,
      subTitle: `Detecting Energy Theft`,
      paragraph1: `If you observe any of the following, it could indicate energy theft.`,
      ul: [
        {
          text: `Someone other than a GPA employee working on a meter.`,
        },
        {
          text: `Someone other than a GPA employee digging near underground power lines or tampering with overhead power lines.`,
        },
        {
          text: `Individuals “bragging” about low energy consumption or low electric bills resulting from by-passing or tampering with their meter.`,
        },
        {
          text: `Suspected illegal activity; for example, marijuana growing operations use large quantities of electricity, which is often stolen.`,
        },
      ],
      paragraph2: ``,
    },
  ],
  contents3: [
    {
      title: ``,
      subTitle: `Help Prevent Energy Theft`,
      paragraph1: `With the help of our customers, GPA can increase efforts to reduce meter tampering and energy theft. If you are aware of or have information about possible energy theft, you can submit information anonymously to the Internal Audit Revenue Protection Service:`,
      ul: [
        {
          text: `Hotline phone number: (671) 648-3199`,
          anchor: ``,
          link: ``,
        },
        {
          text: `Email:`,
          anchor: `internal-audit@gpagwa.com`,
          link: `mailto:internal-audit@gpagwa.com`,
        },
        {
          text: `Drop Boxes are located at the Gloria B. Nelson Public Service Building in Fadian, Mangilao, and the Hagåtña Satellite Office in Julale Shopping Center.`,
          anchor: ``,
          link: ``,
        },
        {
          text: `Report form is provided for your convenience:`,
          anchor: `reporting_energy_theft.pdf`,
          link: `https://admin.guampowerauthority.com/uploads/reporting_energy_theft_165532569d.pdf`,
          anchorText: `reporting_energy_theft.pdf`,
        },
      ],
      paragraph2: `All information submitted is kept strictly confidential.`
    },
  ],
};

export const EnvironmentalCompliance = {
  title: `ENVIRONMENTAL COMPLIANCE`,
  contents: [
    {
      title: `Overview`,
      paragraph: `The Planning & Regulatory Division (P&R) manages environmental compliance for the Authority.  P&R is located on the third floor of Gloria B. Nelson Public Service Building in Fadian, Mangilao`,
      anchor: ``,
      link: ``,
      ul: [],
    },
    {
      title: `Mission Statement`,
      paragraph: `To ensure that the Authority complies with all local and federal environmental laws and regulations, anticipate potential environmental problems early in the planning process, design activities to minimize or avoid problems, continually check performance, and to make improvements where appropriate.`,
      anchor: ``,
      link: ``,
      ul: [],
    },
    {
      title: `Vision`,
      paragraph: `Enhance  GPA's reputation and public stature as an environmental leader through change in the focus of environmental activities from a defensive, reactive posture to one that is proactive and based on sound planning and informed decision making.`,
      anchor: ``,
      link: ``,
      ul: [],
    },
    {
      title: `Staffing`,
      paragraph: `Planning and Regulatory staff is composed of the Environmental Manager, an Administrative Officer and four Engineers`,
      anchor: `Planning and Regulatory Staff - Download 287 kb pdf`,
      link: ``,
      ul: [],
    },
    {
      title: `Projects`,
      paragraph: ``,
      anchor: ``,
      link: ``,
      ul: [`Recycling Program 1`],
    },
    {
      title: `Training`,
      paragraph: ``,
      anchor: ``,
      link: ``,
      ul: [
        `Spill Prevention Control & Countermeasure Plan (SPCC)`,
        `Best Management Practice (BMP)`,
        `Facility Response Plan (FRP)`,
        `Hazardous Waste Operation (HAZWOPER)`,
      ],
    },
  ],
  endContent: [
    {
      title: `GPA ENERGY STORAGE SYSTEM (ESS)`,
      paragraph: [`GPA video describing our energy storage system.`, 'GPA Energy Storage System -'],
      anchor: `Stream YouTube`,
      link: `https://www.youtube.com/watch?v=7ZBymJYdxmc`,
    },
  ],
};

export const StrategicPlanningOperations = {
  title: `STRATEGIC PLANNING AND OPERATIONS RESEARCH`,
  contents: [
    {
      title: `Overview`,
      paragraph: `The Strategic Planning and Operations Research Division, also known as SPORD, was established in FY 2005. This division replaced the System Planning section formerly under the Engineering Division and broadened its workload to address GPA planning for load demand growth, increased power distribution area, improvement in operations efficiencies, implementation of new technologies for information systems used for administration and operations, and environmental concerns including alternative energy research.`,
      ul: [],
    },
    {
      title: `Staffing`,
      paragraph: `SPORD is managed by Jennifer G. Sablan, P.E., and is currently staffed with 5 additional engineers in the Electrical, Mechanical, and Computer Systems fields to address the wide variety of issues concerning daily operations.`,
      ul: [
        {
          anchor: `Highlights/Significant Events`,
          link: `/corporate/operations/highlights-and-significant-events`,
        },
        {
          anchor: `Projects,Forecasts and Studies`,
          link: `/corporate/operations/projects-forecasts-and-studies`,
        },
        {
          anchor: `Anticipated Projects`,
          link: `/corporate/operations/anticipated-projects`,
        },
        {
          anchor: `Planning Document Archive`,
          link: `/corporate/operations/planning-document-archive`,
        },
        {
          anchor: `Renewable Resources Links`,
          link: `/corporate/operations/renewable-resources-links`,
        },
        {
          anchor: `Planning Data`,
          link: `/corporate/operations/planning-data`,
        },
      ],
    },
  ],
};

// export const OperationsResearchAdditionals = {
//   eventsTitle: `Highlights/Significant Events`,
//   eventsBody: `Voice Over IP Phone System: This project addressed GPA's high recurring phone system lease and maintenance costs. Pacific Data Systems (PDS) was contracted to purchase, install and maintain the new phone system for the Harmon Main Office, T&D and Dededo Warehouse Compound, and Cabras Compound. Additional sites such as Dededo Power Plant Compound and Tenjo Vista Power Plant are future sites for this new phone system.`,
//   studies: `Projects,Forecasts and Studies`,
//   studiesLink: ``,
//   projectsTitle: `Anticipated Projects`,
//   projectsBody: `Fiber Project : GPA plans to install fiber in its existing underground conduits except for San Vitores to GMH (Note: Tanguisson to Harmon Fiber Line is already existing). `,
//   mapAnchor: `Underground Location map pdf 420kB`,
//   mapLink: ``,
//   grantProjects: `ARRA Grant Funded Projects`,
//   grantContents: [
//     {
//       anchor: `Smart Grid Program`,
//       link: ``,
//     },
//     {
//       anchor: `Energy Efficient Streetlight Project`,
//       link: ``,
//     },
//     {
//       anchor: `Building Retrofit Project`,
//       link: ``,
//     },
//   ],
//   archiveTitle: `Planning Document Archive`,
//   archiveLink: ``,
//   linksTitle: `Renewable Resources Links`,
//   linksContent: [
//     {
//       title: `National Wind Coordinating Collaborative`,
//       anchor: `www.nationalwind.org`,
//       link: `https://rewi.org/nwcc-timeline/`,
//     },
//     {
//       title: `Wind Energy Resources Atlas`,
//       anchor: `rredc.nrel.gov/wind`,
//       link: `https://www.nrel.gov/grid/solar-resource/renewable-resource-data.html`,
//     },
//     {
//       title: `U.S. Department of Energy—Energy Efficiency and Renewable Energy`,
//       anchor: `www1.eere.energy.gov/windandhydro`,
//       link: `https://www1.eere.energy.gov/windandhydro`,
//     },
//   ],
//   planningTitle: `Planning Data`,
//   planningContents: [
//     {
//       title: `Hourly System Loads`,
//       anchor: `Download 290 kB xls`,
//       link: ``,
//     },
//     {
//       title: `Demand Forecast Scenario`,
//       subTitle: `Baseline 2006`,
//       anchor: `Download 16 kB xls`,
//       link: ``,
//     },
//   ],
// };

export const OperationsDownloads = {
  title: `Downloadable Forms`,
  addResTitle: `ADDITIONAL RESOURCES`,
  addRes: [
    {
      anchor: `Resolution 2017-26 CCU Audit Committee__June2017.pdf`,
      link: ``,
    },
    {
      anchor: `SP-121 Meter Tampering-Illegal Hookup - Investigating & Reporting (REVISED) 2.01.17.pdf`,
      link: ``,
    },
  ],
  topClassTitle: `TOTAL CLASSES HELD`,
  topClass: [
    {
      title: `2009 - TOTAL CLASSES HELD - 8`,
      ul: [
        {
          anchor: `August 3, 2009`,
          link: ``,
        },
        {
          anchor: `August 11, 2009`,
          link: ``,
        },
        {
          anchor: `August 18, 2009`,
          link: ``,
        },
        {
          anchor: `August 25, 2009`,
          link: ``,
        },
        {
          anchor: `September 1, 2009`,
          link: ``,
        },
        {
          anchor: `September 8, 2009`,
          link: ``,
        },
        {
          anchor: `October 15, 2009`,
          link: ``,
        },
        {
          anchor: `October 28, 2009`,
          link: ``,
        },
      ],
    },
    {
      title: `2011 - TOTAL CLASSES HELD - 6`,
      ul: [
        {
          anchor: `July 6, 2011`,
          link: ``,
        },
        {
          anchor: `November 8, 2011`,
          link: ``,
        },
        {
          anchor: `November 10, 2011`,
          link: ``,
        },
        {
          anchor: `November 29, 2011`,
          link: ``,
        },
        {
          anchor: `December 6, 2011`,
          link: ``,
        },
        {
          anchor: `December 21, 2011`,
          link: ``,
        },
      ],
    },
    {
      title: `2012 - TOTAL CLASSES HELD - 8`,
      ul: [
        {
          anchor: `November 13, 2012`,
          link: ``,
        },
        {
          anchor: `November 15, 2012`,
          link: ``,
        },
        {
          anchor: `November 16, 2012`,
          link: ``,
        },
        {
          anchor: `November 30, 2012`,
          link: ``,
        },
        {
          anchor: `December 5, 2012`,
          link: ``,
        },
        {
          anchor: `December 6, 2012`,
          link: ``,
        },
        {
          anchor: `December 11, 2012`,
          link: ``,
        },
        {
          anchor: `December 19, 2012`,
          link: ``,
        },
        {
          anchor: `December 27, 2012`,
          link: ``,
        },
      ],
    },
  ],
  perfTitle: `LEAC PERFORMANCE CONTROL`,
  perf1: [
    {
      title: `Baseload Dispatch Performance Chart`,
      ul: [
        {
          anchor: `Baseload Dispatch Chart LEAC Period AUG2014-JAN2015.pdf`,
          link: ``,
        },
        {
          anchor: `Baseload Dispatch Chart LEAC Period FEB2015-JUL2015`,
          link: ``,
        },
        {
          anchor: `Baseload Dispatch Chart LEAC Period Feb2011-Jul2011`,
          link: ``,
        },
      ],
    },
    {
      title: `Gross Heat Rate Performance Chart`,
      ul: [
        {
          anchor: `Gross Heat Rate LEAC Period AUG2014-JAN2015`,
          link: ``,
        },
        {
          anchor: `Gross Heat Rate LEAC Period AUG2014-JAN2015 (2)`,
          link: ``,
        },
        {
          anchor: `Gross Heat Rate LEAC Period Feb2011-Jul2011`,
          link: ``,
        },
      ],
    },
    {
      title: `Fuel Usage Consumption`,
      ul: [
        {
          anchor: `Fuel Usage Consumption LEAC Period AUG2014-JAN2015`,
          link: ``,
        },
        {
          anchor: `Fuel Usage Consumption LEAC Period AUG2014-JAN2015 (2)`,
          link: ``,
        },
        {
          anchor: `Fuel Usage Consumption LEAC Period Feb2011-Jul2011`,
          link: ``,
        },
      ],
    },
    {
      title: `IWPS Dispatch Performance`,
      ul: [
        {
          anchor: `IWPS Dispatch LEAC Period AUG2014-JAN2015`,
          link: ``,
        },
        {
          anchor: `IWPS Dispatch LEAC Period Feb2015-Jul2015`,
          link: ``,
        },
        {
          anchor: `IWPS Dispatch LEAC Period Feb2011-Jul2011`,
          link: ``,
        },
      ],
    },
    {
      title: `Baseload Dispatch Performance`,
      ul: [
        {
          anchor: `Baseload Dispatch LEAC Period AUG2014-JAN2015`,
          link: ``,
        },
        {
          anchor: `Baseload Dispatch LEAC Period Feb2015-Jul2015`,
          link: ``,
        },
      ],
    },
    {
      title: `Savings from Increased Baseload Use`,
      ul: [
        {
          anchor: `As of Sept 2011`,
          link: ``,
        },
      ],
    },
    {
      title: `Previous LEAC Period Control Charts:`,
      ul: [
        {
          anchor: `Aug10-Jan11 Control Chart`,
          link: ``,
        },
        // NOTE: File not found from old GPA website
        // {
        //   anchor: `Feb10-Jul10 Control Chart`,
        //   link: ``,
        // },
        // {
        //   anchor: `Aug09-Jan10 Control Chart`,
        //   link: ``,
        // },
        // {
        //   anchor: `Feb09-Jul09 Control Chart`,
        //   link: ``,
        // },
        // {
        //   anchor: `Aug08-Jan09 Control Chart`,
        //   link: ``,
        // },
      ],
    },
    {
      title: `Equivalent Forced Outage Rate (EFOR)Charts`,
      ul: [
        {
          anchor: `Baseload Unit 3-year Rolling EFOR AUG2012-JUL2015`,
          link: ``,
        },
        {
          anchor: `Baseload Unit LEAC Period EFOR AUG2014-JAN2015`,
          link: ``,
        },
        {
          anchor: `Baseload Unit LEAC Period EFOR FEB-JUL 2015`,
          link: ``,
        },
        {
          anchor: `Baseload Unit 3-year Rolling EFOR`,
          link: ``,
        },
        {
          anchor: `Baseload Unit LEAC Period EFOR`,
          link: ``,
        },
      ],
    },
  ],
  perf2: [
    {
      title: `Equivalent Availability Factor (EAF)Performance Charts`,
      ul: [
        {
          anchor: `Baseload Unit 3-year Rolling EAF AUG2012-JUL2015 (1)`,
          link: ``,
        },
        {
          anchor: `Baseload Unit LEAC Period EAF AUG2014-JAN2015`,
          link: ``,
        },
        {
          anchor: `Diesel Plant 3-year Rolling EAF AUG2012 -JUL2015`,
          link: ``,
        },
        {
          anchor: `Baseload Unit 3-year Rolling EAF AUG2012-JUL2015 (2)`,
          link: ``,
        },
        {
          anchor: `Baseload Unit LEAC Period EAF FEB-JUL2015`,
          link: ``,
        },
        {
          anchor: `Diesel Plant 3-year Rolling EAF FEB-JUL2015`,
          link: ``,
        },
        {
          anchor: `Baseload Unit 3-year Rolling EAF`,
          link: ``,
        },
        {
          anchor: `Baseload Unit LEAC Period EAF`,
          link: ``,
        },
        {
          anchor: `Diesel Plant 3-year Rolling EAF`,
          link: ``,
        },
      ],
    },
  ],
};

export const LeacPerformance = {
  title: `LEAC PERFORMANCE CONTROL`,
  perfTitle: ``,
  perf1: [
    {
      title: `Baseload Dispatch Performance Chart`,
      ul: [
        {
          anchor: `Baseload Dispatch Chart LEAC Period AUG2014-JAN2015.pdf`,
          link: ``,
        },
        {
          anchor: `Baseload Dispatch Chart LEAC Period FEB2015-JUL2015`,
          link: ``,
        },
        {
          anchor: `Baseload Dispatch Chart LEAC Period Feb2011-Jul2011`,
          link: ``,
        },
      ],
    },
    {
      title: `Gross Heat Rate Performance Chart`,
      ul: [
        {
          anchor: `Gross Heat Rate LEAC Period AUG2014-JAN2015`,
          link: ``,
        },
        {
          anchor: `Gross Heat Rate LEAC Period AUG2014-JAN2015 (2)`,
          link: ``,
        },
        {
          anchor: `Gross Heat Rate LEAC Period Feb2011-Jul2011`,
          link: ``,
        },
      ],
    },
    {
      title: `Fuel Usage Consumption`,
      ul: [
        {
          anchor: `Fuel Usage Consumption LEAC Period AUG2014-JAN2015`,
          link: ``,
        },
        {
          anchor: `Fuel Usage Consumption LEAC Period AUG2014-JAN2015 (2)`,
          link: ``,
        },
        {
          anchor: `Fuel Usage Consumption LEAC Period Feb2011-Jul2011`,
          link: ``,
        },
      ],
    },
    {
      title: `IWPS Dispatch Performance`,
      ul: [
        {
          anchor: `IWPS Dispatch LEAC Period AUG2014-JAN2015`,
          link: ``,
        },
        {
          anchor: `IWPS Dispatch LEAC Period Feb2015-Jul2015`,
          link: ``,
        },
        {
          anchor: `IWPS Dispatch LEAC Period Feb2011-Jul2011`,
          link: ``,
        },
      ],
    },
    {
      title: `Baseload Dispatch Performance`,
      ul: [
        {
          anchor: `Baseload Dispatch LEAC Period AUG2014-JAN2015`,
          link: ``,
        },
        {
          anchor: `Baseload Dispatch LEAC Period Feb2015-Jul2015`,
          link: ``,
        },
      ],
    },
    {
      title: `Savings from Increased Baseload Use`,
      ul: [
        {
          anchor: `As of Sept 2011`,
          link: ``,
        },
      ],
    },
    {
      title: `Previous LEAC Period Control Charts:`,
      ul: [
        {
          anchor: `Aug10-Jan11 Control Chart`,
          link: ``,
        },
        // NOTE: File not found from old GPA website
        // {
        //   anchor: `Feb10-Jul10 Control Chart`,
        //   link: ``,
        // },
        // {
        //   anchor: `Aug09-Jan10 Control Chart`,
        //   link: ``,
        // },
        // {
        //   anchor: `Feb09-Jul09 Control Chart`,
        //   link: ``,
        // },
        // {
        //   anchor: `Aug08-Jan09 Control Chart`,
        //   link: ``,
        // },
      ],
    },
    {
      title: `Equivalent Forced Outage Rate (EFOR)Charts`,
      ul: [
        {
          anchor: `Baseload Unit 3-year Rolling EFOR AUG2012-JUL2015`,
          link: ``,
        },
        {
          anchor: `Baseload Unit LEAC Period EFOR AUG2014-JAN2015`,
          link: ``,
        },
        {
          anchor: `Baseload Unit LEAC Period EFOR FEB-JUL 2015`,
          link: ``,
        },
        {
          anchor: `Baseload Unit 3-year Rolling EFOR`,
          link: ``,
        },
        {
          anchor: `Baseload Unit LEAC Period EFOR`,
          link: ``,
        },
      ],
    },
  ],
  perf2: [
    {
      title: `Equivalent Availability Factor (EAF)Performance Charts`,
      ul: [
        {
          anchor: `Baseload Unit 3-year Rolling EAF AUG2012-JUL2015 (1)`,
          link: ``,
        },
        {
          anchor: `Baseload Unit LEAC Period EAF AUG2014-JAN2015`,
          link: ``,
        },
        {
          anchor: `Diesel Plant 3-year Rolling EAF AUG2012 -JUL2015`,
          link: ``,
        },
        {
          anchor: `Baseload Unit 3-year Rolling EAF AUG2012-JUL2015 (2)`,
          link: ``,
        },
        {
          anchor: `Baseload Unit LEAC Period EAF FEB-JUL2015`,
          link: ``,
        },
        {
          anchor: `Diesel Plant 3-year Rolling EAF FEB-JUL2015`,
          link: ``,
        },
        {
          anchor: `Baseload Unit 3-year Rolling EAF`,
          link: ``,
        },
        {
          anchor: `Baseload Unit LEAC Period EAF`,
          link: ``,
        },
        {
          anchor: `Diesel Plant 3-year Rolling EAF`,
          link: ``,
        },
      ],
    },
  ],
};
