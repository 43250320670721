export const JobOpenings = {
  title: `Find Job Openings`,
  openings: [
    {
      title: `HUMAN RESOURCES | CAREERS AT GPA | CURRENT OPENINGS`,
      description: `Only applications received within the open and close dates listed on the respective Job Announcements will be accepted. "Open" job announcements are available to all GPA employees and the general public for application. "In-House" job announcements are solely for permanent classified employees of Guam Power Authority. Applications received from non-permanent employees of the Guam Power Authority will be returned.`,
      anchor: `General Information for Prospective Employees`,
      link: `/uploads/General_Informationfor_Prospective_Employees_001_30ce1f1a7e.pdf?updated_at=2022-09-20T03:49:53.513Z`,
    },
  ],
  apply: [
    {
      title: `How to Apply`,
      description: `To apply for a position with the Guam Power Authority you must fill out an employment application. Employment applications must include high school diploma and college transcripts. Otherwise, applications are deemed incomplete.`,
      anchor: `GPA Employment Application Download 470 kb pdf`,
      link: `/uploads/GPA_Employment_Application_Download470_KB_a9508fc333.pdf?updated_at=2022-09-20T03:50:14.881Z`,
    },
  ],
  return: [
    {
      title: `How to Return Your Application and Required Documents`,
      quote: `“GPA is an affirmative action and equal opportunity employer."`,
      ol: [
        {
          description: `You may mail your completed employment application and copies of the required documents above to:`,
          content1: `GPA Human Resources Division`,
          content2: `P.O. Box 2977`,
          content3: `Hagatna, Guam 96932`,
        },
        {
          description: `You may fax your completed employment application and copies of the required documents above to:`,
          content1: `(671) 648-3160`,
        },
        {
          description: `You may email your completed employment application and copies of the required documents above to:`,
          text: `GPA Human Resources Office:`,
          email: `gpahr@gpagwa.com`,
          mailto: `mailto:gpahr@gpagwa.com`,
        },
        {
          description: `You may return your completed employment application and copies of the required documents above to the GPA main office:`,
          content1: `Human Resources Division`,
          content2: `Gloria B. Nelson Public Service Building`,
          content3: `688 Route 15 Fadian, Mangilao, Guam`,
        },
      ],
    },
  ],
  equality: [
    {
      description: `"The Guam Power Authority is an Equal Opportunity Employer"`,
    },
  ],
  table: [
    {
      title: {
        title: `General Information for Prospective Employees`,
        subTitle: `Employment Application: Apply for a Position`,
      },
      th: [
        `Announcement No.`,
        `Position Title`,
        `Announcement Type`,
        `Open to Public`,
        `Opening Date`,
        `Closing Date`,
      ],
      // tr: [
      //   {
      //     td: [
      //       {
      //         number: `2021-014`,
      //         position: `Safety Inspector I`,
      //         jobdesc: ``,
      //         type: `Open`,
      //         public: `Yes`,
      //         open: `05/10/2021 `,
      //         close: `Continuous`,
      //       },
      //     ],
      //   },
      //   {
      //     td: [
      //       {
      //         number: `2021-015`,
      //         position: `Safety Inspector II`,
      //         jobdesc: ``,
      //         type: `Open`,
      //         public: `Yes`,
      //         open: `05/10/2021`,
      //         close: `Continuous`,
      //       },
      //     ],
      //   },
      //   {
      //     td: [
      //       {
      //         number: `2021-029`,
      //         position: `Engineer I`,
      //         jobdesc: ``,
      //         type: `Open`,
      //         public: `Yes`,
      //         open: `09/02/2021`,
      //         close: `Continuous`,
      //       },
      //     ],
      //   },
      //   {
      //     td: [
      //       {
      //         number: `2021-030`,
      //         position: `Engineer II`,
      //         jobdesc: ``,
      //         type: `Open`,
      //         public: `Yes`,
      //         open: `09/02/2021`,
      //         close: `Continuous`,
      //       },
      //     ],
      //   },
      //   {
      //     td: [
      //       {
      //         number: `2021-031`,
      //         position: `Engineer III`,
      //         jobdesc: ``,
      //         type: `Open`,
      //         public: `Yes`,
      //         open: `09/02/2021`,
      //         close: `Continuous`,
      //       },
      //     ],
      //   },
      //   {
      //     td: [
      //       {
      //         number: `2022-005`,
      //         position: `SCADA Technician I`,
      //         jobdesc: ``,
      //         type: `Open`,
      //         public: `Yes`,
      //         open: `10/28/2021`,
      //         close: `Continuous`,
      //       },
      //     ],
      //   },
      //   {
      //     td: [
      //       {
      //         number: `2022-006`,
      //         position: `SCADA Technician II`,
      //         jobdesc: ``,
      //         type: `Open`,
      //         public: `Yes`,
      //         open: `10/28/2021`,
      //         close: `Continuous`,
      //       },
      //     ],
      //   },
      //   {
      //     td: [
      //       {
      //         number: `2022-007`,
      //         position: `SCADA Technician Leader`,
      //         jobdesc: ``,
      //         type: `Open`,
      //         public: `Yes`,
      //         open: `10/28/2021`,
      //         close: `Continuous`,
      //       },
      //     ],
      //   },
      //   {
      //     td: [
      //       {
      //         number: `2022-016`,
      //         position: `Refrigeration Mechanic I`,
      //         jobdesc: ``,
      //         type: `Open`,
      //         public: `Yes`,
      //         open: `12/23/2021`,
      //         close: `Continuous`,
      //       },
      //     ],
      //   },
      //   {
      //     td: [
      //       {
      //         number: `2022-017`,
      //         position: `SCADA Technician Supervisor`,
      //         jobdesc: ``,
      //         type: `Open`,
      //         public: `Yes`,
      //         open: `12/23/2021`,
      //         close: `Continuous`,
      //       },
      //     ],
      //   },
      // ],
    },
  ],
};
