import React, { useEffect, useState } from "react";
import {
  H1,
  Details,
  List,
  UnorderedList,
  Content as ContentContainer,
} from "@core";
import { PersonnelServicesLinks } from "@content";
import { HeaderGradient } from "@components";
import { HRPERSONELSERVICES } from "@src/types/HrPersonelServices";
import { HRPERSONELSERVICESANCHOR } from "@src/types/HrPersonelServicesAnchors";
import { getHrPersonelServicesRulesRegulations } from "@src/services/HrPersonelServicesRulesRegulations";
import { getHrPersonelServicesRequiredPostings } from "@src/services/HrPersonelServicesRequiredPostings";
import { getHrPersonelServicesPolicies } from "@src/services/HrPersonelServicesPolicies";
import { getHrPersonelServicesForms } from "@src/services/HrPersonelServicesForms";
import { getHrPersonelServicesCompensation } from "@src/services/HrPersonelServicesCompensation";
import { API } from "@src/config";
// import { getFileData } from '@services';
// import { FILEDATA } from '@types';
// import { API } from '@src/config';

export const PersonnelServices = () => {
  const [rulesRegulations, setRulesRegulations] = useState<
    HRPERSONELSERVICES[]
  >([]);
  const [requiredPosting, setRequiredPosting] = useState<
    HRPERSONELSERVICESANCHOR[]
  >([]);
  const [policies, setPolicies] = useState<HRPERSONELSERVICES[]>([]);
  const [forms, setForms] = useState<HRPERSONELSERVICESANCHOR[]>([]);
  const [compensations, setCompensations] = useState<HRPERSONELSERVICES[]>([]);

  const getData = async () => {
    const rulesRegulationsData = await getHrPersonelServicesRulesRegulations();
    const requiredPostingData = await getHrPersonelServicesRequiredPostings();
    const policiesData = await getHrPersonelServicesPolicies();
    const formsData = await getHrPersonelServicesForms();
    const compensationsData = await getHrPersonelServicesCompensation();

    setRulesRegulations(rulesRegulationsData.data);
    setRequiredPosting(requiredPostingData.data);
    setPolicies(policiesData.data);
    setForms(formsData.data);
    setCompensations(compensationsData.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Personnel Services"
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12 laptop:w-7/12 laptop:mr-20">
            <section>
              <H1>GPA PERSONNEL RULES AND REGULATIONS</H1>
              <Details>
                <span className="font-bold">
                  The GPA Personnel Rules and Regulations were promulgated on
                  October 4, 1982, under Executive Order 82-18. Since then these
                  rules and regulations have not been formally updated. However,
                  certain local or federal laws that may amend provisions of
                  these personnel rules and regulations will supersede. -{" "}
                </span>
                <a
                  className="text-orange"
                  href="https://admin.guampowerauthority.com/uploads/FY_23_Q1_GPA_Staffing_Report_a21ca869a7.pdf?updated_at=2023-03-20T05:48:48.756Z"
                  rel="noreferrer"
                  target="_blank"
                >
                  Download 733 kB
                </a>
              </Details>
              <UnorderedList>
                {rulesRegulations.map((content) => {
                  return (
                    <>
                      <List>
                        <span className="font-bold">
                          {content.attributes.Title} -{" "}
                        </span>
                        {content.attributes.Description} &nbsp;
                        {content.attributes.File.data != null ? (
                          <a
                            className="text-orange"
                            href={`${API}${content.attributes.File.data.attributes.url}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {content.attributes.anchor
                              ? content.attributes.anchor
                              : "Download"}
                          </a>
                        ) : null}
                      </List>
                    </>
                  );
                })}
              </UnorderedList>
            </section>
            <section className="my-7 tabletWide:my-14">
              <H1>COMPENSATION & PAY FOR PERFOMANCE</H1>
              {compensations.map((content) => {
                return (
                  <>
                    <Details>
                      <span className="font-bold">
                        {content.attributes.Title} -{" "}
                      </span>
                      {content.attributes.Description} &nbsp;
                      {content.attributes.File.data != null ? (
                        <a
                          className="text-orange"
                          href={`${API}${content.attributes.File.data.attributes.url}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {content.attributes.anchor
                            ? content.attributes.anchor
                            : "Download"}
                        </a>
                      ) : null}
                    </Details>
                  </>
                );
              })}
            </section>
            <section className="mt-7 tabletWide:mt-14">
              <H1>POLICIES</H1>
              {policies.map((content) => {
                return (
                  <>
                    <Details>
                      <span className="font-bold">
                        {content.attributes.Title} -{" "}
                      </span>
                      {content.attributes.Description} &nbsp;
                      {content.attributes.File.data != null ? (
                        <a
                          className="text-orange"
                          href={`${API}${content.attributes.File.data.attributes.url}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {content.attributes.anchor
                            ? content.attributes.anchor
                            : "Download"}
                        </a>
                      ) : null}
                    </Details>
                  </>
                );
              })}
            </section>
          </div>
          <section className="laptop:mt-0">
            <H1>REQUIRED POSTINGS</H1>
            <UnorderedList>
              {requiredPosting.map((content) => {
                return (
                  <>
                    {content.attributes.File.data != null ? (
                      <List className="text-orange my-6">
                        <a
                          className="text-orange"
                          href={`${API}${content.attributes.File.data.attributes.url}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {content.attributes.Anchor.length > 40
                            ? `${content.attributes.Anchor.substring(0, 40)}...`
                            : content.attributes.Anchor}
                        </a>
                      </List>
                    ) : null}
                  </>
                );
              })}
            </UnorderedList>
            <H1>GPA FORMS</H1>
            <UnorderedList>
              {forms.map((content) => {
                return (
                  <>
                    {content.attributes.File.data != null ? (
                      <List className="text-orange my-6">
                        <a
                          className="text-orange"
                          href={`${API}${content.attributes.File.data.attributes.url}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {content.attributes.Anchor.length > 40
                            ? `${content.attributes.Anchor.substring(0, 40)}...`
                            : content.attributes.Anchor}
                        </a>
                      </List>
                    ) : null}
                  </>
                );
              })}
            </UnorderedList>
            <H1>CONTACT US</H1>
            <span className="font-bold">
              Recruitment/Benefits/Records/Training
            </span>
            <UnorderedList>
              <List className="text-orange my-6">
                <a
                  className="text-orange"
                  href="https://guampowerauthority.com/corporate/human-resources/employee-benefits"
                >
                  Go to Recruitment/Benefits/Records Page
                </a>
              </List>
            </UnorderedList>
            <Details className="text-blue">
              <i>{PersonnelServicesLinks.outro}</i>
            </Details>
          </section>
        </div>
      </ContentContainer>
    </>
  );
};
