import { API } from "@src/config";

export const Rates = {
  title: `Rates`,
  subTitle: `RATE SCHEDULES`,
  description: `The Guam Power Authority provides electric power service under the following rate classes:`,
  ol: [
    {
      text: `R - Residential Service`,
      link: `${API}/uploads/R_Residential_Service_79f5a7e7b6.PDF`,
    },
    {
      text: `D - Condominium or Apartment Services`,
      link: `${API}/uploads/D_Condominium_or_Apartment_Services_40e20e8573.PDF`,
    },
    {
      text: `G - General Service Non-Demand`,
      link: `${API}/uploads/G_General_Service_Non_Demand_06040ec80e.PDF`,
    },
    {
      text: `J - General Service Demand`,
      link: `${API}/uploads/J_General_Service_Demand_5bc2855443.PDF`,
    },
    {
      text: `P - Large Power Service`,
      link: `${API}/uploads/P_Large_Power_Service_2bdf5383db.PDF`,
    },
    {
      text: `I - Independent Power Producer Service`,
      link: `${API}/uploads/I_Independent_Power_Producer_Service_5f5f7dceea.PDF`,
    },
    {
      text: `H - Private Outdoor Lighting `,
      link: `${API}/uploads/H_Private_Outdoor_Lighting_67e643fcc1.PDF`,
    },
    {
      text: `S - Small Government Service - Non-Demand`,
      link: `${API}/uploads/S_Small_Government_Service_Non_Demand_2b6d1fb771.PDF`,
    },
    {
      text: `K - Small Government Service Demand`,
      link: `${API}/uploads/K_Small_Government_Service_Demand_a226a0af7f.PDF`,
    },
    {
      text: `L - Large Government Service`,
      link: `${API}/uploads/L_Large_Government_Service_47f9690347.PDF`,
    },

    {
      text: `M - Standby, Auxiliary, Supplementary or Breakdown Service`,
      link: `${API}/uploads/M_Standby_Auxiliary_Supplementary_or_Breakdown_Service_a61f91703e.PDF`,
    },
    {
      text: `F - Public Street Lighting`,
      link: `${API}/uploads/F_Public_Street_Lighting_d8e7ec280b.PDF`,
    },
    {
      text: `N - Navy Service`,
      link: `${API}/uploads/N_Navy_Service_bc0ba130a1.PDF`,
    },
    {
      text: `A - Accomodation Service Charges`,
      link: `${API}/uploads/A_Accomodation_Service_Charges_d6e247f468.PDF`,
    },
    {
      text: `B - Service Establishment Charges`,
      link: `${API}/uploads/B_Service_Establishment_Charges_09dcbb3a1c.PDF`,
    },
    {
      text: `C - Net Metering Interim`,
      link: `${API}/uploads/C_Net_Metering_Interim_57ba4e25cc.PDF`,
    },
    {
      text: `Z - Levelized Energy Adjustment Clause (LEAC)`,
      link: `${API}/uploads/Z_Levelized_Energy_Adjustment_Clause_LEAC_95394a2965.PDF`,
    },
    {
      text: `All Power and Service Rate Schedules`,
      link: `${API}/uploads/All_Power_Service_Rate_Schedules_9edf28963b.pdf`,
    },
  ],
  table: {
    headers: [
      `LEAC Factor Effective August 1, 2021`,
      `Effective 08/01/21`,
      `Effective 10/01/21`,
      `Effective 12/01/21`,
    ],
    datas: [
      [
        `Distribution level (Secondary Customers)`,
        `$0.130400/kWh`,
        `$0.150800/kWh`,
        `$0.171458/kWh`,
      ],
      [
        `Transmission level (Primary – 13.8 KV Customers)`,
        `$0.126501/kWh`,
        `$0.146291/kWh`,
        `$0.166332/kWh`,
      ],
      [
        `Transmission level
      (Primary – 34.5 KV Customers) `,
        `$0.126137/kWh`,
        `$0.145870/kWh`,
        `$0.165853/kWh`,
      ],
      [
        `Transmission level
      (Primary – 115KV Customers)`,
        `$0.124584/kWh`,
        `$0.144074/kWh`,
        `$0.163811/kWh`,
      ],
    ],
  },
  ol2: [
    {
      text: `Accommodation Service Charges - Schedule A - 11kb`,
      link: ``,
    },
    {
      text: `Service Establishment Charges - Schedule B - 8kb`,
      link: ``,
    },
    {
      text: `All Power Service Rate Schedules `,
      link: ``,
    },
  ],
};
