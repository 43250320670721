import React from 'react';
import { H2, Details, Content as ContentContainer } from '@core';
import { HeaderGradient } from '@components';
import { TrainingAndDevelopmentSectionDetails } from '@content';

export const TrainingAndDevelopmentPage = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={TrainingAndDevelopmentSectionDetails.title}
      />
      <ContentContainer>
        <H2>{TrainingAndDevelopmentSectionDetails.subtitle1}</H2>
        <Details>{TrainingAndDevelopmentSectionDetails.description1}</Details>
        <H2>{TrainingAndDevelopmentSectionDetails.subtitle2}</H2>
        <Details>{TrainingAndDevelopmentSectionDetails.description2}</Details>
        <Details>
          {TrainingAndDevelopmentSectionDetails.ul.map((list, index) => {
            return <ul key={index}>{list}</ul>;
          })}
        </Details>{' '}
        <Details>{TrainingAndDevelopmentSectionDetails.description3}</Details>
      </ContentContainer>
    </>
  );
};
