import React, { FC, Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { Dialog, Transition } from '@headlessui/react';
import { Icons, Images } from '@core';
import { Navigations as Routes } from '@constants';
import { useWindowSize } from '@hooks';
import { ProcurementDisclosure } from './ProcurementDisclosure';
// import { FaChevronUp } from 'react-icons/fa';
// import { getFileData } from '@services';
// import { FILEDATA } from '@types';
// import { API } from '@src/config';
// import { ProcurementDisclosure } from './ProcurementDisclosure';

interface NavigationsProps {
  isOpen: boolean;
  onClose: any;
}

const Navigations: FC<NavigationsProps> = ({ isOpen, onClose }) => {
  const { width } = useWindowSize();
  const location = useLocation();

  // const [file, setFile] = useState<FILEDATA[]>([]);

  // const getData = async () => {
  //   const fileDatas = await getFileData();
  //   setFile(fileDatas.data);
  // };

  useEffect(() => {
    // getData();
  }, []);

  return (
    <>
      {width <= 1000 ? (
        <Transition appear as={Fragment} show={isOpen}>
          <Dialog
            as="div"
            className="container-1600px fixed inset-0 h-full z-40 overflow-scroll scrollbar-hide tablet:pt-20 phone:pt-20 bg-secondary-200"
            // onClose={() => (isOpen ? onClose : {})}
            onClose={onClose}
          >
            <div className="phone:h-full tablet:h-auto text-center flex justify-end">
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease duration-1000"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease duration-1000"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div
                  className="tablet:h-auto phone:h-full w-full flex flex-col overflow-scroll scrollbar-hide text-left z-20
                align-middle transition-all transform bg-white shadow-xl relative tabletWide:mb-auto"
                >
                  <div className="px-10 phone:h-full tablet:h-auto">
                    <div className="w-full border-t-2 mt-4 border-divider" />
                    <div className="pt-8 flex items-stretch">{width < 800 && <SearchBar />}</div>

                    <div className="w-full h-auto flex tabletWide:flex-row flex-wrap tablet:justify-between navTablet:justify-start navLaptop:justify-between tabletWide:gap-10  pb-12">
                      {Routes.map((navigation) => {
                        return (
                          <div
                            className="flex flex-col phone:mr-10 tablet:mr-0 phone:w-44 tablet:w-60 tabletWide:w-40"
                            key={navigation.navigation}
                          >
                            <h3 className="font-rubik font-bold text-blue mb-4 mt-10">
                              {navigation.navigation === 'In Our Community' && (
                                <a href=" /in-our-community">{navigation.navigation}</a>
                              )}
                              {navigation.navigation === 'GPA Initiatives' && (
                                <a href=" /gpa-initiatives">{navigation.navigation}</a>
                              )}
                              {navigation.navigation !== 'In Our Community' &&
                                navigation.navigation !== 'GPA Initiatives' &&
                                navigation.navigation}
                            </h3>
                            {navigation.routes.map((route) => (
                              <div key={route.routeName}>
                                {route.routeName !== 'In Our Community' &&
                                  route.routeName !== 'GPA Initiatives' &&
                                  route.routeName !== 'Procurement' && (
                                    <a
                                      className={`font-rubik hover:text-blue mb-1 ${
                                        location.pathname === route.link
                                          ? 'text-blue'
                                          : 'text-black'
                                      }`}
                                      href={route.link}
                                    >
                                      {route.routeName}
                                    </a>
                                  )}
                                {route.routeName === 'Procurement' && <ProcurementDisclosure />}
                              </div>
                            ))}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      ) : (
        <Transition appear as={Fragment} show={isOpen}>
          <Dialog
            as="div"
            className="container-1600px fixed inset-0 h-auto z-40 desktop:mt-28 mt-20 overflow-scroll scrollbar-hide bg-secondary-200"
            // onClose={() => (isOpen ? onClose : {})}
            onClose={onClose}
          >
            <div className="h-full text-center flex justify-end">
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease duration-1000"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease duration-1000"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div
                  className="h-full w-full flex flex-col overflow-hidden text-left z-20
align-middle transition-all transform navbar__linear-gradient shadow-xl relative "
                >
                  <div className="desktop:pt-0 laptop:px-20 laptop:pt-20 tablet:px-12 phone:px-7">
                    <div className="w-full border-t-2 laptop:mt-4 mt-20 border-divider" />
                    <div className="w-full h-full flex flex-row laptop:py-16 py-12 justify-evenly">
                      <div>
                        {/* How do I */}
                        <div className="flex flex-col mb-4 min-h-210px">
                          <h3 className="font-rubik font-bold text-blue mb-4">
                            {Routes[0].navigation}
                          </h3>
                          {Routes[0].routes.map((route) => (
                            <a
                              className={`font-rubik hover:text-blue mb-1 ${
                                location.pathname === route.link ? 'text-blue' : 'text-black'
                              }`}
                              href={route.link}
                              key={route.routeName}
                            >
                              {route.routeName}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div>
                        {/* Business Customers */}
                        <div className="flex flex-col mb-4 min-h-210px">
                          <h3 className="font-rubik font-bold text-blue mb-4">
                            {Routes[2].navigation}
                          </h3>
                          {Routes[2].routes.map((route) => (
                            <a
                              className={`font-rubik hover:text-blue mb-1 ${
                                location.pathname === route.link ? 'text-blue' : 'text-black'
                              }`}
                              href={route.link}
                              key={route.routeName}
                            >
                              {route.routeName}
                            </a>
                          ))}
                        </div>
                        {/* Residential Customers */}
                        <div className="flex flex-col">
                          <h3 className="font-rubik font-bold text-blue mb-4">
                            {Routes[1].navigation}
                          </h3>
                          {Routes[1].routes.map((route) => (
                            <a
                              className={`font-rubik hover:text-blue mb-1 ${
                                location.pathname === route.link ? 'text-blue' : 'text-black'
                              }`}
                              href={route.link}
                              key={route.routeName}
                            >
                              {route.routeName}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div>
                        {/* In Our Community */}
                        <div className="flex flex-col mb-4 min-h-210px">
                          <h3 className="font-rubik font-bold text-blue hover:text-lightblue mb-4">
                            <a href="/in-our-community">{Routes[4].navigation}</a>
                          </h3>
                          {Routes[4].routes.map((route) => {
                            if (route.routeName !== 'In Our Community') {
                              return (
                                <a
                                  className={`font-rubik hover:text-blue mb-1 ${
                                    location.pathname === route.link ? 'text-blue' : 'text-black'
                                  }`}
                                  href={route.link}
                                  key={route.routeName}
                                >
                                  {route.routeName}
                                </a>
                              );
                            }
                            return null;
                          })}
                        </div>
                        {/* Rates */}
                        <div className="flex flex-col">
                          <h3 className="font-rubik font-bold text-blue mb-4">
                            {Routes[3].navigation}
                          </h3>
                          {Routes[3].routes.map((route) => (
                            <a
                              className={`font-rubik hover:text-blue mb-1 ${
                                location.pathname === route.link ? 'text-blue' : 'text-black'
                              }`}
                              href={route.link}
                              key={route.routeName}
                            >
                              {route.routeName}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div>
                        {/* Corporate */}
                        <div className="flex flex-col mb-4 min-w-186px">
                          <h3 className="font-rubik font-bold text-blue mb-4">
                            {Routes[6].navigation}
                          </h3>
                          {Routes[6].routes.map((route) => {
                            if (route.routeName !== 'Procurement') {
                              return (
                                <a
                                  className={`font-rubik hover:text-blue mb-1 ${
                                    location.pathname === route.link ? 'text-blue' : 'text-black'
                                  }`}
                                  href={route.link}
                                  key={route.routeName}
                                >
                                  {route.routeName}
                                </a>
                              );
                            }
                            return <ProcurementDisclosure key={route.routeName} />;
                          })}
                        </div>
                        {/* GPA Initiatives */}
                        <div className="flex flex-col">
                          <h3 className="font-rubik font-bold text-blue hover:text-lightblue mb-4">
                            <a href="/gpa-initiatives">{Routes[5].navigation}</a>
                          </h3>
                          {Routes[5].routes.map((route) => {
                            if (route.routeName !== 'GPA Initiatives') {
                              return (
                                <a
                                  className={`font-rubik hover:text-blue mb-1 ${
                                    location.pathname === route.link ? 'text-blue' : 'text-black'
                                  }`}
                                  href={route.link}
                                  key={route.routeName}
                                >
                                  {route.routeName}
                                </a>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};

const SearchBar = () => {
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const param = new URL(window.location.href).searchParams.get('q');
    setSearchInput(param !== null ? param : '');
  }, []);

  const inputOnChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchInput(e.currentTarget.value);
    if (location.pathname === '/search') {
      navigate(`search?q=${e.currentTarget.value}`);
    }
  };

  const searchSubmitHandler = (e: any) => {
    e.preventDefault();
    navigate(`search?q=${searchInput}`);
  };

  return (
    <form
      className="search-box__container mr-0 w-full tablet:mr-8 tablet:w-auto"
      onSubmit={searchSubmitHandler}
    >
      <div className="flex items-center justify-center">
        <input
          className="border-blue border-1 border-r-0 h-12 w-full"
          onChange={inputOnChangeHandler}
          placeholder="Search"
          type="text"
          value={searchInput}
        />
        <button
          className="bg-blue text-white cursor-pointer h-12 w-12 flex items-center justify-center"
          type="submit"
        >
          <img alt="MENUBAR" className="h-6 w-6" src={Icons.MagnifyingGlass} />
        </button>
      </div>
    </form>
  );
};

export const Navbar = ({
  toggleScrollable,
}: {
  toggleScrollable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const location = useLocation();
  const [isOpen, setOpen] = React.useState(false);
  const { width } = useWindowSize();

  // const onClose = () => {
  //   setOpen(false);
  // };

  const toggleNavbarHandler = () => {
    if (isOpen) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  React.useEffect(() => {
    // onClose();
    setOpen(false);
    toggleScrollable(true);
  }, [location]);

  useEffect(() => {
    if (isOpen) {
      toggleScrollable(false);
    } else {
      toggleScrollable(true);
    }
  }, [isOpen]);

  return (
    <nav className="w-full absolute z-50 bg-white flex flex-row py-3 items-center desktop:h-28 tablet:h-20 phone:h-20 laptop:px-20 tablet:px-12 phone:px-7">
      <Navigations isOpen={isOpen} onClose={toggleNavbarHandler} />
      <a className="h-full mr-auto" href="/">
        <img alt="GPALOGO" className="h-full mr-auto" src={Images.GPALogo} />
      </a>
      {width >= 800 && <SearchBar />}
      <a
        className="font-rubik font-medium text-base desktop:text-xl text-darkblue hover:opacity-60"
        href="/contact-us"
      >
        Contact Us
      </a>

      <button
        className="desktop:h-14 h-10 px-3 bg-orange flex flex-row justify-center items-center phone:ml-5 tablet:ml-10 
    cursor-pointer hover:opacity-60"
        onClick={() => setOpen(!isOpen)}
        type="button"
      >
        <span className="font-rubik font-semibold text-sm text-white">Menu</span>
        <img alt="MENUBAR" className="h-8 w-7 ml-3" src={Icons.MenuBar} />
      </button>
    </nav>
  );
};
