import React from 'react';

export const PageNotFound = () => (
  <div className="flex phone:px-5 tablet:px-10 laptop:px-20 justify-center desktop:h-4/5 items-center">
    <div className="items-center laptop:pt-32 phone:pt-32 tablet:w-500px phone:w-full pb-24">
      <h2 className="font-semibold font-rubik text-secondary-100 text-2xl mb-5 text-center">
        OOPS!
      </h2>
      <h1
        className="font-rubik font-bold laptop:text-4xl phone:text-3xl text-primary-100
        text-center mb-10"
      >
        <span className="text-blue">Error 404</span> Page Not Found
      </h1>
      <p className="mb-10 text-center">
        Sorry, we can't find the page for the address that you entered.
      </p>
    </div>
  </div>
);
