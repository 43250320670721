import React, { useEffect, useState } from 'react';
import { H1, Details, Content as ContentContainer } from '@core';
import { PlanningDataLinks } from '@content';
import { HeaderGradient } from '@components';
import { getFileData } from '@services';
import { FILEDATA } from '@types';
import { API } from '@src/config';

export const PlanningData = () => {
  const [file, setFile] = useState<FILEDATA[]>([]);

  const getData = async () => {
    const fileDatas = await getFileData();
    setFile(fileDatas.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={PlanningDataLinks.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-full">
            <section>
              <H1 className="text-blue mb-5">{PlanningDataLinks.title}</H1>
              {PlanningDataLinks.contents.map((content) => {
                return (
                  <>
                    {file
                      .filter((fileData) => fileData.attributes.AnchorText === content.anchor)
                      .map((fileData) => {
                        return (
                          <>
                            <Details>
                              {content.title} <br />
                              <a
                                className="text-orange"
                                href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                rel="noreferrer"
                              >
                                {fileData.attributes.AnchorText}
                              </a>
                            </Details>
                          </>
                        );
                      })}
                  </>
                );
              })}
            </section>
          </div>
        </div>
      </ContentContainer>
      <div className="desktop:h-20 desktopWide:h-1/3 w-full">&nbsp;</div>
    </>
  );
};
