import React, { FC, PropsWithChildren, HTMLAttributes } from 'react';

interface DetailsProp extends HTMLAttributes<HTMLParagraphElement> {
  className?: string;
}

export const Details: FC<PropsWithChildren<DetailsProp>> = ({ children, className }, props) => {
  return (
    <p
      className={`font-rubik font-light text-black phone:text-sm tablet:text-base laptop:text-lg 
      desktop:text-lg mb-5 ${className}`}
      {...props}
    >
      {children}
    </p>
  );
};
