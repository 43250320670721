import React, { useState, useEffect } from "react";
import { Markup } from "interweave";
import {
  H1,
  H2,
  UnorderedList,
  List,
  Content as ContentContainer,
  Details,
  Images,
  Table,
  TableCell as TD,
} from "@core";
import {
  CustomerService,
  SSMServiceContent1,
  SSMServiceContent2,
  ServiceTable,
} from "@content";
import { HeaderGradient } from "@components";
import { getResidentialServiceTableData } from "@services";
import { RESIDENTIALSERVICEDATA } from "@types";
// import { API } from '@src/config';

export const StartStopMoveService = () => {
  const [tableData, setTableData] = useState<RESIDENTIALSERVICEDATA[]>([]);
  // const [file, setFile] = useState<FILEDATA[]>([]);

  const getData = async () => {
    const tableDatas = await getResidentialServiceTableData();
    // const fileDatas = await getFileData();
    setTableData(tableDatas.data);
    // setFile(fileDatas.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={SSMServiceContent1.title}
      />
      <div
        className="h-1/4 tabletWide:h-1/3 laptop:h-1/2 bg-top bg-cover bg-hero"
        style={{ backgroundImage: `url(${Images.StartTopService})` }}
      />
      <ContentContainer>
        <div className="w-12/12">
          <H1 className="mb-5">
            <Markup content={CustomerService.title} />
          </H1>
          <UnorderedList>
            <div className="grid laptop:grid-flow-col phone:grid-flow-row laptop:grid-cols-2 laptop:grid-rows-5 gap-x-10">
              {CustomerService.contents.map((content) => {
                return (
                  <>
                    <List className="text-orange">
                      <a href={content.link}>{content.anchor}</a>
                    </List>
                  </>
                );
              })}
            </div>
          </UnorderedList>
        </div>
        <div
          className="w-full border-t-2 my-10 border-divider"
          id="start-service"
        />
        {SSMServiceContent1.contents.map((content) => (
          <div key={content.header}>
            <H1 className="mb-5">{content.header}</H1>
            <Details>
              <p>
                To apply for power, visit the Payment Center at the Gloria B.
                Nelson Public Service Building, 688 Route 15 Fadian, Mangilao;
                the Hagatña Satellite Office, Suite 103 Julale Shopping Center,
                424 West O’Brien Drive; or the Guam Waterworks Authority in
                upper Tumon. To save time, you may{" "}
                <a
                  className="text-orange"
                  href="https://admin.guampowerauthority.com/uploads/GPA_Applicationand_Agreementfor_Electrical_Service_9a69b5b961.pdf?updated_at=2022-09-09T05:54:17.432Z"
                  rel="noreferrer"
                  target="_blank"
                >
                  download the application
                </a>{" "}
                and fill it out prior your visit. You'll also need to bring the
                documents and payments that are explained below.
              </p>
            </Details>
            {/* {file
              .filter((fileData) => fileData.attributes.AnchorText === content.link.text)
              .map((fileData) => {
                return (
                  <>
                    <Details className="text-orange">
                      <a
                        href={`${API}${fileData.attributes.File.data.attributes.url}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {fileData.attributes.AnchorText}
                      </a>
                    </Details>
                  </>
                );
              })} */}
            <Details className="mt-10">{content.ulTitle}</Details>
            <UnorderedList className="mb-8">
              {content.ul.map((ul) => {
                return (
                  <List className="mb-5" key={ul}>
                    {ul}
                  </List>
                );
              })}
            </UnorderedList>
            <Details>{content.descriptionEnd}</Details>
            {content.semiContents.map((semiContent) => {
              return (
                <div id={semiContent.id} key={semiContent.header}>
                  <H2>{semiContent.header}</H2>
                  <Details>{semiContent.description}</Details>
                  {semiContent.ulTitle !== "" ? (
                    <Details className="mt-10">{semiContent.ulTitle}</Details>
                  ) : (
                    ""
                  )}
                  {semiContent.ul.length !== 0 ? (
                    <UnorderedList className="mb-8">
                      {semiContent.ul.map((ul) => {
                        return <List key={ul}>{ul}</List>;
                      })}
                    </UnorderedList>
                  ) : (
                    ""
                  )}
                  <Details>{semiContent.descriptionEnd}</Details>
                </div>
              );
            })}
            {ServiceTable.table.map((cell) => {
              return (
                <>
                  <div className="flex justify-center py-8 mb-8">
                    <Table className="text-center">
                      <tr>
                        {cell.th.map((heading) => {
                          return (
                            <TD key={heading} rowType="heading">
                              {heading}
                            </TD>
                          );
                        })}
                      </tr>
                      {tableData.map((data) => {
                        return (
                          <>
                            <tr>
                              <TD>{data.attributes.NumberOfRooms}</TD>
                              <TD>{data.attributes.DepositAmount}</TD>
                            </tr>
                          </>
                        );
                      })}
                    </Table>
                  </div>
                </>
              );
            })}
          </div>
        ))}
        {SSMServiceContent2.contents.map((content) => {
          return (
            <div key={content.header}>
              <H2>{content.header}</H2>
              <Details>{content.description}</Details>
            </div>
          );
        })}
        {SSMServiceContent2.contents2.map((content) => {
          return (
            <>
              <div id={content.id}>
                <H1>{content.header}</H1>
                <Details>
                  <strong className="font-bold">{content.intro}</strong>
                  {content.description}
                </Details>
                <UnorderedList className="mb-8">
                  {content.ul.map((ul) => {
                    return <List key={ul}>{ul}</List>;
                  })}
                </UnorderedList>
                {content.semiContents.map((semiContent) => {
                  return (
                    <div key={semiContent.header}>
                      <H2>{semiContent.header}</H2>
                      <Details>{semiContent.description}</Details>
                      {semiContent.ulTitle !== "" ? (
                        <Details className="mt-10">
                          {semiContent.ulTitle}
                        </Details>
                      ) : (
                        ""
                      )}
                      {semiContent.ul.length !== 0 ? (
                        <UnorderedList className="mb-8">
                          {semiContent.ul.map((ul) => {
                            return <List key={ul}>{ul}</List>;
                          })}
                        </UnorderedList>
                      ) : (
                        ""
                      )}

                      <Details>{semiContent.descriptionEnd}</Details>
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
        {CustomerService.additionals.map((additional) => {
          return (
            <div id={additional.id} key={additional.header}>
              <H2 className="font-bold text-blue">{additional.header}</H2>
              {/* {file
                .filter((fileData) => fileData.attributes.AnchorText === additional.anchor)
                .map((fileData) => {
                  return (
                    <>
                      <Details className="text-orange">
                        <a
                          href={`${API}${fileData.attributes.File.data.attributes.url}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <u>{fileData.attributes.AnchorText}</u>
                        </a>
                      </Details>
                    </>
                  );
                })} */}
              <Details className="text-orange">
                <a href={additional.link}>
                  <u>{additional.text}</u>
                </a>
              </Details>
            </div>
          );
        })}
      </ContentContainer>
    </>
  );
};
