import React, { FC, PropsWithChildren, HTMLAttributes } from 'react';

interface ContentProp extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

export const Content: FC<PropsWithChildren<ContentProp>> = ({ children, className }, props) => {
  return (
    <div
      className={`laptop:pt-10 laptop:px-20 laptop:pb-20 tablet:p-12 phone:p-7 ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};
