import React from 'react';
import { HeaderGradient, WattsNew } from '@components';
import { Content as ContentContainer } from '@core';

export const CommunityNews = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-misc-orange"
        rightColor="to-misc-yellow"
        title="Community News"
      />
      <ContentContainer>
        <WattsNew />
      </ContentContainer>
      <div className="desktop:h-1/3 w-full">&nbsp;</div>
    </>
  );
};
