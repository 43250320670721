export const PetitionsContent = {
  pageTitle: `Petitions`,
  title: `The Guam Power Authority petitions the Consolidated Commision on Utilites (CCU) the following:`,
  contents: [
    {
      anchor: `Creation of Position - GIS Supervisor`,
    },
    {
      anchor: `Creation of Position - Safety and Physical Security Manager (GPA)`,
    },
    {
      anchor: `Creation of Position - Utility Customer Care Representative Series`,
    },
  ],
};
