import React, { useEffect, useState } from 'react';
import { H1, H2, Details, List, UnorderedList } from '@core';
import {
  Tips,
  PurchaseAppliance,
  EnergyStar,
  ShopWisely,
  SaveUsingAircon,
  StandByPower,
  HeatingPower,
  ImportantWarning,
  Lighting,
  BulbComparison,
  HeatResistantHomes,
  CookingPrep,
  Refrigerator,
  Laundry,
} from '@content';
import { getFileData } from '@services';
import { FILEDATA } from '@types';
import { API } from '@src/config';

export const WhatYouCanDo = () => {
  const [file, setFile] = useState<FILEDATA[]>([]);

  const getData = async () => {
    const fileDatas = await getFileData();
    setFile(fileDatas.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="mb-10 tablet:mb-20">
        {Tips.contents.map((content) => {
          return (
            <>
              <H1>{content.title}</H1>
              <Details>
                {content.desc}
                {file
                  .filter((fileData) => fileData.attributes.AnchorText === content.title)
                  .map((fileData) => {
                    return (
                      <>
                        <a
                          className="text-orange"
                          href={`${API}${fileData.attributes.File.data.attributes.url}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          HERE
                        </a>
                      </>
                    );
                  })}
              </Details>
              <img alt={content.alt} className="mb-10 tablet:mb-20" src={content.img} />
            </>
          );
        })}
      </section>
      <hr className="border-gray" />
      <section className="my-10 tablet:my-20 flex flex-col-reverse tabletWide:flex-row gap-0 tabletWide:gap-20">
        <div className="w-full tabletWide:w-1/2 laptop:pr-24 desktop:pr-64">
          <H1>{PurchaseAppliance.title}</H1>
          <UnorderedList>
            {PurchaseAppliance.ul1.map((value) => {
              return <List key={value}>{value}</List>;
            })}
          </UnorderedList>
          <Details className="text-darkblue">{PurchaseAppliance.description}</Details>
          <UnorderedList>
            {PurchaseAppliance.ul2.map((value) => {
              return <List key={value}>{value}</List>;
            })}
          </UnorderedList>
        </div>
        <div className="w-full tabletWide:w-1/2 flex justify-center">
          <img alt="/" className="pb-10 tabletWide:pb-0" src={PurchaseAppliance.img} />
        </div>
      </section>
      <hr className="border-gray" />
      <section className="my-10 tablet:my-20 flex flex-col tabletWide:flex-row gap-0 tabletWide:gap-20">
        <div className="w-full tabletWide:w-1/2 flex justify-center">
          <img
            alt="/"
            className="pb-10 tabletWide:pb-0 laptop:w-11/12 laptop:h-auto self-center"
            src={EnergyStar.img}
          />
        </div>
        <div className="w-full tabletWide:w-1/2">
          <H1>{EnergyStar.title}</H1>
          <UnorderedList>
            {EnergyStar.ul.map((value) => {
              return <List key={value}>{value}</List>;
            })}
          </UnorderedList>
          <Details>{EnergyStar.description}</Details>
        </div>
      </section>
      <hr className="border-gray" />
      <section className="my-10 tablet:my-20 flex flex-col-reverse tabletWide:flex-row gap-0 tabletWide:gap-20">
        <div className="w-full tabletWide:w-1/2 pb-10 tabletWide:pb-0">
          <H1>{ShopWisely.title}</H1>
          <H2>{ShopWisely.description1}</H2>
          <UnorderedList>
            {ShopWisely.ul.map((value) => {
              return <List key={value}>{value}</List>;
            })}
          </UnorderedList>
          <Details>{ShopWisely.description2}</Details>
        </div>
        <div className="w-full tabletWide:w-1/2 flex justify-center">
          <img
            alt="/"
            className="pb-10 tabletWide:pb-0 laptop:w-5/12 laptop:h-4/12 desktop:w-auto desktop:h-full self-center"
            src={ShopWisely.img}
          />
        </div>
      </section>
      <hr className="border-gray" />
      <section className="my-10 tablet:my-20 flex flex-col tabletWide:flex-row gap-0 tabletWide:gap-20">
        <div className="w-full tabletWide:w-1/2 flex justify-center">
          <img alt="/" className="pb-10 tabletWide:pb-0" src={SaveUsingAircon.img} />
        </div>
        <div className="w-full tabletWide:w-1/2">
          <H1>{SaveUsingAircon.title}</H1>
          <UnorderedList>
            {SaveUsingAircon.ul.map((value) => {
              return <List key={value}>{value}</List>;
            })}
          </UnorderedList>
        </div>
      </section>
      <hr className="border-gray" />
      <section className="my-10 tablet:my-20 flex flex-col-reverse tabletWide:flex-row gap-0 tabletWide:gap-20">
        <div className="w-full tabletWide:w-1/2">
          <H1>{StandByPower.title}</H1>
          <H2>{StandByPower.description}</H2>
          {StandByPower.contents.map((content) => {
            return (
              <Details key={content.strong}>
                <strong className="font-bold">{content.strong}</strong>
                {content.p}
              </Details>
            );
          })}
          <Details>{StandByPower.subTitle}</Details>
          <UnorderedList>
            {StandByPower.ul.map((value) => {
              return <List key={value}>{value}</List>;
            })}
          </UnorderedList>
        </div>
        <div className="w-full tabletWide:w-1/2 flex justify-center">
          <img
            alt="/"
            className="w-auto h-1/2 pb-10 tabletWide:pb-0 laptop:mt-28"
            src={StandByPower.img}
          />
        </div>
      </section>
      <hr className="border-gray" />
      <section className="my-10 tablet:my-20 flex flex-col tabletWide:flex-row gap-0 tabletWide:gap-20">
        <div className="w-full tabletWide:w-1/2 flex justify-center">
          <img alt="/" className="pb-10 tabletWide:pb-0" src={HeatingPower.img} />
        </div>
        <div className="w-full tabletWide:w-1/2">
          <H1>{HeatingPower.title}</H1>
          <H2>{HeatingPower.description}</H2>
          <Details>{HeatingPower.subTitle}</Details>
          <UnorderedList>
            {HeatingPower.ul.map((value) => {
              return <List key={value}>{value}</List>;
            })}
          </UnorderedList>
        </div>
      </section>
      <section className="my-10 tablet:my-20 flex flex-col-reverse tabletWide:flex-row gap-0 tabletWide:gap-20">
        <div className="w-full tabletWide:w-1/2">
          <H2>{ImportantWarning.strong}</H2>
          <UnorderedList>
            {ImportantWarning.ul.map((value) => {
              return (
                <>
                  <List key={value.p}>{value.p}</List>
                </>
              );
            })}
          </UnorderedList>
        </div>
        <div className="w-full tabletWide:w-1/2 flex justify-center">
          <img alt="/" className="pb-10 tabletWide:pb-0" src={ImportantWarning.img} />
        </div>
      </section>
      <hr className="border-gray" />
      <section className="my-10 tablet:my-20">
        <H1>{Lighting.title}</H1>
        <H2>{Lighting.description}</H2>
        <H2>{Lighting.subTitle}</H2>
        <UnorderedList>
          {Lighting.ul.map((value) => {
            return <List key={value}>{value}</List>;
          })}
        </UnorderedList>
      </section>
      <hr className="border-gray" />
      <section className="my-10 tablet:my-20 flex flex-col justify-center text-center">
        <H1>{BulbComparison.title}</H1>
        <img alt="/" className="tabletWide:w-1/2 self-center" src={BulbComparison.img} />
        <div className="tabletWide:text-left flex justify-center">
          <Details className="tabletWide:w-1/2 text-orange">
            {BulbComparison.text}
            <a href={BulbComparison.link} rel="noreferrer" target="_blank">
              {BulbComparison.anchor}
            </a>
          </Details>
        </div>
      </section>
      <hr className="border-gray" />
      <section className="my-10 tablet:my-20 flex flex-col tabletWide:flex-row gap-0 tabletWide:gap-20">
        <div className="w-full tabletWide:w-1/2 flex justify-center">
          <img
            alt="/"
            className="w-auto h-2/3 pb-10 tabletWide:pb-0 self-center"
            src={HeatResistantHomes.img}
          />
        </div>
        <div className="w-full tabletWide:w-1/2">
          <H1>{HeatResistantHomes.title}</H1>
          <Details>
            <strong className="font-bold">{HeatResistantHomes.strong1}</strong>
            {HeatResistantHomes.p}
            <br />
          </Details>
          <Details>
            <strong className="font-bold">{HeatResistantHomes.strong2}</strong>
          </Details>
          <UnorderedList>
            {HeatResistantHomes.contents.map((content) => {
              return <List key={content}>{content}</List>;
            })}
          </UnorderedList>
        </div>
      </section>
      <hr className="border-gray" />
      <section className="my-10 tablet:my-20 flex flex-col-reverse tabletWide:flex-row gap-0 tabletWide:gap-20">
        <div className="w-full tabletWide:w-1/2">
          <H1>{CookingPrep.title}</H1>
          <H2>{CookingPrep.description}</H2>
          <UnorderedList>
            {CookingPrep.ul.map((value) => {
              return <List key={value}>{value}</List>;
            })}
          </UnorderedList>
        </div>
        <div className="w-full tabletWide:w-1/2 flex justify-center">
          <img alt="/" className="pb-10 tabletWide:pb-0" src={CookingPrep.img} />
        </div>
      </section>
      <section className="my-10 tablet:my-20 flex flex-col tabletWide:flex-row gap-0 tabletWide:gap-20">
        <div className="w-full tabletWide:w-1/2 flex justify-center">
          <img alt="/" className="pb-10 tabletWide:pb-0" src={Refrigerator.img} />
        </div>
        <div className="w-full tabletWide:w-1/2">
          <H1>{Refrigerator.title}</H1>
          <UnorderedList>
            {Refrigerator.ul.map((value) => {
              return <List key={value}>{value}</List>;
            })}
          </UnorderedList>
          <Details>{Refrigerator.note}</Details>
        </div>
      </section>
      <section className="my-10 tablet:my-20 flex flex-col-reverse tabletWide:flex-row gap-0 tabletWide:gap-20">
        <div className="w-full tabletWide:w-1/2">
          <H1>{Laundry.title}</H1>
          <UnorderedList>
            {Laundry.ul.map((value) => {
              return <List key={value}>{value}</List>;
            })}
          </UnorderedList>
        </div>
        <div className="w-full tabletWide:w-1/2 flex justify-center">
          <img alt="/" className="pb-10 tabletWide:pb-0" src={Laundry.img} />
        </div>
      </section>
    </>
  );
};
