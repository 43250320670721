import React, { useEffect, useState } from "react";
import {
  H1,
  H3,
  List,
  UnorderedList,
  OrderedList,
  Details,
  Content as ContentContainer,
  Images,
} from "@core";
import {
  Overview,
  MissionVisionFactors,
  // Documents,
  Officials,
  // Reports,
  Standards,
  DepartmentNumbers,
} from "@content";
import { HeaderGradient } from "@components";
import { ABOUTUSCCUMEETINGRECORDINGS } from "@src/types/AboutUsCCUMeetingRecordings";
import { ABOUTUSDEPARTMENTNUMBERS } from "@src/types/AboutUsDepartmentNumbers";
import { ABOUTUSENGINEERING } from "@src/types/AboutUsEngineerings";
import { ABOUTUSGMPERFORMANCES } from "@src/types/AboutUsGMPerformances";
import { ABOUTUSINTERNALAUDITS } from "@src/types/AboutUsInternalAudits";
import { ABOUTUSSUPPLYMANAGEMENTADMINISTRATION } from "@src/types/AboutUsSupplyManagementAdministrations";
import { getAboutUsCCUMeetingRecordings } from "@src/services/AboutUsCCUMeetingRecordings";
import { getAboutUsDepartmentNumbers } from "@src/services/AboutUsDepartmentNumbers";
import { getAboutUsEngineering } from "@src/services/AboutUsEngineering";
import { getAboutUsGMPerformance } from "@src/services/AboutUsGMPerformance";
import { getAboutUsInternalAudit } from "@src/services/AboutUsInternalAudit";
import { getAboutUsSupplyManagementAdministration } from "@src/services/AboutUsSupplyManagementAdministration";
import { API } from "@src/config";
// import { getFileData } from '@services';
// import { FILEDATA } from '@types';
// import { API } from '@src/config';

export const About = () => {
  // const [file, setFile] = useState<FILEDATA[]>([]);

  const [meetingRecords, setMeetingRecords] = useState<
    ABOUTUSCCUMEETINGRECORDINGS[]
  >([]);
  const [departmentNumbers, setDepartmentNumbers] = useState<
    ABOUTUSDEPARTMENTNUMBERS[]
  >([]);
  const [engineering, setEngineering] = useState<ABOUTUSENGINEERING[]>([]);
  const [gmPerformance, setGmPerformance] = useState<ABOUTUSGMPERFORMANCES[]>(
    []
  );
  const [internalAudit, setInternalAudit] = useState<ABOUTUSINTERNALAUDITS[]>(
    []
  );
  const [supplyManagementAdministration, setsupplyManagementAdministration] =
    useState<ABOUTUSSUPPLYMANAGEMENTADMINISTRATION[]>([]);

  const getData = async () => {
    // const fileDatas = await getFileData();
    // setFile(fileDatas.data);
    const meetingRecordsData = await getAboutUsCCUMeetingRecordings();
    const departmentNumbersData = await getAboutUsDepartmentNumbers();
    const engineeringData = await getAboutUsEngineering();
    const gmPerformanceData = await getAboutUsGMPerformance();
    const internalAuditData = await getAboutUsInternalAudit();
    const supplyManagementAdministrationData =
      await getAboutUsSupplyManagementAdministration();

    setMeetingRecords(meetingRecordsData.data);
    setDepartmentNumbers(departmentNumbersData.data);
    setEngineering(engineeringData.data);
    setGmPerformance(gmPerformanceData.data);
    setInternalAudit(internalAuditData.data);
    setsupplyManagementAdministration(supplyManagementAdministrationData.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const clickItem = (link?: string, file?: any) => {
    if (file != null) {
      window.open(`${API}${file}`);
    } else {
      window.open(`${link}`);
    }
  };

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="About Us"
      />
      <div
        className="h-1/4 tabletWide:h-1/3 laptop:h-1/2 bg-top bg-cover bg-hero"
        style={{ backgroundImage: `url(${Images.AboutUsBanner})` }}
      />
      <ContentContainer>
        {/* <div className="flex laptop:flex-row phone:flex-col"> */}
        <div className="flex flex-col">
          <section className="grid grid-cols-1 tablet:grid-cols-2 my-2 tablet:my-4">
            {Overview.contents.map((content) => {
              return (
                <>
                  <div className="mb-5 tablet:mb-0 pr-10">
                    <H1 className="mb-5">{content.title}</H1>
                    <UnorderedList>
                      {content.ul.map((value) => {
                        return <List key={value}>{value}</List>;
                      })}
                    </UnorderedList>
                  </div>
                </>
              );
            })}
          </section>
          <section
            className="grid grid-cols-1 tablet:grid-cols-2 gap-x-20 my-2 tablet:my-5
            bg-shadow  laptop:-mx-20 tablet:-mx-12 phone:-mx-7 laptop:p-20 tablet:p-12 phone:p-7"
          >
            <div className="text-center flex items-center justify-center flex-row tablet:flex-col my-4">
              <h1 className="font-jubilat tracking-normal text-blue laptop:text-5xl tablet:text-2xl phone:text-xl m-1">
                ORGANIZATIONAL
              </h1>
              <h1 className="font-jubilat tracking-normal text-blue laptop:text-5xl tablet:text-2xl phone:text-xl m-1">
                CHART
              </h1>
            </div>
            <img
              alt="Organizational Chart"
              className="laptop:h-full tablet:h-auto phone:h-auto"
              src={Images.OrgChart3}
            />
          </section>
          <section className="grid grid-cols-1 tablet:grid-cols-3 gap-x-20 my-5 tablet:my-14">
            {MissionVisionFactors.contents.map((content) => {
              return (
                <>
                  <div className="first-mb-3 tablet:first-mb-0">
                    <H1 className="mb-5">{content.title}</H1>
                    <Details>{content.description}</Details>
                    <OrderedList>
                      {content.ol.map((value) => {
                        return <List key={value}>{value}</List>;
                      })}
                    </OrderedList>
                  </div>
                </>
              );
            })}
          </section>
          <section
            className="grid grid-cols-1 tablet:grid-cols-3 gap-x-20 my-2 tablet:my-5
            bg-shadow laptop:-mx-20 tablet:-mx-12 phone:-mx-7 laptop:pb-20 laptop:px-20 laptop:pt-14 tablet:p-12 phone:p-7"
          >
            <div>
              <H1 className="mb-5">Authority Policies</H1>
              <Details className="text-orange">
                <a
                  href="https://admin.guampowerauthority.com/uploads/P_L_No_34_5_6157162da5.pdf"
                  rel="noreferrer"
                  target="_blank"
                >
                  P.L. No. 34-5.pdf
                </a>
              </Details>
              <Details>
                “In compliance with Public Law 34-05, relative to require
                policies/procedures to be posted on the Agency’s website.”
              </Details>
            </div>
            <div>
              <H1 className="mb-5">Engineering</H1>
              <UnorderedList className="mb-5">
                {engineering.map((value) => {
                  return (
                    <>
                      <List className="text-orange">
                        <a
                          href={
                            value.attributes.File?.data != null
                              ? `${API}${value.attributes.File.data.attributes.url}`
                              : `#`
                          }
                          rel="noreferrer"
                          target="_blank"
                        >
                          {value.attributes.Anchor}
                        </a>
                      </List>
                    </>
                  );
                })}
              </UnorderedList>
            </div>
            <div>
              <H1 className="mb-5">Internal Audit</H1>
              <UnorderedList className="mb-5">
                {internalAudit.map((value) => {
                  return (
                    <>
                      <List className="text-orange">
                        <a
                          href={
                            value.attributes.File?.data != null
                              ? `${API}${value.attributes.File.data.attributes.url}`
                              : `#`
                          }
                          rel="noreferrer"
                          target="_blank"
                        >
                          {value.attributes.Anchor}
                        </a>
                      </List>
                    </>
                  );
                })}
              </UnorderedList>
            </div>
            <div>
              <H1 className="mb-5">Planning & Regulatory Division</H1>
            </div>
            <div>
              <H1 className="mb-5">Supply Management Administration</H1>
              <UnorderedList className="mb-5">
                {supplyManagementAdministration.map((value) => {
                  return (
                    <>
                      <List className="text-orange">
                        <a
                          href={
                            value.attributes.File?.data != null
                              ? `${API}${value.attributes.File.data.attributes.url}`
                              : `#`
                          }
                          rel="noreferrer"
                          target="_blank"
                        >
                          {value.attributes.Anchor}
                        </a>
                      </List>
                    </>
                  );
                })}
              </UnorderedList>
            </div>
          </section>
          <section
            className="grid grid-cols-1 mt-10 mb-5 tablet:my-20"
            id="about-us-general-manager"
          >
            <img
              alt="John Benevente"
              className="h-full w-64 m-auto"
              src={Images.JohnBenevente}
            />
            <div>
              {Officials.johnBenevente.map((content) => {
                return (
                  <>
                    <H1 className="mb-5 text-center">{content.title}</H1>
                    <Details className="text-center">
                      {content.position}
                      <br />
                      {content.company}
                    </Details>
                    <Details>{content.paragraph1}</Details>
                    <Details>{content.paragraph2}</Details>
                    <Details>{content.paragraph3}</Details>
                    <Details>{content.paragraph4}</Details>
                    <Details>{content.paragraph5}</Details>
                    <Details>{content.paragraph6}</Details>
                    <Details>{content.paragraph7}</Details>
                    <Details>{content.paragraph8}</Details>
                    <Details>{content.paragraph9}</Details>
                    <Details>{content.paragraph10}</Details>
                    <Details>{content.paragraph11}</Details>
                    <Details>{content.paragraph12}</Details>
                    <Details>{content.paragraph13}</Details>
                    <Details>{content.paragraph14}</Details>
                    <Details>{content.paragraph15}</Details>
                    <Details>{content.paragraph16}</Details>
                    <Details>{content.paragraph17}</Details>
                    <Details>
                      {content.paragraph18}
                      <br />
                      {content.paragraph19}
                    </Details>
                  </>
                );
              })}
            </div>
          </section>
          <section className="grid grid-cols-1 tablet:grid-cols-2 gap-x-20 gap-y-5 my-2 tablet:my-5">
            <div>
              <H1 className="mb-5">GM PERFORMANCE</H1>
              <H3>The Consolidated Commission on Utilities</H3>
              <Details>
                “Herein provides public notice pursuant to 5 GCA § 43203, of the
                public availability of the Annual Performance Review of the
                General Manager of the Guam Power Authority that took place on
                December 3, 2020. The performance review is available in full on
                the Authority’s website and on the Consolidated Commission on
                Utilities website.”
              </Details>
              <UnorderedList>
                {gmPerformance.map((value) => {
                  return (
                    <>
                      <List className="text-orange">
                        <a
                          href={
                            // eslint-disable-next-line no-nested-ternary
                            value.attributes.File?.data != null
                              ? `${API}${value.attributes.File.data.attributes.url}`
                              : value.attributes.Link
                              ? value.attributes.Link
                              : "#"
                          }
                          rel="noreferrer"
                          target="_blank"
                        >
                          {value.attributes.Anchor}
                        </a>
                      </List>
                    </>
                  );
                })}
              </UnorderedList>
            </div>
            <div>
              <H1 className="mb-5">CCU MEETING 11.10.20 AUDIO RECORDINGS</H1>
              <UnorderedList>
                {meetingRecords.map((value) => {
                  return (
                    <>
                      <List className="text-orange">
                        <a
                          href={
                            value.attributes.File?.data != null
                              ? `${API}${value.attributes.File.data.attributes.url}`
                              : `#`
                          }
                          rel="noreferrer"
                          target="_blank"
                        >
                          {value.attributes.Anchor}
                        </a>
                      </List>
                    </>
                  );
                })}
              </UnorderedList>
            </div>
          </section>
          <hr className="border-gray" />
          {/* <section className="grid grid-cols-1 tablet:grid-cols-2 gap-x-20 mt-20">
            <div>
              <img
                alt="Melinda Mafnas"
                className="tabletWide:w-2/3 desktop:w-1/2 phone:w-auto h-auto pb-10"
                src={Images.MelindaMafnas}
              />
            </div>
            <div>
              {Officials.melindaMafnas.map((content) => {
                return (
                  <>
                    <H1 className="mb-5">{content.title}</H1>
                    <Details>
                      {content.position}
                      <br />
                      {content.company}
                    </Details>
                    <Details>{content.paragraph1}</Details>
                    <Details>{content.paragraph2}</Details>
                    <Details>{content.paragraph3}</Details>
                    <Details>{content.paragraph4}</Details>
                    <Details>{content.paragraph5}</Details>
                  </>
                );
              })}
            </div>
          </section> */}
          <section
            className="flex flex-col my-2 tablet:my-5
            bg-shadow  laptop:-mx-20 tablet:-mx-12 phone:-mx-7 laptop:px-20 laptop:py-14 tablet:p-12 phone:p-7"
          >
            {Standards.contents.map((content) => {
              return (
                <>
                  <div>
                    <H1 className="mb-5 mt-5">{content.title}</H1>
                    <Details>{content.description}</Details>
                  </div>
                </>
              );
            })}
          </section>
          <section className="my-20">
            <H1 className="mb-5">{DepartmentNumbers.title}</H1>
            <div className="grid grid-cols-1 tablet:grid-cols-3 gap-x-20">
              {departmentNumbers.map((value) => {
                return (
                  <>
                    <UnorderedList>
                      <List
                        className="text-orange"
                        key={value.attributes.Anchor}
                      >
                        <button
                          className="text-left"
                          onClick={() =>
                            clickItem(
                              value.attributes.Link,
                              value.attributes.File?.data != null
                                ? value.attributes.File.data.attributes.url
                                : value.attributes.File.data
                            )
                          }
                          type="button"
                        >
                          {value.attributes.Anchor}
                        </button>
                      </List>
                    </UnorderedList>
                  </>
                );
              })}
            </div>
          </section>
        </div>
      </ContentContainer>
    </>
  );
};
