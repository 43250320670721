import { API } from '@config';
import { AboutUsInternalAuditsTable } from '@src/types/AboutUsInternalAudits';

export const getAboutUsInternalAudit = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/about-us-internal-audits?populate=File&sort=id%3Adesc`, options);
  const data: AboutUsInternalAuditsTable = await response.json();

  return data;
};
