import React, { useEffect } from "react";
import {
  H1,
  Details,
  UnorderedList,
  List,
  Content as ContentContainer,
  // H2,
} from "@core";
import { Rates as Content } from "@content";
import { HeaderGradient } from "@components";
// import { getRateInformationFiles } from "@src/services/RateInformationFiles";
// import { RATEINFORMATIONFILES } from "@src/types/RateInformationFiles";
// import { getFileData } from '@src/services';
// import { FILEDATA } from '@src/types';
// import { API } from "@src/config";

export const RatesResidential = () => {
  // const [inforFiles, setInforFiles] = useState<RATEINFORMATIONFILES[]>([]);

  const getData = async () => {
    // const fileDatas = await getFileData();
    // setFile(fileDatas.data);
    // const inforFilesData = getRateInformationFiles();
    // setInforFiles((await inforFilesData).data);
  };

  // const clickItem = (link?: string, file?: any) => {
  //   if (file != null) {
  //     window.open(`${API}${file}`);
  //   } else {
  //     window.open(`${link}`);
  //   }
  // };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={Content.title}
      />
      <ContentContainer>
        <div className="w-11/12 mr-20">
          <H1>{Content.subTitle}</H1>
          <Details>{Content.description}</Details>
          <UnorderedList>
            <div className="rate-schedules-container">
              {Content.ol.map((value) => {
                return (
                  <List className="text-orange" key={value.text}>
                    <a href={value.link} rel="noreferrer" target="_blank">
                      {value.text}
                    </a>
                  </List>
                );
              })}
            </div>
          </UnorderedList>
        </div>

        {/* <div className="w-11/12 mr-20 mt-10">
          <H2 className="mb-5 text-blue">Rate Information Files</H2>
          <UnorderedList>
            <div className="grid laptop:grid-flow-row phone:grid-flow-row gap-x-10">
              {inforFiles.map((value) => {
                return (
                  <List
                    className="text-orange"
                    key={value.attributes.createdAt}
                  >
                    <button
                      className="text-left"
                      onClick={() =>
                        clickItem(
                          value.attributes.Link,
                          value.attributes.File?.data != null
                            ? value.attributes.File.data.attributes.url
                            : value.attributes.File.data
                        )
                      }
                      type="button"
                    >
                      {value.attributes.Anchor}
                    </button>
                  </List>
                );
              })}
            </div>
          </UnorderedList>
        </div> */}

        {/* <div className="w-11/12 mr-20 mt-12">
          <UnorderedList>
            <div className="grid laptop:grid-flow-row phone:grid-flow-row gap-x-10">
              <List className="text-orange">
                <a
                  href="https://admin.guampowerauthority.com/uploads/20141001_13_Rate_Schedule_A_55cc484c18.pdf"
                  rel="noreferrer"
                  target="_blank"
                >
                  Accommodation Service Charges - Schedule A - 11kb
                </a>
              </List>
              <List className="text-orange">
                <a
                  href="https://admin.guampowerauthority.com/uploads/20141001_14_Rate_Schedule_B_92a3b655e1.pdf"
                  rel="noreferrer"
                  target="_blank"
                >
                  Service Establishment Charges - Schedule B - 8kb
                </a>
              </List>
              <List className="text-orange">
                <a
                  href="https://admin.guampowerauthority.com/uploads/20151001_Base_Rate_Tariff_FY_2016_Full_Set_bfbc5c81f7.pdf"
                  rel="noreferrer"
                  target="_blank"
                >
                  All Power Service Rate Schedules
                </a>
              </List>
            </div>
          </UnorderedList>
        </div> */}
      </ContentContainer>
    </>
  );
};
