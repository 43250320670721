import React, { useEffect, useState } from 'react';
import { HeaderGradient, Pagination } from '@components';
import { Content as ContentContainer, Details, H1 } from '@core';
import { searchDataGpa } from '@services';
import { useParams } from 'react-router-dom';
// import { SEARCHRESULTDATA } from '@types';

export const SearchPage = () => {
  const [searchData, setSearchData] = useState<any>();
  const [searchKeyword, setSearchKeyword] = useState<string | null>('');
  const searchParameter = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage] = useState(5);

  const getSearchData = async () => {
    const searchDataTemp = await searchDataGpa();
    // console.log(searchDataTemp);
    setSearchData(searchDataTemp);
  };

  useEffect(() => {
    getSearchData();
  }, []);

  useEffect(() => {
    const param = new URL(window.location.href).searchParams.get('q');
    setSearchKeyword(param);
  }, [searchParameter]);

  let result: any; // Raw Result from the API
  let currentResult: any; // paginated Result

  const paginateResult = (resultParam: any) => {
    const indexOfLastArticle = currentPage * resultPerPage;
    const indexOfFirstArticle = indexOfLastArticle - resultPerPage;
    currentResult = resultParam.slice(indexOfFirstArticle, indexOfLastArticle);
  };

  const generateResult = () => {
    if (searchData && searchKeyword !== '' && searchKeyword !== null) {
      result = searchData.data
        .filter((item: any) => {
          const pattern = new RegExp(searchKeyword.toLowerCase(), 'gi');
          const condition =
            pattern.test(item.attributes.Title.toLowerCase()) ||
            pattern.test(item.attributes.Description.toLowerCase());
          return condition;
        })
        .map((post: any, index: number) => {
          return (
            <a className="block border-b border-black pb-8" href={post.attributes.Link} key={index}>
              <H1>{post.attributes.Title}</H1>
              <Details>{post.attributes.Description}</Details>
              <span className="text-orange">{post.attributes.Link}</span>
            </a>
          );
        });

      paginateResult(result);

      if (currentResult === null || currentResult.length === 0) {
        currentResult = (
          <div>
            No results found for
            <b> {searchKeyword}</b>
          </div>
        );
      }
      return currentResult;
    }
    // console.log(searchKeyword);
    if (searchKeyword === '' || searchKeyword === null) {
      currentResult = <div>Please enter something to perform a search</div>;
    }
    return currentResult;
  };

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Search"
      />
      <ContentContainer className="search-page__content">{generateResult()}</ContentContainer>
      <div className="flex items-center justify-center space-x-4 mb-8">
        <Pagination
          currentPage={currentPage}
          itemsPerPage={resultPerPage}
          paginate={(pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber)}
          totalItems={result ? result.length : 0}
        />
        {/* <button
          className="cursor-pointer bg-blue h-12 w-12 flex items-center justify-center text-white"
          onClick={() => pageOnClickHandler(1)}
          type="button"
        >
          1
        </button>
        <button
          className="cursor-pointer bg-white border border-blue h-12 w-12 flex items-center justify-center text-blue"
          onClick={() => pageOnClickHandler(2)}
          type="button"
        >
          2
        </button>
        <button
          className="cursor-pointer bg-white border border-blue h-12 w-12 flex items-center justify-center text-blue"
          onClick={() => pageOnClickHandler(3)}
          type="button"
        >
          3
        </button>
        <button
          className="cursor-pointer bg-white border border-blue h-12 w-12 flex items-center justify-center text-blue"
          onClick={() => pageOnClickHandler(4)}
          type="button"
        >
          4
        </button> */}
      </div>
    </>
  );
};
