import { API } from '@config';
import { ContactUsBusinessHoursTable } from '@src/types/ContactUsBusinessHours';

export const getBusinessHours = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/contact-us-business-hours?populate=File&sort=id%3Adesc`, options);
  const data: ContactUsBusinessHoursTable = await response.json();

  return data;
};
