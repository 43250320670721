import React from 'react';
import { Details, Content as ContentContainer, H2 } from '@core';
import { PaidHolidaysContent } from '@content';
import { HeaderGradient } from '@components';

export const PaidHolidays = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={PaidHolidaysContent.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <section>
              <H2 className="text-blue">{PaidHolidaysContent.intro}</H2>
              <Details>
                {PaidHolidaysContent.contents.map((content) => {
                  return (
                    <>
                      {content} <br />
                    </>
                  );
              })}
              </Details>
              <Details>{PaidHolidaysContent.outro}</Details>
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
