import MenuBar from '@assets/icons/menubar.png';
import Facebook from '@assets/icons/facebook.png';
import Instagram from '@assets/icons/instagram.png';
import Bulb from '@assets/icons/bulb.png';
import CreditCard from '@assets/icons/creditcard.png';
import PowerButton from '@assets/icons/powerbutton.png';
import Bolt from '@assets/icons/bolt.png';
import Home from '@assets/icons/home.png';
import Payments from '@assets/icons/payments.png';
import Receipt from '@assets/icons/receipt.png';
import Spa from '@assets/icons/spa.png';
import NavLeftArrow from '@assets/icons/left-arrow.png';
import NavRightArrow from '@assets/icons/right-arrow.png';
import Bulb2 from '@assets/icons/bulb2.png';
import OnlinePayment from '@assets/icons/makeanonlinepayment.png';
import TransferService from '@assets/icons/startstoptransferservice.png';
import WattsNew from '@assets/icons/wattsnew.png';
import MyEnergyGuam from '@assets/icons/myenergyguam.png';
import EnergyTips from '@assets/icons/energytipsandrebates.png';
import OutageMap from '@assets/icons/outagemap.png';
import Bulb3 from '@assets/icons/bulb3.png';
import PayGPA from '@assets/icons/pay.png';
import MyEnergy from '@assets/icons/my-energy.png';
import Outages from '@assets/icons/outages.png';
import EzPay from '@assets/icons/ez-pay.png';
import MagnifyingGlass from '@assets/icons/magnifying-glass.png';
import Youtube from '@assets/icons/youtube.png';
import ViewOuttages from '@assets/icons/viewouttages.png';

export const Icons = {
  MenuBar,
  Facebook,
  Instagram,
  Bulb,
  CreditCard,
  PowerButton,
  Bolt,
  Home,
  Payments,
  Receipt,
  Spa,
  NavLeftArrow,
  NavRightArrow,
  Bulb2,
  OutageMap,
  EnergyTips,
  MyEnergyGuam,
  WattsNew,
  TransferService,
  OnlinePayment,
  PayGPA,
  MyEnergy,
  Outages,
  Bulb3,
  EzPay,
  MagnifyingGlass,
  Youtube,
  ViewOuttages,
};
