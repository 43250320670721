export const IRP = {
  pageTitle: `Integrated Resource Plan`,
  title: `2022 INTEGRATED RESOURCE PLAN`,
  contents: [
    {
      title: `Overview`,
      description: `In November 2019 GPA entered into a contract with Guam Ukudu Power, LLC for the construction and operation of a 198 MW Combined Cycle Combustion Turbine Power Plant.  This new power plant will allow for the retirement of its oldest plant, Cabras 1&2, and address its emission compliance issues.  In addition, this new power plant will also set the foundation for a more flexible grid that will address grid reliability and stability issues and allow for the increase in inverter based renewable energy and energy storage systems to be integrated into our system.  This is just one part of GPA's plan to address customer growth, system stability and sustainability.  From January 2021 thru June 2021 GPA, with input from our Stakeholders, will be developing its latest IRP which will include the following main topics:`,
      ul: [
        {
          anchor: `Generation Expansion`,
          link: ``,
        },
        {
          anchor: `Long-Range Transmission Study`,
          link: ``,
        },
        {
          anchor: `Medium Range Distribution Plan`,
          link: ``,
        },
        {
          anchor: `Demand-Side Management Plan`,
          link: ``,
        },
        {
          anchor: `Environmental Strategic Plan`,
          link: ``,
        },
        {
          anchor: `Resiliency Plan`,
          link: ``,
        },
      ],
    },
    // {
    //   title: '',
    //   description: '',
    //   ul: [
    //     {
    //       anchor: 'Download the 2022 Integrated Resource Plan',
    //       link: '',
    //     },
    //   ],
    // },
  ],
};

export const IRPLatest = {
  contents: [
    {
      anchor: `Download the 2022 Integrated Resource Plan`,
      link: `/uploads/GPA_2022_Integrated_Resource_Plan_b16ef41f9e.pdf?updated_at=2022-09-20T07:24:07.680Z`,
    },
  ],
};

export const IRPStakeHolderMeetings = {
  pageTitle: ``,
  title: ``,
  contents: [
    {
      title: `Stakeholder Meetings`,
      description: `The IRP process integrates stakeholders to review and discuss the assumptions, results and recommendations presented during the IRP development.  GPA initiated the 2021 Integrated Resource Plan with a virtual Stakeholder Kickoff Meeting held in January 2021.  Partcipants included representatives from Navy, Mayor's Council, University of Guam and Red Cross. The Kick Off meeting focused on process, drivers and assumptions for this new IRP.  Presentation files are accessible by the links provided in the table below:`,
      ul: [],
    },
  ],
};

export const IRPMeetingSummary = {
  meeting: `2022 IRP STAKEHOLDER MEETING SUMMARY`,
  date: ``,
  note: `*Page Updated 12/07/21`,
  th: [`Meeting`, `Date`, `Time`, `Topics`],
  tr: [
    {
      td: [
        {
          meetingNumber: `1`,
          date: `Friday, Jan. 29, 2021`,
          time: `8:30AM – 11:30AM`,
          topics: `KICKOFF Meeting, Introduction & Assumptions`,
        },
      ],
    },
    {
      td: [
        {
          meetingNumber: `2`,
          date: `Friday, Mar. 26, 2021`,
          time: `8:30AM – 12:00PM`,
          topics: `Assumptions, Project Status & Discussions`,
        },
      ],
    },
    {
      td: [
        {
          meetingNumber: `3`,
          date: `Friday, Dec. 10, 2021`,
          time: `9:00 - 11:00AM`,
          topics: `Results`,
        },
      ],
    },
    {
      td: [
        {
          meetingNumber: `4`,
          date: `TBD`,
          time: `TBD`,
          topics: `Final Report Summary`,
        },
      ],
    },
  ],
};
export const Meeting = {
  meetingGroup1: [
    {
      meeting: `2021 IRP STAKEHOLDER MEETING NO. 1`,
      date: `January 29, 2021`,
      note: ``,
      th: [`Presentation No.`, `Topic`, `Presentation File`, `Presentation Video`],
      tr: [
        {
          td: [
            {
              presentation: `1-0`,
              topic: `Introductory Remarks`,
              pdf: `PDF, 277 KB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/1-0IntroductoryRemarksFinal.pdf`,
              video: `MP4 Video, 15.2 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/IRPKick-OffMeeting2021-01-29-08-34-46.mp4`,
            },
          ],
        },
        {
          td: [
            {
              presentation: `1-1`,
              topic: `Introduction to Resource Planning & Stakeholder Process`,
              pdf: `PDF, 330 KB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/1-1IntroductionFinal.pdf`,
              video: `MP4 Video, 15.2 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/IRPKick-OffMeeting2021-01-29-08-34-46.mp4`,
            },
          ],
        },
        {
          td: [
            {
              presentation: `1-2`,
              topic: `GPA Strategic Issues`,
              pdf: `PDF, 330 KB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/1-2GPAStrategicIssuesFinal.pdf`,
              video: `MP4 Video, 30.3 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/IRPKick-OffMeeting2021-01-29-08-51-12.mp4`,
            },
          ],
        },
        {
          td: [
            {
              presentation: `1-3`,
              topic: `Assumptions & Inputs`,
              pdf: `PDF, 265 KB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/1-3AssumptionsandInputsFINAL.pdf`,
              video: `MP4 Video, 24.9 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/IRPKick-OffMeeting2021-01-29-09-20-03.mp4`,
            },
          ],
        },
        {
          td: [
            {
              presentation: `1-4`,
              topic: `Existing Supply Side Resources`,
              pdf: `PDF, 567 KB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/1-4ExistingSupplySideResourcesFINAL.pdf`,
              video: `MP4 Video, 24.0 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/IRPKick-OffMeeting2021-01-29-09-50-51.mp4`,
            },
          ],
        },
        {
          td: [
            {
              presentation: `1-5`,
              topic: `On the Horizon Programs & Projects`,
              pdf: `PDF, 373 KB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/1-5OntheHorizonProgramsandProjectsFinal.pdf`,
              video: `MP4 Video, 21.5 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/IRPKick-OffMeeting2021-01-29-10-11-34.mp4`,
            },
          ],
        },
        {
          td: [
            {
              presentation: `1-6`,
              topic: `Next Steps & How You Can Contribute`,
              pdf: `PDF, 271 KB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/1-6NextStepsandHowYouCanContributeFINAL.pdf`,
              video: `MP4 Video, 15.5 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/IRPKick-OffMeeting2021-01-29-10-31-56.mp4`,
            },
          ],
        },
      ],
    },
    {
      meeting: `2021 IRP STAKEHOLDER MEETING NO. 2`,
      date: `March 26, 2021`,
      note: `* Note:  Network issues affected sound and video quality of some of the presentations during this meeting.`,
      th: [`Presentation No.`, `Topic`, `Presentation File`, `Presentation Video`],
      tr: [
        {
          td: [
            {
              presentation: `2-0`,
              topic: `Introductory Remarks`,
              pdf: `PDF, 302 KB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/2-0IntroductoryRemarksFinal.pdf`,
              video: `MP4 Video, 6.5 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/GPAIRP2021Meeting2-202103252237-2.mp4`,
            },
          ],
        },
        {
          td: [
            {
              presentation: `2-1`,
              topic: `Summary of Regulations and Orders Changing GPA Operations`,
              pdf: `PDF, 202 KB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/2-1ESP-SummaryofRegsandOrdersChangingGPAOperations1.2.pdf`,
              video: `MP4 Video, 8.12 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/GPAIRP2021Meeting2-202103252243-3.mp4`,
            },
          ],
        },
        {
          td: [
            {
              presentation: `2-2`,
              topic: `Energy Storage System Update`,
              pdf: `PDF, 1.37 MB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/2-2EnergyStorageSystemUpdate.pdf`,
              video: `MP4 Video, 22.1 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/GPAIRP2021Meeting2-202103252253-4.mp4`,
            },
          ],
        },
        {
          td: [
            {
              presentation: `2-3`,
              topic: `GPA's Forecasted Fuel Prices`,
              pdf: `PDF, 1.31 MB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/2-3GPAForecastedFuelPrices_final.pdf`,
              video: `MP4 Video, 16.63 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/GPAIRP2021Meeting2-202103252311-5.mp4`,
            },
          ],
        },
        {
          td: [
            {
              presentation: `2-4`,
              topic: `GPA's Forecasted Load`,
              pdf: `PDF, 613 KB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/2-4GPAForecastedLoad.pdf`,
              video: `MP4 Video,10.32 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/GPAIRP2021Meeting2-202103252324-6.mp4`,
            },
          ],
        },
        {
          td: [
            {
              presentation: `2-5`,
              topic: `GPA New Resource Projects and Demand Side Management`,
              pdf: `PDF, 387 KB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/2-5NewResourceProjectsandDSMPresentation2021Final.pdf`,
              video: `MP4 Video, 14.66 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/GPAIRP2021Meeting2-202103252336-7.mp4`,
            },
          ],
        },
        {
          td: [
            {
              presentation: ``,
              topic: `Q&A / Open Discussion`,
              pdf: `None`,
              pdfLink: ``,
              video: `MP4 Video, 12.2 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/GPAIRP2021Meeting2-202103252355-8.mp4`,
            },
          ],
        },
        {
          td: [
            {
              presentation: `2-6`,
              topic: `New Resource Options`,
              pdf: `PDF, 343 KB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/2-6NewResourceOptions.pdf`,
              video: `MP4 Video, 15.9 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/GPAIRP2021Meeting2-202103260021-9.mp4`,
            },
          ],
        },
        {
          td: [
            {
              presentation: `2-7`,
              topic: `Generation System Reliability Preliminary Analysis`,
              pdf: `PDF, 151 KB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/2-7GenerationSystemReliabilityPreliminaryAnalysisfinal.pdf`,
              video: `MP4 Video, 23.1 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/GPAIRP2021Meeting2-202103260042-10.mp4`,
            },
          ],
        },
        {
          td: [
            {
              presentation: `2-8`,
              topic: `Transmission System Study`,
              pdf: `PDF, 188 KB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/2-8TransmissionSystemStudyfinal.pdf`,
              video: `MP4 Video, 30.7 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/GPAIRP2021Meeting2-202103260059-11.mp4`,
            },
          ],
        },
        {
          td: [
            {
              presentation: `2-9`,
              topic: `Distribution System Study`,
              pdf: `PDF, 186 KB`,
              pdfLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/2-9DistributionSystemStudyfinal.pdf`,
              video: `MP4 Video, 17.7 MB`,
              videoLink: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/GPAIRP2021Meeting2-202103260121-12.mp4`,
            },
          ],
        },
      ],
    },
    {
      meeting: `2021 IRP STAKEHOLDER MEETING NO. 3`,
      date: `December 10, 2021`,
      note: ``,
      th: [`Presentation No.`, `Topic`, `Presentation File`, `Presentation Video`],
      tr: [
        {
          td: [
            {
              presentation: `3-0`,
              topic: `Introductory Remarks`,
              pdf: `Pending`,
              pdfLink: ``,
              video: `Pending`,
              videoLink: ``,
            },
          ],
        },
        {
          td: [
            {
              presentation: `3-1`,
              topic: `Generation System Reliability and Adequancy`,
              pdf: `Pending`,
              pdfLink: ``,
              video: `Pending`,
              videoLink: ``,
            },
          ],
        },
        {
          td: [
            {
              presentation: `3-2`,
              topic: `Generation Expansion Plan`,
              pdf: `Pending`,
              pdfLink: ``,
              video: `Pending`,
              videoLink: ``,
            },
          ],
        },
        {
          td: [
            {
              presentation: `3-3`,
              topic: `On-going Studies in Support of GPA's Integrated`,
              pdf: `Pending`,
              pdfLink: ``,
              video: `Pending`,
              videoLink: ``,
            },
          ],
        },
        {
          td: [
            {
              presentation: `3-4`,
              topic: `Summary & Closing Remarks`,
              pdf: `Pending`,
              pdfLink: ``,
              video: `Pending`,
              videoLink: ``,
            },
          ],
        },
      ],
    },
  ],
  meetingGroup2: [
    {
      meeting: `2022 IRP STAKEHOLDER MEETING SUMMARY`,
      date: ``,
      note: `*Page Updated 12/07/21`,
      th: [`Meeting`, `Date`, `Time`, `Topics`],
      tr: [
        {
          td: [
            {
              meetingNumber: `1`,
              date: `Friday, Jan. 29, 2021`,
              time: `8:30AM – 11:30AM`,
              topics: `KICKOFF Meeting, Introduction & Assumptions`,
            },
          ],
        },
        {
          td: [
            {
              meetingNumber: `2`,
              date: `Friday, Mar. 26, 2021`,
              time: `8:30AM – 12:00PM`,
              topics: `Assumptions, Project Status & Discussions`,
            },
          ],
        },
        {
          td: [
            {
              meetingNumber: `3`,
              date: `Friday, Dec. 10, 2021`,
              time: `9:00 - 11:00AM`,
              topics: `Results`,
            },
          ],
        },
        {
          td: [
            {
              meetingNumber: `4`,
              date: `TBD`,
              time: `TBD`,
              topics: `Final Report Summary`,
            },
          ],
        },
      ],
    },
  ],
};

export const IRPLinks = {
  title: `Related Links and Downloads`,
  ul: [
    {
      p1: `FY 2016 GPA Response to PUC on New Power Plant and Implementation of 2012-2013 IRP (Docket 15-05) `,
      anchor1: `Download 40.1 MB`,
      link1: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/PUCFilingRevisedIRPPlanResponsetoPUCOrdernl.pdf`,
      p2: ``,
      anchor2: ``,
      link2: ``,
    },
    {
      p1: `2011 LNG Study, `,
      anchor1: `Download 7.92 MB pdf`,
      link1: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/FinalReportGuamLNGStudywExhibits.pdf`,
      p2: ` - A feasibility investigation for Liquefied Natural Gas as a fuel source for GPA generation fleet. `,
      anchor2: ``,
      link2: ``,
    },
    {
      p1: `FY 2008 GPA Generation Resource Handbook `,
      anchor1: `Download 1080 KB pdf`,
      link1: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/FY2008GenerationResourceHandbook.pdf`,
      p2: ``,
      anchor2: ``,
      link2: ``,
    },
    {
      p1: `FY 1999 Integrated Resource Plan `,
      anchor1: `Download 2.90 MB pdf`,
      link1: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/Integrated_Resource_Plan_1999.pdf`,
      p2: ``,
      anchor2: ``,
      link2: ``,
    },
    {
      p1: `FY 1996 Integrated Resource Plan `,
      anchor1: `Download 18.6 MB pdf`,
      link1: `https://oldsite.guampowerauthority.com/gpa_authority/strategicplanning/documents/IRP-1996.pdf`,
      p2: ``,
      anchor2: ``,
      link2: ``,
    },
    {
      p1: `Historical Renewable Data `,
      anchor1: `Download 210 KB pdf`,
      link1: `https://admin.guampowerauthority.com/uploads/Historical_Renewable_Energy_Data_798ad58342.pdf?updated_at=2023-04-11T05:17:28.371Z`,
      p2: ``,
      anchor2: ``,
      link2: ``,
    },
  ],
};
