import { WhatYouCanDoImages } from '@core';

export const Tips = {
  pageTitle: `What You Can Do`,
  contents: [
    {
      title: `ENERGY SAVING TIPS`,
      desc: ``,
      anchor: ``,
      link: ``,
      img: WhatYouCanDoImages.YouCanSaveUse,
      alt: ``,
    },
    {
      title: `Buying New Appliances`,
      desc: `Download the updated Appliance Guide `,
      anchor: `Home Appliance Guide`,
      link: ``,
      img: WhatYouCanDoImages.GuideCover,
      alt: ``,
    },
  ],
};

export const PurchaseAppliance = {
  title: `Purchasing an appliance costs you money in two ways:`,
  ul1: [`the cost of to purchase`, `the cost of the energy to operate`],
  description: `Always look for the Energy Guide! Major home appliances are required to meet certain energy efficiency guidelines and carry a yellow EnergyGuide label. On the label, you'll find:`,
  ul2: [
    `amount of energy the appliance uses`,
    `comparision of its energy use to similar products`,
    `approximate annual operating cost`,
    `The higher the overall rating, the more energy efficient the product!`,
  ],
  img: WhatYouCanDoImages.EnergyGuide,
};

export const EnergyStar = {
  title: `You'll find the ENERGY STAR label on:`,
  ul: [
    `clothes washers`,
    `dishwashers`,
    `refrigerators`,
    `freezers`,
    `air conditioners`,
    `dehumidifiers`,
  ],
  description: `(Not included : labels for clothes dryers, water heaters, and kitchen ranges.)`,
  img: WhatYouCanDoImages.EnergyStar,
};

export const ShopWisely = {
  title: `Purchasing an appliance costs you money in two ways:`,
  description1: `Shop wisely and save money every month!`,
  ul: [
    `Buy the right size. A unit that is too large for your space wastes energy. A unit that is too small will work hard and still not do the job.`,
    `Buy an efficient unit. Go to www.energystar.gov. to learn more Energy Star standardized ratings and choosing the right size room air conditioning unit for your space.`,
    `Purchase the highest SEER rated type and size of unit you need. This is the most effective way to cut your cost!`,
  ],
  description2: `An energy efficient air conditioner could save you substantial energy !`,
  img: WhatYouCanDoImages.Aircon,
};

export const SaveUsingAircon = {
  title: `How to Save on Using Aircon`,
  ul: [
    `Target 78 degrees! Each degree below 78° could cost you 8% on your cooling costs. Keep an eye on your thermostat or room thermometer and save!`,
    `Use ceiling fans and you can re-set your thermostat two to four degrees warmer without sacrificing comfort. But ceiling fans are a waste of energy if they're running while no one is in the room.`,
    `Keep hot sun out and cool air in! Keep windows and doors closed and shuttered or curtained when the aircon is on. Add weather-stripping and caulk around windows and doors where cool air escapes.`,
    `Clean or replace air filters every month. Dirty filters make the unit work harder. See the owner's manual or your supplier for preventative maintenance tips.`,
  ],
  img: WhatYouCanDoImages.CeilingFan,
};

export const StandByPower = {
  title: `Standby Power`,
  description: `Standby Power is the electrical power that is used even though the main function of an electrical device is turned off. It's well worth monitoring this use of energy and deciding for yourself if each device is useful or wasteful.`,
  contents: [
    {
      strong: `Useful?`,
      p: `Sometimes the standby power is a convenience such as when a television is off, but remains in the standby mode to receive a signal from the remote control.`,
    },
    {
      strong: `Wasteful?`,
      p: `Sometimes the standby power is wasteful such as when power adapters remain plugged in after the device has been removed or after their batteries are re-charged.`,
    },
  ],
  subTitle: `You Can Save!`,
  ul: [
    `Look for the ENERGY STAR to find appliances and devices that are efficient in standby mode.`,
    `Enable the "energy saver" mode on your computer and monitor so they "sleep" or dim when not in use.`,
    `Use a power strip as a central "turn off" You can completely disconnect the power supply to everything plugged into the strip by flipping one switch. Use one strip for your computer and monitors and another for your TV, VCR, DVD, sound or game system, etc.`,
    `Unplug cell phone chargers, camera chargers, battery chargers or power adapters. Chargers use energy even when not connected to a device! Make it a habit to unplug the charger when the batteries have completed recharging instead of leaving it plugged in overnight or for long periods.`,
  ],
  img: WhatYouCanDoImages.Switch,
};

export const HeatingPower = {
  title: `Heating Water`,
  description: `The cost of making hot water could be the second most expensive item on your power bill!`,
  subTitle: `You Can Save!`,
  ul: [
    `Reduce the temperature settings on your water heater to 110-120 degrees. Before opening the panel on the water heater, make sure the breaker is turned OFF for safety. See your owner's manual and be sure to adjust both upper and lower thermostats to the same level to prevent one element from wearing out prematurely.`,
    `Install a timer that can automatically turn the water heater off except for a few key hours a day. Water heater timers cost about $60. Even counting the services of an electrician for installation, the timer will soon pay for itself and generate big savings.`,
  ],
  img: WhatYouCanDoImages.WaterTimer,
};

export const ImportantWarning = {
  img: WhatYouCanDoImages.Tankless,
  strong: `IMPORTANT WARNING: `,
  ul: [
    {
      p: `Never try to use the breaker itself to manually turn the hot water tank on or off on a regular basis. Breakers are meant for service only.`,
    },
    {
      p: `Install low-flow shower heads and faucets to cut hot water usage. Since showers can account for 40% of the hot water used in a typical home, this is another investment that will save in the long run.`,
    },
    {
      p: `Do full loads in dishwashers. Wash small amounts of dirty dishes by hand.`,
    },
    {
      p: `Don't use hot water for washing clothes. Today's detergents work in cold water. overnight or for long periods.`,
    },
  ],
};

export const Lighting = {
  title: `Lighting`,
  description: `Efficient lighting is the fastest and easiest way to save on your energy bills!`,
  subTitle: `Ways to save on lighting`,
  ul: [
    `One inexpensive way to get started is to replace your most frequently used light bulbs with compact fluorescent bulbs. aAlways turn off the lights in any room that is not occupied even if you'll be gone for just for a few moments. (It's not true that it takes more energy to turn lights off-and-on instead of leaving them on.)`,
    `Gently dust light bulbs occasionally. A coat of dust will decrease the actual light from the bulb. aWhen bulbs burn out, replace them with compact fluorescent light bulbs. You'll use 60% to 80% less energy and produce more light with each bulb.`,
    `Consider installing timers, photocells, or motion sensors to reduce the amount of time your lights are on. aLight-colored lamp shades allow more light to get through. aFocus light where you need it instead of lighting an entire room. Install under-cabinet lighting above your kitchen counter tops.`,
    `For outside lights, consider lights that are controlled with motion detectors so they only turn on when needed. For outside floodlights, use energy-saving compact fluorescent floodlights to replace all old style floodlights.`,
  ],
};

export const BulbComparison = {
  title: `COMPARISONS BETWEEN BULBS`,
  img: WhatYouCanDoImages.LightBulb,
  text: `Source: `,
  anchor: `www.energy.gov`,
  link: `https://www.energy.gov/`,
};

export const HeatResistantHomes = {
  title: `Heat Resistant Homes`,
  strong1: `Making sure your home's "shell" is airtight `,
  p: `is one of the best and cheapest fixes for energy waste.`,
  strong2: `Ways to save on windows, exterior doors, walls, foundation, floor and roof.`,
  contents: [
    `Windows can account for a large portion of your cooling bill. Letting the heat of direct sunlight into your home puts stress on your air conditioner.`,
    `Consider installing Low-E (low emissivity) energy-efficient windows for optimum efficiency. They'll save you up to 15 percent on the cooling portion of your electric bill.`,
    `Caulk, seal and weather-strip air leaks where plumbing, ducting, or electrical wiring enters your home.`,
    `Consider applying solar tint or other reflective films on east- and west-facing windows or every window in your home for optimum efficiency.`,
    `Plant shade trees to protect west windows and walls.`,
    `Install awnings to protect windows on east and west.`,
    `Keep concrete roofs clean and paint with reflective paint to keep your home cooler.`,
    `Metal roofs can be painted with reflective paint. Adding a radiant barrier or insulation inside helps reduce cooling costs, too.4 Low-E (low emissivity) energy-efficient windows may save you up to 15 percent on the cooling portion of your electric bill. If you're building a new home, consider specifying this type of window. You can also refit your home's existing windows.`,
  ],
  img: WhatYouCanDoImages.Window,
};

export const CookingPrep = {
  title: `Cooking and Prep`,
  description: `Shop wisely and save money every month!`,
  ul: [
    `Keep range-top burners and reflectors clean for maximum efficiently.`,
    `Turn off burners/oven early. You'll have a few more minutes cooking without using electricity.`,
    `Use your microwave oven whenever possible. You save energy by reducing cooking time. Small appliances such as toaster type convection ovens use less energy, too.`,
  ],
  img: WhatYouCanDoImages.DripPan,
};

export const Refrigerator = {
  title: `Refrigerator/Freezer`,
  note: `NOTE: It's best to unplug the unit first!`,
  ul: [
    `Set refrigerator temperatures at 37-40 degrees and freezer temperatures at five degrees. Free standing deep freeze units should be set to zero degrees.`,
    `Vacuum refrigerator condenser coils to maximize efficiency.`,
  ],
  img: WhatYouCanDoImages.Condenser,
};

export const Laundry = {
  title: `Laundry`,
  ul: [
    `Always wash and dry full loads.`,
    `Switching the temperature from hot water to cold water cuts the cost of each load in half.`,
    `If there is a moisture sensor on your dryer, use it.`,
    `Clean the lint filter on the dryer after every load and inspect dryer vent often for blockage.`,
  ],
  img: WhatYouCanDoImages.Washer,
};
