export const RateSched = {
  title: `Rates`,
  subTitle: `RATE SCHEDULES`,
  description: `The Guam Power Authority provides electric power service under the following rate classes:`,
  ol: [
    {
      text: `Residential Service`,
      link: ``,
    },
    {
      text: `General Service - Non-Demand`,
      link: ``,
    },
    {
      text: `General Service - Demand`,
      link: ``,
    },
    {
      text: `Condominium or Apartment Services`,
      link: ``,
    },
    {
      text: `Large Power Service`,
      link: ``,
    },
    {
      text: `Private Outdoor Lighting`,
      link: ``,
    },
    {
      text: `Independent Power Producer Service`,
      link: ``,
    },
    {
      text: `Small Government Service - Non-Demand`,
      link: ``,
    },
    {
      text: `Small Government Service - Demand`,
      link: ``,
    },
    {
      text: `Large Government Service`,
      link: ``,
    },

    {
      text: `Standby, Auxiliary, Supplementary or Breakdown Service`,
      link: ``,
    },
    {
      text: `Street Lighting`,
      link: ``,
    },
    {
      text: `Navy Service (Transmission Level Tariff)`,
      link: ``,
    },
    {
      text: `Levelized Energy Adjustment Clause`,
      link: ``,
    },
    {
      text: `Net Metering`,
      link: ``,
    },
    {
      text: `Levelized Energy Adjustment Clause - as of  July 1, 2022`,
      link: ``,
    },
  ],
};

export const Surcharges = {
  title: `SURCHARGES`,
  contents: [
    {
      title: `Self-Insurance Surcharge`,
      description: `GPA maintains a self-insurance fund to pay for items and deductibles not covered by its insurance policies. This surcharge is $0.0029 per kilowatt-hour (kWh) for civilian rate classes and $0.0007 per kilowatt-hour for U.S. Navy. The use of this fund is restricted by the PUC and cannot be used for other than purposes specified. Once the fund is fully funded, the surcharge disappears until it is depleted by a quarter at which time the surcharge resumes.`,
    },
    {
      title: `Guam Waterworks Surcharge`,
      description: `This surcharge pays down the amounts owed Guam Power Authority by the Guam Waterworks Authority.`,
    },
  ],
};

// NOTE: Client asked to hide Base Rate FAQ section for now, might be useful in the future.
// export const BaseRateFAQ = {
//   title: `BASE RATE CASE FAQ`,
//   villageMeetings: [
//     {
//       title: `Village Meetings (May 29 - June 1)`,
//       subTitle: `Background`,
//       background: `Prior to the CCU's approval of a 6 year phase in base rate increase on June 7, 2007, GPA sought to inform customers on the justifications for a base rate adjustment by conducting public outreach sessions at regional village meetings from May 29 - June 1. Meetings were held in Inarajan, Agat, Dededo and Chalan Pago.`,
//       introduction: `The following are some of the issues raised at different regional meetings. Select the issue to review GPA's response:`,
//       ol: [
//         {
//           text: `Government of Guam Debt to GPA should be collected as a means to offset the base rate adjustment;`,
//           anchor: `(view GPA's response)`,
//           link: ``,
//         },
//         {
//           text: `Energy Conservation should be promoted more;`,
//           anchor: ``,
//           link: ``,
//         },
//         {
//           text: `U.S. Military build up monies should be used to improve power infrastructure to offset increase;`,
//           anchor: `(view GPA's response)`,
//           link: ``,
//         },
//         {
//           text: `Customers would like to see effects of increase communicated to them;`,
//           anchor: `(view GPA's response)`,
//           link: ``,
//         },
//         {
//           text: `GPA should invest more in our youth to promote local jobs instead of outsourcing work;`,
//           anchor: ``,
//           link: ``,
//         },
//         {
//           text: `Surcharge to Government Agencies to pay for GovGuam Debt;`,
//           anchor: `(view GPA's response)`,
//           link: ``,
//         },
//         {
//           text: `Streetlights Fund should go directly to GPA instead of General Fund;`,
//           anchor: ``,
//           link: ``,
//         },
//         {
//           text: `CCU should have reviewed power rate alternatives prior to village meetings for feedback since base rate adjustment is already a “done deal”;`,
//           anchor: `(view GPA's response)`,
//           link: ``,
//         },
//         {
//           text: `GPA should purchase vehicles that are cheaper instead of purchasing “Ford Explorers” and “Expensive SUVs” to read power meters or buying big trucks instead of utility trucks;`,
//           anchor: `(view GPA's response)`,
//           link: ``,
//         },
//         {
//           text: `GPA should bring back its “Guam Energy Sense” Program on energy rebates;`,
//           anchor: `(view GPA's response)`,
//           link: ``,
//         },
//         {
//           text: `The salary of the GM should be reduced to offset the base rate increase;`,
//           anchor: ``,
//           link: ``,
//         },
//         {
//           text: `GPA customers are reducing their energy consumption; GPA will in turn begin to raise rates again to offset lost revenues;`,
//           anchor: ``,
//           link: ``,
//         },
//         {
//           text: `GPA is experiencing cost savings due to cuts in personnel, yet its labor costs remains constant;`,
//           anchor: `(view GPA's response)`,
//           link: ``,
//         },
//         {
//           text: `Alternative energy methods should be part of Authority’s plans to reduce reliance on fossil fuels;`,
//           anchor: `(view GPA's response)`,
//           link: ``,
//         },
//       ],
//       moreAnchor: `View More Issues`,
//       moreLink: ``,
//       conclusion: `As the Base Rate Case is being filed and reviewed by the Public Utilities Commission, GPA will be addressing these issues.`,
//     },
//   ],
//   pucRequirements: [
//     {
//       title: `Public Utilities Commission (PUC) Requirement:`,
//       subTitle: `Comprehensive Management & Organizational Assessment`,
//       ul: [
//         {
//           anchor: `Transmittal Letter`,
//           link: ``,
//         },
//         {
//           anchor: `Executive Summary`,
//           link: ``,
//         },
//         {
//           anchor: `Staffing and Productivity`,
//           link: ``,
//         },
//         {
//           anchor: `Corporate Goverance`,
//           link: ``,
//         },
//         {
//           anchor: `Strategic Planning`,
//           link: ``,
//         },
//         {
//           anchor: `Accountability & Performance Metrics`,
//           link: ``,
//         },
//         {
//           anchor: `Transmission & Distribution`,
//           link: ``,
//         },
//         {
//           anchor: `Generation`,
//           link: ``,
//         },
//         {
//           anchor: `Customer Service`,
//           link: ``,
//         },
//         {
//           anchor: `Informaiton Technology`,
//           link: ``,
//         },
//         {
//           anchor: `Financial Strength`,
//           link: ``,
//         },
//         {
//           anchor: `Staffing and Productivity Study Report `,
//           link: ``,
//         },
//       ],
//     },
//   ],
//   whatsNext: [
//     {
//       title: `What Next?`,
//       description: `The rate increase is far from complete and customers should not expect any changes until 2008. There are procedures in the Ratepayers' Bill of Rights as well as with the Public Utilities Commission (PUC) that require certain timelines for notification, review and depending on the PUC implementation. Keep posted to the GPA website for more information on the rate case proceedings.`,
//       anchor: ``,
//       link: ``,
//       instruction: ``,
//     },
//     {
//       title: `Related Links`,
//       description: `Ratepayers' Bill of Rights (12 GCA, Chapter 12, § 12001.1 - § 12001.2, and § 12016)`,
//       anchor: `Public Law No 26-23`,
//       link: ``,
//       instruction: `Access the section of the Guam Code Annotated from the Supreme Court of Guam Compiler of Laws pertaining to the Ratepayers' Bill of Rights - updated through P.L. 29-001 (April 2, 2007)`,
//     },
//   ],
//   additionals: [
//     {
//       anchor: `12 GCA , Chapter 12 (Public Utilities Commission and the Guam Telecommunications Act of 2004)`,
//       link: ``,
//     },
//     {
//       anchor: `Consolidated Commission on Utilities (CCU)`,
//       link: ``,
//     },
//     {
//       anchor: `Public Utilities Commission (PUC)`,
//       link: ``,
//     },
//   ],
// };
