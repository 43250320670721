import { Icons } from '@core';

export const ManageMyAccountBusiness = {
  title: `Business - Manage My Account`,
  description: `Manage your Guam Power Authority account with PayGPA`,
  learnMore: [
    {
      title: 'Power Alerts',
      icon: Icons.Bolt,
      link: '/power-alerts',
    },
    {
      title: 'Start, Stop & Move Service',
      icon: Icons.Home,
      link: '/how-do-i/start-stop-move-services',
    },
    {
      title: 'Electricity Bill',
      icon: Icons.Receipt,
      link: '/how-do-i/understand-my-bill',
    },
    {
      title: 'Ways to Pay',
      icon: Icons.Payments,
      link: '/ways-to-pay',
    },
    {
      title: 'Energy Sense',
      icon: Icons.Spa,
      link: 'https://energysense.guampowerauthority.com/',
    },
  ],
};
