import { API } from '@config';
import { FinancialsAnnualReportsTable } from '@src/types/FinancialsAnnualReports';

export const getFinancialAnnualReport = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/financials-annual-reports?populate=*&sort=id%3Adesc`, options);
  const data: FinancialsAnnualReportsTable = await response.json();

  return data;
};
