import React, { FC, PropsWithChildren, HTMLAttributes } from 'react';

interface UnorderedListProp extends HTMLAttributes<HTMLOListElement> {
  className?: string;
}

type UnorderedListProps = PropsWithChildren<UnorderedListProp>;

export const UnorderedList: FC<UnorderedListProps> = ({ children, className }, props) => {
  return (
    <ul className={`font-rubik list-disc list-inside pl-4 ${className}`} {...props}>
      {children}
    </ul>
  );
};
