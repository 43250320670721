export const PaidHolidaysContent = {
  pageTitle: `Paid Holidays`,
  intro: `The Guam Power Authority observes the following legal holidays, but reserves the right to schedule work on these days of Authority business demands:`,
  contents: [
    `New Year’s Day ......................................................... January 1st`,
    `Martin Luther King, Jr. Day .................................... Third Monday in January`,
    "Guam History and Chamorro Heritage Day........ First Monday in March",
    `Memorial Day ............................................................ Last Monday in May`,
    `Independence Day .................................................. July 4th`,
    `Liberation Day .......................................................... July 21st`,
    `Labor Day .................................................................. First Monday in September`,
    `All Soul’s Day ........................................................... November 2nd`,
    `Veteran’s Day ........................................................... November 11th`,
    `Thanksgiving Day ................................................... Fourth Thursday in November`,
    `Our Lady of Camarin Day ..................................... December 8th`,
    `Christmas Day ........................................................ December 25th`,
  ],
  outro: `In accordance with Public Law 27-153, the Guam Power Authority follows the Federal government’s practice that whenever a federal holiday falls on a Saturday or Sunday, the preceding Friday or following Monday shall be observed as the holiday.`,
};