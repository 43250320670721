import React from 'react';
import { Details, Images, List, Content as ContentContainer, UnorderedList } from '@core';
import { HeaderGradient } from '@components';

export const TrackMyEnergyConsumption = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Track My Energy Consumption"
      />
      <section className=" relative">
        <div className="absolute h-full bg-black/50" />
        <div className="absolute inset-0  h-full bg-black bg-opacity-10 z-10" />
        <img
          alt="News And Media Banner"
          className="w-full aspect-ratio__16-5"
          src={Images.TractMyEnergyConsumptionBanner}
        />
      </section>
      <ContentContainer>
        <Details>
          You are in control of the amount of energy you use. Manage your consumption with{' '}
          <a
            className="text-orange"
            href="https://myenergyguam.com/HomeConnect/registration.html"
            rel="noreferrer"
            target="_blank"
          >
            myenergyguam.com
          </a>
          . To register, have your account number and amount of your last bill ready.
        </Details>
        <UnorderedList>
          <List>Track your current and projected usage</List>
          <List>See your daily kilowatt hours (kWh) used</List>
          <List>Set your own energy consumption alerts and stay ahead of your bill</List>
          <List>
            Use the{' '}
            <a className="text-orange" href="/rate-calculator">
              rate calculator
            </a>{' '}
            provided to calculate your bill
          </List>
        </UnorderedList>
      </ContentContainer>
    </>
  );
};
