export const FormsAndPermits = {
  title: `Forms and Permits`,
  subTitle: `CHECKLIST & GUIDELINES`,
  description: `PLAN REVIEW REQUIREMENTS CHECKLIST All plans submitted to GPA for plan review must include the following:`,
  ol: [
    `Project Name`,
    `Vicinity Map showing the project location`,
    `General Electrical Notes (listed below)`,
    `Electrical Site Plan`,
    `Electrical One-Line Diagram`,
    `Load Calculations`,
    `Necessary GPA standard details`,
    `Professional Engineer Seal`,
  ],
  contents: [
    {
      title: `PLAN GUIDELINES FOR COMMERCIAL AND MULTIPLE RESIDENTIAL APPLICANTS`,
      description: `General Design Guidelines for commercial and multiple residential 
      applicants Provide load calculations. Minimum service design for loads under 100 kVA, 
      single phase or 225 kVA three phase is the hybrid system. All others require pad mounted 
      transformers. The hybrid system consists of overhead primary distribution and pole 
      mounted transformers that feed a secondary handhole system. General Notes The following 
      electrical notes must be on the plan:`,
      ol: [
        `Coordinate with GPA Engineering 48 hours in advance for inspection of manhole, 
        handhole, conduit installation, transformer pad, and conduit/duct mandrelling prior 
        to concrete pouring.`,
        `Owner shall grant a utility easement to GPA for power line, handhole and transformer
         prior to final connection.`,
        `Application for power service must be Submitted 8 months in advance before final
         connection/energization to allow for delivery of GPA materials and equipment. 
         For pad mounted transformer projects, application must be submitted 12 months in advance.`,
        `All conduits must be cleaned and mandrelled in the presence of a GPA Inspector. 
        All conduits must be provided with nylon pull rope of 200 lbs. minimum pull strength.`,
        `GPA handhole, transformer and meter shall be accessible 24 hours a day for maintenance and meter reading.`,
        `All above ground GPA conduits shall be rigid aluminum conduit. All below grade 
        GPA conduit shall be concrete encased PVC schedule 40.`,
        `All electrical work shall be done in accordance with the latest National Electrical Cod e 
        (NEC) and National Electrical Safety Code (NESC).`,
        `Contractor/Owner shall identify the Registered Land Surveyor (RLS) property markers/points 
        to the GPA Inspector at the job site`,
        `Provide 3 feet min. clearance all around handholes, transformers, and metering equipment 
        from fences, walls, and structures, etc.`,
        `Contractor/Owner shall obtain a Registered Land Surveyor to provide new pole stakeout 
        and down-guy locations; to prepare easement exhibits for GPA poles, handholes, 
        transformers, overhead/underground power lines and other associated power facilities. 
        Coordinate with GPA Engineering for specific requirements.`,
        `All survey stakeouts, maps, and easement documents shall be field verified by GPA.`,
        `Any changes to the approved permitted drawings will require as-built drawings for approval.`,
      ],
    },
    {
      title: `Electrical Site Plan Guidelines:`,
      description: ``,
      ol: [
        `Show ALL new/existing power facilities including poles, transformers, handholes and 
        overhead/underground lines on the electrical site plan.`,
        `Place handholes and metering equipment within the private property. Fencing shall be routed around 
        handholes and metering equipment to ensure 24 hour access.`,
        `Provide pipe guard protection for handholes and transformers. These shall be placed 1 foot 
        diagonal from each corner.`,
      ],
    },
    {
      title: `One-Line Diagram Guidelines:`,
      description: ``,
      ol: [
        `Provide load calculations showing existing and new loading. Show the total demand load. 
        Show how the new load/electircal work affects the existing GPA transformer, service, and meter. 
        If the existing service is sufficient, please indicate on plan with the note: “Interior work only.
         No change to GPA service, wire or meter.”`,
        `Show all existing/new power facilities including transformers, handholes and meters on one-line diagram.`,
        `Provide 6' slack in secondary handhole on customer side`,
        `Indicate transformer size on electrical layout.`,
        `Show how the new electrical work ties in with the existing GPA transformer.`,
        `Indicate on the one-line diagram if the meter and transformer are new or existing.`,
        `Service voltage, number of phases, number of wires, and type of system shall be specified on the One-Line 
        diagram near the metering unit. (Example: 208Y/120 V, 3-Phase, 4 Wires)`,
        `Meter socket type shall be specified on the One-Line diagram. (Example: 7 Terminal, 20 Class, NEMA 3R)`,
        `Meter sockets for CT metering systems shall be the combination type, which includes an enclosure for a 
        test switch. GPA will provide the test switch.`,
        `For CT metering systems, owner will provide color-coded, # 12 copper, stranded CT wires as 
        required and GPA will terminate wires.`,
        `For CT metering systems, GPA requires a non-fused main disconnect switch with sealing provision for GPA on line side of CT`,
      ],
    },
  ],
};

export const DownloadableForms = [
  {
    title: `STANDARDS & GUIDELINES`,
    description: `Resources for Owners and Contractors`,
    ul: [
      {
        anchor: `Electrical Inspection Report`,
      },
      {
        anchor: `Underground Distribution Systems`,
      },
      {
        anchor: `Temporary Power Post Detail`,
      },
      {
        anchor: `2'x2'x3' Concrete Secondary Handhole Detail`,
      },
      {
        anchor: `2'x4'x3' Concrete Secondary Handhole (Traffic Rated) Detail`,
      },
      {
        anchor: `2'x4'x3' Concrete Secondary Handhole Detail`,
      },
      {
        anchor: `5'x4'x5' Concrete Primary Handhole Detail`,
      },
      {
        anchor: `Concrete Handhole Cover (Traffic Rated) Detail`,
      },
      {
        anchor: `Concrete Handhole Cover Detail`,
      },
      {
        anchor: `8'x8'x6' Concrete Primary Manhole Detail`,
      },
      {
        anchor: `Underground Trench and Conduit Detail`,
      },
      {
        anchor: `Concrete Riser Pole Detail`,
      },
      {
        anchor: `Transformer Pad Detail`,
      },
    ],
  },
  {
    title: `WORK ORDER PROCESSING`,
    description: ``,
    ul: [
      {
        anchor: `Processing of engineering work orders`,
      },
    ],
  },
  {
    title: `SIGNS`,
    description: ``,
    ul: [
      {
        anchor: `Temporary pole attachment guidelines for side-post banner installation, signs, and posters`,
      },
    ],
  },
  {
    title: `OTHERS`,
    description: ``,
    ul: [
      {
        anchor: `File a Damage Claim`,
      },
      {
        anchor: `RELEASE OF UTILITY INFORMATION`,
      },
    ],
  },
];
