export const GetFinAsst = {
  title: `Get Financial Assistance`,
  subTitle: `Avoid power service account interruptions.`,
  link: [
    // {
    //   content: `Please read our `,
    //   anchor: '',
    // },
    {
      content: ``,
      anchor: "FAQs",
    },
    // {
    //   content: `for more information. `,
    //   anchor: '',
    // },
  ],
  contents: [
    `Contact us at (671) 647-5787/8/9 or email customersfirst@gpagwa.com or businessfirst@gpagwa.com.`,
    `If you are a customer with a past-due balance, we’re here to help. We’ll work together to find a solution for you.`,
  ],
};
