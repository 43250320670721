import React, { FC, PropsWithChildren, HTMLAttributes } from 'react';

interface H4Prop extends HTMLAttributes<HTMLHeadElement> {
  className?: string;
}

type H4Props = PropsWithChildren<H4Prop>;

export const H4: FC<H4Props> = ({ children, className }, props) => {
  return (
    <h1
      className={`font-rubik font-normal text-black phone:text-lg tablet:text-xl laptop:text-2xl 
  desktop:text-2xl ${className}`}
      {...props}
    >
      {children}
    </h1>
  );
};
