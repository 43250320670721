export const NewPowerPlantProjects = {
  title: `Clean Energy Master Plan`,
  contents: [
    {
      title: `Overview`,
      heading: `The new 180-megawatt power plant is crucial for Guam’s sustainable energy requirements.`,
      ul: [
        `The $400 million investment will be located in Dededo.`,
        `Replaces the two power Cabras plants left inoperable by the explosion and fire of August 2015.`,
        `Allows for retirement of Cabras 1 and Cabras 2 plants.`,
        `Allows integration of existing solar photovoltaic sources of renewable energy and an additional 130 megawatts from planned solar photovoltaic farms.`,
        `Complies with federal environmental standards.`,
      ],
    },
    {
      title: `Important Features`,
      heading: `The new 180-megawatt power plant is`,
      ul: [
        `Reliable`,
        `Quiet`,
        `Efficient (uses less fuel)`,
        `Has clean emissions`,
        `Allows for fuel diversity`,
        `Works well with solar and other renewables`,
      ],
    },
    {
      title: `FAQs`,
      heading: `The new 180-megawatt power plant is`,
      ul: [
        `Reliable`,
        `Quiet`,
        `Efficient (uses less fuel)`,
        `Has clean emissions`,
        `Allows for fuel diversity`,
        `Works well with solar and other renewables`,
      ],
    },
    {
      title: ``,
      heading: `What are some advantages of the GPA 180: New Power Plant?`,
      ul: [
        `Reliable! Uses simple and efficient technology.`,
        `Capable of meeting our island’s future energy demands.`,
        `Costs less to operate`,
      ],
    },
    {
      title: ``,
      heading: `Why does the new GPA 180: New Power Plant Project cost less to operate?`,
      ul: [
        `Burns clean ULSD (Ultra-Low-Sulfur Diesel) and natural gas instead of “heavy” fuel.`,
        `Diverse fuel sources results in stabilized fuel recovery charges.`,
      ],
    },
    {
      title: ``,
      heading: `How will the new power plant impact the environment?`,
      ul: [
        `The new power plant integrates existing and future sources of renewable energy into the island wide power system.`,
        `In addition, the new plant is compliant with U.S. EPA and Guam EPA regulations.`,
      ],
    },
    {
      title: ``,
      heading: `Why was Dededo chosen as the site of the power plant?`,
      ul: [
        `Increased reliability was a key factor.`,
        `The new plant will be near an existing GPA substation that has many underground transmission lines.`,
        `The underground lines increase the potential to operate continuously during typhoons and to provide a quicker recovery for all customers after typhoons.`,
        `The location near dense population provides efficiency for delivering power.`,
      ],
    },
    {
      title: ``,
      heading: `What are the environmental benefits of this site?`,
      ul: [
        `The location is not subject to storm surges, tsunamis, sea level rise and other climate change impacts.`,
        `The site does not impact the aquifer which supplies almost all of the freshwater used by the population of Guam.`,
        `Up to three million gallons per day of treated sewer water will be diverted to the plant for cooling purposes.`,
      ],
    },
  ],
};