import React, { MouseEventHandler, useEffect, useState } from "react";
import { H1, Details, Images, Content as ContentContainer, H2 } from "@core";
import { GetFinAsst } from "@content";
import { HeaderGradient } from "@components";
// import { getFileData } from '@src/services';
// import { FILEDATA } from '@src/types';
// import { API } from '@src/config';
import { FaPlus } from "react-icons/fa";

export const GetFinancialAssistance = () => {
  // const [file, setFile] = useState<FILEDATA[]>([]);
  const [showEmergencyRentalAssistance, setShowEmergencyRentalAssistance] =
    useState<boolean>(false);
  const [homeOwnerAssistanceFund, setShowHomeOwnerAssistanceFund] =
    useState<boolean>(false);
  const [showOtherResourcesContent, setShowOtherResourcesContent] =
    useState<boolean>(false);
  const [showRentAndUtilContent, setShowRentAndUtilContent] =
    useState<boolean>(false);
  const [showFAQContent, setShowFAQContent] = useState<boolean>(false);
  const getData = async () => {
    // const fileDatas = await getFileData();
    // setFile(fileDatas.data);
  };

  const openAnimation = {
    animation: "buttonOpening 250ms ease-in",
  };

  const closeAnimation = {
    animation: "buttonClosing 270ms ease-out",
    animationFillMode: "forwards",
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={GetFinAsst.title}
      />
      <div
        className="h-96 relative"
        style={{
          minHeight: "350px",
          backgroundImage: `url(${Images.GPAAugBanner})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
      <ContentContainer>
        <hr />
        <div className="flex items-center">
          <a
            href="https://doa.guam.gov/guam-emergency-rental-assistance-program/"
            rel="noreferrer"
            target="_blank"
          >
            <H1 className="text-blue mb-5">
              Emergency Rental Assistance (ERA) Program
            </H1>
          </a>
          <FaPlus
            // eslint-disable-next-line no-unused-vars
            onClick={(_event): MouseEventHandler<SVGElement> | void =>
              setShowEmergencyRentalAssistance((prevState) => !prevState)
            }
            style={{
              ...(showEmergencyRentalAssistance
                ? closeAnimation
                : openAnimation),
              marginLeft: "15px",
              cursor: "pointer",
            }}
          />
        </div>

        {showEmergencyRentalAssistance ? (
          <>
            <img
              alt="financial-assistance"
              // className="bg-top bg-no-repeat bg-hero"
              src={Images.ERAnewlogo2}
              style={{
                maxWidth: "500px",
                marginLeft: "-25px",
              }}
            />
            <Details>
              Emergency Rental Assistance (ERA)‘s has entered Cycle 6 for their
              rent and utility relief services, and eligible households may
              receive up to 18 months of assistance at this time.
            </Details>
            <Details>
              Funding is through the Department of Administration’s (DOA)
              Emergency Rental Assistance (ERA) Program, to assist households
              unable to pay rent and utility expenses dating back to March 3,
              2020.
            </Details>
            <Details>
              Eligible households may receive up to 18 months of assistance, to
              include current rent and utility payments, and arrears. Ratepayers
              are encouraged to call <b>(671) 638-4518/4519</b>, email 
              <a className="text-orange" href="mailto:era@doa.guam.gov">
                era@doa.guam.gov
              </a>
               or visit{" "}
              <a className="text-orange" href="http://www.doa.guam.gov">
                www.doa.guam.gov
              </a>{" "}
              for more information.
            </Details>
            <Details>
              An DOA-ERA representative is available at the GPA Fadian, Mangilao
              Customer Service Lobby on Thursdays from 9:00am to 1:00pm.
            </Details>
            <Details>
              GPA can assist customers eligible for ERA assistance by providing
              account information directly to DOA.
            </Details>
            <Details>
              GPA account information release forms are available at the DOA
              program website, village mayors’ offices, GPA customer lobbies,
              and by downloading the{" "}
              <a
                className="text-orange"
                href="https://admin.guampowerauthority.com/uploads/ERA_GPA_Utility_Release_Form_cebcae5579.pdf"
              >
                GPA ERA Release Form
              </a>
              .
            </Details>
            <Details>
              For more information, email{" "}
              <a
                className="text-orange"
                href="mailto:customersfirst@gpagwa.com"
              >
                customersfirst@gpagwa.com
              </a>{" "}
              or call <b>(671) 647-5787/8/9</b>.
            </Details>
          </>
        ) : null}
        <hr />
        <div className="flex items-center">
          <a
            href="https://doa.guam.gov/guam-homeowners-assistance-fund-program/"
            rel="noreferrer"
            target="_blank"
          >
            <H1 className="text-blue mb-5">
              Homeowner Assistance Fund (HAF) Program
            </H1>
          </a>
          <FaPlus
            // eslint-disable-next-line no-unused-vars
            onClick={(_event): MouseEventHandler<SVGElement> | void =>
              setShowHomeOwnerAssistanceFund((prevState) => !prevState)
            }
            style={{
              ...(homeOwnerAssistanceFund ? closeAnimation : openAnimation),
              marginLeft: "15px",
              cursor: "pointer",
            }}
          />
        </div>
        {homeOwnerAssistanceFund ? (
          <>
            <Details>
              Guam’s Homeowner Assistance Fund (HAF) Program is a federally
              funded relief program to assist eligible homeowners impacted by
              the COVID-19 pandemic with their mortgage, utilities and property
              tax payments. The Department of Administration (DOA) is the lead
              government entity to administer and implement Guam’s HAF Program.
            </Details>
            <Details>
              Guam’s Homeowner Assistance Fund (HAF)‘s Cycle 4 mortgage,
              utilities and property tax relief services are available now.
            </Details>
          </>
        ) : null}
        {/* <>
          <div className="mt-12 desktop:mt-12" />
          <div className="flex laptop:flex-row phone:flex-col">
            <div className="w-full flex flex-col tablet:flex-row items-start">
              <section className="w-full tablet:w-1/2 tablet:mr-5 tabletWide:mr-20">
                <H1 className="text-blue mb-5">{GetFinAsst.subTitle}</H1>
                <Details>
                  {GetFinAsst.link.map((content) => {
                    return (
                      <>
                        {content.content}
                        {file
                        .filter((fileData) => fileData.attributes.AnchorText === content.anchor)
                        .map((fileData) => {
                          return (
                            <>
                              <a
                                className="text-orange"
                                href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <u>{content.anchor}</u>
                              </a>
                              &nbsp;
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </Details>
                {GetFinAsst.contents.map((content) => {
                  return (
                    <>
                      <Details>{content}</Details>
                    </>
                  );
                })}
              </section>
              <div className="w-full tablet:w-1/2">
                <img
                  alt="We're here to help"
                  className="laptop:h-full tablet:h-auto phone:h-auto"
                  src={Images.HereToHelp}
                />
              </div>
            </div>
          </div>
        </> */}
        {/* <div className="mt-12 desktop:mt-12" /> */}
        <hr />
        <div className="flex items-center">
          <H1 className="text-blue mb-5">Rent and Utilities</H1>
          <FaPlus
            // eslint-disable-next-line no-unused-vars
            onClick={(_event): MouseEventHandler<SVGElement> | void =>
              setShowRentAndUtilContent((prevState) => !prevState)
            }
            style={{
              ...(showRentAndUtilContent ? closeAnimation : openAnimation),
              marginLeft: "15px",
              cursor: "pointer",
            }}
          />
        </div>
        {showRentAndUtilContent ? (
          <Details>
            <b className="font-bold text-orange">
              Looking for assistance with rent and utilities?
            </b>{" "}
            <br /> The Guam Department of Administration Guam Emergency Rental
            Assistance (ERA) program provides rental and utility funding to
            assist rental households impacted during the COVID-19 pandemic
            dating back to March 13, 2020, to May 11, 2023. Eligible rental
            households may receive up to 18 months of assistance to include
            rent, rental arrears, utilities, and utility arrears. <br />
            <br />{" "}
            <b className="font-bold text-orange">
              Eligibility: The tenant household must meet all three eligibility
              criteria:
            </b>{" "}
            <br /> 1. Experienced a reduction in household income, incurred
            significant costs, or experienced other financial hardship during
            the COVID-19 pandemic; and <br />
            2. Demonstrates a risk of experiencing homelessness or housing
            instability; and <br />
            3. Has a household income below 80% of the area median income.{" "}
            <br />
            <br /> <b className="font-bold text-orange">Where to Apply:</b>{" "}
            <br /> Visit{" "}
            <a
              className="text-orange"
              href="https://doa.guam.gov"
              rel="noreferrer"
              target="_blank"
            >
              doa.guam.gov
            </a>{" "}
            to download the Tenant pre-qualification application or apply
            online. Applications are also available at the ERA office building.
            Completed applications can be submitted to the ERA office, ITC
            building, 2nd floor suite 219 between the hours of 8AM-4PM, Monday
            through Friday or at the Government of Guam Relief Center (GRC), 3rd
            Floor Bank of Hawaii building in Hagatna. <br />
            <br /> <b className="font-bold text-orange">APPLICATION STATUS:</b>
            <br /> Tenant applications will be reviewed and applicants will be
            notified within 10 business days of their eligibility status. To
            check the status of your application or for general inquiries,
            please contact the ERA office at 671-638-4518 /4519 or the GRC
            office at 671-475-2072. Applicants who have received ineligibility
            letters have 15 business days from the notice date to appeal their
            case. Appeal forms can be found at doa.guam.gov.
            <br />
            <br />
            <b className="font-bold text-orange">Household Size & Income:</b>
            <br /> 1 &nbsp; $42,250 <br />2 &nbsp; $51,700 <br />3 &nbsp;
            $58,150 <br />4 &nbsp; $64,600 <br />5 &nbsp; $69,800 <br />6 &nbsp;
            $74,950
            <br />7 &nbsp; $80,150 <br />8 &nbsp; $85,300
          </Details>
        ) : null}
        <hr />
        <div className="flex items-center">
          <H1 className="text-blue mb-5">Other Resources</H1>
          <FaPlus
            // eslint-disable-next-line no-unused-vars
            onClick={(_event): MouseEventHandler<SVGElement> | void =>
              setShowOtherResourcesContent((prevState) => !prevState)
            }
            style={{
              ...(showOtherResourcesContent ? closeAnimation : openAnimation),
              marginLeft: "15px",
              cursor: "pointer",
            }}
          />
        </div>
        {showOtherResourcesContent ? (
          <>
            <H2 className="text-orange">
              <a href="https://energy.guam.gov/programs-2">
                Weatherization Assistance Program
              </a>
            </H2>
            <Details>
              The Guam Weatherization Assistance Program (WAP) is a federally
              funded annual program that helps reduce energy costs for eligible
              low-income households by improving the energy efficiency of their
              homes while ensuring their health and safety. For more
              information, contact the Guam Energy Office at 671-646-4361 or
              visit{" "}
              <a className="text-orange" href="https://energy.guam.gov">
                https://energy.guam.gov
              </a>
              .
            </Details>
            <H2 className="text-orange">
              <a href="https://energysense.guampowerauthority.com/about-program">
                Energy Rebates
              </a>
            </H2>
            <Details>
              Earn a rebate with an energy-efficient air conditioner and lower
              your energy consumption. GPA offers rebates when you buy a GPA
              program-qualified air conditioner. Rebates range between $100 to
              $800 depending on system size and efficiency. Contact a vendor
              listed on the GPA Energy Sense website to purchase AC systems that
              qualify. Qualified vendors provide rebate forms to customers at
              the time of purchase. You must properly dispose of old equipment
              with a professional recycler in order to qualify for a rebate. For
              more information, click{" "}
              <a
                className="text-orange"
                href="https://energysense.guampowerauthority.com/rebates/air-conditioning"
              >
                here
              </a>
              .
            </Details>
            <Details>
              Replace washers and dryers with energy efficient models to lower
              your energy consumption. GPA offers rebates when you buy
              appliances that are ENERGY STAR® qualified. Rebates are $200 per
              qualified appliance. Contact a Guam vendor listed on the GPA
              Energy Sense website to purchase appliances that qualify.
              Qualified vendors provide rebate forms to customers at the time of
              purchase. You must properly dispose of old equipment with a
              professional recycler in order to qualify for a rebate. For more
              information, click{" "}
              <a
                className="text-orange"
                href="https://energysense.guampowerauthority.com/rebates/washers-dryers"
              >
                here
              </a>
              .
            </Details>
            <Details>
              Ready to apply? Click{" "}
              <a className="text-orange" href="https://gparebates.com">
                here
              </a>
              .
            </Details>
          </>
        ) : null}
        <hr />
        <div className="flex items-center">
          <H1 className="text-blue mb-5">FAQ's</H1>
          <FaPlus
            // eslint-disable-next-line no-unused-vars
            onClick={(_event): MouseEventHandler<SVGElement> | void =>
              setShowFAQContent((prevState) => !prevState)
            }
            style={{
              ...(showFAQContent ? closeAnimation : openAnimation),
              marginLeft: "15px",
              cursor: "pointer",
            }}
          />
        </div>
        {showFAQContent ? (
          <>
            <Details>
              <b className="font-bold text-orange">
                FREQUENTLY ASKED QUESTIONS (FAQs){" "}
              </b>{" "}
              <br /> <br />{" "}
              <b className="font-bold">
                1. What documents do I need to submit?
              </b>{" "}
              <br /> Once notified of your eligibility, submit all required
              documents listed on the checklist located on the web page or
              emailed with eligibility letter. <br /> <br />{" "}
              <b className="font-bold">
                2. What documents does my landlord need to provide?
              </b>
              <br /> Landlords are required to submit copies of a valid business
              license for rental unit,, W-9, ,Vendor Electronic Funds Transfer
              form, ERA Landlord Verification form and current rental ledger.
              Other documents may be requested as necessary. All forms located
              on the Landlord web page. <br />
              <br />{" "}
              <b className="font-bold">
                3. Is there a limit to how many months of financial assistance a
                tenant can receive?{" "}
              </b>
              <br />
              Yes, the aggregate amount of financial assistance eligible
              households under ERA must not exceed 18 months combined relief
              assistance at all locations previously assisted current and/or
              former adult household members (rent/utilities/
              displacement/relocation). <br />
              <br />{" "}
              <b className="font-bold">
                4. Can you pay my rent in advance if I qualify?
              </b>
              <br />
              All payments are made for current month and arrears only and does
              not reimburse for borrowed monies used to pay rent.; *Only with
              Continuance approved (3mos) prospective rent may be paid if you
              have not exceeded the maximum months of assistance.* <br />
              <br />{" "}
              <b className="font-bold">5. Can ERA help with relocation?</b>
              <br /> Yes, ERA can assist with security deposit plus 1st month's
              rent, if ERA application date is within 30 days of move in date on
              lease (you may only receive relocation assistance every 6 months).{" "}
              <br />
              <br />{" "}
              <b className="font-bold">
                6. I was determined ineligible previously, can I Appeal?
              </b>
              <br />
              Yes, applicants have 15 business days of ineligibility letter date
              to appeal, and it must be filed on the Appeal Request Form. <br />
              <br />{" "}
              <b className="font-bold">
                7. Can the Landlord receive a paper check instead of an
                Electronic Funds Transfer (EFT)?
              </b>
              <br />
              Due to the Department of Administration's (DOA) 'Green Policy,' It
              is strongly encouraged that all ERA assistance be processed as an
              EFT. <br />
              <br />{" "}
              <b className="font-bold">
                8. My utilities were disconnected, can ERA assist with
                reconnection?
              </b>
              <br />
              Yes, if you applied and were determined eligible or submitted
              support documents and determined qualified, we can contact the
              utility agencies to advise of your status. Ultimately, individual
              agencies have the final decision to reconnect or not.
              <br />
              <br />
              <b className="font-bold">
                9. I am displaced, can ERA assist with temporary housing?
              </b>
              <br />
              Yes, if you qualify, ERA can assist with the cost of the hotel or
              motel room occupied by an eligible household subject to
              availability. Displacement Assistance upon approval is limited to
              a maximum 30 days. <br />
              <br />
              <br />
              <b className="font-bold text-orange">
                Landlord Frequently Asked Questions (FAQs)
              </b>
              <br />
              <br />{" "}
              <b className="font-bold">
                1. How long is the application review and payment process?
              </b>
              <br />
              After all the supporting documents are submitted and application
              is approved, it can take up to 2-3 weeks for payment to be
              processed. <br />
              <br />{" "}
              <b className="font-bold">
                2. What documents do I need to provide?
              </b>
              <br />
              Landlords are required to submit copies of a valid business
              license for rental unit, W-9, Vendor Electronic Funds Transfer
              form, ERA Vendor Verification form, and rental ledger. Other
              documents may be requested, as necessary. All forms located on the
              Landlord web page. <br />
              <br />{" "}
              <b className="font-bold">
                3. Why did the ERA program not fully pay my tenant's rent?
              </b>
              <br />
              There may be several reasons - (1) the lease agreement monthly
              rent exceeds the HUD Fair Market Rent (FMR) for Guam, (2) The
              tenant/ applicant received the maximum assistance (18 mos) allowed
              under the ERA program, (3) the landlord included non-allowable
              charges (ex. work orders/lock out fees/ cleaning fees), (4) Late
              fees over the allowable maximum of 10% of the monthly rental
              amount. <br />
              <br />{" "}
              <b className="font-bold">
                4. When did ERA implement the HUD Fair Market Rent (FMR)? Do I
                need to change my lease agreement to match it?
              </b>
              <br />
              The HUG FMR maximum was implemented in ERA Cycle 5 (October 2022)
              in compliance with the U.S. Department of Treasury requirements to
              follow the HUD FMR. There is no need to change the lease agreement
              as it is between the landlord and the tenant. However, ERA will
              only pay the FMR rent maximum allowable. This rule also applies to
              the security deposit and the 10% late fee maximum of monthly rent.{" "}
              <br />
              <br />{" "}
              <b className="font-bold">
                5. Can Landlords receive a paper check instead of Electronic
                Funds Transfer?
              </b>
              <br />
              The Department of Administration (DOA) has a 'Green Policy' and
              strongly encourages that all ERA relief assistance is processed as
              an EFT. <br />
              <br />{" "}
              <b className="font-bold">
                6. Landlord banking information has changed, what do I need to
                do to ensure the funds are applied to the correct account?
              </b>
              <br />
              The landlord is responsible to notify the ERA program and submit a
              new EFT form to update their information. Any reject of
              payment(s), the landlord has 24 hours to make correction. Any
              subsequent reject of payment(s) to the same landlord for the same
              vendor number will be assessed $1000, and be deducted from the
              payment due. <br />
              <br />{" "}
              <b className="font-bold">
                7. What is the process if the landlord bills the utilities
                directly to the tenant?
              </b>
              <br />
              The landlord must provide copies of the utility bills to justify
              the charges or other documentation to show usage or how amount was
              calculated. The lease agreement must show that utilities are the
              responsibility of the tenant. If any of the requirements are not
              met, the utility charges will be considered 'part of the rent.'{" "}
              <br />
              <br />
              <b className="font-bold">
                8. I received the Initial Disposition Letter (letter of Final
                Assistance) advising tenant has reached the maximum 18 months of
                assistance under the ERA program, but the tenant has lived in
                the current unit less than 18 months. How did this happen?
              </b>
              <br />
              The applicant/tenant or another adult member in the household
              received ERA assistance in a previous location rent and/or
              utilities assistance causing their months of assistance to be
              added to their current application. <br />
              <br />
              <b className="font-bold">
                9. Can ERA assist with security deposit?
              </b>
              <br />
              Yes, upon approval, the program currently assists with Relocation
              Assistance. ERA can assist with the first month's rent and/or
              security deposit. However, the application must be submitted
              within 30 days of the 'move-in' date and is subject to ERA
              eligibility criteria. <br />
              <br />
              <b className="font-bold">
                10. What is the procedure if the Landlord has received an
                overpayment of rental assistance?{" "}
              </b>
              <br />
              The amount overpaid should be returned via check, made payable to
              Treasurer of Guam with the ERA Case No. in the memo section of
              check. Submit the check to the ERA office. NOTE: ERA internal
              audit may discover the overpayment and will contact the landlord
              via letter explaining the overpayment. <br />
              <br />
              <b className="font-bold">
                11. Can I evict my tenant who is under the ERA Program?{" "}
              </b>{" "}
              <br />
              When a tenant applies for ERA assistance, they have to submit an
              ERA Landlord Verification Form signed by both applicant/landlord.
              On the ERA Landlord Verification Form, the landlord agrees not to
              evict the tenant/applicant for non-payment of rent for 90 days to
              allow for ERA application processing time or while the tenant is
              actively participating in the program (Continuance status).
              <br />
              <br />{" "}
              <b className="font-bold">
                12. How can a landlord confirm if payment is processed and
                deposited?
              </b>{" "}
              <br />
              The Landlord will receive via USPS mail, an EFT statement from
              Treasurer of Guam within 10 business days of EFT completion. The
              statement will reflect the amount of assistance and name of
              tenant. Additionally, the landlord may check with their bank
              institution. <br />
              <br />
              <br />{" "}
              <b className="font-bold">
                Guam Emergency Rental Assistance Program: ERAP Maximum FMRS by
                Unit Bedroom
              </b>
              <br />
              <br /> Area &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Guam &nbsp;&nbsp;{" "}
              <br /> Studio &nbsp;&nbsp;&nbsp; $1,093 <br />1
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;$1,199 <br />
              2
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              $1,578 <br />3
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              $2,243 <br />4
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              $2,688 <br />5
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              $3,091
              <br />
              <br />
              <b className="font-bold"> Need assistance? </b> <br />
              Contact ERA office at (671) 638-4518/4519 <br />
              Email:{" "}
              <a className="text-orange" href="mailto:era@doa.guam.gov">
                era@doa.guam.gov
              </a>{" "}
              <br />
              Office Hours: Monday - Friday 8AM - 4PM <br />
              <br />
              <b className="font-bold">
                {" "}
                Guam’s Emergency Rental Assistance Program was awarded to the
                Government of Guam by the U.S. Department of Treasury{" "}
              </b>
            </Details>
          </>
        ) : null}
        <hr />
        <div
          className="border-rounded border-red mx-auto rounded-full w-full text-center phone:text-sm phoneWide:text-3xl tablet:text-4xl px-12 py-4 font-bold mt-28"
          style={{
            border: "1px solid #DC3545",
            color: "#DC3545",
          }}
        >
          Avoid power service account interruptions.
        </div>
        <p
          className="text-center mx-auto mt-4"
          style={{
            maxWidth: "820px",
          }}
        >
          If you are a customer with a past-due balance, we're here to help.
          We'll work together to find a solution for you. Contact us at (671)
          647-5787/8/9 or email{" "}
          <a className="text-orange" href="mailto:customersfirst@gpagwa.com">
            customersfirst@gpagwa.com
          </a>
          .
        </p>
      </ContentContainer>
    </>
  );
};
