import React from 'react';
import { H1, Details, Content as ContentContainer, H2 } from '@core';
import { ProjectsForecastsStudiesContent } from '@content';
import { HeaderGradient } from '@components';

export const ProjectsForecastsStudies = () => {
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={ProjectsForecastsStudiesContent.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-full flex flex-col tablet:flex-row items-center">
            <section className="w-full">
              <H1 className="text-blue mb-5">{ProjectsForecastsStudiesContent.title}</H1>
              {ProjectsForecastsStudiesContent.contents.map((content) => {
                return (
                  <>
                    <H2>{content.title}</H2>
                    <Details>
                      {content.body}
                      <a className="text-orange" href={content.link}>
                        {content.anchor}
                      </a>
                    </Details>
                  </>
                );
              })}
              <Details className="text-blue">{ProjectsForecastsStudiesContent.note}</Details>
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
