export const WaysToPayMethod = {
  methodArray: [
    {
      method: 'Online',
      description:
        'Vist PayGPA.com or Download the Pay GPWA App. You can also pay your GWA water bill on the app.',
      linkText: 'www.payGPA.com',
      link: 'https://paygpa.com/',
    },
    {
      method: 'Pay By Phone',
      description:
        'To make a GPA payment over the phone, please contact (671) 647-5787/8/9 option 2 for Pay By Phone from Monday to Friday 8am – 5pm. Afterhours 1-(855) 977-2002.',
      linkText: '',
      link: '',
    },

    {
      method: 'Mail-In Payment',
      description: 'Make Check payable to GPA and mailed to: P.O. Box 21868 Barrigada, GU 96921',
      linkText: '',
      link: '',
    },
    {
      method: 'Drop Box',
      description: [
        'Only ',
        'CHECK',
        ' payment to: Gloria B. Nelson Public Service Building 688 Route 15, Fadian, Mangilao, GU 96913. (along back road to andersen)',
      ],
      linkText: '',
      link: '',
    },
    {
      method: 'Drive-Thru',
      description:
        'Cash, Check, debit card and Credit Card payments Visa, Mastercard and Discover are accepted at: GPA Fadian Main Office Monday-Friday 7:30am – 3:30pm',
      linkText: '',
      link: '',
    },
    {
      method: 'In Person',
      description: 'Pay by cash, check, debit card,  credit card, Visa, Mastercard and Discover. ',
      linkText: '',
      link: '',
    },
  ],
};

export const PaymentCenter = {
  title: 'Payment Center Locations/Hours/Phone Numbers',
  subtitle: 'CONTACT GPA',
  mainContact: ['Main Phone Number', '(671) 648-3000'],
  contactGpa: [
    'Customer Service Call Center (671) 647-5787/8/9',
    'Emergency / Trouble Call Reporting (671) 475-1472 /3 /4',
    'Report Fraud, Waste & Abuse Hotline (671) 648-3199',
  ],
  reportAbusement: [
    'Report Fraud, Waste & Abuse Email',
    'ia_rps@gpagwa.com',
    'mailto:ia_rps@gpagwa.com',
  ],
};

export const PayByPhone = {
  title: 'Pay by Phone',
  contact: 'EZ Pay (671) 647-5787/8/9',
};

export const PayOnline = {
  title: 'Pay Online',
  contact: 'PayGPA',
  linkText: 'www.paygpa.com',
  link: 'https://paygpa.com/',
};

export const CustomerServices = {
  title: 'Customer Services',
  contact: 'For Mailing Addresses, Business Hours and Locations',
  linkText: 'Click Here.',
  link: '#business-hours-and-location',
};

export const EmailCustomerService = {
  title: 'Email Customer Service',
  linkText: 'CustomersFirst@gpagwa.com',
  link: 'mailto:CustomersFirst@gpagwa.com',
};

export const DepartmentNum = {
  title: 'Department Numbers',
  contactArray: [
    'Accounts Payable (671) 648-3072',
    'Accounts Receivable (671) 648-3074',
    'Automated Attendant (671) 648-3000',
    'Budget (671) 648-3127',
    'Chief Financial Officer (671) 648-3120',
    'Communications and Public Information (671) 648-3226',
    'Computer Services (671) 648-3060',
    'Controller (671) 648-3122',
    'Customer Service (671) 647-5787',
    'Engineering (671) 648-3011 / (671) 648-3205',
    'Facilities (671) 648-3142',
    'General Manager (671) 648-3180',
    'Human Resources (671) 648-3134',
    'Internal Auditor (671) 648-3090',
    'Payroll (671) 648-3126',
    'Plant Accounting (671) 648-3071',
    'Planning and Regulatory (671) 648-3029',
    'Procurement Materials Management (671) 648-3054/5',
    'Procurement Dededo Supply Receiving (671) 635-2073',
    'Procurement Supply Transportation Receiving (671) 300-8321',
    'Procurement Cabras Supply Receiving (671) 475-5319',
    'Safety (671) 648-3056',
    'SPORD (671) 648-3083',
  ],
};

export const DepartmentFaxNumbers = {
  title: 'Department Fax Numbers',
  contactArray: [
    'Accounting/Finance Fax (671) 648-3168',
    'Communications and Public Information Fax (671) 648-3166',
    'Customer Services Fax (671) 648-3164',
    'General Manager/ Executive Fax (671) 648-3290',
    'Human Resources Fax (671) 648-3160',
    'Internal Audit Fax (671) 648-3163',
    'Procurement Fax (671) 648-3165',
  ],
};

export const GenerationAdministration = {
  title: 'Generation Administration',
  contactArray: [
    'Generation Administration Office (671) 475-5213',
    'Cabras #1 & #2 (671) 475-5249',
    'Cabras #3 & #4 (671) 475-5272',
    'Central Maintenance (671) 475-5237',
    'Combustion Turbine Administration (671) 300-8306',
    'Diesel Administration (671) 300-8312',
    'Water Systems Diesel (671) 475-5243',
  ],
};

export const GenerationAdministrationFaxNumbers = {
  title: 'Generation Administration Fax Numbers',
  contactArray: [
    'Generation Administration Fax (671) 475-5217',
    'Water Systems Diesel Fax (671) 475-5216',
  ],
};

export const TransmissionAndDistribution = {
  title: 'Transmission & Distribution',
  contactArray: [
    'Office (671) 635-2000 / (671) 637-4100',
    'Fax (671) 637-6759',
    'T&D Overhead (671) 635-2000 / (671) 635-2007',
    'T&D Underground (671) 635-2000 / (671) 635-2033',
    'T&D Substation (671) 635-2000 / (671) 635-2016',
    'T&D Meter/Relay (671) 635-2000 / (671) 635-2047',
    'T&D Operations (671) 635-2000 / (671) 635-2025',
  ],
};

export const Transportation = {
  title: 'Transportation',
  contact: '(671) 300-8318',
};

export const BusinessHoursAndLocations = {
  title: 'Business Hours and Locations',
};

export const CustomerServiceCenterAndMainOffice = {
  title: 'Customer Service Center & Main Office',
  linkText: 'Map',
  link: '',
  contactArray: [
    'Gloria B. Nelson Public Service Building',
    '688 Route 15 Fadian, Mangilao, Guam',
    'Monday-Friday 7am-5pm',
    'Closed on Weekends & Holidays',
  ],
};

export const HagatnaSatelliteOffice = {
  title: 'Hagatna Satellite Office',
  contactArray: [
    'Julale Shopping Center Ste 103, 424 West O’Brien Drive, Hagåtña, Guam',
    'Monday-Friday 8am-5pm',
    'Closed on Weekends & Holidays',
  ],
};

export const GpwaUpperTumonOffice = {
  title: 'GPWA Upper Tumon Office',
  contactArray: [
    '578 North Marine Corps Drive, Tamuning, Guam',
    'Monday-Friday 7:30am-6pm',
    'Saturday 8am-12pm',
  ],
};

export const Other = {
  title: 'Other',
  contactArray: [
    'Your local banks are subject to individual bank policies and all Treasure of Guam locations.',
  ],
};

export const MailingAddresses = {
  title: 'Mailing Addresses',
  addressesArray: [
    {
      subTitle: 'Customer Service Center (Utility Payments)',
      address: 'P. O. Box 21868, GMF, GU 96921-1868',
    },
    {
      subTitle: 'Main Office:',
      address: ' Gloria B. Nelson Public Service Building',
      address2: ' 688 Route 15, Suite 100, Mangilao, Guam 96913-6203',
    },
    {
      subTitle: 'All mail',
      address: 'P. O. Box 21868, GMF, GU 96921',
    },
  ],
};
