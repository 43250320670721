import React, { ChangeEvent, MouseEventHandler, useState } from "react";
import { HeaderGradient } from "@src/components";

import {
  //   H1,
  // Details,
  // List,
  // UnorderedList,
  Content as ContentContainer,
  Details,
  Images,
} from "@core";
import { FaPlus } from "react-icons/fa";

interface IVillageOutageDetails {
  [key: string]: {
    title: string;
    codes: Array<string>;
    details: Array<{
      timeRanges: Array<string>;
      description: string;
    }>;
  };
}

const villageOutageDetails: IVillageOutageDetails = {
  hågat: {
    title: "HÅGAT | Potential Load Shedding Schedule",
    codes: ["P-223", "P-341"],
    details: [
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Route 2 from Route 2A intersection to Nimitz Beach, including Inn on the Bay, portion of Route 12, Pagachag Dr., Hågat village, Umang Rd.  and surrounding areas.",
      },
      {
        timeRanges: [
          "4:00 PM – 5:00 PM",
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10:00 PM",
        ],
        description:
          "Route 2 south part of Hågat village including De Los Marteres St., Pagachao Subdivision, Agat Cemetery and surrounding areas.",
      },
    ],
  },
  "agana-heights": {
    title: "AGANA HEIGHTS | Potential Load Shedding Schedule",
    codes: ["P-283"],
    details: [
      {
        timeRanges: [
          "4:00 PM – 5:00 PM",
          "7:00 PM - 8:00 PM",
          "9:00 PM - 10:00 PM",
        ],
        description:
          "Chalan Palasyo, Government House, Civil Defense, Agana Heights, Agana Heights Elem. School and surrounding areas.",
      },
    ],
  },
  anigua: {
    title: "ANIGUA | Potential Load Shedding Schedule",
    codes: ["P-280", "P-281"],
    details: [
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Route 1 northbound Anigua, including Governor’s office (Gov. Ricardo J. Bordallo Complex in Adelup), Mobil to House of Chin Fe, Napa and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "West O'Brien Dr. northbound including Pedro's Plaza, PDN/DNA building and surrounding areas.",
      },
    ],
  },
  asan: {
    title: "ASAN | Potential Load Shedding Schedule",
    codes: ["P-280"],
    details: [
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Route 1 south to Asan village and Asan Park. Route 1 northbound (Ramona St. to Consolacion Ct.) and surrounding areas.",
      },
    ],
  },
  barrigada: {
    title: "BARRIGADA | Potential Load Shedding Schedule",
    codes: ["P-211", "P-212", "P-213", "P-311"],
    details: [
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "8:00 PM – 9:00 PM",
          "11:00 PM – 12:00 AM",
        ],
        description:
          "Route 10 northbound side towards Dairy Road, Untalan Middle School, San Antonio St., and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Between Route 10 and Route 8, Barrigada Village Area, Leyang, Canada-Toto Rd., parts of Tiyan (EPA & GPD Headquarters) and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "8:00 PM – 9:00 PM",
          "11:00 PM – 12:00 AM",
        ],
        description:
          "Route 10 and Route 8 intersection, 76 Station, Radio Barrigada area, up Route 16 to Tiyan ball fields, GDOE Main Office; post office, and to lower Barrigada Heights and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Bello Area Macheche, Tun Pedro Benavente St., Route 16 to Barrigada Heights, DMR and Commercial Tire and surrounding areas.",
      },
    ],
  },
  dededo: {
    title: "DEDEDO | Potential Load Shedding Schedule",
    codes: [
      "P-046",
      "P-087",
      "P-088",
      "P-089",
      "P-270",
      "P-271",
      "P-272",
      "P-310",
      "P-322",
    ],
    details: [
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "8:00 PM – 9:00 PM",
          "11:00 PM – 12:00 AM",
        ],
        description:
          "Along Route 3 from Route 1 intersection to Potts Junction including Swamp Rd., Machananao, Chalan Kareta and nearby areas; Ysengsong to Fire Station, parts of Astumbo Gardens and surrounding areas.",
      },
      {
        timeRanges: [
          "4:00 PM – 5:00 PM", 
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10:00 PM"
        ],
        description:
          "Lucky Supplies to parts of Batulo St. (Municipal Golf Course) to Ysengsong, Swamp Rd., Stampa Rd., Chalan Palauan to Machanao area to Astumbo to Ironwood Estates to Route 3, Astumbo Elementary School, Astumbo Middle and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Route 1, southbound from Prestige Motors to Fatima Rd., including Dededo village, Kaiser areas, Summerville Estates, Ysengsong Rd. (Route 1 to Clara St.), including Mobil, GTA and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "8:00 PM – 9:00 PM",
          "11:00 PM – 12:00 AM"
        ],
        description:
          "Route 1, southbound from Wusstig Rd. to Batulo St., including GHURA 505, Chalan Kaskahu, Paradise Estates and GICC and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "9:00 PM – 10:00 PM",
          "11:00 PM – 12:00 AM",
        ],
        description:
          "Macheche - Harmon Loop north side to Cost U Less; Route 16 right side towards Mobil, Fatima Rd, North Liguan and Micronesia Mall and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM", 
          "8:00 PM – 9:00 PM",
          "11:00 PM – 12:00 AM"
        ],
        description:
          "Harmon Loop Rd (left side) towards Adrian Sanchez St / Hamburger Road, and Route 1 northbound to Dos Amantes Plaza, Sgambelluri St., and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "9:00 PM – 10:00 PM",
          "11:00 PM – 12:00 AM"
        ],
        description:
          "Macheche - Liguan Area, Sateena Mall, Payless, Macheche Rd. to Bread Box and PGD, Chandhia Rd., East Nandez St., Harmon Loop Rd. eastbound (sports complex to Route 1), Dededo Payless and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Route 16 North to Pacific Laundry both sides, after Harmon Loop Intersection southbound lane customers to American Bakery, Alegeta St., to include Airport Hill Route 16/Route 1 intersection (Shell station, Harmon Villa Apts, & Pia Marine); including St. Paul’s to Benavente St. and surrounding areas.",
      },
      {
        timeRanges: [
          "4:00 PM – 5:00 PM",
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10:00 PM",
        ],
        description:
          "Macheche Ave, portions of Alegeta Rd, including Mogfog, Las Palmas Phase II and surrounding areas.",
      },
    ],
  },
  hagåtna: {
    title: "HAGÅTÑA | Potential Load Shedding Schedule",
    codes: ["P-202", "P-250", "P-251", "P-252", "P-281", "P-282", "P-283"],
    details: [
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM"
        ],
        description:
          "Route 1 from Alupang Beach Park to Route 1/Route 8 intersection and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM"
        ],
        description:
          "Hagåtña swamp to Route 4 toward Afame Rd and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "E O’Brien Dr, Route 1 and Route 8 intersection, including Bank of Guam, Reflection Center, Ada’s Commercial Center, Big Hook and surrounding area",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "9:00 PM – 10:00 PM",
          "11:00 PM – 12:00 AM",
        ],
        description:
          "East O'Brien southbound including Hagatna Mayor's Office, Hagatna Pool, Hagåtña Library, Red Cross Building), San Ramon St., Route 4, Agana Shopping Center, McDonalds and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Route 33/Roy T Damian intersection to West O'Brien Dr. (from Hagåtña Library to 9th St.); Martyr St., Archbishop Flores St., Chamorro Village, Agana Cathedral, Superior Court of Guam (main building) and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "9:00 PM – 10:00 PM",
          "11:00 PM – 12:00 AM"
        ],
        description:
          "West O'Brien Dr., Julale Center, Hagåtña Post Office, Academy of our Lady of Guam School, GWA Treatment Plant and surrounding areas.",
      },
      {
        timeRanges: [
          "4:00 PM – 5:00 PM",
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10:00 PM",
        ],
        description:
          "West O'Brien Dr. eastbound, including Santa Cruz Ln to Pizza Hut, including Superior Court (San Ramon Building), Post Office, and San Ramon Hill and surrounding areas.",
      },
    ],
  },
  humåtak: {
    title: "HUMÅTAK | Potential Load Shedding Schedule",
    codes: ["P-340", "P-341"],
    details: [
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Route 2 Humåtak village, Chalan Kanton Tasi including San Dionisio St., JS Quinata St. and surrounding areas. ",
      },
      {
        timeRanges: [
          "4:00 PM – 5:00 PM",
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10-:00 PM",
        ],
        description:
          "Route 2 from Humåtak Village northbound including Jesus A Quidachay St., Humåtak Bridge  and surrounding areas.",
      },
    ],
  },
  inalåhan: {
    title: "INALÅHAN | Potential Load Shedding Schedule",
    codes: ["P-340", "P-261"],
    details: [
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Route 4 Chalan Kanton Tasi, including Inalåhan village including Pale Duenas St., Inedo Lane, Bear Rock, Jesus M Crisostomo St., Inalahan Way, Ija Subdivision, Belen Avenue, Chagamin Lagu’, parts of Malojloj, Poultry Farm and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Malojloj up to Poultry Farm, Dandan Rd. and surrounding areas.",
      },
    ],
  },
  "ma'ina": {
    title: "MA’INA | Potential Load Shedding Schedule. This schedule is subject to change based on generation capacity and power demand.",
    codes: ["P-280"],
    details: [
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Route 6/Halsey Dr., including Maria Candelaria, Binakle Rd. and surrounding areas.",
      },
    ],
  },
  malesso: {
    title: "MALESSO' | Potential Load Shedding Schedule",
    codes: ["P-340"],
    details: [
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Route 4 Chalan Kanton Tasi, Malesso village including Malesso Pier, Merizo Elementary School, Quinene Rd., Arriola’s Beach, Malesso’ Canoe and surrounding areas.",
      },
    ],
  },
  mangilao: {
    title: "MANGILAO | Potential Load Shedding Schedule. This schedule is subject to change based on generation capacity and power demand.",
    codes: ["P-210", "P-211", "P-250", "P-294", "P-321", "P-322", "P-323"],
    details: [
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Route 10 southbound to Route 15 intersection, Jesus Mariano area, UOG Campus, UOG Fieldhouse, UOG Marine Lab and surrounding areas. ",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "8:00 PM – 9:00 PM",
          "11:00 PM – 12:00 AM",
        ],
        description:
          "Route 10/15 intersection toward Dairy Road, Lalo Ave, DYA, Price Elementary, and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM"
        ],
        description:
          "From Mangilao on to Dairy Road toward Route 10 including DOC, portion of DYA and surrounding areas.",
      },
      {
        timeRanges: [
          "4:00 PM – 5:00 PM",
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10:00 PM",
        ],
        description:
          "Route 10 westbound from old Public Health to Route 4 intersection. Chalan Pago and Tai Mangilao and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "8:00 PM – 9:00 PM",
          "11:00 PM – 12:00 AM",
        ],
        description:
          "Mangilao Mayor's Office, Green Park Condo, Washington Drive, Corten Torres St., GWHS, GCC, Ladera Towers, Fadian area, Route 15 northbound to Marbo Cave intersection and surrounding areas.",
      },
      {
        timeRanges: [
          "4:00 PM – 5:00 PM",
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10:00 PM",
        ],
        description:
          "Carnation Ave, Adacao Rd. to Latte Heights to Las Palmas Phase II and surrounding areas.",
      },
      {
        timeRanges: [
          "4:00 PM – 5:00 PM",
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10:00 PM",
        ],
        description:
          "Route 15 (Hawaiian Rock to Fausto Pangelinan St, including Marbo Cave Rd.) and surrounding areas.",
      },
    ],
  },
  "mongmong-toto-maite": {
    title: "MONGMONG-TOTO-MAITE | Potential Load Shedding Schedule",
    codes: ["P-251", "P-252", "P-253", "P-281"],
    details: [
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Route 8 from Sgt. Roy T. Damian St exit to ET Calvo Way, JA Camacho St from E O’Brien Dr to Biang St, MongMong Catholic Church, CHamorro Gardens and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "9:00 PM – 10:00 PM",
          "11:00 PM – 12:00 AM",
        ],
        description:
          "A portion of MTM Rd., including Tenbat St. and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "8:00 PM – 9:00 PM",
          "11:00 PM – 12:00 AM"
        ],
        description:
          "Sgt. Roy T. Damian St., Biang St., west to Toto-Cañada Rd., Route 8 from Shop for Less to Maite Mobil; including West Cesario St., Chalan Machaute and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM", 
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM"
        ],
        description:
          "MongMong Parish Rectory / Social Hall, a portion of Sgt Roy T. Damian St, parts of CHamorro Gardens and surrounding areas",
      },
    ],
  },
  "ordot-chalan-pago": {
    title: "ORDOT/CHALAN PAGO | Potential Load Shedding Schedule",
    codes: ["P-250"],
    details: [
      {
        timeRanges: [
          "5:00 PM – 6:00 PM", 
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM"
        ],
        description:
          "Along Route 4 from Spring Ln. to Rte 10 intersection, including Dero Rd., Maimai St and surrounding areas.",
      },
    ],
  },
  piti: {
    title: "PITI | Potential Load Shedding Schedule",
    codes: ["P-005", "P-007"],
    details: [
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Route 1 from GSA to Polaris Point entrance; Part of Route 11A, Port Authority Beach, Atlantis area; Route 6 from Rte 1 intersection to Halsey Dr, and Turner Rd. and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Route 1 from Port intersection northbound to War In the Pacific Park, including Fish Eye; parts of Piti village, including Masso Ct., portion of Assumption Dr., Tuncap St., JR Roberto, San Nicolas Ln., and surrounding areas.",
      },
    ],
  },
  "sånta-rita-sumai": {
    title: "SÅNTA RITA-SUMAI | Potential Load Shedding Schedule",
    codes: ["P-220", "P-221", "P-223", "P-260"],
    details: [
      {
        timeRanges: [
          "4:00 PM – 5:00 PM",
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10:00 PM",
        ],
        description:
          "Route 17 (Cross Island Rd.) up to Bishop Flores St. and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "9:00 PM – 10:00 PM",
          "11:00 PM – 12:00 AM",
        ],
        description:
          "Sånta Rita village through Pale Ferdinand up Chalan Obispo Olano to RR Cruz St. and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Route 2A from Taco Bell to Route 2, including Route 5 and Sumai Payless and surrounding areas.",
      },
      {
        timeRanges: [
          "4:00 PM – 5:00 PM",
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10:00 PM",
        ],
        description:
          "Cross Island Rd. from Cotal wind turbine to Bishop Apuron St. and surrounding areas.",
      },
    ],
  },
  sinajana: {
    title: "SINAJANA | Potential Load Shedding Schedule",
    codes: ["P-250", "P-252", "P-283"],
    details: [
      {
        timeRanges: [
          "5:00 PM – 6:00 PM", 
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description: "Sinajana Mobil, Holiday Towers, Afame, Chaot, and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "9:00 PM – 10:00 PM",
          "11:00 PM – 12:00 AM",
        ],
        description:
          "Along Route 4, Sinajana Underground including St. Jude Thaddeus Church and surrounding areas.",
      },
      {
        timeRanges: [
          "4:00 PM – 5:00 PM",
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10:00 PM",
        ],
        description:
          "Sinajana village through GHURA Housing, Sinajana Mobil, BBM Catholic School, CL Taitano Elem. School and surrounding areas.",
      },
    ],
  },
  "talo'fo'fo": {
    title: "TALO’FO’FO | Potential Load Shedding Schedule",
    codes: ["P-260", "P-261", "P-262"],
    details: [
      {
        timeRanges: [
          "4:00 PM – 5:00 PM",
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10:00 PM",
        ],
        description:
          "Talo’fo’fo village to Onward Golf Course left side to Route 17 towards Apra up to Tarzan Falls, to include Route 4A from Talo’fo’fo Fire Station and surrounding areas",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Talo’fo’fo village, along Route 4A towards Talo'fo'fo Bay, including Wolford Heights and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Talo’fo’fo Golf Course right side to Route 17 to Ipan area, Route 4 southbound (Cross Island Rd. intersection to Tun Mariano Rd.); Route 4A (village to Route 17, including golf course) and surrounding areas.",
      },
    ],
  },
  tamuning: {
    title: "TAMUNING/HARMON | Potential Load Shedding Schedule",
    codes: [
      "P-201",
      "P-202",
      "P-203",
      "P-204",
      "P-206",
      "P-205",
      "P-240",
      "P-245",
      "P-271",
      "P-312",
      "P-313",
      "P-400",
    ],
    details: [
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM"
        ],
        description:
          "Route 1 southbound from Mobil Airport to Atkins Kroll, including Ypao Rd. and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM"
        ],
        description:
          "Route 1 southbound side from Fire station to Docomo then both sides on Route 1 to Route 8 intersection, Tun Josen Fejeran St. and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "8:00 PM – 9:00 PM",
          "11:00 PM – 12:00 AM"
        ],
        description:
          "Chalan San Antonio northbound (Jerry Kitchen to Sagua Managu, and Ada compound); Farenholt Ave westbound (St. Anthony Church to Oka Payless) to include a portion of Gov. Camacho Rd toward GMH; Jonestown, Guam Greyhound and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM", 
          "8:00 PM – 9:00 PM",
          "11:00 PM – 12:00 AM"
        ],
        description:
          "Chalan San Antonio southbound to ITC intersection, including Baltej Plaza, GPO, and surrounding areas.",
      },
      {
        timeRanges: [
          "4:00 PM – 5:00 PM", 
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10:00 PM"
        ],
        description:
          "Route 1 northbound (Hafa Adai lane to Don Don Ki) to airport road and surrounding areas.",
      },
      {
        timeRanges: [
          "4:00 PM – 5:00 PM", 
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10:00 PM"
        ],
        description:
          "Gov. Carlos G Camacho Rd., Alupang Cove, RIHGA Resort; Calvo Memorial Industrial Park (behind ITC); Route 1 northbound (Jack In The Box to Rexall); and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "8:00 PM – 9:00 PM",
          "11:00 PM – 12:00 AM",
        ],
        description:
          "Route 1 north to East West Center, Triple J and DPW; Route 1 southbound (top of Old San Vitores Rd.) and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM", 
          "9:00 PM – 10:00 PM",
          "11:00 PM – 12:00 AM"
        ],
        description:
          "Harmon Industrial Park, Boy Scouts and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM", 
          "8:00 PM – 9:00 PM",
          "11:00 PM – 12:00 AM"
        ],
        description:
          "Harmon Loop Rd (left side) towards Adrian Sanchez St./Hamburger Road, Route 1 northbound to Dos Amantes Plaza, Sgambelluri St and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM - 7:00 PM",
          "8:00 PM - 9:00 PM",
          "11:00 PM – 12:00 AM",
        ],
        description:
          "Route 1 northbound from Triple J to JFK HS; Route 10A from 76 station to Home Depot; Talo Verde and surrounding areas.",
      },
      {
        timeRanges: [
          "4:00 PM – 5:00 PM",
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10:00 PM",
        ],
        description:
          "GIAA and surrounding areas",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM", 
          "9:00 PM – 10:00 PM",
          "11:00 PM – 12:00 AM"
        ],
        description:
          "San Vitores, Tumon, Archbishop Flores Circle, Perezville, and surrounding areas.",
      },
    ],
  },
  yigo: {
    title: "YIGO| Potential Load Shedding Schedule",
    codes: ["P-089", "P-320", "P-330", "P-331", "P-332"],
    details: [
      {
        timeRanges: [
          "6:00 PM – 7:00 PM", 
          "8:00 PM – 9:00 PM",
          "11:00 PM – 12:00 AM"
        ],
        description:
          "Route 1 southbound from old Ace Hardware to Wusstig Rd. and surrounding areas.",
      },
      {
        timeRanges: [
          "4:00 PM – 5:00 PM",
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10:00 PM",
        ],
        description:
          "Nelson Area, Route 15 northbound from Marbo Cave intersection to AAFB back gate, to include UPI Elem., Chalan Kirida, parts of Takano Subdivision, Gayinero East, Mt. Santa Rosa and surrounding areas.",
      },
      {
        timeRanges: [
          "6:00 PM – 7:00 PM",
          "9:00 PM – 10:00 PM",
        ],
        description:
          "North on Route 1 to Chalan La Chanch, Mataguac along Route 9 (Machanaonao, Agafa Gumas) to Potts Junction; to include Route 1 southbound (Paradise Meadows, Chalan Koda) and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Route 1 southbound lane Japanese Memorial to Simon Sanchez HS; Route 1 southbound to Latter Day Saints to old Ace Hardware; Route 15 from Raceway Park to Mt. Santa Rosa, including Gill Breeze Subdivision and surrounding areas.",
      },
      {
        timeRanges: [
          "4:00 PM – 5:00 PM",
          "7:00 PM – 8:00 PM",
          "9:00 PM – 10:00 PM",
        ],
        description:
          "Route 1 northbound (Turner Rd. to AAFB), south Route 1 left side to Yigo Fire Station and Gayinero Rd. to Route 15 and Takano Subdivision; Route 15 northbound (Guam Raceway Park to Mt. Santa Rosa) and surrounding areas.",
      },
    ],
  },
  "yo'ña": {
    title: "Yo'ña | Potential Load Shedding Schedule",
    codes: ["P-262", "P-294", "P-298", "P-301"],
    details: [
      {
        timeRanges: [
          "5:00 PM – 6:00 PM", 
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Windward Hills, Baza Gardens to Route 4 and surrounding areas.",
      },
      {
        timeRanges: [
          "4:00 PM – 5:00 PM ",
          "7:00 PM – 8:00 PM ",
          "9:00 PM – 10:00 PM  ",
        ],
        description:
          "Route 4 to MU Lujan Rd., including Yo’ña village, Pago Bay Estates, Pulantat - Chalan Teleforo to tracking station, Yo’ña Village to Pago Bay and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "8:00 PM – 9:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description: "Pulantat – Leo Palace and surrounding areas.",
      },
      {
        timeRanges: [
          "5:00 PM – 6:00 PM",
          "7:00 PM – 8:00 PM",
          "10:00 PM – 11:00 PM",
        ],
        description:
          "Pulantat, Chalan Teleforo; bottom of loop to Yo’ña cliffside to Ylig and surrounding areas.",
      },
    ],
  },
};

const openAnimation = {
  animation: "buttonOpening 250ms ease-in",
};

const closeAnimation = {
  animation: "buttonClosing 270ms ease-out",
  animationFillMode: "forwards",
};

const PotentialRotatingOutageSched = () => {
  const [selectedVillage, setSelectedVillage] = useState<string>("");
  const [showAboutThisProgramContent, setShowAboutThisProgramContent] =
    useState<boolean>(false);
  const [
    showHowToFindYourFeederNumberContent,
    setShowHowToFindYourFeederNumberContent,
  ] = useState<boolean>(false);
  const [showWaysToConservePowerContent, setShowWaysToConservePowerContent] =
    useState<boolean>(false);
  const [showGpaInitiativesContent, setShowGpaInitiativesContent] =
    useState<boolean>(false);
  const [showFAQsContent, setShowFAQsContent] = useState<boolean>(false);
  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title="Potential Rotating Outage Schedule"
      />
      <div
        className="relative"
        style={{
          minHeight: "350px",
          backgroundImage: `url(${Images.GPAPotentialOutageBanner})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        {/* <img
          alt="Mawar BG"
          className="z-1 h-full w-full object-cover absolute"
          src={Images.GPAPotentialOutageBanner}
        /> */}
        <div className="flex phone:flex-col tabletWide:flex-row absolute items-center phone:top-2 phone:left-0 tabletWide:top-16 tabletWide:left-16">
          <img
            alt="Alert"
            className="h-24 w-24 object-cover phone:hidden phoneLg:block"
            src={Images.Alert}
          />
          <div className="flex flex-col  phoneLg:ml-0 phone:px-10">
            <h1
              className="font-rubik font-normal text-white font-bold phone:text-lg tablet:text-xl laptop:text-2xl 
  desktop:text-2xl mb-1 phone:mt-0 tablet:mt-5 phone:text-center tabletWide:text-left"
            >
              January 2024
            </h1>
            <p
              className="font-rubik font-light phone:text-xs phoneMd:text-sm tablet:text-base laptop:text-lg 
      desktop:text-lg mb-5 text-white phone:text-center tabletWide:text-left phone:text-xs"
              style={{
                maxWidth: "1000px",
              }}
            >
              In the wake of the challenges posed by Typhoon Mawar and its impact on our already fragile power generation infrastructure, Guam Power Authority (GPA) is notifying its valued customers of a monthly potential outage schedule effective January 2024. Outages will be conducted according to the schedule if the anticipated demand from customers between the hours of 4 pm to 11 pm exceeds GPA’s available energy capacity. GPA will, as a last resort, implement rotating outages lasting up to 1-hour. Power will be reconnected to all affected customers at the top of each hour, if not sooner.
            </p>
          </div>
        </div>
      </div>
      <div
        className="relative phone:hidden phoneLg:flex"
        style={{
          minHeight: "150px",
          backgroundImage: `url(${Images.PotentialOutageSecondary})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div style={{ width: "60%", paddingTop: "12px" }}>
          <h1
            className="font-newmake text-white text-center phone:text-3xl phone:mb-6 tablet:text-4xl mx-auto mt-4"
            style={{
              maxWidth: "350px",
            }}
          >
            YOU HAVE THE POWER TO STOP{" "}
            <span className="with-outline">OUTAGES</span>
          </h1>
        </div>
        <div className="mx-auto" style={{ width: "25%", paddingTop: "12px" }}>
          <img
            alt="beat-the-peak"
            className="laptopSm:mt-0 tabletWide:mt-4 tablet:mt-8 phoneWide:mt-8"
            src={Images.BeatThePeakSVG}
          />
        </div>
      </div>
      <div
        className="relative phone:flex phoneLg:hidden flex-col items-center justify-between"
        style={{
          minHeight: "300px",
          backgroundImage: `url(${Images.PotentialOutageSecondaryPortrait})`,
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
        }}
      >
        <div style={{ width: "60%", paddingTop: "12px" }}>
          <h1
            className="font-newmake text-white text-center phone:text-xl phoneMd:text-3xl phone:mb-6 tablet:text-4xl mx-auto mt-4"
            style={{
              maxWidth: "350px",
            }}
          >
            YOU HAVE THE POWER TO STOP{" "}
            <span className="with-outline">OUTAGES</span>
          </h1>
        </div>
        <div
          className="mx-auto"
          style={{ width: "60%", paddingBottom: "24px" }}
        >
          <img
            alt="beat-the-peak"
            className="laptopSm:mt-0 tabletWide:mt-4 tablet:mt-8 phoneWide:mt-8 mx-auto"
            src={Images.BeatThePeakSVG}
          />
        </div>
      </div>
      <ContentContainer>
        <select
          id="select-village"
          name="village-items"
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            setSelectedVillage(event.target.value);
          }}
        >
          <option value="">Select Village</option>
          <option value="agana-heights">Agana Heights</option>
          <option value="anigua">Anigua</option>
          <option value="asan">Asan</option>
          <option value="barrigada">Barrigada</option>
          <option value="dededo">Dededo</option>
          <option value="hågat">Hågat</option>
          <option value="hagåtna">Hagåtna</option>
          <option value="humåtak">Humåtak</option>
          <option value="inalåhan">Inalåhan</option>
          <option value="ma'ina">Ma'ina</option>
          <option value="malesso">Malesso</option>
          <option value="mangilao">Mangilao</option>
          <option value="mongmong-toto-maite">Mongmong-Toto-Maite</option>
          <option value="ordot-chalan-pago">Ordot/Chalan Pago</option>
          <option value="piti">Piti</option>
          <option value="sånta-rita-sumai">Sånta Rita-Sumai</option>
          <option value="sinajana">Sinajana</option>
          <option value="talo'fo'fo">Talo'fo'fo</option>
          <option value="tamuning">Tamuning</option>
          <option value="yigo">Yigo</option>
          <option value="yo'ña">Yo'ña</option>
        </select>
        {selectedVillage !== "" ? (
          <div
            className="mt-4 px-8 py-8 overflow-y-scroll no-scrollbar"
            style={{
              border: "1px solid #6b7280",
              maxHeight: "400px",
            }}
          >
            <h1 className="font-bold">
              {villageOutageDetails[selectedVillage].title}
            </h1>
            <p>{villageOutageDetails[selectedVillage].codes.join(" | ")}</p>
            <p>
              {villageOutageDetails[selectedVillage].details.map((dtl) => {
                return (
                  <div className="mt-4" key={dtl.description}>
                    <p className="font-bold">{dtl.timeRanges.join(" | ")}</p>
                    <p>{dtl.description}</p>
                  </div>
                );
              })}
            </p>
          </div>
        ) : null}
        <div className="flex items-center mt-8">
          <h1
            className="font-rubik font-normal text-blue phone:text-lg tablet:text-xl laptop:text-2xl 
  desktop:text-2xl phone:mt-0"
          >
            About this program
          </h1>
          <FaPlus
            // eslint-disable-next-line no-unused-vars
            onClick={(_event): MouseEventHandler<SVGElement> | void =>
              setShowAboutThisProgramContent((prevState) => !prevState)
            }
            style={{
              ...(showAboutThisProgramContent ? closeAnimation : openAnimation),
              marginLeft: "15px",
              cursor: "pointer",
            }}
          />
        </div>
        {showAboutThisProgramContent ? (
          <>
            <Details>
              In the wake of the challenges posed by Typhoon Mawar and its
              impact on our already fragile power generation infrastructure,
              Guam Power Authority (GPA) is notifying its valued customers of a
              monthly potential outage schedule effective{" "}
              <span className="font-bold">Monday, January 1, 2024.</span>{" "}
              Outages will be conducted according to the schedule{" "}
              <span className="font-bold">
                {" "}
                if the anticipated demand from customers between the hours of 4
                pm to 11 pm exceeds GPA’s available energy capacity.
              </span>{" "}
              GPA will, <span className="font-bold">as a last resort</span>,
              implement rotating outages lasting up to 1-hour. Power will be
              reconnected to all affected customers at the top of each hour, if
              not sooner.
            </Details>
            <Details>
              <span className="font-bold">
                Infrastructure and Energy Demand{" "}
              </span>{" "}
              <br /> The recent damage inflicted by Typhoon Mawar has left our power generation infrastructure vulnerable and in need of attention. As we work diligently to restore full operational capacity, GPA faces the challenging task of maintaining a stable power supply amidst varying energy demand. The two utility scale solar PV farms, (totaling of 85 MW) are operational and provide sufficient energy during the day which allow short maintenance windows for traditional power plants. When daylight hours decrease on cloudy days, the energy output of solar renewables decreases. Traditional power plants and energy batteries help meet customer demand. The potential rotating outage schedule will be dependent on factors such as energy consumption patterns and overall grid stability.
            </Details>
            <Details>
              <span className="font-bold">Current Schedule Details</span> <br />{" "}
              Duration: 1-month (new schedules will be issued on the first of
              every month as needed) <br />
              Effective Date:{" "}
              <span className="font-bold">
                Monday, January 1, 2024
              </span>{" "}
              <br /> Timing:{" "}
              <span className="font-bold">Between 4 pm to 11 pm</span> <br />
              Frequency: Outages may occur during the specified period reflected in the schedule
              <br />
              Occurrence: Outages occur when energy demand exceeds the total
              available energy capacity. The most vulnerable times for outages
              are during peak (5pm-11pm daily) and when traditional and
              renewable power generation are limited or unavailable. Notices
              will be released to the media and GPA’s social media pages when
              the schedule is <span className="font-bold">likely</span> to be
              implemented due to loss of large units.
            </Details>
            <Details>
              <span className="font-bold">Customer Cooperation</span> <br /> 
              GPA deeply appreciates the understanding and cooperation of our customers during this challenging period. We kindly ask our customers to contribute to the stability of the power grid by conserving energy, especially during the peak hours of 5 pm to 11 pm. By minimizing energy consumption during these hours, you can significantly reduce the strain on our fragile infrastructure, potentially avoiding the need for rotating outages altogether.
            </Details>
            <Details>
              <span className="font-bold">Energy Conservation Tips</span> <br />{" "}
              • Turn off your water heater between 5 pm and 11 pm and use the hot water you already have in your tank. <br /> 
              • Adjust Thermostats: Turn off or raise your air conditioning thermostat to 75 degrees or higher during peak hours to reduce cooling demand.<br /> 
              • Limit Non-Essential Usage: During peak hours, consider minimizing the use of non-essential electrical appliances and lighting.{" "}
              <br /> • Unplug Devices: Unplug electronic devices that are not in use to prevent standby energy consumption.
            </Details>
            <Details>
              <span className="font-bold">
                Interim Capacity Procurement: Addressing Short-Term Challenges
              </span>{" "}
              <br />
              Recognizing the urgency of the situation, GPA is actively working to secure interim energy capacity within the next five months. These interim solutions aim to bridge the gap between our current infrastructure challenges and the full operation of the Ukudu Power Plant.
              <br /> <br />
              Ongoing efforts include the expedited repair of the Yigo 20 MW Combustion Turbine Unit that was damaged in the typhoon expected to be completed in February 2024. The PUC commissioners approved the procurement of 40 MW of temporary power generation during its November 30, 2023 meeting, with the emergency procurement issued in December 2023 and a targeted commissioning date of May 2024, barring any other delays.  Additionally, GPA has authorized the emergency procurement of supplies, services, and minor construction work to increase generation capacity for the Yigo, Tenjo, Manenggon and Talo’fo’fo diesel generators.<br /><br />
              Additionally, boiler tube repairs to Cabras 2 was completed on November 20, 2023, and repair of Piti 7 to increase the capacity from 22MW to 30MW was completed ahead of schedule on November 30, 2023.
              <br /><br />
              To bolster our ability to manage peak energy demand and mitigate potential outages during base load unit maintenance, GPA is being assisted by the Department of Defense as well as GovGuam and private partners through the Interruptible Load Program. This program was specifically established to safeguard grid stability and make additional capacity accessible for residents during peak periods, by allowing larger customers to use their own generators during peak hours, thereby averting potential outages.
            </Details>
            <Details>
              <span className="font-bold">Islandwide Support</span> <br />
              The next two years have significant challenges for GPA generation capacity and we are taking swift action to restore capacity until the completion of the Ukudu Power Plant which is expected between late 2025 and early 2026  . GPA deeply appreciates the understanding and cooperation of our government, business and residential customers during this challenging period.
              <br />
              <br />
              We kindly ask our customers to contribute to the stability of the
              power grid by conserving energy,{" "}
              <span className="font-bold">
                especially during the peak hours of 5 pm to 11 pm.
              </span>{" "}
              By minimizing energy consumption during these hours, we can
              significantly reduce the strain on our fragile infrastructure,
              potentially avoiding the need for rotating outages altogether.{" "}
              <br />
              <br />
              We understand that these potential outages might cause
              inconvenience, and we are here to support you. For inquiries,
              concerns, or more information, please reach out to our Customer
              Service Call Center at (671) 647-5787/8/9 or{" "}
              <a
                className="text-orange"
                href="mailto:customersfirst@gpagwa.com"
              >
                CustomersFirst@gpagwa.com
              </a>
              .
              <br /><br />
              Beat the Peak
              <br />
              <a className="text-blue" href="https://guampowerauthority.com/beatthepeak">https://guampowerauthority.com/beatthepeak</a>
              <br /><br />
              Facebook
              <br />
              <a className="text-blue" href = "https://www.facebook.com/GuamPowerAuthority">https://www.facebook.com/GuamPowerAuthority</a>
              <br /><br />
              Instagram
              <br />
              <a className="text-blue" href="https://www.instagram.com/guampowerauthority">https://www.instagram.com/guampowerauthority</a>
              <br />< br />
              Latest Updates and Release
              <br />
              <a className="text-blue" href="https://guampowerauthority.com/news-and-media">https://guampowerauthority.com/news-and-media</a>
            </Details>
            <Details>
              <span className="font-bold">Ways to Conserve Power</span> <br />•
              Turn off your water heater between 5 pm and 11 pm and use the hot
              water you already have in your tank.
              <br />• Adjust Thermostats: Raise your air conditioning thermostat
              to 75 degrees or higher during peak hours to reduce cooling
              demand.
              <br />• Limit Non-Essential Usage: During peak hours, consider
              minimizing the use of non-essential electrical appliances and
              lighting.
              <br />• Unplug Devices: Unplug electronic devices that are not in
              use to prevent standby energy consumption.
            </Details>
          </>
        ) : null}
        <div className="flex items-center mt-8">
          <h1
            className="font-rubik font-normal text-blue phone:text-lg tablet:text-xl laptop:text-2xl 
  desktop:text-2xl phone:mt-0 "
          >
            How to find your feeder number
          </h1>
          <FaPlus
            // eslint-disable-next-line no-unused-vars
            onClick={(_event): MouseEventHandler<SVGElement> | void =>
              setShowHowToFindYourFeederNumberContent((prevState) => !prevState)
            }
            style={{
              ...(showHowToFindYourFeederNumberContent
                ? closeAnimation
                : openAnimation),
              marginLeft: "15px",
              cursor: "pointer",
            }}
          />
        </div>
        {showHowToFindYourFeederNumberContent ? (
          <>
            <Details>
              Your feeder number can be found above the perforation marks on
              your monthly energy bill.
            </Details>
          </>
        ) : null}
        <div className="flex items-center mt-8">
          <h1
            className="font-rubik font-normal text-blue phone:text-lg tablet:text-xl laptop:text-2xl 
  desktop:text-2xl phone:mt-0 "
          >
            Ways to conserve power
          </h1>
          <FaPlus
            // eslint-disable-next-line no-unused-vars
            onClick={(_event): MouseEventHandler<SVGElement> | void =>
              setShowWaysToConservePowerContent((prevState) => !prevState)
            }
            style={{
              ...(showWaysToConservePowerContent
                ? closeAnimation
                : openAnimation),
              marginLeft: "15px",
              cursor: "pointer",
            }}
          />
        </div>
        {showWaysToConservePowerContent ? (
          <>
            <Details>
              • Turn off your water heater between 5 pm and 11 pm and use the hot water you already have in your tank.
              <br /> • Adjust Thermostats: Turn off or raise your air conditioning thermostat to 75 degrees or higher during peak hours to reduce cooling demand.
              <br /> • Limit Non-Essential Usage: During peak hours, consider minimizing the use of non-essential electrical appliances and lighting.
              <br /> • Unplug Devices: Unplug electronic devices that are not in use to prevent standby energy consumption.
            </Details>
          </>
        ) : null}
        <div className="flex items-center mt-8">
          <h1
            className="font-rubik font-normal text-blue phone:text-lg tablet:text-xl laptop:text-2xl 
  desktop:text-2xl phone:mt-0 "
          >
            {" "}
            GPA Initiatives
          </h1>
          <FaPlus
            // eslint-disable-next-line no-unused-vars
            onClick={(_event): MouseEventHandler<SVGElement> | void =>
              setShowGpaInitiativesContent((prevState) => !prevState)
            }
            style={{
              ...(showGpaInitiativesContent ? closeAnimation : openAnimation),
              marginLeft: "15px",
              cursor: "pointer",
            }}
          />
        </div>
        {showGpaInitiativesContent ? (
          <>
            <Details>
              <span className="font-bold">
                Interim Capacity Procurement: Addressing Short-Term Challenges
              </span>
              <br />
              Recognizing the urgency of the situation, GPA is actively working to secure interim energy capacity within the next five months. These interim solutions aim to bridge the gap between our current infrastructure challenges and the full operation of the Ukudu Power Plant.
              <br />
              <br />
              Ongoing efforts include the expedited repair of the Yigo 20 MW Combustion Turbine Unit that was damaged in the typhoon expected to be completed in February 2024. The PUC commissioners approved the procurement of 40 MW of temporary power generation during its November 30, 2023 meeting, with the emergency procurement issued in December 2023 and a targeted commissioning date of May 2024, barring any other delays.  Additionally, GPA has authorized the emergency procurement of supplies, services, and minor construction work to increase generation capacity for the Yigo, Tenjo, Manenggon and Talo’fo’fo diesel generators.<br /><br />
              Additionally, boiler tube repairs to Cabras 2 was completed on November 20, 2023, and repair of Piti 7 to increase the capacity from 22MW to 30MW was completed ahead of schedule on November 30, 2023.
              <br /> <br />
              To bolster our ability to manage peak energy demand and mitigate potential outages during base load unit maintenance, GPA is being assisted by the Department of Defense as well as GovGuam and private partners through the Interruptible Load Program. This program was specifically established to safeguard grid stability and make additional capacity accessible for residents during peak periods, by allowing larger customers to use their own generators during peak hours, thereby averting potential outages.
            </Details>
            <Details>
              <span className="font-bold">
                Ukudu Power Plant: A Beacon of Stability{" "}
              </span>{" "}
              <br /> Anticipating the growing energy demands of our island as
              well as the aged capacity of current generation, GPA emphasizes
              the significance of the upcoming Ukudu Power Plant. The upcoming
              power plant has been in progress since 2012 and is set to generate
              an additional 198 MW of energy as part of GPA’s Clean Energy
              Master Plan (CEMP) which will improve reliability and provide
              affordable energy.
              <br />
              <br /> However, numerous setbacks to the project, including
              significant damage from Typhoon Mawar, have required GPA to delay
              the retirement of its aging Cabras units that have been in
              operation for 48-49 years, leaving Guam’s grid in a fragile state.
              The milestone Ukudu Power Plant is now slated for completion in
              2026 and will play a pivotal role in restabilizing our power grid.
              With enhanced capacity, the Ukudu Power Plant will strengthen our
              ability to provide reliable electricity to homes, businesses, and
              critical infrastructure.
            </Details>
          </>
        ) : null}
        <div className="flex items-center mt-8">
          <h1
            className="font-rubik font-normal text-blue phone:text-lg tablet:text-xl laptop:text-2xl 
  desktop:text-2xl phone:mt-0 "
          >
            FAQs
          </h1>
          <FaPlus
            // eslint-disable-next-line no-unused-vars
            onClick={(_event): MouseEventHandler<SVGElement> | void =>
              setShowFAQsContent((prevState) => !prevState)
            }
            style={{
              ...(showFAQsContent ? closeAnimation : openAnimation),
              marginLeft: "15px",
              cursor: "pointer",
            }}
          />
        </div>
        {showFAQsContent ? (
          <>
            <Details>
              <span className="font-bold">
                Why is GPA implementing a rotating outage schedule?{" "}
              </span>{" "}
              <br />
              The recent damage inflicted by Typhoon Mawar has left our power generation infrastructure vulnerable over the next five months and in need of attention. As we work diligently to restore full operational capacity, GPA faces the challenging task of maintaining a stable power supply amidst varying energy demand in the interim. The schedule is to give customers notice to adjust for the potential outages in the event power supply cannot meet customer demand. The rotating outage schedule is being issued as a precautionary notice to allow customers to plan accordingly should outages be necessary while critical generation units are under repair.   <br />
              <br />
              <span className="font-bold">
                Does my schedule mean that my power will go out during all the times listed?
              </span>{" "}
              <br />
              No.  Outages will be conducted according to the schedule only <i>if the anticipated demand</i> from customers between the hours of <span className="font-bold">4 pm to 11 pm</span> exceeds GPA’s available energy capacity.  GPA will, <span className="font-bold">as a last resort</span>, implement rotating outages lasting up to 1-hour.  Power will be reconnected to all affected customers at the top of each hour, if not sooner.
              <br />
              <br />{" "}
              <span className="font-bold">
                Why did my power not go out even though I was scheduled for an
                outage?
              </span>{" "}
              <br />
              When energy supply is sufficient to meet customer demand, GPA will not implement scheduled outages.  This is why conservation during the hours of 5 pm – 11 pm is crucial.  By minimizing energy consumption during these hours, we can significantly reduce the strain on our fragile infrastructure, potentially avoiding the need for rotating outages altogether.
              <br />
              <br />
              <span className="font-bold">
                {" "}
                Why did my power go out, but it wasn’t on the schedule?{" "}
              </span>{" "}
              <br />
              Maintenance outages, emergency outages, and other types of outages are not covered under this schedule.
              <br /><br />
              Customers may experience outages caused by something other than generation shortfall.  The most common occurrences include outages caused by down or damaged power lines, auto-pole collision, brown treesnake, vegetation, or transformer issues. 
              <br />
              <br />
              <span className="font-bold">
                Why does the schedule change monthly?
              </span>
              <br />
              Rotating outage schedules can change monthly due to shifting energy demand and supply patterns on Guam, influenced by factors like weather and seasonal fluctuations, ongoing repairs and maintenance of current generators, which can encounter unforeseen delays or issues, which may necessitate adjustments to the outage schedule. Additionally, the Guam Power Authority (GPA) may make modifications based on successful energy procurement efforts and their strategic plans to address capacity shortfalls in the next five months. 
              <br /> <br />
              To keep customers informed and minimize disruptions, GPA commits to providing regular updates on the status of ongoing repairs and any alterations to the outage schedule. These updates help residents and businesses plan accordingly and stay informed about the evolving energy landscape on Guam, where demand and supply dynamics are subject to change month by month.
              <br />
              <br />
              <span className="font-bold">
                How can I contribute to energy conservation during peak hours?
              </span>{" "}
              <br />
              <ul>
                <li>
                To help stabilize the power grid during peak hours of 5 pm – 11 pm:
                </li>
                <li>
                  - Turn off your water heater between 5 pm and 11 pm and use the hot water you already have in your tank.
                </li>
                <li>
                  - Adjust Thermostats: Raise your air conditioning thermostat to 75 degrees or higher during peak hours to reduce cooling demand.
                </li>
                <li>
                  - Limit Non-Essential Usage: During peak hours, consider minimizing the use of non-essential electrical appliances and lighting.
                </li>
                <li>
                  - Unplug Devices: Unplug electronic devices that are not in use to prevent standby energy consumption.
                </li>
              </ul>
              <br />
              <span className="font-bold">
                What is GPA’s plan to address load shedding?
              </span>{" "}
              <br /> GPA aims to ensure a stable power supply for the community, bridging the gap between our current infrastructure challenges and the full operation of the Ukudu Power Plant. While short-term solutions include potential outages, they are strategically managed to minimize disruptions, and will be implemented <span className="font-bold">only as a last resort</span> if demand exceeds capacity.
              <br /> <br />
              In addition to the Interruptible Load Program and the Beat the Peak energy conservation campaign, ongoing efforts include the expedited repair of the Yigo 20 MW Combustion Turbine Unit that was damaged during Typhoon Mawar.  The return to service of the unit is targeted for February 2024.
              <br /><br />
              Multipronged Approach and future solutions include:
              <ul>
                <li>
                  - Procuring 40 MW of Temporary Power (Target: May 2024 if no other delays).
                </li>
                <li>
                  - Returning Caterpillar and Wartsila units to service, which will increase plant capacity by 17 MW (Target: April 2024).
                </li>
                <li>
                  - Utilizing existing Energy Storage Batteries (ESS) to shift 40 MWH energy to peak time
                </li>
                <li>
                  - Continuing to expand Interruptible Load Capacity (Currently approx. 16 MW).
                </li>
              </ul>
              <br />
              <br />{" "}
              <span className="font-bold">
                What is the Interruptible Load Program?
              </span>{" "}
              <br />
              This program was specifically established to safeguard grid stability and make additional capacity accessible for residents during peak periods, by allowing larger customers to use their own generators during peak hours in an effort to avert potential outages. GPA is steadfastly working to bolster participation in this program for the primary purpose of safeguarding residents from power disruptions.
              <br />
              <br />{" "}
              <span className="font-bold">
                How long will GPA be implementing monthly potential rotating outage schedules?
              </span>{" "}
              <br />
              GPA plans to continue the monthly potential rotating outage schedules for the next five months, aligning with the anticipated duration required for capacity recovery of typhoon-damaged generators, generators undergoing significant maintenance, and the establishment of temporary power sources. These schedules are integral to our continuous efforts to maintain a balance in energy demand and ensure grid stability as we concurrently explore other short to mid-term solutions. As we gradually restore the typhoon-damaged power plants, we expect a reduction in the necessity for load shedding, and GPA remains dedicated to minimizing load shedding within the next few months
              <br />
              <br />{" "}
              <span className="font-bold">
                What is the role of the upcoming Ukudu Power Plant in this plan?
              </span>{" "}
              <br />
              The Ukudu Power Plant, set to be commissioned in 2026, is set to generate an additional 198 MW and will play a vital role in stabilizing the power grid and meeting the island's energy needs as part of a long-term solution.  Construction of the new power plant is continuing in spite of the damages incurred from Typhoon Mawar.
              <br /> <br />
              Planning for Guam’s sustainable future is possible with the Ukudu Power Plant and GPA’s commitment to a 50% Renewable Portfolio Standard by 2030.  GPA is in pursuit of more renewable energy and implementing projects and programs to support grid security, stability and reliability through a balanced approach that includes Energy Efficiency, Renewable Energy, Grid Transformation, and Transportation Electrification.
              <br />
              <br />{" "}
              <span className="font-bold">
               How can I stay updated on these initiatives?
              </span>{" "}
              <br />
              Stay connected with GPA through our website, social media channels, and customer service for the latest updates and information.
            </Details>
          </>
        ) : null}
      </ContentContainer>
    </>
  );
};

export default PotentialRotatingOutageSched;
