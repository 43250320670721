import React, { useEffect, useState } from 'react';
import { H1, Details, Content as ContentContainer, H3 } from '@core';
import { PlanningDocumentOverview, PlanningDocumentLibrary, DocumentArchive } from '@content';
import { HeaderGradient } from '@components';
import { getFileData } from '@services';
import { FILEDATA } from '@types';
import { API } from '@src/config';

export const PlanningDocumentArchive = () => {
  const [file, setFile] = useState<FILEDATA[]>([]);

  const getData = async () => {
    const fileDatas = await getFileData();
    setFile(fileDatas.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={PlanningDocumentOverview.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-full">
            <section className="mb-10">
              <H1 className="text-blue mb-5">{PlanningDocumentOverview.title}</H1>
              <Details>
                <i>{PlanningDocumentOverview.quote}</i>
                <br />
                {PlanningDocumentOverview.speaker}
              </Details>
            </section>
            <section className="my-10">
              {PlanningDocumentLibrary.contents.map((content) => {
                return (
                  <>
                    <H3 className='text-blue'>{content.title}</H3>
                    <Details>{content.paragraph}</Details>
                  </>
                );
              })}
              {file
                .filter(
                  (fileData) => fileData.attributes.AnchorText === PlanningDocumentLibrary.anchor,
                )
                .map((fileData) => {
                  return (
                    <>
                      <Details>
                        {PlanningDocumentLibrary.text}&nbsp;
                        <a
                          className="text-orange"
                          href={`${API}${fileData.attributes.File.data.attributes.url}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {PlanningDocumentLibrary.anchor}
                        </a>
                      </Details>
                    </>
                  );
                })}
            </section>
            <section className="mt-10">
              <H3 className='text-blue pb-5'>{DocumentArchive.title}</H3>
              {DocumentArchive.contents.map((content) => {
                return (
                  <>
                    {file
                      .filter((fileData) => fileData.attributes.AnchorText === content.anchor)
                      .map((fileData) => {
                        return (
                          <>
                            <Details>
                              {content.title}&nbsp;
                              <a
                                className="text-orange"
                                href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                rel="noreferrer"
                                target="_blank"
                              >
                                {fileData.attributes.AnchorText}
                              </a>
                            </Details>
                          </>
                        );
                      })}
                  </>
                );
              })}
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
