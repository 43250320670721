import React, { useEffect, useState } from 'react';
import { H2, Details, Content as ContentContainer } from '@core';
import { GPADemographics } from '@content';
import { HeaderGradient } from '@components';
import { HRDEMOGRAPHICS } from '@src/types/HrDemographics';
import { getHrDemographics } from '@src/services/HrDemographics';
import { API } from '@src/config';
// import { getFileData } from '@services';
// import { FILEDATA } from '@types';
// import { API } from '@src/config';

export const Demographics = () => {
    // const [file, setFile] = useState<FILEDATA[]>([]);
    const [demographics, setDemographics]  = useState<HRDEMOGRAPHICS[]>([]);

    const getData = async () => {
      const demographicsData = await getHrDemographics();
      setDemographics(demographicsData.data);
    };

    useEffect(() => {
      getData();
    }, []);

    const clickItem = (link?: string, file?: any) => {
      if(file != null){
        window.open(`${API}${file}`);
      }else{
        window.open(`${link}`);
      }
    }

    console.log(demographics)

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={GPADemographics.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12 laptop:w-7/12 mr-20">
            <section>
              <H2 className="text-blue">Guam Power Authority demographics by:</H2>
              {demographics.map((content) => {
                return(
                  <>
                      <Details>
                        <span>{content.attributes.Title} - </span>
                        {content.attributes.Description} &nbsp;
                        <button className='text-orange text-left' onClick={()=>clickItem(content.attributes.Link, content.attributes.File?.data != null ? content.attributes.File.data.attributes.url : content.attributes.File.data)} type="button">{content.attributes.Anchor}</button>
                      </Details>
                  </>
                )
              })}
            </section>
          </div>
        </div>
      </ContentContainer>
      <div className="desktop:h-1/6 desktopWide:h-1/3 w-full">&nbsp;</div>
    </>
  );
};
