export const TrainingAndDevelopmentSectionDetails = {
  title: 'Training and Development',
  subtitle1: 'Training and Development',
  description1:
    'The Guam Power Authority’s Training and Development Section is responsible for providing quality training opportunities to develop and enhance employee skills, knowledge, and performance on the job. The Training and Development Section is also committed to providing continuous training services to ensure employee safety in the workplace.',
  subtitle2: 'Training',
  description2:
    'The Guam Power Authority provides various quality training opportunities to develop, maintain, and enhance employee skills, knowledge, and performance on the job. The Authority is also committed to providing continuous training services to ensure employee safety in the workplace. Some of the various trainings offered and/or provided to the Authority’s employees include, but are not limited to:',
  ul: [
    '1. Defensive Driving Training',
    '2. CPR/First Aid Safety Training',
    '3. Spill Prevention Training',
    '4. National Electrical Safety Code On-Site Training',
    '5. National Electrical Code On-Site Training',
    '6. Geographic Information Systems (GIS) On-Site Training',
    '7. Safe Mobile Crane Operation/Certification Training',
    '8. 8-Hour Refresher/Recertification Safe Mobile Crane Operation Training',
    '9. Overhead Crane Operation/Certification Training',
    '10. 8-Hour Refresher/Recertification on Overhead Crane Operation',
    '11. Rigging Safety & Inspection Training',
    '12. Hazardous Wastes & Operations (HAZWOPER) Training',
    '13. 8-Hour Refresher/Recertification HAZWOPER',
    '14. Confined Space Entry Training',
    '15. Asbestos Awareness Training',
    '16. Lead Awareness Training',
    '17. Intro to PowerPoint Training',
    '18. Intermediate/Advance PowerPoint Training',
    '19. Intro to Excel Training',
    '20. Intermediate Excel Training',
    '21. Intro to Access Training',
    '22. Accounting and Auditing Update',
  ],
  description3:
    'Other valuable training is also offered to the Authority’s employees by the Department of Administration’s Division of Personnel Management/Training Branch. Trainings and workshops are announced on a quarterly basis, and most often are offered at no cost. These include, but are not limited to, Anger Management, Best Customer Service, Preventing Sexual Harassment in the Workplace, Disability & Sensitivity Awareness, and Stress Management Workshop.',
};
