import React, { useEffect, useState } from 'react';
import {
  H1,
  H2,
  H3,
  Details,
  UnorderedList,
  List,
  Images,
  Table,
  TableCell as TD,
  Content as ContentContainer,
} from '@core';
import { EnergyConsumption, EnergySaving, EnergyProgram } from '@content';
import { HeaderGradient } from '@components';
import { getEnergySenseTableData, getFileData } from '@services';
import { ENERGYSENSEDATA, FILEDATA } from '@types';
import { API } from '@src/config';

export const GetARebate = () => {
  const [tableData, setTableData] = useState<ENERGYSENSEDATA[]>([]);
  const [file, setFile] = useState<FILEDATA[]>([]);

  const getData = async () => {
    const tableDatas = await getEnergySenseTableData();
    const fileDatas = await getFileData();
    setTableData(tableDatas.data);
    setFile(fileDatas.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-clean-lightyellow"
        rightColor="to-clean-green"
        title={EnergyConsumption.title}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-full">
            <section className="w-12/12 phone:flex phone:flex-col tabletWide:grid tabletWide:grid-cols-2 gap-20">
              <div className="mt-6">
                <div className="flex items-center mb-10">
                  <img alt="Energy Sense" className="w-1/3 mr-5" src={Images.EnergySense} />
                  <H1 className="text-blue w-2/3">{EnergyConsumption.subtitle}</H1>
                </div>
                <div className="flex gap-5">
                  <img
                    alt="Woman using washer & dryer"
                    className="w-1/6 h-1/6 laptop:w-24 laptop:h-24 mb-5 self-center"
                    src={Images.WasherDryer}
                  />
                  <Details className="w-5/6 self-end">{EnergyConsumption.p1}</Details>
                </div>
                <Details>{EnergyConsumption.p2}</Details>
                <Details>{EnergyConsumption.p3}</Details>
                <UnorderedList>
                  {EnergyConsumption.pdfs.map((value) => {
                    return (
                      <>
                        <div className="flex items-center mb-4">
                          <img alt="PDF Link" src={Images.PDFIcon} />
                          {file
                            .filter((fileData) => fileData.attributes.AnchorText === value.text)
                            .map((fileData) => {
                              return (
                                <>
                                  <List className="list-none text-orange">
                                    <a
                                      href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      {fileData.attributes.AnchorText}
                                    </a>
                                  </List>
                                </>
                              );
                            })}
                        </div>
                      </>
                    );
                  })}
                </UnorderedList>
              </div>
              <div className="bg-blue p-10 h-auto mb-auto mt-6">
                <H2 className="white-text mb-5">{EnergySaving.title}</H2>
                <UnorderedList>
                  {EnergySaving.ul.map((value) => {
                    return (
                      <>
                        <List className="white-text mb-5" key={value}>
                          {value}
                        </List>
                      </>
                    );
                  })}
                </UnorderedList>
              </div>
            </section>
            <hr className="border-gray my-20" />
            <section>
              {EnergyProgram.table.map((content) => {
                return (
                  <>
                    <div className="text-center">
                      <H1 className="mt-10 text-blue">{content.title.title}</H1>
                      <H3>{content.title.subTitle}</H3>
                    </div>
                    <div className="w-full flex justify-center">
                      <Table className="text-center">
                        <tr>
                          {content.th.map((heading) => {
                            return (
                              <TD key={heading} rowType="heading">
                                {heading}
                              </TD>
                            );
                          })}
                        </tr>
                        {tableData.map((data) => {
                          return (
                            <>
                              <tr>
                                <TD>{data.attributes.Vendor}</TD>
                                <TD>{data.attributes.Address}</TD>
                                <TD>{data.attributes.Phone}</TD>
                              </tr>
                            </>
                          );
                        })}
                        {/* {content.tr.map((row) => {
                          return (
                            <>
                              {row.td.map((data) => {
                                return (
                                  <>
                                    <tr>
                                      <TD>{data.vendor}</TD>
                                      <TD>{data.address}</TD>
                                      <TD>{data.phone}</TD>
                                    </tr>
                                  </>
                                );
                              })}
                            </>
                          );
                        })} */}
                      </Table>
                    </div>
                  </>
                );
              })}
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
