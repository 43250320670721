export const EmployeeCerts = {
  pageTitle: `Certifications`,
  contents: [
    {
      title: `Professional Registration:`,
      certs: [`P.E.`, `EIT`],
    },
    {
      title: `Industry Certifications:`,
      certs: [
        `Master Electrician`,
        `Fiber Optic Installer`,
        `Certified Cat-5 Cable Installer`,
        `IT Certifications`,
      ],
    },
  ],
};