import { Icons } from '@core';

export const AboutGpaPowerAlerts = {
  title: `Business - Manage My Account`,
  subtitle: 'ABOUT GPA POWER ALERTS',
  accordionArray: [
    {
      question: 'What is GPA Power Alerts?',
      answer:
        'GPA Power Alerts is a new 2-way text messaging service, providing customers with real time information about any scheduled or unscheduled power outage affecting their home and/or business, and estimated time of restoration.',
    },
    {
      question: 'How do I sign up for this service?',
      answer:
        'There is no need to sign-up. On September 12, 2020, the primary mobile phone number listed in your account was auto enrolled and should have received a message from 1-855-252-9998 (toll-free). To sign-up after September 12, 2020, simply text ENROLL to 1-855-252-9998.',
    },
    {
      question: 'Is there a charge for using the GPA Power Alert Service?',
      answer:
        'No. This free service via a toll-free telephone number is provided to you by the Guam Power Authority. However, text message charges from your telecommunications carrier may apply depending on your mobile plan. Please contact your telecommunications provider for information.',
    },
    {
      question:
        'How do I verify the correct phone number is on my account so I will be auto enrolled?',
      answer:
        'Log in to your GPA account at www.PayGPA.com to view or change your mobile phone number. You may also email poweralert@gpagwa.com or contact GPA Customer Service at 671-647-5787.',
    },
    {
      question: "Why doesn't my text message look right?",
      answer:
        'If you received a text response made up of garbled letters and symbols, it may that your mobile number is not linked to your GPA account. Please contact GPA Customer Service at PowerAlert@gpagwa.com or 671-647-5787. To report an outage, please call 671-475-1472/3/4.',
    },
  ],
  image: '',
};

export const CancellingOrEnrolling = {
  title: 'Cancelling or Enrolling for GPA Power Alerts After September 12, 2020',
  accordionArray: [
    {
      question: 'What if I don’t want to receive text messages  on my cellphone?',
      answer:
        'Text QUIT to 1-855-282-9998 will remove you from participating in the GPA Power Alerts program.',
    },
    {
      question: 'What if I opted out, but would like to re-enroll?',
      answer: 'Text “ENROLL” to 1-855-282-9998. To verify your service addresses, text “STATUS”. ',
    },
  ],
};

export const UsingPowerAlertsMessage = {
  title: ['Using Power Alerts', 'Message Options to send to', '1-855-252-9998'],
  descriptionArray: [
    {
      word: 'OUT',
      meaning:
        'Send OUT to report outages at the service address(es) associated with your mobile phone number.',
    },
    {
      word: 'STATUS',
      meaning:
        'Send STATUS to receive known information about your power service address(es) and any outages affecting those accounts.',
    },
    {
      word: 'QUIT',
      meaning: 'Send QUIT to opt out of receiving text message updates from GPA Power Alerts.   ',
    },
    {
      word: 'ENROLL',
      meaning: 'Send ENROLL to enroll or re-enroll in GPA Power Alerts.',
    },
    {
      word: 'HELP',
      meaning: 'Send HELP to receive information for assistance with GPA Power Alerts.',
    },
  ],
};

export const OtherFAQ = {
  title: 'Other Frequently Asked Question',
  accordionArray: [
    {
      question: 'What if I manage multiple accounts with my cell phone number?',
      answer:
        'GPA Power Alerts will assign a letter to each power account associated with your mobile number. When you text the word OUT to report an outage, you will receive a message asking for which account. Simply follow the instructions provided by the Power Alert reply texts.',
    },
    {
      question: 'How many cell numbers can receive text messages about my outages?',
      answer: 'One cell number per account at this time.',
    },
    {
      question:
        'I accidentally sent the word OUT to GPA Power Alerts, but I do not actually have a power outage. What do I do?',
      answer:
        'You may receive follow-up text questions. If you do not have an outage, there is no need to respond to the follow up texts.',
    },
    {
      question:
        'Will I receive text notifications if my account is affected by an outage even if I am not home to report the outage myself?',
      answer:
        'Yes, you will receive automatic messages if your service address is affected by a known or predicted area outage. If there is an outage that is only affecting your service address, and you have not received a report, please text OUT or contact GPA Dispatch at 671-475-1472/3/4.',
    },
    {
      question: 'My power went out. Why didn’t I get a text message?',
      answer:
        'To receive text notifications, your mobile number must be associated with your power account(s). You will receive text notifications if the GPA system is experiencing any outages that affect your service address(es).',
    },
    {
      question:
        'I received a message that my power has been restored, but my power is actually still out. What do I do?',
      answer:
        'You may text OUT again to report the outage or contact GPA Dispatch at 671-475-1472/3/4.',
    },
    {
      question:
        'There is a downed power line in the street in front of my house. Can I report this via text message?',
      answer:
        'Contact GPA Dispatch at 671-475-1472/3/4.  Please do not attempt to clear or go near the power line. ',
    },
    {
      question:
        "I reported my outage via text message. Why haven't I received a message with an estimated restoration time yet?",
      answer:
        'Updates will be provided as quickly as possible. Restoration time depends on the complexity and scope of work.',
    },
    {
      question: 'How accurate are the restoration times provided?',
      answer:
        'Restoration times are estimates based on the information we have about the outage. We can make good estimates on how long it typically takes to restore different types of outages based on historical data, but each outage time will vary based on the specific circumstances.',
    },
    {
      question:
        'It is past my estimated restore time, but my power is still not on. Do I need to report the outage again?',
      answer:
        'No, the estimated restore times are based on historical data and feedback from the crews in the field. This is to give our customers an idea of when their power will be restored to help you plan and await restoration. However, the actual restoration time may be longer or shorter than the estimate.',
    },
  ],
};

export const LookingForSomethingElse = {
  title: `Looking for something else?`,
  lookingFor: [
    {
      title: 'PayGPA',
      icon: Icons.PayGPA,
      link: 'https://paygpa.com/',
    },
    // {
    //   title: 'EZPay',
    //   icon: Icons.EzPay,
    //   link: 'https://oldsite.guampowerauthority.com/special/ezpay.html',
    // },
    {
      title: 'Energy Sense',
      icon: Icons.Spa,
      link: 'https://energysense.guampowerauthority.com/',
    },
    {
      title: 'myEnergy',
      icon: Icons.MyEnergy,
      link: 'https://www.myenergyguam.com/HomeConnect/login.html',
    },
    // {
    //   title: 'Outages',
    //   icon: Icons.Outages,
    //   link: 'https://web.guamalerts.com/notification/gpa',
    // },
    {
      title: 'Watts New',
      icon: Icons.Bulb3,
      link: '/news-and-media',
    },
  ],
};
