import { Disclosure, Transition } from '@headlessui/react';
import { ProcurementDisclosureRoutes } from '@src/constants';
import React from 'react';
import { FaChevronUp } from 'react-icons/fa';

export const ProcurementDisclosure = () => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`flex w-full gap-1
      font-rubik text-black phone:w-44 tablet:w-60 tabletWide:w-40`}
          >
            Procurement
            <FaChevronUp className={`${open ? 'w-2 mt-0.5' : 'mt-0.5 w-2 transform rotate-180'}`} />
          </Disclosure.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="font-rubik shadow-3xl rounded-2xl shadow-cyan-500/50 pl-4">
              {ProcurementDisclosureRoutes.map((route, index) => (
                <div key={index}>
                  <a
                    className="text-black hover:text-blue"
                    href={route.link}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {route.routeName}
                  </a>
                  <br />
                </div>
              ))}
              {/* {file
                .filter(
                  (fileData: any) => fileData.attributes.AnchorText === '2022VendorRegistration',
                )
                .map((fileData: any) => {
                  return (
                    <>
                      <a
                        className="text-blue hover:text-black"
                        href={`${API}${fileData.attributes.File.data.attributes.url}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Vendor Portal
                      </a>
                    </>
                  );
                })} */}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};
