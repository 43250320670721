import { API } from '@config';
import { HrPersonelServicesTable } from '@src/types/HrPersonelServices';

export const getHrPersonelServicesRulesRegulations = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${API}/api/hr-personel-services-rules-regulations?populate=File&sort=id%3Adesc`, options);
  const data: HrPersonelServicesTable = await response.json();

  return data;
};
