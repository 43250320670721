import React, { useEffect } from 'react';
import {
  H1,
  H2,
  Details,
  UnorderedList,
  List,
  Table,
  TableCell as TD,
  Content as ContentContainer,
} from '@core';
import { IRP, Meeting, IRPLinks, IRPStakeHolderMeetings, IRPLatest } from '@content';
import { HeaderGradient } from '@components';
// import { FILEDATA } from '@types';
// import { getFileData } from '@services';
import { API } from '@config';

export const IntegratedResourcePlan = () => {
  // const [file, setFile] = useState<FILEDATA[]>([]);

  const getData = async () => {
    // const fileDatas = await getFileData();
    // setFile(fileDatas.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="mt-20 desktop:mt-28" />
      <HeaderGradient
        leftColor="from-general-darkblue"
        rightColor="to-general-red"
        title={IRP.pageTitle}
      />
      <ContentContainer>
        <div className="flex laptop:flex-row phone:flex-col">
          <div className="w-12/12">
            <section>
              <H1>{IRP.title}</H1>
              {IRP.contents.map((content) => {
                return (
                  <>
                    <H2 className="text-blue mt-10">{content.title}</H2>
                    <Details>{content.description}</Details>
                    <UnorderedList>
                      {content.ul.map((value) => {
                        return (
                          <List className="text-orange" key={value.anchor}>
                            {value.anchor}
                          </List>
                        );
                      })}
                    </UnorderedList>
                  </>
                );
              })}
            </section>
            <section>
              <div className="mt-8" />
              <Details>
                {IRPLatest.contents.map((content) => {
                  return (
                    <>
                      {/* {file
                        ?.filter((fileData) => fileData.attributes.AnchorText === content.anchor)
                        .map((fileData) => {
                          return (
                            <>
                              <span className="text-orange">
                                <a
                                  href={`${API}${fileData.attributes.File.data.attributes.url}`}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  {fileData.attributes.AnchorText}
                                </a>
                              </span>
                            </>
                          );
                        })} */}
                      <span className="text-orange" key={content.anchor}>
                        <a href={`${API}${content.link}`} rel="noreferrer" target="_blank">
                          {content.anchor}
                        </a>
                      </span>
                    </>
                  );
                })}
              </Details>
            </section>
            <section>
              {IRPStakeHolderMeetings.contents.map((content) => {
                return (
                  <>
                    <H2 className="text-blue mt-10">{content.title}</H2>
                    <Details>{content.description}</Details>
                  </>
                );
              })}
            </section>
            <section className="my-10 tablet:my-20">
              {Meeting.meetingGroup1.map((content) => {
                return (
                  <>
                    <div className="text-center">
                      <H2 className="text-blue mt-14">{content.meeting}</H2>
                      <H2 className="text-blue">{content.date}</H2>
                    </div>
                    <Table className="flex justify-center tabletWide:px-32 laptop:px-48">
                      <tr>
                        {content.th.map((heading) => {
                          return (
                            <TD className="text-left" key={heading} rowType="heading">
                              {heading}
                            </TD>
                          );
                        })}
                      </tr>
                      {content.tr.map((row) => {
                        return (
                          <>
                            <tr>
                              {row.td.map((data) => {
                                return (
                                  <>
                                    <TD>{data.presentation}</TD>
                                    <TD>{data.topic}</TD>
                                    <TD>
                                      {data.pdfLink === '' && (
                                        <span className="text-black">{data.pdf}</span>
                                      )}
                                      {data.pdfLink !== '' && (
                                        <a
                                          className="text-orange"
                                          href={data.pdfLink}
                                          target="blank"
                                        >
                                          <u>{data.pdf}</u>
                                        </a>
                                      )}
                                    </TD>
                                    <TD>
                                      {data.videoLink === '' && (
                                        <span className="text-black">{data.video}</span>
                                      )}
                                      {data.videoLink !== '' && (
                                        <a
                                          className="text-orange"
                                          href={data.videoLink}
                                          target="blank"
                                        >
                                          <u>{data.video}</u>
                                        </a>
                                      )}
                                    </TD>
                                  </>
                                );
                              })}
                            </tr>
                          </>
                        );
                      })}
                      <Details className="text-left">{content.note}</Details>
                    </Table>
                  </>
                );
              })}
              {Meeting.meetingGroup2.map((content) => {
                return (
                  <>
                    <div className="text-center flex flex-col justify-center">
                      <H2 className="text-blue mt-14 tabletWide:w-3/4 laptop:w-2/3 desktop:w-1/3 self-center">
                        {content.meeting}
                      </H2>
                      <H2 className="text-blue">{content.date}</H2>
                    </div>
                    <Table className="flex justify-center tabletWide:px-32 laptop:px-48">
                      <tr>
                        {content.th.map((heading) => {
                          return (
                            <TD className="text-left" key={heading} rowType="heading">
                              {heading}
                            </TD>
                          );
                        })}
                      </tr>
                      {content.tr.map((row) => {
                        return (
                          <>
                            <tr>
                              {row.td.map((data) => {
                                return (
                                  <>
                                    <TD>{data.meetingNumber}</TD>
                                    <TD>{data.date}</TD>
                                    <TD>{data.time}</TD>
                                    <TD>{data.topics}</TD>
                                  </>
                                );
                              })}
                            </tr>
                          </>
                        );
                      })}
                      <Details className="text-left text-blue">{content.note}</Details>
                    </Table>
                  </>
                );
              })}
            </section>
            <section className="mt-10 tablet:mt-20">
              <H1>{IRPLinks.title}</H1>
              <UnorderedList>
                {IRPLinks.ul.map((value) => {
                  return (
                    <List key={value.p1}>
                      {value.p1}
                      <a className="text-orange" href={value.link1} target="blank">
                        {value.anchor1}
                      </a>
                      {value.p2}
                      <a className="text-orange" href={value.link2} target="blank">
                        {value.anchor2}
                      </a>
                    </List>
                  );
                })}
              </UnorderedList>
            </section>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};
